import {
    VIEW_FEATURED_PRODUCTS,
    REMOVE_FEATURED_PRODUCTS,
} from "../actions/menu_items";

export default function (state = [], action) {
    switch (action.type) {
        case VIEW_FEATURED_PRODUCTS: {
            return action.payload;
        }
        case REMOVE_FEATURED_PRODUCTS: {
            return [];
        }
    }
    return state;
}
