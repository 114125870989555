export const INSERT_TICKET = "INSERT_TICKET";
export const CLEAR_TICKET = "CLEAR_TICKET";
export const INSERT_TICKET_TO_ORDER = "INSERT_TICKET_TO_ORDER";
export const INSERT_TICKET_LINES_TO_ORDER_LINES = "INSERT_TICKET_LINES_TO_ORDER_LINES";

export function insertTicket(results) {
  return {
    type: INSERT_TICKET,
    payload: results
  };
}
export function clearTicket() {
  return {
    type: CLEAR_TICKET
  };
}
export function insertTicketToOrder(results) {
  return {
    type: INSERT_TICKET_TO_ORDER,
    payload: results
  };
}
export function insertTicketLinesToOrderLines(result,product_data) {
  let payload1 = {
    reOrder_item: result,
    product_data: product_data
  }
  return {
    type: INSERT_TICKET_LINES_TO_ORDER_LINES,
    payload: payload1
  };
}
