
export const ADD_REWARD = 'ADD_REWARD';
export const ADD_REWARD_CARD_OBJECT = 'ADD_REWARD_CARD_OBJECT';
export const APPLY_REWARD = 'APPLY_REWARD';
export function addReward(reward) {
       
    
    return {
        type: ADD_REWARD,
        payload: reward
    };
}

export function addRewardCardObject(reward) {
    
    return {
        type: ADD_REWARD_CARD_OBJECT,
        payload: reward
    };
}

export function applyReward(reward) {
       
    return {
        type: APPLY_REWARD,
        payload: reward
    };
}