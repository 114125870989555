
export const ADD_USER_CARD_DETAILS = 'ADD_USER_CARD_DETAILS';
export const REMOVE_USER_CARD_DETAILS = 'REMOVE_USER_CARD_DETAILS';

export function addUserCardDetails(results) {
    return {
        type: ADD_USER_CARD_DETAILS,
        payload: results
    };
}

export function removeUserCardDetails() {
    return {
        type: REMOVE_USER_CARD_DETAILS, 
    };
}

