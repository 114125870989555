import axios from 'axios';


  export function getRewardCard(phone) {
    return new Promise(async (resolve, reject) => {
      let payload = { phone: phone };
      let token =localStorage.getItem('token')
      let tenant = localStorage.getItem("tenant")
      axios.defaults.timeout = 60000
      axios
        .post(process.env.REACT_APP_ACCESS_KEY + "/" + tenant + "/api/v1/rewardcard", payload, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer" + token,

          },
        })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }