import axios from "axios";

export function postOrder(payload1) {
    return new Promise(async (resolve, reject) => {
        let token = localStorage.getItem("token");
        var payload = JSON.parse(JSON.stringify(payload1));
        for (var x in payload.lines) {
            if (payload.lines[x]["extra_item"] == 1 || payload.lines[x]['gift_card'] == 1) {
                payload.lines[x]["product_id"] = null;
            }
            if (payload.lines[x]["for_here"]) {
                payload.lines[x]["for_here"] = 1;
            } else {
                payload.lines[x]["for_here"] = 0;
            }
            // Have to change key names as per server key names
            if (payload.lines[x].modifier_set.length > 0) {
                payload.lines[x].modifier_set.map((single) => {
                    single.modifiers_set_name = single.name; // key change from name to modifiers_set_name
                    single.modifiers_set_only_one = single.only_one; // key change from only_one to modifiers_set_only_one
                    single.modifiers_set_server_id = single.server_id; // key change from server_id to modifiers_set_server_id
                    delete single.name;
                    delete single.only_one;
                    delete single.server_id;
                });
            }
            if ("applied_coupon" in payload.lines[x]) {
                if (payload.lines[x].applied_coupon.campaign_coupon_id == 0.1) {
                    payload.lines[x].applied_coupon.campaign_coupon_id = "";
                }
            }
            if (payload.lines[x]["taxes"].length > 0) {
            } else if (Object.keys(payload.lines[x]["taxes"]).length > 0) {
                const objArray = [];
                Object.keys(payload.lines[x]["taxes"]).map((tax) => {
                    var ob_tax = {
                        id: payload.lines[x]["taxes"][tax].tax_server_id,
                        name: payload.lines[x]["taxes"][tax].name,
                        type: payload.lines[x]["taxes"][tax].type,
                        percentage: payload.lines[x]["taxes"][tax].percentage,
                    };
                    objArray.push(ob_tax);
                });
                payload.lines[x].taxes = objArray;
            } else if (
                Object.keys(payload.lines[x]["taxes"]).length === 0 ||
                Object.keys(payload.lines[x]["taxes"]).length == 0
            ) {
                payload.lines[x].taxes = [];
            }
        }
        let tenant = localStorage.getItem("tenant")
        axios
            .post(
                process.env.REACT_APP_ACCESS_KEY +
                    "/" +
                    tenant +
                    "/api/v1/create-online-order",
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer" + token,
                    },
                }
            )
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function orderHistory(payload) {

    return new Promise(async (resolve, reject) => {
        let token = localStorage.getItem("token");
        let tenant = localStorage.getItem("tenant")

      axios.defaults.timeout = 60000
      axios
        .post(process.env.REACT_APP_ACCESS_KEY +  "/" +
        tenant +"/api/v1/get-user-online-order-history", payload, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + token,

            },
        })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  export function getReoderProductDetails(payload) {

    return new Promise(async (resolve, reject) => {
        let token = localStorage.getItem("token");
        let tenant = localStorage.getItem("tenant")

      axios.defaults.timeout = 60000
      axios
        .post(process.env.REACT_APP_ACCESS_KEY +  "/" +
        tenant +"/api/v1/get-prod-details-for-reorder", payload, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + token,

            },
        })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  export function checkGiftCardNumber(payload) {

    return new Promise(async (resolve, reject) => {
        let token = localStorage.getItem("token");
        let tenant = localStorage.getItem("tenant")
        let payload1 = {
            gift_card_code : payload
          }
      axios.defaults.timeout = 60000
      axios
        .post(process.env.REACT_APP_ACCESS_KEY +  "/" +
        tenant +"/api/v1/check-gift-card-uniqueness", payload1, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + token,

            },
        })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  export function applyGiftCard(payload) {

    return new Promise(async (resolve, reject) => {
        let token = localStorage.getItem("token");
        let tenant = localStorage.getItem("tenant")
        let payload1 = {
            gift_card_code : payload
          }
      axios.defaults.timeout = 60000
      axios
        .post(process.env.REACT_APP_ACCESS_KEY +  "/" +
        tenant +"/api/v1/get-gift-card-current-balance", payload1, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + token,

            },
        })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }