import _ from 'lodash';
export const INSERT_ITEM = 'INSERT_ITEM'
export const MINUS_ITEM = 'MINUS_ITEM'
export const ADD_ITEM = 'ADD_ITEM'
export const CLEAR_ITEMS = 'CLEAR_ITEMS'
export const INSERT_EXTRA_ITEM = 'INSERT_EXTRA_ITEM'
export const DISCOUNT_PERCENTAGE_ITEM = 'DISCOUNT_PERCENTAGE_ITEM'
export const REMOVE_ITEM = 'REMOVE_ITEM'
export const DELETE_ITEM = 'DELETE_ITEM'
export const REMOVE_DISCOUNT_PERCENTAGE_ITEM = 'REMOVE_DISCOUNT_PERCENTAGE_ITEM'
export const REMOVE_ITEM_COUPON = 'REMOVE_ITEM_COUPON'
export const UPDATE_ITEM_MODIFIERS = 'UPDATE_ITEM_MODIFIERS'
export const UPDATE_ITEM_QTY = 'UPDATE_ITEM_QTY'
export const UPDATE_IS_GO = 'UPDATE_IS_GO'
export const ALL_ITEM_FOR_HERE = 'ALL_ITEM_FOR_HERE'

export function insertItem(item, quantity, current_order,order_for_here) {
  if (current_order === null || current_order === undefined || current_order === '') {
    var current_date =
      new Date().valueOf().toString() + '' + (Math.floor(Math.random() * (999 - 100)) + 101)
    localStorage.setItem('current_order', current_date)
  }
  let payload = { item: item, quantity: quantity }

  return function(dispatch, getState) {
    // eslint-disable-next-line
    if(payload.item != null && payload.item != undefined && payload.item!= ''){
      const appState = getState()
      let item_id = payload.item.server_id

      let itemAlreadyExists = _.findIndex(appState.order_items, function(o) {
        // eslint-disable-next-line
        return o.server_id == item_id
      })
      // return appState.order_items[itemAlreadyExists];
      var stateProduct = appState.order_items[itemAlreadyExists]
      if (itemAlreadyExists <= -1) {
        //no product in order
         stateProduct = {
          product_id: payload.item.server_id,
          quantity: payload.quantity,
          name: payload.item.name,
          product_description: payload.item.product_description,
          description: payload.item.description,
          // eslint-disable-next-line
          regular_price: (null != payload.item.online_ordering_price && undefined != payload.item.online_ordering_price && "" != payload.item.online_ordering_price) ? payload.item.online_ordering_price : payload.item.sale_price,
          // eslint-disable-next-line
          sale_price: (null != payload.item.online_ordering_price && undefined != payload.item.online_ordering_price && "" != payload.item.online_ordering_price) ? payload.item.online_ordering_price : payload.item.sale_price,
          tax_percentage: payload.item.tax_percentage,
          tax_amount: payload.item.tax_amount,
          tax_collected: 0,
          ebt_tax: 0,
          reward_points: payload.item.reward_points,
          extra_item: 0,
          server_id: payload.item.server_id,
          barcode: payload.item.barcode,
          sold_by_weight: payload.item.sold_by_weight,
          prompt_qty: payload.item.prompt_qty,
          exclude_ebt: payload.item.exclude_ebt,
          discount_percentage: 0,
          total_discount: 0,
          discount_reason: '',
          taxes: payload.item.taxes,
          for_here: order_for_here,
        }
      } 
      // else {
      //   //product found
      //   var stateProduct = appState.order_items[itemAlreadyExists]
      // }
      dispatch({
        type: INSERT_ITEM,
        payload: {
          item: item,
          quantity: quantity,
          stateProduct: stateProduct,
          state: appState,
          itemAlreadyExists: itemAlreadyExists,
        },
      })
    }
  }
}

export function minusItem(item, quantity = 1) {
  let payload = { item: item, quantity: quantity }

  return {
    type: MINUS_ITEM,
    payload: payload,
  }
}

export function updateItemQty(item, quantity, description) {
  let payload = { item: item, quantity: quantity, description: description }

  return {
    type: UPDATE_ITEM_QTY,
    payload: payload,
  }
}

export function deleteItem(item) {
  let payload = { item: item }

  return {
    type: DELETE_ITEM,
    payload: payload,
  }
}

export function discountPercentageItem(item, discount) {
  let payload = { item: item, discount: discount }

  return {
    type: DISCOUNT_PERCENTAGE_ITEM,
    payload: payload,
  }
}

export function removeDiscountPercentageItem(item, percentage) {
  let payload = { item: item, percentage: percentage }

  return {
    type: REMOVE_DISCOUNT_PERCENTAGE_ITEM,
    payload: payload,
  }
}

export function removeItem(item) {
  let payload = { item: item }

  return {
    type: REMOVE_ITEM,
    payload: payload,
  }
}

export function addItem(item, quantity = 1) {
  let payload = { item: item, quantity: quantity }

  return {
    type: ADD_ITEM,
    payload: payload,
  }
}

export function clearItems() {
  localStorage.removeItem('current_order')
  return {
    type: CLEAR_ITEMS,
  }
}

export function addExtraItem(item,for_here) {
  localStorage.getItem('current_order', (order) => {
    if (order === null) {
      var current_date =
        new Date().valueOf().toString() + '' + (Math.floor(Math.random() * (999 - 100)) + 101)
      localStorage.setItem('current_order', current_date)
    }
  })

  // async problem solved
  // if (AsyncStorage.getItem('current_order') === null) {
  //   var current_date =
  //     new Date().valueOf().toString() + '' + (Math.floor(Math.random() * (999 - 100)) + 101)
  //   AsyncStorage.setItem('current_order', current_date)
  // }

  let payload = {
    item: item,
    for_here:for_here
  }
  return {
    type: INSERT_EXTRA_ITEM,
    payload: payload,
  }
}

export function removeItemCoupon(item, percentage, applied_coupon) {
  let payload = {
    item: item,
    percentage: percentage,
    applied_coupon: applied_coupon,
  }
  return {
    type: REMOVE_ITEM_COUPON,
    payload: payload,
  }
}

export function updateSelectedModifiers(item) {
  let payload = {
    item: item,
  }
  return {
    type: UPDATE_ITEM_MODIFIERS,
    payload: payload,
  }
}

export function updateIsGo(item,to_go) {
  let payload = {
    item: item,
    for_here:to_go
  }
  return {
    type: UPDATE_IS_GO,
    payload: payload,
  }
}

export function allItemForHere(allItem,for_here) {
  let payload = {
    order_Line: allItem,
    for_here:for_here
  }
  return {
    type: ALL_ITEM_FOR_HERE,
    payload: payload,
  }
}
