import {
  ADD_CURRENT_DELIVERY_PICKUP,
  REMOVE_CURRENT_DELIVERY_PICKUP,
  UPDATE_ORDER_TYPE,
  UPDATE_SCHEDULE_TIME,
} from "../actions/delivery_pickup_action";

export default function (state = [], action) {
  switch (action.type) {
    case ADD_CURRENT_DELIVERY_PICKUP: {
      return action.payload;
    }
    case REMOVE_CURRENT_DELIVERY_PICKUP: {
      return [];
    }
    case UPDATE_ORDER_TYPE: {
      return action.payload;
    }
    case UPDATE_SCHEDULE_TIME: {
      return action.payload;
    }
  }

  return state;
}
