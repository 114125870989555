import React from "react";
import { withStyles } from "@material-ui/styles";
import {
  currentItem,
  removeCurrentItem,
} from "../../../../actions/current_item";
import { insertItem } from "../../../../actions/order_items";
import { orderCalculation } from "../../../../actions/order_actions";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { Paper, Button, TextField, Grid, Divider } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import * as Constant from "../../../../constants";
import { userLogin } from "../../../../actions/user_actions";
import { forgotPassword } from "../../../../services/user/user";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";

import "react-toastify/dist/ReactToastify.css";
const useStyles = (theme) => ({
  flexLayout: {
    flex: 10,
    flexDirection: "row",
    alignItems: "stretch",
    overflow: "hidden",
    padding: 13,
    marginBottom: 8,
    backgroundColor: "#fff",
    overflowY: "scroll",
  },
  buttonLayout: {
    display: "flex",
    flex: 0.6,
    padding: 8,
    justifyContent: "center",
    // alignItems:"center",
    backgroundColor: "#fff",
  },
});

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView();

  return { executeScroll, elRef };
};

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      item_qty: 1,
      email: "",
      password: "",
      loading: false,
      errors: {
        email: "",
        password: "",
      },
    };
    this.elScroll = utilizeScroll();
  }
  componentDidMount() {
  }

  componentWillUnmount() {
      this.setState = (state,callback)=>{
        return;
    };
  }

  handleChange = (panel) => (event, isExpanded) => {
    isExpanded
      ? this.setState({ expanded: panel })
      : this.setState({ expanded: false });
  };

  ForgotPassword = () => {
    this.elScroll.executeScroll()
    this.setState({loading:true})
    if (
      null != this.state.email &&
      undefined != this.state.email &&
      "" != this.state.email.trim() && null != localStorage.getItem("tenant")
      && ''!=localStorage.getItem("tenant")
    ) {
      let payload = {
        email: this.state.email,
        tenant: localStorage.getItem("tenant"),
        image: this.props.delivery_pickup[0].location.image_url
      };
      forgotPassword(payload)
        .then((res) => {
          if (res.data.error) {
            if (res.data.error.code === 404) {
              // const path = "/" + localStorage.getItem("tenant") + "/404";
              this.props.history.push('/404');
            }
          } else if (res.data.flag) {
            toast.success(res.data.message);
            this.goBack("signIn");
            this.setState({loading:false})
          } else {
            this.setState({loading:false})
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err.toString());
          this.setState({loading:false})
        });
    }
  };

  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "email":
        errors.email =
          value.length === 0
            ? Constant.USER_EMAIL_REQUIRED
            : validEmailRegex.test(value)
            ? value.length < 255 
            ? ""
            : Constant.USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER
            : Constant.USER_EMAIL_NOT_VALID;
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };

  goBack = (type) => {
    this.props.checkOutInsidePage(type);
  };

  render() {
    const { classes } = this.props;
    return (
      <>
      <div ref={this.elScroll.elRef}></div>
        {this.state.loading ? <LinearProgress color="primary" /> : null}
        <Paper elevation={1} className={classes.flexLayout}>
          <Grid
            container
            direction="row"
            // justifyContent="center"
            alignItems="center"
          >
            <Grid xs={2} md={2} lg={2} item>
              <Link to="#" className="text-dark font-bold">
                <ArrowBackIcon onClick={() => this.goBack("signIn")} />
              </Link>
            </Grid>
            <Grid xs={8} md={8} lg={8} item>
              <h1 className="sidebar-layout-header-name">
                {"Forgot Password"}
              </h1>
            </Grid>
          </Grid>
          <TextField
            autoFocus
            style={{ margin: "2%", borderRadius: 0, width: "96%" }}
            error={this.state.errors.email.length == 0 ? false : true}
            fullWidth
            size="small"
            helperText={this.state.errors.email}
            label="Email address *"
            name="email"
            onChange={this.handleInputChange}
            onBlur={this.handleInputChange}
            onKeyPress= {(e) => {
              if (e.key === 'Enter' && this.state.email !== "" &&
              this.state.errors.email.length === 0 ) {
                this.ForgotPassword()
              }
            }}
            type="email"
            value={this.state.email}
            variant="outlined"
          />
          <p className="font-semibold text-muted text-right lh-1">
            <Link
              to="#"
              onClick={() => this.goBack("signIn")}
              className="text-dark font-bold"
            >
              Sign In?&nbsp;&nbsp;&nbsp;&nbsp;
            </Link>
          </p>
        </Paper>
        <Paper elevation={1} className={classes.buttonLayout}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "100%", height: "93%" }}
            onClick={() => this.ForgotPassword()}
            disabled={
              this.state.email == "" || this.state.errors.email.length > 0
            }
          >
            Continue
          </Button>
        </Paper>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { currentItem, removeCurrentItem, orderCalculation, insertItem, userLogin },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    current_item: state.current_item,
    order: state.order,
    order_items: state.order_items,
    delivery_pickup: state.delivery_pickup
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true })
)(ForgotPassword);
