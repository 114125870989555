import axios from 'axios';

export function getAllLocations(date,day) {
  let payload = {
    todays_date : date,
    todays_day : day,
  }
    return new Promise(async (resolve, reject) => {
      let route_name = window.location.pathname.substring(window.location.pathname.substring(1).indexOf("/")+1)
      let route=window.location.pathname.split( '/' )
      let lastElement = route.slice(-1);
      if(route_name !== "/products" && route_name !== "/rewardcard" && route_name !== "/api/v1/create-online-order" && route_name !== "/get-user-online-order-history") {
        axios
        .post(process.env.REACT_APP_ACCESS_KEY + "/" + lastElement[0] + "/get-location-details", payload,{
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
      } else {
        reject("error")
      }
      
    })
  }