import { CURRENT_ITEMS,REMOVE_CURRENT_ITEM } from '../actions/current_item';

export default function (state = [], action) {
    switch (action.type){
    case CURRENT_ITEMS:
        {
            return  action.payload;
        }
    case REMOVE_CURRENT_ITEM:
        {
                return []
        }
    }
    
    return state;
}