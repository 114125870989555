import { REQUEST_WEIGHT, RECEIVED_WEIGHT, CLEAR_WEIGHT } from '../actions/weight_actions'

export default function(
  state = {
    weight_requested: false,
    product: null,
    item: null,
    weight: null,
    received: false,
  },
  action
) {
  switch (action.type) {
    case REQUEST_WEIGHT: {
      let newWeightState = {
        weight_requested: true,
        product: action.payload.product,
        item: action.payload.item,
        weight: null,
        received: false,
      }
      return newWeightState
    }
    case RECEIVED_WEIGHT: {
      let newWeightState = {
        weight_requested: true,
        product: state.product,
        item: state.item,
        weight: action.payload,
        received: true,
      }
      return newWeightState
    }
    case CLEAR_WEIGHT: {
      let newWeightState = {
        weight_requested: false,
        product: null,
        item: null,
        weight: null,
        received: false,
      }
      return newWeightState
    }
  }
  return state
}
