export const SCAN_COUPON = 'SCAN_COUPON'
export const CANCLE_COUPON = 'CANCLE_COUPON'
export const DELETE_ORDER_COUPON = 'DELETE_ORDER_COUPON'
export const CLEAR_COUPON = 'CLEAR_COUPON'
export const APPLY_COUPON = 'APPLY_COUPON'
export const TICKET_COUPON_STATUS = 'TICKET_COUPON_STATUS'
export const TICKET_ORDER_COUPON_APPLY = 'TICKET_ORDER_COUPON_APPLY'
export const TICKET_ORDER_ITEMS_COUPON_APPLY = 'TICKET_ORDER_ITEMS_COUPON_APPLY'

export function scan_coupon(response) {
  return function(dispatch, getState) {
    const appState = getState()
    dispatch({
      type: SCAN_COUPON,
      payload: { response, appState },
    })
  }
}

export function cancleCoupon() {
  return {
    type: CANCLE_COUPON,
    payload: {},
  }
}

export function deleteOrderCoupon() {
  return {
    type: DELETE_ORDER_COUPON,
    payload: {},
  }
}

export function clearCoupon(payload) {
  return {
    type: CLEAR_COUPON,
    payload: payload,
  }
}

export function applyCoupon(payload) {
  return {
    type: APPLY_COUPON,
    payload: payload,
  }
}

export function ticketCouponStatus(results) {
  return {
    type: TICKET_COUPON_STATUS,
    payload: results,
  }
}

export function ticketOrderCouponApply(results) {
  return {
    type: TICKET_ORDER_COUPON_APPLY,
    payload: results,
  }
}

export function ticketOrderItemsCouponApply(results) {
  return {
    type: TICKET_ORDER_ITEMS_COUPON_APPLY,
    payload: results,
  }
}
