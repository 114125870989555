import axios from 'axios';

export function userRegistration(payload) {
    return new Promise(async (resolve, reject) => {
      let tenant = localStorage.getItem("tenant")
      axios.defaults.timeout = 60000
      axios
        .post(process.env.REACT_APP_ACCESS_KEY +'/'+tenant+ "/register", payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }


export function userSignIn(payload) {
  return new Promise(async (resolve, reject) => {
    let tenant = localStorage.getItem("tenant")
    axios.defaults.timeout = 60000
    axios
      .post(process.env.REACT_APP_ACCESS_KEY +'/'+tenant+ "/login", payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}


export function forgotPassword(payload) {
  return new Promise(async (resolve, reject) => {
    let tenant = localStorage.getItem("tenant")
    axios.defaults.timeout = 60000
    axios
      .post(process.env.REACT_APP_ACCESS_KEY +'/'+ tenant +"/forgot-password",payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export function confirmPassword(payload) {
  return new Promise(async (resolve, reject) => {
    let tenant = localStorage.getItem("tenant")
    axios.defaults.timeout = 60000
    axios
      .post(process.env.REACT_APP_ACCESS_KEY +'/'+ tenant + "/reset-password",payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export function deleteCard(payload) {
  return new Promise(async (resolve, reject) => {
    let tenant = localStorage.getItem("tenant")
    let token = localStorage.getItem("token");

    axios.defaults.timeout = 60000
    axios
    .post(process.env.REACT_APP_ACCESS_KEY +  "/" +
    tenant +"/api/v1/delete-user-card-detail", payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Bearer" + token,
        },
      })
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}