export const DB_NAME = 'octopos.db'

export const DB_ERROR = 'Error occured with database.'

export const COUPON_ALREADY_APPLIED = 'Coupon is already applied.'

export const COUPON_PRODUCT_ELIGIBLE_ORDER_AMOUNT_LESS =
  'After applying this coupon minimum order total is less.'

export const MANUAL_DISCOUNT_ALREADY_APPLIED = 'Manual discount is already applied.'

export const COUPON_MAX_USAGE = 'Coupon is already used maximum time.'

export const ORDER_MINIMUM_AMOUNT_LESS_PRODUCT_ELIGIBLE_CHECK =
  'Minimum total is less than coupon minimum total.'

export const ORDER_MINIMUM_AMOUNT_LESS = 'Minimum total is less than coupon minimum total.'

export const REWARD_CARD_NOT_MATCHED_WITH_COUPON =
  'Sorry, This reward card does not belong to entered coupon. Please try again.'

export const COUPONS_NOT_MATCHED_WITH_ORDER_ITEMS = 'Sorry, No coupons are match with this order.'

export const COUPON_SCAN_ORDER_EMPTY = 'Sorry, You can not add coupon as order is empty.'

export const COUPON_NOT_MATCHED_WITH_REWARD_CARD =
  'Sorry, This coupon does not belong to entered reward card. Please try again.'

export const ENTER_VALID_BARCODE = 'Please enter a valid barcode.'

export const ENTER_VALID_LOCAL_ID = 'Please enter a valid local ID / Order No.'

export const ENTER_LOCAL_ID = 'Please enter local ID / Order No.'

export const COUPON_SUSSESS_APPLIED = 'Coupons are successfully applied.'

export const VALI_BANKDROP = 'Please enter amount greater than 0.00 or less than equal to 999999.99.'

export const VALID_PIN = 'Please enter a valid pin.'

export const VALID_CLOSEING_AMOUNT = 'Please enter amount greater than 0.00 or less than equal to 999999.99.'

export const VALID_PAYIN_AMOUNT = 'Please enter amount greater than 0.00 or less than equal to 999999.99.'

export const VALID_PAYOUT_AMOUNT = 'Please enter amount greater than 0.00 or less than equal to 999999.99.'

export const VALID_STARTING_AMOUNT = 'Please enter amount greater than 0.00 or less than equal to 999999.99.'

export const COUPON_MESSAGE1 = 'If barcode starts with C, system will consider'

export const COUPON_MESSAGE2 = ' it as coupon barcode.'

export const VALID_DISCOUNT_PERCENTAGE = 'Please enter a valid percentage.'

export const ENTER_VALID_PHONE_NUMBER = 'Please enter a valid phone number.'

export const ENTER_EMPTY_EMAIL_ID = 'Please enter an email.'

export const ENTER_VALID_EMAIL_ID = 'Please enter a valid email.'

export const REWARD_REDEEMED = 'REWARD REDEEMED.'

export const SOLD_BY_WEIGHT_GREATER_005_LESS_9999999 =
  'Please enter weight greater than 0.00 or less than or equal to 999999.99.'

export const ENTER_VALID_WEIGHT =
  'Please enter a valid weight.'

export const TOFIXED5 = 100000

export const TimeDuration= 2000

export const LOADING_ERROR_SYNC="Sorry,you can not press back button while sync"

export const LOADING_ERROR='Sorry,you can not press back button while login'

export const NO_PRODUCT = 'No product found.'

export const BANKDROP_SUCCESS = 'Bank Drop done successfully.'

export const CLOCKIN_SUCCESS = 'User Clocked In successfully.'

export const CLOCKOUT_SUCCESS = 'User Clocked Out successfully.'

export const TILL_OPEN_SUCCESS = 'Till opened successfully.'

export const PRINT_NETWORK_FAIL = 'Unable to communicate with Printer.'

export const PAYIN_SUCCESS = 'Pay In created successfully.'

export const PAYOUT_SUCCESS = 'Pay Out created successfully.'

export const VALID_PAYIN_REASON = 'Pay In reason may not be greater than 255 characters.'

export const VALID_PAYOUT_REASON = 'Pay Out reason may not be greater than 255 characters.'

export const VALID_FIRST_NAME_REASON = 'First Name may not be greater than 255 characters.'

export const VALID_LAST_NAME_REASON = 'Last Name may not be greater than 255 characters.'

export const DRAWER_OPENED = 'Drawer Opened.'

export const SELECT_ORDER_DISCOUNT_PER = 'Please select discount percentage.'

export const DISCOUNT_AMOUNT_GREATER_THAN_TAX =
  "Discount Amount can't be greater than Order Pre Tax Total."

export const ONLY_ONE_ORDER_DISCOUNT = 'You can only add one Order discount per Order.'

export const VALID_ORDER_DISCOUNT_REASON = 'Please enter a valid discount reason.'

export const VALID_ORDER_DISCOUNT_AND_REASON =
  'Please enter a valid discount percentage and reason.'

export const VALID_ORDER_DISCOUNT_AND_REASON_AMOUNT =
  'Please enter a valid discount amount and reason.'

export const ENTER_VALID_DISCOUNT_AMOUNT = 'Please enter a valid discount amount.'

export const ENTER_VALID_DISCOUNT_PERCENTAGE = 'Please enter a valid discount percentage.'

export const VALID_ORDER_REFUND_AMOUNT = 'Please enter a valid refund amount.'

export const VALID_ORDER_TAX_REFUND_AMOUNT = 'Please enter a valid tax refund amount.'

export const CAN_NOT_REFUND_MORE_THAN_ORDER_AMOUNT = "Can't refund more than sale amount."

export const VALID_EMAIL = 'Please enter a valid email.'

export const VALID_PASSWOOD = 'Please enter a valid password.'

export const VALID_LOCATION_URL = 'Please enter a valid location URL.'

export const VALID_STATION_ID = 'Please select a valid station.'

export const VALID_LOCATION_ID = 'Please select a valid location.'

export const VALIDATED_SUCCESS_WITH_SERVER = 'Information validated with server.'

export const VALID_EXTRA_ITEM = 'Please enter valid name and sale price of the item.'

export const ORDER_PROCESSED = 'Order processed.'

export const ORDER_BACKEND_ERROR = 'Payment is processed however order is not registered on server!'

export const COUPON_APPLIED = 'COUPON APPLIED'

export const UNABLE_TO_REACH_PRINTER = 'Unable to communicate with Printer.'

export const UNABLE_TO_REACH_PRINTER_TO_OPEN_DRAWER = 'Unable to communicate with Printer to open Drawer.'

export const VALID_EXTRA_ITEM_NAME = 'Please enter a valid item name.'

export const VALID_EXTRA_ITEM_SALE_PRICE = 'Please enter a valid sale price.'

export const VALID_EXTRA_ITEM_SALEPRISE_GREATER = 'Sale price must be less than or equal to 999999.99.'

export const UNABLE_TO_CONNECT_WITH_PINPAD = 'Unable to connect with pinpad!'

export const USER_CANCLED_ENTRY = 'User cancelled the entry.'

export const CANT_ADD_REWARDCARD = "Can't add reward card as there is no order in progress."

export const VALID_ITEM_DISCOUNT100 = 'Discount percentage must be less than or equals to 100%.'

export const REWARD_CREATE_SUCCESS = 'Reward card created successfully.'

export const COUPON_REMOVE_SUCCESS = 'All coupons are successfully removed.'

export const PAYMENT_STARTED = 'Sorry, You can not change order as payment process is started.'

export const VALID_REASON = 'Please enter valid reason.'

export const PRINT_HEADER1 = 'OctoPos'

export const PRINT_HEADER2 = 'www.octopos.com'

export const PRINT_FOOTER1 = 'Thank You For Using '

export const PRINT_FOOTER2 = 'OctoPos'

export const CREATE_REWARD = 'Card created successfully and has been added to this order.'

export const APPROVED = 'APPROVED'

export const SUCCESS = 'Successfully connected to CDS.'

export const TransactionApproved_NoSignatureCollected = 'TransactionApproved_NoSignatureCollected'

export const CANCEL_TRANSACTION='Cancel Transaction';

export const EBT = 'EBT'

export const WRONG_EBT_CARD = 'Sorry you have entered wrong card. Please enter ebt card.'


export const EBT_ORDERITEM_BALANCE_ZERO =
  "EBT eligible item total for this order is $0. Can't accept EBT."

export const EBT_TOTAl_LESSTHEN_ORDERDISCOUNT =
  'Sorry, Discount is applied so you can not proceed with EBT payment.'

export const MESSAGE_HEADER_NOTICE = 'Notice'

export const EBT_CASH = 'EBT CASH'

export const PAYMENT_PROCESSING = 'PAYMENT PROCESSING'

export const PAYMENT_PROCESSING_MESSAGE = 'Please wait while transaction is in process...'

export const EPSON = 'EPSON'

export const STAR = 'STAR'

export const CONTACT_ADMINISTRATOR = 'Please contact to system administrator.'

export const REFUND_NOT_MORE_THEN_TOTALTAX =
  "Sorry, Tax amount is more than actual order tax amount. Please change it and try again."

//export const REFUND_NOT_MORE_THEN_TOTALTAX = "Refund cannot be more than total tax amount of order."

export const REFUND_AMOUNT_NOT_MORETHEN_CARD_REFUNDED_AMOUNT =
  'Sorry, Refund total is more than card payment. Please change it and try again.'

//export const REFUND_AMOUNT_NOT_MORETHEN_CARD = "Refund amount not greater then card payment.";

export const REFUND_DIALOGUE_TITLE = 'Amount Refunded Successfully'

export const REFUND_ISSUE = 'DO NOT CLOSE. Issuing Refund.' //Not in use

export const CASH = 'CASH'

export const CARD = 'CARD'

//export const CARD_REFUND =  "System has refunded to the customer: $";

export const CASH_REFUND = 'Please refund $'

export const CASH_REFUND_AFTER = ' as in cash to customer. Thank You.'

export const REFUNDED_CASH_AMOUNT = 'Customer has been refunded: $'

export const REFUND_BACKEND_ERROR =
  'Customer has been refunded to the card, however refund was not registered on our server for account purposes.'

export const REFUND_MORE_THEN_CARD_PAYMENT =
  'Error occured while trying to process the refund. Most likely refund amount is more than what was charged on that card.'

export const NOT_ATTACHED_CREDIT_CARD =
  'There is no attached Credit Card for this order. Please refund using cash.'

export const MULTIPLE_CARD_PAYMENT =
  'Multiple credit card payment done. Please select cash method to continue.'

export const REWARD_CARD_ADD_ORDER = 'Reward card added to this order.'

export const UNABLE_TO_REACH_SERVER = 'Error: Unable to reach Server.'

export const ENTER_REWARD = 'Enter Reward Card Number'

export const ENTER_REWARDCARD_NUMBER = 'Please enter reward card number'

export const INPUT = 'Please enter your '

export const SCALE_ERROR = 'Scale Error!'

export const MANUAL_WEIGHT = 'manual_weight'

export const CLEAR_ALL_ITEMS = 'Are you sure you want to clear all items?'

export const REMOVE_ORDER_DISCOUNT = 'Order discount is removed.'

export const REMOVE_ORDER_COUPON = 'Order coupon is removed.'

export const OPEN_CASH_DRAWER = 'Open Cash Drawer'

export const ADD_EXTRA_ITEM = 'Add Extra Item'

export const ADD_ORDER_DISCOUNT = 'Add Order Discount'

export const REMOVE_MANUAL_DISCOUNT = 'Remove Order Manual Discount'

export const REMOVE_ORDER_COUPON_TITLE = 'Remove Order Coupon'

export const REMOVE_ALL_COUPON = 'Clear All Coupons'

export const CLEAR_ALL_ITEMS_TTTLE = 'Clear All Items'

export const ORDER_DISCOUNT = 'Order Discount'

export const DISCOUNT_PERCENTAGE = 'discount_percentage'

export const REASON = 'Reason'

export const EXTRA_ITEM = 'Extra Item'

export const TILL_CLOSE_DIFFERENCE = 'Till closed successfully. Difference is $ '

export const CLOSING_AMOUNT = 'Closing Amount'

export const STARTING_AMOUNT = 'Starting Amount'

export const ENTER_AMOUNT = 'Enter Amount in $'

export const CREATE_PAY_IN = 'Create Pay In'

export const CREATE_PAY_OUT = 'Create Pay Out'

export const TILL_ERROR = 'Till is already set. Please close it first before setting new one.'

export const UNABLE_TO_CONNECT_WITH_SCALE =
  'Unable to communicate with Scale. Please enter weight manually.'

export const DENIED = 'Denied'

export const DENIED_MESSAGE = "Can't cancel while Pinpad is in Processing stage!"

export const TRANSACTION_ERROR = 'Transaction was processed but not signature was collected!'

export const SNAP = 'SNAP'

export const POS_CANCELLED = 'POSCancelled'

export const POS_CANCELLED_MESSAGE = 'Transaction was cancelled from POS.'

export const USER_CANCELLED = 'UserCancelled'

export const USER_CANCELLED_MESSAGE = 'Transaction was cancelled by Customer.'

export const CAYAN_ERROR = "Payment couldn't process."


export const PAYMENT_ERROR_MESSAGE = 'Unable to Process this payment. Please try again.'

export const PAYMENT_TITLE = 'PAYMENT'

export const PAYMENT_PROCEED_FOR = 'Payment processed for $'

export const BALANCE_IS = '. Balance is $'

export const PAYMENT_APPROVED = 'Payment is over approved. Please return $'

export const CUSTOMER = ' to customer.'

export const EBT_PAYMENT_PROCESSING = 'EBT Payment processing'

export const REFRESH_COMPLETED_MESSAGE = 'Refresh is completed. Please restart the app.'

export const REFRESH_COMPLETED = 'refresh-completed'

export const REWARD_CARD_ERROR =
  'Error: Could not create new Reward Card. Most likely unable to reach the server.'

export const CREATE_REWARD_CARD = 'Create a New Reward Card'

export const ADD_REWARD_CARD = 'ADD a Reward Card'

export const ENTER_CELL_PHONE_NUMBER = 'Please enter a cell phone number.'

export const ENTER_PIN = 'Login - Please enter your employee Pin.'

export const LOGIN_ERROR = 'Please log in to start the order.'

export const PRODUCT_NOT_IN_SYSTEM = 'Product is not in system.'

export const EMPTY_ORDER_ADD_REWARD_CARD = "Can't add reward card as there is no order in progress."

export const PROCESSING = 'Processing'

export const CONNECTING_SERVER = 'Contacting server.'

export const USE_REWARD_CARD_REMOVE_ORDER_COUPON = 'To use reward you need to remove order coupon.'

export const USE_REWARD = 'Use Reward?'

export const WEIGHT_MEASURED_PRODUCT_MINUSERROR =
  'You can not minus quantity to Weight Measured Items.'

export const WEIGHT_MEASURED_PRODUCT_ADDERROR = 'You can not add quantity to Weight Measured Items.'

export const ADD_PERCENTAGE_DISCOUNT_ORDERITEM = 'Add Discount to an Item'

export const RETURN_AMOUNT_CASHBACK = 'Please return $'

export const CASHBACK_MESSAGE = ' as a cashback to customer.'

export const ENTER_BARCODE = 'Enter Barcode.'

export const CAN_NOT_CANCEL_IDLE_SCREEN = 'Cannot Cancel In Idle Screen'

export const TILL_SET_BEFORE_ORDERSTART = 'To start the order please set the till first.'

export const MORE_AMOUNT_CASH_CDS = ' as a change from cashier.'

export const MORE_AMOUNT_CASHBACK = '\nTotal CashBack = $'

export const MORE_AMOUNT_CASH = 'Total Change = $'

export const FINAL_TOTAL = 'Final Total = $'

export const CASHBACK = 'CASHBACK / CHANGE'

export const TOTAL_AMOUNT_APPROVE = 'Total Approve Amount = $'

export const PAYMENT_APPROVED_CDS = 'Payment is over approved. Please collect $'

export const CASHIER = ' from cashier.'

export const RETURN_AMOUNT_CASHBACK_CDS = 'Please collect $'

export const CASHBACK_MESSAGE_CDS = ' as a cashback from cashier.'

export const SIGNATURE_EMPTY_ERROR = 'Please do your signature in box.'

export const MANUAL_DISCOUNT_NOTMORE = 'You can not add discount more than sale price.'

export const MANUAL_DISCOUNT_MORE_THAN_ORDERTOTAL =
  'Discount amount must be less than or equal to order total amount.'

export const MAIL_SEND_SUCCSS = 'Order receipt sent successfully on your email.'

export const SIGNATURE_UPLOAD = 'Signature uploaded successfully.'

export const CREATE_TICKET = 'Create Ticket'

export const TICKET_CREATE_SUCCESS = 'Ticket is created successfully.'

export const TICKET_UPDATE_SUCCESS = 'Ticket is updated successfully.'

export const TICKET_DELETE_SUCCESS = 'Ticket is deleted successfully.'

export const VALID_TICKET_NAME = 'Please enter valid ticket name.'

export const VALID_TICKET_NAME_REASON = 'Ticket name may not be greater than 191 characters.'

export const VALID_TICKET_NOTES = 'Please enter valid ticket notes.'

export const VALID_TICKET_NOTE_REASON = 'Ticket note may not be greater than 255 characters.'

export const NET_CONNECTIONCHECK_CARD_PAYMENT =
  'We are accepting only cash payment as of now due to unavailability of internet connection. Sorry for inconvenience.'

export const NONE_MODIFIER = { name: 'None', price: 0, server_id: -1, sort: 0 }

export const SYNC_PROCESS_STRAT =
  'Order Synchronization process is started in background. Please Continue your work.'

export const SYNC_PROCESS_COMPLETE =
  'Order Synchronization process is completed successfully. All orders are successfully synchronized on server.'

export const NOTINTERNETCONNECTION_NOT_ADDREWARD =
  'We can not add reward card as of now due to unavailability of internet connection. Sorry for inconvenience.'

export const NOTINTERNETCONNECTION_NOT_CREATEREWARDREWARD =
  'We can not create reward card as of now due to unavailability of internet connection. Sorry for inconvenience.'

export const NO_ANYCOUPON_APPLY =
  'We can not add coupon as of now due to unavailability of internet connection. Sorry for inconvenience.'

export const ENTER_VALID_AMOUNT = 'Please enter a valid amount.'

export const NET_CONNECTION_SYNC_ERROR =
  "Sorry, Order synchronization process couldn't start as of now due to unavailability of internet connection. Please try later."

export const MODIFIER_UPDATE_ERROR =
  'Sorry, You can not update this item as discount is applied in this product or order. Please remove it and try again.'

export const INTERNET_CONNECTION_NOT_AVAILABLE = 'POS system was offline.'

export const SERVER_NOT_AVAILABLE =
  'Looks like server is offline. Please contact to system administrator.'

export const SOME_ORDER_NOT_SYNC =
  'Offline order sync process is completed. Please note some of order was not sync to server due to some issue so restart the sync process or contact to administrator.'

export const OTHER_CHECK = 'Other Check'

export const OTHER_CARD = 'Other Credit Card'

export const OTHER_EBT = 'Other EBT'

export const REF_NO_MORE_THAN_191 = 'Reference number may not be greater than 191 characters.'

export const EMAIL_LESSTHEN_191 = 'Email may not be greater than 191 characters.'

export const EMPTY_RADIO_RECIPT = 'Please select one of the listed option.'

export const REASON_NOT_GREATER_THEN_255 = 'Reason may not be greater than 255 characters.'

export const EXTRAITEM_NOT_GREATER_THEN_191 = 'Extra Item name may not be greater than 191 characters.'

export const NETWORK_ERROR = 'Unable to access internet.'

export const VALID_DISCOUNT = 'Please enter a valid discount.'

export const ALREADY_ADDED_REWARD_CARD = "Reward card is already added to this order,so can't add reward card."

export const CANT_CREATE_TICKET = "Can't create ticket as there is no order in progress."

export const CANT_CREATE_TICKET_ORDER_IN_PROGRESS = "Can't create ticket as order in progress."

export const PAYMENT_TYPE_CAYAN = 'Cayan'

export const PAYMENT_TYPE_PAX = 'Pax'

export const BACKBUTTONERROR = "You can not press back button while transaction is in process."

export const HALF_PAYMENT = 'Sorry, Payment process is already started. Please complete it first.'

export const CANCLE_TRANSACTION = 'Are you sure you want to cancel transaction as order is not created and you have done some partial payment?'

export const UNABLE_TO_USE_REWARD_CARD = 'We can not use reward points as of now due to unavailability of internet connection. Sorry for inconvenience.'

export const API_SETTING_NOT_IN_DATABASE = 'Sorry, API settings are not available. Please insert it.';

export const MULTIPLE_ORDER_COUPON_SELECT = "Sorry, You can select only one order reward coupon.";

export const ORDER_TOTAL_MINUS_AFTER_APPLY_REWARD = 'Reward coupon discount amount is more than order total amount. Please unselect some reward coupon and try again.';

export const AFTER_APPLY_ORDER_REWARD_MINIMUM_TOTAL_NOT_MAINTAIN ='Minimum order total criteria is not achieved. Please check applied reward coupon.';

export const MULTIPLE_PRODUCT_REWARD_SELECT = 'Sorry, You can select only one reward coupon per product.';

// export const SELECT_ANY_REWARD = 'Please select any of the reward.';

export const NO_ANY_ELIGIBLE_REWARD_FOUND = 'Eligible reward coupon could not found.';

export const REWARD_SUCCESSFULLY_APPLY = 'Reward coupons are successfully applied.';
export const  ARRSETTING = [
"ServerUrl","signature_amount",
"print_footer1", "print_footer2", "print_header1", "print_header2", 
"LastUpdatedDate", "ServerAuthorization","StationId" ,
"LocationId","merchant_name","merchant_key","merchant_site_id","location_type","kds"
];

export const UPDATESUCCESS ='Settings updated successfully.';

export const UPDATEERROR="Error occurred while update settings.";

export const SETTINGNOTAVAILABLE ="Settings are not available, please sync the data."

export const UPDATEOFFLINESUCCESS ="Offline settings updated successfully.";

export const Quantity_UPDATE_ERROR =
  'Sorry, You can not update this item\'s Qty as discount is applied in this product or order. Please remove it and try again.'

export const WEIGHT_MEASURED_PRODUCT_UPDATEERROR =   'You can not update quantity of Weight Measured Items.'

export const BACKEND_ERROR = 'Error: Network Error';


export const IP_DELETED_SUCCESS = "IP deleted successfully.";

export const VALID_IP_NAME ="Please enter valid KDS name and IP.";

export const VALID_IP ="Please enter valid KDS IP.";

export const VALID_NAME ="Please enter valid KDS name.";


export const VALID_NAME_LENGTH = 'KDS name may not be greater than 255 characters.';

export const IP_ALREADY_EXISTS_DATABASE = 'IP is already exists in system.';

export const SUCCESSFULLY_ADDED_IP = 'IP added successfully.';

export const LOADING_STOP = 'Some order are Synchronized with server but the process is stopped  due to no internet connection.';

export const PERSENT_DISCOUNT_ERROR = "Your Order discount is 0.00. Please enter valid discount in percentage."

export const PERSENT_PRODUCT_DISCOUNT_ERROR = "Your Product discount is 0.00. Please enter valid discount in percentage."

export const ORDER_BALANCE_LESS_ACCEPT_CREDIT_PAYMENT = 'Order Balance should be less or equal to the payment amount to accept Credit payment.';

export const CUSTOMER_CHARGED_ORDER_NOT_PROCESSED = "Customer was charged but order couldn't be registered on server.";

export const PAYMENT="Payment";

export const HOMESTACK="HomeStack";

export const LOGOUT= 'Logout';

export const CONFIRM_LOGOUT='Are you sure you want to Logout?';

export const HOME_PAGE='Home';

export const MANAGER='Manager';

export const SINGLEORDERDISPLAY='SingleOrderDisplay';

export const ERROR_PROCESSING_TRANSACTION='Error trying to process this transaction.';

export const REFUNDTAX_MORETHAN_ORDERTAX='Sorry, refund total tax is more than order total tax. So you can not refund this Product.';

export const REFUND_MORETHAN_ORDERTOTAL='Sorry, refund total is more than order total. Please change it and try again.';

export const TIPS_AMOUNT='Tips Amount';

export const NO_ORDER_FOUNDS_REWARDCARD='No orders found for this reward card.';

export const DIFF_FOR_POS='Difference Total for Pos';

export const PAYOUT_FOR_POS='PayOut Total for Pos';

export const PAYIN_FOR_POS='PayIn Total for Pos';

export const REGISTER_OPEN_BUTNOT_CLOSE='Register was opened but not closed.';

export const STATION_NAME=' Station Name: ' ;

export const OPEN_AMT='Open Amount';

export const CLOSE_AMT='Close Amount';

export const PAYIN_AMT='PayIn Amount';

export const PAYOUT_AMT='PayOut Amount';

export const DIFFERENCE= 'Difference';

export const DIFF_TOTAL='differenceTotal';

export const PAYOUT_TOTAL= 'payoutTotal';

export const PAYIN_TOTAL='payinTotal';

export const TOTAL_PAYIN='Total PayIn';

export const TOATAL_PAYOUT='Total PayOut';

export const CONSOLIDATED_DATA_TILLS=' Consolidated Data of Tills';

export const TOTAL_SALES= 'total_sales';

export const TOTAL_NUMBER_ORDERS='total_number_of_orders';

export const AVERAGE_ORDER='average_sales_per_order';

export const TOTAL_DISCOUNT='total_discount';

export const TOTAL_PAYMENT='total_payment';

export const ENDOFTHEDAYREPORT='EndofDayReports';

export const TICKET_DELETE_CONFIRM='Ticket will be delete permanently. Are you sure?';

export const ONGOING_ORDER_CLEAR='Your Ongoing Order will clear. Are you sure?';

export const ONGOING_TICKET_CLEAR= 'Your Ongoing Ticket will clear. Are you sure?';

export const BANKDROP_ERROR_RECEIPT='Bank Drop Created but unable to print the receipt.';

export const NOT_CREATE_BANKDROP='Sorry, you could not create bankdrop because till is yet not open.';

export const ENTER_PAYIN_REASON= 'Please enter a valid pay in reason.';

export const PAYIN_ERROR_RECEIPT='Pay In Created but unable to print the receipt.';

export const NOT_CREATE_PAYIN='Sorry, you could not create pay in because till is yet not open.';

export const ENTER_PAYOUT_REASON='Please enter a valid pay out reason.';

export const PAYOUT_ERROR_RECEIPT='Pay Out Created but unable to print the receipt.';

export const NOT_CREATE_PAYOUT='Sorry, you could not create pay out because till is yet not open.';

export const TODAYSORDERS='TodayOrder';

export const TICKET='Ticket';

export const OFFLINEORDERS='OfflineOrder';

export const CHANGESETTINGS='ChangeSetting';

export const KDSSETTINDS='KDSSetting';

export const COUPONSTATUS_PAGE='CouponStatus';

export const COUPONREWARD_PAGE='CouponReward';

export const NOTFOUND_PRODUCT='Product not found.';

export const MANUALWEIGHT='ManualWeight';

export const PROMPTSCREEN='PromptScreen';

export const MODIFIERCONTAINER='ModifierContainer';

export const PRODUCTCATEGORY_SCREEN='ProductCategory';

export const ADD_REWARD_SUCCESSFULL='Reward card added successfully.';

export const COUPON_IS_APPLIED='Coupon is successfully applied.';

export const CATEGORY_SCREEN='Category';

export const DISCOUNT_APPLY='Discount is applied.';

export const ORDER_CLEARED='Order is cleared.';

export const ADDORDERDISCOUNT='AddOrderDiscount';

export const REMOVE_ORDER_DISCOUNT_TEXT='Remove Order Discount';

export const ALERT_REMOVE_DISCOUNT='Are you sure you want to remove the Order Discount?';

export const ALERT_REMOVE_COUPON='Are you sure you want to remove the coupon?';

export const REMOVE_ALL_COUPONS_TITLE='Remove All Coupons';

export const ALERT_REMOVE_ALL_COUPON='Are you sure you want to remove all the coupons?';

export const QUANITY_LESS_THAN='Quantity should be less than or equal to 999999.';

export const VALID_QUANTITY='Please enter valid quantity.';

export const REMOVE_COUPON_ONLY='Remove Coupon';

export const ALERT_REMOVE_PRODUCT_COUPON='Are you sure you want to remove the coupon?';

export const ORDERITEMOPTIONS='OrderItemOptions';

export const ORDER_DISCOUNT_ALERT='Order Discount Details';

export const ORDERACTIONS='OrderActions';

export const EXTRAITEM_PAGE='ExtraItem';

export const REWARD_COUPON='RewardCoupon';

export const REWARD_STATUS='RewardStatus';

export const SOFTWARE_CAUSE_ABORT_CONNECTION='Software caused connection abort';

export const REWARD_ADDD='Reward card added.';

export const BARCODE_PAGE='Barcode';

export const NO_REWARD_COUPON='No coupon reward found.';

export const CLOSE_APPLICATION='Close App';

export const ALERT_CLOSE_APP= 'Are you sure you want to close the application?';

export const ERROR_TRYING_TRANSACTION='Error occured trying to process the transaction. Detail: ';

export const CASHBACKSCREEN='CashbackScreen';

export const PLEASE_GIVE_CASHBACKTO_CUSTOMER='Please give cashback to customer of $';

export const INTERNET_CONNECTION_CHECK='Internet connection check';

export const CREATE_REWARD_CARD_SCREEN='CreateRewardCard';

export const ADD_REWARD_CARD_SCREEN='AddRewardCard';

export const REWARD_COUPON_APPLIED='Reward coupon is successfully applied.';

export const MANUAL_DISCOUNT_APPLIED_ALREADY='Manual discount is already applied';

export const THIS_COUPON_ALREADY_APPLY='This Coupon is already applied';

export const PRODUCT_NOT_FOUND='Product Not Found';

export const COUPON_APLLY_ALREADY='Coupon is already applied';

export const DATA_SYNC_SUCCESS='Data synced successfully.';

export const APISETTINGS='ApiSettings';

export const ITEM_WIZE_REFUND='ItemWiseRefund';

export const REWARD_CARD_ADD='RewardCardAdd';

export const SETTINGS_INSERT_DATABASE='Settings are inserting in database.';

export const ERROR_TRYING_INSERT_PRODUCT= 'Error occured trying to insert Products.';

export const PRODUCT_UPDATED=' Products will be updated.';

export const PRODUCT_INSERTING_IN_DATABASE=' Products are inserting into the database.';

export const NO_PRODUCT_FOUND='No Product found';

export const CANT_ADD_QUANTITY_999999= 'You can not add quantity more than 999999.';

export const CANT_ADD_QUANTITY_999999_99='You can not add quantity more than 999999.99.';

export const MINIMUM_ORDER_AMT_MUSTBE='Sorry, you can not remove this item as minimum order amount must be ';

export const MINIMUM_ORDER_AMT_MUSTBE_0ORMORE='Sorry, you can not remove this item as minimum order amount must be 0 or more.';

export const  MINIMUM_ORDER_AMT_DISCOUNT='Sorry, you can not apply discount on this item as minimum order amount must be ' ;

export const DISCOUNT_MUST_BE_LESS='Discount amount must be less or equal to';

export const DISCOUNT_MUST_BE_LESS$= 'Discount amount must be less or equal to $';

export const  ORDER_DICOUNT_100='Sorry, order discount is apply 100%, so you can not add product discount.';

export const CANT_REMOVE_ITEM= 'Sorry, you can not remove this item as minimum order amount must be 0 or more.';

export const SCANNER_SOCKET_SUCCESS='Scanner Socket Connection Successful';

export const SCALE_SOCKET_SUCCESS= 'Scale Socket Connection Successful.';

export const ERROR_FROM_SOCKET='ERROR FROM SOCKET';

export const CONNECTION_CLOSE='CONNECTION CLOSED';

export const PRODUCT_NOT_FOUND_BARCODE='Product not found using Barcode: ';

export const USB_ATTACHED='USB Devices Attached. Will try to connect.';

export const SERVICE_STOPPED='service stopped';

export const DATASYNCED_SUCCESS='Data synced successfully.';

export const SETTINGS_FETCHING_SERVER='Settings are fetching from server.';

export const SETTING_VALIDATED_WITH_SERVER='Api Settings are validating with server.';

export const SETTINGS_INSERTING_INTO_REALM='Settings are inserting into database.';

export const PRODUCTS_FETCHING_SERVER='Products are fetching from server.';

export const PRODUCTS_INSERTING_INTODB= 'Products are inserting into database.';

export const TIPS_AMOUNT_ADDED='Tips Amount Added Successfully.';

export const CANT_CREATE_REFUND_TILL_NOT_OPEN='Sorry, you could not create refund because till is yet not open.';

export const CANT_INITIATE_REFUND_PROCESS='Sorry, refund of total amount (sale price total) is already done. You can not initiate the refund process.';

export const USER_NOT_ASSOCIATE="User is not associated with system.";

export const VALID_ORDER_USER_NAME_LENGTH = 'Order name may not be greater than 191 characters.'

export const VALID_ORDER_USER_NAME = 'Please enter a valid order name.'

export const VALID_PORTAL_URL='Please enter valid Octopos portal url.'

export const SMS_SEND_SUCCSS="Order receipt link sent successfully on your sms."

export const SMS_SEND_ERROR="Sorry, we couldn't send sms.kindly contact system administrator."

export const MAIL_SEND_ERROR = "Sorry, we couldn't send email.kindly contact system administrator."


// OCTO GRUB CONSTANT



       //    *******************    delivery details start   ****************************    
export const URL = window.location.protocol+'//'+window.location.hostname+':8000'

export const BACKEND_ERROR_CODE = 'Error: Request failed with status code 404';

export const ERROR = 'error'

export const DELIVERY_TIME_SELECT_ERROR = "Select custom delvery time."

export const PICKUP_TIME_SELECT_ERROR = "Select custom pickup time."

export const PICKUP_LOCATION_NOT_FOUND = "Enter valid pickup location."

export const PICKUP_TIME_SLOTE_NOT_FOUND = "Time slot is not available."

export const DAY_CHANGE_TIME_SLOTE_NOT_AVAILABLE = "Time slot is not available for today."

export const DELIVERY_LOCATIONS_NOT_AVAILABLE = "Looks like there isn't any location available for delivery."

export const ENTER_DELIVERY_ADDRESS_ZIPCODE_NOT_AVAILABLE = "Sorry, we are not providing delivery service in your area. Currently serving following zip codes: "

export const DELIVERY_TIME_SLOTE_NOT_FOUND = "Delivery time slot is not available."

export const DELIVERY_ADDRESS_LOCATION_NOT_FOUND = "Sorry, we are not providing delivery service in your area. Currently serving following zip codes: "//todo delivery not available for selected address

export const DELIVERY_ADDRESS_ZIPCODES_NOT_FOUND = "Sorry, we are not providing delivery service in your area. Currently we are not serving delivery in any zip codes. "//todo delivery not available for selected address

export const DELIVERY_ADDRESS_NOT_FOUND = "Looks like you have enter wrong address. Please try again."//Enter Delivery address not found.

export const DELIVERY_ADDRESS_ZIPCODE_NOT_FOUND = "Zipcode is not available in entered address. Please try again."//Enter Delivery address zipcode not found.

export const DELIVERY_ADDRESS_COUNTRY_NOT_FOUND = "Country is not available in entered address. Please try again."//Your selected address country not available.

export const DELIVERY_ADDRESS_STATE_NOT_FOUND = "State is not available in entered address. Please try again."//Your selected address state not available.

export const DELIVERY_ADDRESS_CITY_NOT_FOUND = "City is not available in entered address. Please try again."//Your selected address city not available.

export const STORE_OPEN_BEFORE_ORDER_START = "Sorry, we are closed at the moment for delivery or pickup. Please try again after some time."

export const STORE_CLOSE_AFTER_ORDER_START = "Sorry, we are closed at the moment for delivery or pickup. Please try again tomorrow."
            //    *******************    delivery details End   ****************************  

            //    *******************    Create User,SIGNIN,FORGOT PASSWORD,PASSWORD RESET ***************************************

export const USER_ENTER_ADDRESS_ZIPCODE_NOT_FOUND = "Zipcode is not available in entered address. Please try again."

export const USER_ENTER_ADDRESS_COUNTRY_NOT_FOUND = "Country is not available in entered address. Please try again."

export const USER_ENTER_ADDRESS_STATE_NOT_FOUND = "State is not available in entered address. Please try again."

export const USER_ENTER_ADDRESS_CITY_NOT_FOUND = "City is not available in entered address. Please try again."

export const USER_ENTER_ADDRESS_NOT_FOUND = "Please enter a valid address."

export const USER_FIRST_NAME_REQUIRED = "First name is required."

export const USER_FIRST_NAME_LENGTH_GREATE_THEN_255 = "First name should be less then 255 character."

export const USER_LAST_NAME_REQUIRED = "Last name is required."

export const USER_LAST_NAME_LENGTH_GREATE_THEN_255 = "Last name should be less then 255 character."

export const USER_EMAIL_REQUIRED = "Email is required."

export const USER_EMAIL_NOT_VALID = "Please enter a valid email."

export const USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER = "Your email can not exceed 255 characters."

export const USER_PHONE_NUMBER_REQUIRED = "Phone Number is required."

export const USER_PHONE_NUMBER__NOT_VALID = 'Please enter a valid phone number.'

export const USER_PHONE_NUMBER_GREATER_THEN_10 = "Your phone number must be less than 11 digits long."

export const USER_PHONE_NUMBER_MUST_BE_3_DIGITS = "Your phone number must be greater than 2 digits long."

export const PASSWORD_FIELD_REQUIRED = "Password is required."

export const PASSWORD_MUST_BE_8_CHARACHTER = "Password must be 8 characters long."

export const CONFIRM_PASSWORD_FIELD_REQUIRED = "Confirm password is required."

export const CONFIRM_PASSWORD_MUST_BE_8_CHARACHTER = "Confirm password must be 8 characters long."

export const PASSWORD_CONFIRM_PASSWORD_NOT_SAME = "Password and confirm password must be same."

                //    ************************ Create User,SIGNIN,FORGOT PASSWORD,PASSWORD RESET END

                //    ***********************  ReOrder List   **********************************

export const NO_REORDER_FOUND = "Looks like you haven't place any order yet."

export const TENENT_DEACTIVE = "Your backend has been temporarily suspended. Please contact to administrator."

                //    ***********************  ReOrder List End  **********************************

               //     *********************** My Bag start   ********************

export const VALID_ITEM_QUANTITY = 'Please enter quantity between 0 to 999999.'

export const CLEAR_ALL_ITEM = "Are you sure you want to clear all items?"

export const CLEAR_ORDER = "Are you sure you want to clear order and go back to home?"

export const VALID_SPECIAL_INSTRUCTION = 'Special instruction may not be greater than 255 characters.'

                //     *********************** My Bag end   ********************

                //     *********************** Delivery-Pickup inside file   ********************

export const ORDER_BALANCE_ZERO = 'Sorry, order total is $0.00, so you can not proceed with checkout.'

              //    ***********************  User Card Details  *************************

export const USER_CARD_DETAILS_USER_LOGOUT = "Sorry, you have not logged in. Please login to continue."

export const CVC_REQUIRED = "CVC is required."

export const VALID_CVC = "CVC is invalid."

export const EXPIRY_DATE_REQUIRED = "Expiry date is required."

export const VALID_EXPIRY_DATE = "Expiry date is invalid." 

export const PAST_EXPIRY_DATE =  "Expiry date cannot be in the past." 

export const CARD_NUMBER_REQUIRED = "Card number is required."

export const VALID_CARD_NUMBER = "Card number is invalid."

export const CARD_NAME_REQUIRED = "Card name is required."

export const VALID_CARD_NAME = "Card name should be less then 255 character."

export const SELECTE_PAYMENT_CARD = "Please select a payment card."

export const VALID_INSTRUCTION = "Please enter a valid instruction."

export const VALID_ORDER_INSTRUCTION = 'Order instruction may not be greater than 255 characters.'

export const SELECT_TIME_SLOTE = "Please select a timeslot."

            //   ************************  check out page start *********************************

export const VALID_TIPAMOUNT="Please enter a valid tip amount."

export const TIPS_AMOUNT_VALIDATION = 'Please enter tips amount between 0.01 to  99999.'

export const ENTER_TIPAMOUNT = "Tip amount is requierd."

export const CHECKOUT_PAGE_USER_LOGOUT = "Looks like you are not logged in. Please login to continue."

export const CHECKOUT_PAGE_ADDRESS_NOT_FOUND = "Delivery / pickup location details are not found."

export const USER_CARD_DETAILS_NOT_FOUND = "Please enter payment card details."

export const SELECTE_TIME_SLOTE_IS_BEFORE_CURRUNT_TIME = "The time slot which you have selected is already passed. Kindly select new slot if there is any slots are available."

export const NO_SLOTES="Looks like there isn't any slot availble today."

          //   -------------------------   gift card page start   ---------------------------------

export const GIFT_CARD_CODE_REQUIRED = "GiftCard code is required."

export const GIFT_CARD_CODE_LENGHT_GREATER_THAN_24 = "enter valid giftCard code"

export const USER_GIFTCARD__NOT_VALID = 'Please enter a valid giftCard code.'

export const VALID_GIFT_CARD_AMOUNT = 'Please enter a numeric value only.' 

export const VALID_GIFT_CARD_AMOUNT_LESS_OR_GREATER = 'Amount must be between $0.01 to $500.00'

export const CAN_NOT_ADD_GIFT_CARD = 'You can not add Gift Card as order is already in process. Please clear order items and try again.'

export const VALID_GIFT_CARD_CODE_LESS_OR_GREATER = 'Gift Card code must be between 4 to 24 digits.'

export const VALID_GIFT_CARD_CODE = 'Please enter a numeric value only.'

export const VALIDATION_OF_EMAIL_OR_SMS = 'Email or Phone Number is required.'

export const CAN_NOT_ADD_PRODUCT = 'Product can not be added while purchasing gift card.'

export const INSUFFICIENT_BALANCE = 'Sorry, you can not use this gift card as current balance is 0.00.'

export const GIFT_CARD_INSTRUCTION = 'Gift Card Custom message must be less than 255 characters.'

export const BUY_GIFT_CARD_SUCCESS = 'Gift card is purchased successfully.'
