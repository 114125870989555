import React from "react";
import { viewProducts, removeProducts } from "../actions/menu_items";
import { currentItem, removeCurrentItem } from "../actions/current_item";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { orderHistory, getReoderProductDetails } from "../services/order/order";
import {
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { insertTicketLinesToOrderLines } from "../actions/tickets";
import { orderCalculation } from "../actions/order_actions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import { red, indigo } from "@material-ui/core/colors";
import * as Constant from "../constants";
import { Row, Col } from "antd";
import moment from "moment";
import { LinearProgress } from "@material-ui/core";
import { userLogout } from "../actions/user_actions";
import { removeUserCardDetails } from "../actions/card_Details_action";
import { clearItems } from "../actions/order_items";

const styles = (theme) => ({
  text: {
    fontWeight: "bold",
  },
  subheader: {
    fontWeight: "bold",
  },
  margin: {
    backgroundColor: indigo[100],
    color: indigo[900],
    // backgroundColor: purple[500],
  },
  layoutContainer:{
    height: "88vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  layoutContainer1:{
    height: "88vh",
    width: "100%",
  },
});

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 1000,
      page: 0,
      orderDetails: [],
      expanded: false,
      loading: true,
      itemModel: false,
      selected_order:'',
      error_message : ''
    };
  }

  componentDidMount() {
    let location_id = ''
    if(this.props.delivery_pickup.length > 0) {
      location_id = this.props.delivery_pickup[0].location.id
    }
    let payload = {
      user_id: this.props.user.user.id,
      location_id: location_id,
      limit: 1000,
      offset: 1,
    };
    orderHistory(payload)
      .then((res) => {
        if (res.data.error) {
          if (res.data.error.code === 404) {
            // const path = "/" + localStorage.getItem("tenant") + "/404";
            this.props.history.push('/404');
          }
        } else if (res.data.flag) {
          if (res.data.data.length > 0) {
            this.setState({
              orderDetails: res.data.data,
              loading: false,
            });
          } else {
            this.setState({ orderDetails: [], loading: false, error_message: Constant.NO_REORDER_FOUND });
          }
        } else {
          if (
            null != res.data.code &&
            undefined != res.data.code &&
            "" != res.data.code
          ) {
            if (res.data.code == 999) {
              this.props.removeUserCardDetails();
              this.props.userLogout();
              localStorage.removeItem("token");
              const path = "/" + localStorage.getItem("tenant") + "/products";
              this.props.history.push(path);
              toast.error(res.data.message);
              this.setState({ orderDetails: [], loading: false, error_message: Constant.NO_REORDER_FOUND });
            } else {
              if(res.data.message == Constant.TENENT_DEACTIVE) {
                this.setState({ orderDetails: [], loading: false,error_message: Constant.TENENT_DEACTIVE});
              } else {
                // toast.error(res.data.message);
                this.setState({ orderDetails: [], loading: false, error_message: Constant.NO_REORDER_FOUND });
              }
            }
          } else {
            toast.error(res.data.message);
            this.setState({ orderDetails: [], loading: false, error_message: Constant.NO_REORDER_FOUND });
          }
        }
      })
      .catch((err) => {
        this.setState({ orderDetails: [], loading: false, error_message: Constant.NO_REORDER_FOUND });
        toast.error(err.toString());
      });
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
  };
  }

  renderReorderProductName = (item) => {
    var final = "";
    var single_price = "";
    if (item.is_modifier_product) {
      item.modifier_set.map((single, index) => {
        if (single.selected_modifiers.length > 0) {
          single_price = single.selected_modifiers
            .map((item) => item.name)
            .reduce((prev, next) => prev + ", " + next);
          if (single_price != "") {
            if (final == "") {
              final = final + "" + single_price;
            } else {
              final = final + ", " + single_price;
            }
          }
        }
      });
    }
    if (final) {
      // final = item.name + " (" + final + ")";
      return (
        <Typography
          style={{ textAlign: "left" }}
          variant="body1"
          align="center"
        >
          <b>{item.name}</b>{ " (" + final + ")"}
        </Typography>
      );
    } else {
      return (
        <Typography
        style={{ fontWeight: "bold",textAlign: "left" }}
          variant="body1"
          align="center"
        >
          {item.name}
        </Typography>
      );
    }
  };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  processOrder = (data) => {
    let array = []
    let location_id = ''
    if(this.props.delivery_pickup.length > 0) {
      location_id = this.props.delivery_pickup[0].location.id
    }
    data.all_lines.map((item) => {
      let obj = {
        "product_id": item.product_id
      }
      array.push(obj)
    })
    let payload = {
      location_id: location_id,
      product_details: array,
    };
    getReoderProductDetails(payload)
    .then((res) => {
      if (res.data.error) {
        if (res.data.error.code === 404) {
          this.props.history.push('/404');
        }
      } else if (res.data.flag) {
        if (res.data.data.length > 0) {
          this.setState({loading:true})
          this.props.clearItems();
          this.props.removeCurrentItem();
          const path = "/" + localStorage.getItem("tenant") + "/products";
          this.props.insertTicketLinesToOrderLines(data.all_lines,res.data.data);
          this.props.orderCalculation();
          setTimeout(() => {
            this.props.history.push(path);
            this.setState({ loading: false})
          }, 1500);
        }
      } else {
        if (
          null != res.data.code &&
          undefined != res.data.code &&
          "" != res.data.code
        ) {
          if (res.data.code == 999) {
            this.props.removeUserCardDetails();
            this.props.userLogout();
            localStorage.removeItem("token");
            const path = "/" + localStorage.getItem("tenant") + "/products";
            this.props.history.push(path);
            toast.error(res.data.message);
            this.setState({ orderDetails: [], loading: false });
          } else {
            toast.error(res.data.message);
          }
        } else {
          this.setState({ loading: false})
          toast.error(res.data.message);
        }
      }
    })
    .catch((err) => {
      this.setState({ loading: false})
      toast.error(err.toString());
    });
    
  };
  handleChange = (panel) => (event, isExpanded) => {
    isExpanded
      ? this.setState({ expanded: panel })
      : this.setState({ expanded: false });
  };
  handleOpenModel = (Data) => {
    if(this.props.order_items.length > 0) {
      this.setState({ itemModel: true, selected_order:Data})
    } else {
      this.processOrder(Data)
    }
 }
  handleitemClose = () => {
    this.setState({ itemModel: false})
  }

  render() {
    const { classes } = this.props;
    if (this.state.loading) {
      return (
        <>
        <div
            layout="vertical"
            className={classes.layoutContainer}
          >
<LinearProgress color="primary" />
          </div>
              
        </>
      );
    } else {
      if (this.state.orderDetails.length > 0) {
        return (
          <>
          <Dialog
        open={this.state.itemModel}
        onClose={() => this.handleitemClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Constant.ONGOING_ORDER_CLEAR}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleitemClose()} variant="contained" color="secondary">
            CANCEL
          </Button>
          {/* <Route
            render={({ history }) => ( */}
          <Button onClick={() => {
           this.processOrder(this.state.selected_order)
          }} variant="contained" color="primary" autoFocus>
            OK
          </Button>
          {/* )}
          />  */}
        </DialogActions>
      </Dialog>
            <div className={classes.layoutContainer1}>
              <Col xs="24" xl={24}>
                <React.Fragment>
                  <Row gutter={[24, 0]}>
                    <Col>
                      <Link
                        to={`/${localStorage.getItem("tenant")}/products`}
                        className="text-dark font-bold"
                      >
                        <ArrowBackIcon />
                      </Link>
                    </Col>
                    <Col>
                      <Typography
                        className="text-dark font-bold "
                        variant="body1"
                        align="center"
                      >
                        Order History
                      </Typography>
                    </Col>
                  </Row>
                </React.Fragment>
              </Col>
              {this.state.orderDetails
                .slice(0, this.state.rowsPerPage)
                .map((data, index) => (
                  <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Col xs="12" xl={20}>
                        <Button
                          key={index}
                          size="small"
                          type="button"
                          className={classes.margin}
                        >
                          {data.online_order_detail.order_dispatch_type.toUpperCase()}
                        </Button>
                        <Typography className={classes.subheaderLength}>
                          {moment(data.created_at).format("dddd") +
                            " " +
                            moment(data.created_at).format(
                              "MMMM Do YYYY, h:mm:ss A"
                            )}
                        </Typography>

                        <Typography
                          className={classes.subheader}
                          variant="body1"
                          align="left"
                        >
                          Total : {"$" + data.total}
                        </Typography>
                      </Col>
                      <Col xl={4}>
                        <Button
                          key={index}
                          color="primary"
                          size="small"
                          onClick={() => {
                            this.handleOpenModel(data);
                          }}
                        >
                          Reorder
                        </Button>
                      </Col>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Col xs="24" xl={24}>
                        {data.all_lines.map((item, index) => (
                          <React.Fragment key={index}>
                            <Row gutter={[24, 0]}>
                              <Col span={1}>
                                <Typography
                                  className={classes.subheader}
                                  variant="body1"
                                  align="center"
                                >
                                  {item.quantity}
                                </Typography>
                              </Col>
                              <Col span={23} >
                                {this.renderReorderProductName(item)}
                              </Col>
                            </Row>
                          </React.Fragment>
                        ))}
                      </Col>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className={classes.layoutContainer1}>
              <Col xs="24" xl={24}>
                <React.Fragment>
                  <Row gutter={[24, 0]}>
                    <Col>
                      <Link
                        to={`/${localStorage.getItem("tenant")}/products`}
                        className="text-dark font-bold"
                      >
                        <ArrowBackIcon />
                      </Link>
                    </Col>
                    <Col>
                      <Typography
                        className="text-dark font-bold "
                        variant="body1"
                        align="center"
                      >
                        Order History
                      </Typography>
                    </Col>
                  </Row>
                </React.Fragment>
              </Col>
              <Typography className={classes.subheader} align="left">
                {this.state.error_message}
              </Typography>
            </div>
          </>
        );
      }
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      viewProducts,
      currentItem,
      removeProducts,
      removeUserCardDetails,
      removeCurrentItem,
      insertTicketLinesToOrderLines,
      orderCalculation,
      userLogout,
      clearItems,
    },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    delivery_pickup: state.delivery_pickup,
    featured_item: state.featured_item,
    order_items: state.order_items,
    order: state.order,
    category_products: state.category_products,
    current_item: state.current_item,
    user: state.user,
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(OrderDetails);
