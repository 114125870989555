import axios from 'axios';

export function syncWithServer(location_id) {
    return new Promise(async (resolve, reject) => {
      let payload = {
        location_id: location_id,
      }
      let tenant = localStorage.getItem("tenant")
      axios.defaults.timeout = 60000
      axios
        .post(process.env.REACT_APP_ACCESS_KEY + "/" + tenant + "/products", payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }