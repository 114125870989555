export const MAKE_PAYMENT = 'MAKE_PAYMENT'
export const GET_UNPAID_BALANCE = 'GET_UNPAID_BALANCE'
export const COMPLETE_ORDER = 'COMPLETE_ORDER'
export const DISCOUNT_ORDER = 'DISCOUNT_ORDER'
export const REMOVE_ORDER_DISCOUNT = 'REMOVE_ORDER_DISCOUNT'
export const ADD_REWARD_CARD = 'ADD_REWARD_CARD'
export const ORDER_CALCULATION = 'ORDER_CALCULATION'
export const ORDER_NAME = 'ORDER_NAME'
export const ORDER_FOR_HERE = 'ORDER_FOR_HERE'
export const ORDER_INSTRUCTION = 'ORDER_INSTRUCTION'
export const ADD_TIP_IN_ORDER = 'ADD_TIP_IN_ORDER'

export function makePayment(payment) {
  return {
    type: MAKE_PAYMENT,
    payload: payment,
  }
}

export function orderCalculation() {
  return function(dispatch, getState) {
    const appState = getState()
    dispatch({
      type: ORDER_CALCULATION,
      payload: { state: appState },
    })
  }
}

export function getBalance() {
  return {
    type: GET_UNPAID_BALANCE,
  }
}

export function discountOrder(discount) {
  return {
    type: DISCOUNT_ORDER,
    payload: discount,
  }
}


export function removeOrderDiscount() {
  return {
    type: REMOVE_ORDER_DISCOUNT,
  }
}

export function addRewardCard(reward_card_server_id,reward_card_number) {
  let payload1 = {
    id: reward_card_server_id,
    number: reward_card_number
  }
  return {
    type: ADD_REWARD_CARD,
    payload: payload1,
  }
}
export function completeOrder() {
  return {
    type: COMPLETE_ORDER,
  }
}

export function orderUserName(order_name) {
  return function(dispatch, getState) {
    const appState = getState()

    dispatch({
      type: ORDER_NAME,
      payload: { state: appState ,order_name:order_name},
    })
  }
}
export function allOrderForHere(order_for_here) {
  return function(dispatch, getState) {
    const appState = getState()
    dispatch({
      type: ORDER_FOR_HERE,
      payload: { state: appState ,order_for_here:order_for_here},
    })
  }
}
export function orderInstruction(instruction) {
  return function(dispatch, getState) {
    const appState = getState()

    dispatch({
      type: ORDER_INSTRUCTION,
      payload: { state: appState ,instruction:instruction},
    })
  }
}

export function addTipInOrder(type,amount) {
  return function(dispatch, getState) {
    dispatch({
      type: ADD_TIP_IN_ORDER,
      payload: { type:type ,amount:amount },
    })
  }
}
