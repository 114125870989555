import {
  DELETE_ITEM,
  INSERT_ITEM,
  MINUS_ITEM,
  ADD_ITEM,
  CLEAR_ITEMS,
  INSERT_EXTRA_ITEM,
  DISCOUNT_PERCENTAGE_ITEM,
  REMOVE_ITEM,
  REMOVE_DISCOUNT_PERCENTAGE_ITEM,
  REMOVE_ITEM_COUPON,
  UPDATE_ITEM_MODIFIERS,
  UPDATE_ITEM_QTY,
  UPDATE_IS_GO,
  ALL_ITEM_FOR_HERE
} from '../actions/order_items'
import { COMPLETE_ORDER } from '../actions/order_actions'
import {
  CLEAR_COUPON,
  APPLY_COUPON,
  TICKET_ORDER_ITEMS_COUPON_APPLY,
} from '../actions/coupons_actions'
import _ from 'lodash'
import * as Constant from '../constants'
import { twofix } from '../helpers/calculation'
import { INSERT_TICKET_LINES_TO_ORDER_LINES } from '../actions/tickets'
import { APPLY_REWARD } from '../actions/reward_actions'

export default function(state = [], action) {
  // use for Order Line item all value set in redux
  switch (action.type) {
    case INSERT_ITEM: {
      let response = action.payload.item
      let stateProduct = action.payload.stateProduct
      let itemAlreadyExists = _.findIndex(state, function(o) {
        return o.server_id == response.server_id && _.isEqual(o.modifier_set, response.modifier_set)
      }) // get item and item all Selected modifiers same this item already exists or not
      if (itemAlreadyExists > -1) {
        // item already exist
        if (
          'applied_coupon' in state[itemAlreadyExists] &&
          state[itemAlreadyExists].applied_coupon != undefined
        ) {
          //coupon applie in item
          var quantity = twofix(
            twofix(state[itemAlreadyExists].quantity) + twofix(action.payload.quantity)
          ) // add new quantity in already exist item quantity

          var maxQty
          if (quantity >= state[itemAlreadyExists].applied_coupon.max_qty_per_coupon) {
            // check Existting item new quantity is greater then Coupone max_qty_per_coupon
            maxQty = state[itemAlreadyExists].applied_coupon.max_qty_per_coupon // maxQty set Coupone max_qty_per_coupon
          } else {
            maxQty = quantity // maxQty set Existting item new quantity
          }
          if (state[itemAlreadyExists].applied_coupon.coupon_discount_type == 'amou') {
            // Coupone type is amount
            var totalDiscount
            if (
              state[itemAlreadyExists].applied_coupon.coupon_discount_value >
              state[itemAlreadyExists].sale_price
            ) {
              // Coupon discount value greater then Existting item sale Price
              totalDiscount = twofix(maxQty * twofix(state[itemAlreadyExists].sale_price)) // cal total discount
            } else {
              // Coupon discount value less then or Equal to Existting item sale Price
              totalDiscount = twofix(
                maxQty * twofix(state[itemAlreadyExists].applied_coupon.coupon_discount_value)
              ) // cal to totaldiscount
            }
          } else {
            // Coupone type is Percentage
            var totalDiscount = twofix(
              twofix(
                (twofix(twofix(state[itemAlreadyExists].sale_price) * twofix(maxQty)) *
                  parseFloat(state[itemAlreadyExists].discount_percentage)) /
                  100
              )
            ) // cal to totaldiscount
          }
          var texCollected = twofix(
            twofix(
              twofix(
                twofix(
                  twofix(twofix(state[itemAlreadyExists].sale_price * quantity) - totalDiscount)
                ) * state[itemAlreadyExists].tax_percentage
              ) / 100
            ) + twofix(state[itemAlreadyExists].tax_amount * quantity)
          ) // cal tax colleted for Existting item
          var pushItem = {
            // set new object in Existting item
            product_id: Number(state[itemAlreadyExists].server_id),
            quantity: twofix(quantity),
            name: response.name,
            product_description: response.product_description,
            description: state[itemAlreadyExists].description,
            regular_price: twofix(state[itemAlreadyExists].regular_price),
            sale_price: twofix(state[itemAlreadyExists].sale_price),
            tax_percentage: Number(response.tax_percentage),
            tax_amount: Number(response.tax_amount),
            tax_collected: twofix(texCollected),
            ebt_tax: 0,
            cash_discount_amount: 0,
            reward_points: Number(response.reward_points),
            extra_item: Number(state[itemAlreadyExists].extra_item),
            server_id: Number(state[itemAlreadyExists].server_id),
            barcode: response.barcode,
            sold_by_weight: Number(response.sold_by_weight),
            prompt_qty: Number(response.prompt_qty),
            exclude_ebt: Number(response.exclude_ebt),
            discount_percentage: Number(state[itemAlreadyExists].discount_percentage),
            total_discount: twofix(totalDiscount),
            discount_reason: state[itemAlreadyExists].discount_reason,
            taxes: state[itemAlreadyExists].taxes,
            applied_coupon: state[itemAlreadyExists].applied_coupon,
            modifier_set: state[itemAlreadyExists].modifier_set,
            new_identity: state[itemAlreadyExists].new_identity,
            total_modifiers_price: state[itemAlreadyExists].total_modifiers_price,
            is_modifier_product: state[itemAlreadyExists].is_modifier_product,
            discount_type: state[itemAlreadyExists].discount_type,
            for_here:state[itemAlreadyExists].for_here,
          }
        } else {
          // coupon not applied in Existting item
          var quantity = twofix(
            twofix(state[itemAlreadyExists].quantity) + twofix(action.payload.quantity)
          ) // set new Quantity in Existting item Quantity
          if (state[itemAlreadyExists].discount_type == 'Percentage') {
            // check manualy discount applied in Existting item dicount type is Percentage
            var totalDiscount = twofix(
              twofix(
                twofix(state[itemAlreadyExists].sale_price * quantity) *
                  state[itemAlreadyExists].discount_percentage
              ) / 100
            ) // cal Percentage wise totaldiscount
          } else {
            //discount type Amount
            var totalDiscount = twofix(
              twofix(twofix(quantity) * twofix(state[itemAlreadyExists].discount_percentage))
            ) // cal Amount wise totaldiscount
          }

          var texCollected = twofix(
            twofix(
              twofix(
                twofix(
                  twofix(twofix(state[itemAlreadyExists].sale_price * quantity) - totalDiscount)
                ) * state[itemAlreadyExists].tax_percentage
              ) / 100
            ) + twofix(state[itemAlreadyExists].tax_amount * quantity)
          ) // cal Existting item total taxcollected
          var pushItem = {
            // set new object in Existting item
            product_id: Number(state[itemAlreadyExists].server_id),
            quantity: twofix(quantity),
            name: response.name,
            product_description: response.product_description,
            description: (null != response.description && undefined != response.description && '' != response.description) ? response.description : state[itemAlreadyExists].description,
            regular_price: twofix(state[itemAlreadyExists].regular_price),
            sale_price: twofix(state[itemAlreadyExists].sale_price),
            tax_percentage: Number(response.tax_percentage),
            tax_amount: Number(response.tax_amount),
            tax_collected: twofix(texCollected),
            ebt_tax: 0,
            cash_discount_amount: 0,
            reward_points: Number(response.reward_points),
            extra_item: Number(state[itemAlreadyExists].extra_item),
            server_id: Number(state[itemAlreadyExists].server_id),
            barcode: response.barcode,
            sold_by_weight: Number(response.sold_by_weight),
            prompt_qty: Number(response.prompt_qty),
            exclude_ebt: Number(response.exclude_ebt),
            discount_percentage: Number(state[itemAlreadyExists].discount_percentage),
            total_discount: twofix(totalDiscount),
            discount_reason: state[itemAlreadyExists].discount_reason,
            taxes: state[itemAlreadyExists].taxes,
            modifier_set: state[itemAlreadyExists].modifier_set,
            new_identity: state[itemAlreadyExists].new_identity,
            total_modifiers_price: state[itemAlreadyExists].total_modifiers_price,
            is_modifier_product: state[itemAlreadyExists].is_modifier_product,
            discount_type: state[itemAlreadyExists].discount_type,
            for_here:state[itemAlreadyExists].for_here,
          }
        }
        let newState = [...state.slice(0, itemAlreadyExists), ...state.slice(itemAlreadyExists + 1)] // remove Existting item in reducer state
        return [...newState, pushItem] // add Existting item in reducer
      } else {
        // new item
        var itemAlreadyExistsWithCoupon = action.payload.itemAlreadyExists // set item already Exists
        if (
          action.payload.item.is_modifier_product &&
          itemAlreadyExistsWithCoupon > -1 &&
          state[itemAlreadyExistsWithCoupon].total_discount > 0.0
        ) {
          // item is Allready Exists , item is modifier and item total discount grater then 0
          // check discount type manual or coupon
          if (
            state[itemAlreadyExistsWithCoupon].applied_coupon != '' &&
            state[itemAlreadyExistsWithCoupon].applied_coupon != undefined &&
            (state[itemAlreadyExistsWithCoupon].discount_reason == Constant.COUPON_APPLIED ||
              state[itemAlreadyExistsWithCoupon].discount_reason == 'REWARD APPLIED')
          ) {
            // coupon

            //modifier product apply coupon when product serverid is already in store and coupon applyed...
            if (
              action.payload.item.sale_price <
                state[itemAlreadyExistsWithCoupon].applied_coupon.coupon_discount_value &&
              state[itemAlreadyExistsWithCoupon].applied_coupon.coupon_discount_type == 'amou'
            ) {
              // consider it as normal Product cos can't add coupon to this product total may get in negative number
              var newItem = {
                server_id: Number(response.server_id),
                product_id: Number(response.server_id),
                quantity: twofix(action.payload.quantity),
                name: response.name,
                product_description: response.product_description,
                description: response.description,
                tax_percentage: Number(response.tax_percentage),
                tax_amount: Number(response.tax_amount),
                tax_collected:(null != response.online_ordering_price && undefined != response.online_ordering_price && "" != response.online_ordering_price) ?
                  twofix(
                    twofix(
                      twofix(
                        twofix(Number(response.online_ordering_price) * action.payload.quantity) *
                          response.tax_percentage
                      ) / 100
                    )
                  ) + twofix(response.tax_amount * action.payload.quantity) :
                  twofix(
                    twofix(
                      twofix(
                        twofix(Number(response.sale_price) * action.payload.quantity) *
                          response.tax_percentage
                      ) / 100
                    )
                  ) + twofix(response.tax_amount * action.payload.quantity)
                  ,
                ebt_tax: 0,
                cash_discount_amount: 0,
                regular_price: (null != response.online_ordering_price && undefined != response.online_ordering_price && "" != response.online_ordering_price) ? twofix(response.online_ordering_price) : twofix(response.sale_price),
                sale_price: (null != response.online_ordering_price && undefined != response.online_ordering_price && "" != response.online_ordering_price) ? twofix(response.online_ordering_price) : twofix(response.sale_price),
                reward_points: Number(response.reward_points),
                extra_item: response.extra_item ? Number(response.extra_item) : Number(0),
                discount_percentage: 0,
                sold_by_weight: Number(response.sold_by_weight),
                prompt_qty: Number(response.prompt_qty),
                total_discount: response.total_discount > 0.0 ? twofix(response.total_discount) : 0,
                discount_reason: '',
                exclude_ebt: Number(response.exclude_ebt),
                barcode: response.barcode,
                taxes: response.taxes,
                modifier_set: response.modifier_set,
                new_identity: response.new_identity
                  ? response.new_identity
                  : twofix(Math.floor(10000000 + Math.random() * 90000000)),
                total_modifiers_price: response.is_modifier_product
                  ? response.total_modifiers_price
                  : 0,
                is_modifier_product: response.is_modifier_product,
                discount_type: response.discount_type,
                for_here:stateProduct.for_here,
              }
              return [...state, newItem] // add in reducer state
            } else {
              // total will not be zero apply coupon on new added product
              let discount = 0
              let percentage =
                state[itemAlreadyExistsWithCoupon].applied_coupon.coupon_discount_value //set coupon dicount value
              // check coupon max Qty
              var maxQty
              if (
                action.payload.quantity >=
                state[itemAlreadyExistsWithCoupon].applied_coupon.max_qty_per_coupon
              ) {
                maxQty = state[itemAlreadyExistsWithCoupon].applied_coupon.max_qty_per_coupon
              } else {
                maxQty = action.payload.quantity
              }
              if (state[itemAlreadyExistsWithCoupon].applied_coupon.coupon_discount_type == 'per') {
                // applied coupon discount type per
                discount = twofix(
                  twofix(twofix(action.payload.item.sale_price) * twofix(maxQty)) *
                    parseFloat(percentage / 100) // cal totalDiscount for Existting item
                )
                percentage = twofix(percentage)
              } else {
                // applied coupon discount value amount
                discount = twofix(
                  twofix(state[itemAlreadyExistsWithCoupon].applied_coupon.coupon_discount_value) *
                    twofix(maxQty)
                ) // cal amount wise total discount
                percentage =
                  parseFloat(
                    discount / twofix(twofix(action.payload.item.sale_price) * twofix(maxQty))
                  ) * 100 // cal discount percentage
              }
              let total 
              if(null != action.payload.item.online_ordering_price && undefined != action.payload.item.online_ordering_price && "" != action.payload.item.online_ordering_price) {
                total = twofix(
                  twofix(twofix(Number(action.payload.item.online_ordering_price)) * twofix(maxQty)) - twofix(discount)
                ) // cal Product total
              } else {
                total = twofix(
                  twofix(twofix(action.payload.item.sale_price) * twofix(maxQty)) - twofix(discount)
                ) // cal Product total
              } 
              let taxCollected =
                twofix((twofix(total) * parseFloat(action.payload.item.tax_percentage)) / 100) +
                parseFloat(action.payload.item.tax_amount * twofix(maxQty)) // cal Existting Product total tax
              var stateDiscount = twofix(discount) // set discount in variable
              const newItem = {
                // create new item payload
                server_id: Number(action.payload.item.server_id),
                product_id: Number(action.payload.item.server_id),
                quantity: Number(action.payload.quantity),
                name: action.payload.item.name,
                product_description: response.product_description,
                description: action.payload.item.description,
                tax_percentage: Number(action.payload.item.tax_percentage),
                tax_amount: Number(action.payload.item.tax_amount),
                tax_collected: twofix(taxCollected), //
                ebt_tax: 0,
                cash_discount_amount: 0,
                regular_price: (null != action.payload.item.online_ordering_price && undefined != action.payload.item.online_ordering_price && "" != action.payload.item.online_ordering_price) ? twofix(action.payload.item.online_ordering_price) : twofix(action.payload.item.sale_price),
                sale_price: (null != action.payload.item.online_ordering_price && undefined != action.payload.item.online_ordering_price && "" != action.payload.item.online_ordering_price) ? twofix(action.payload.item.online_ordering_price) : twofix(action.payload.item.sale_price),
                reward_points: action.payload.item.reward_points,
                extra_item: action.payload.item.extra_item
                  ? Number(action.payload.item.extra_item)
                  : Number(0),
                discount_percentage: twofix(percentage * 1), //
                sold_by_weight: action.payload.item.sold_by_weight,
                prompt_qty: action.payload.item.prompt_qty,
                total_discount: twofix(stateDiscount), //
                discount_reason: state[itemAlreadyExistsWithCoupon].discount_reason,
                exclude_ebt: action.payload.item.exclude_ebt,
                barcode: action.payload.item.barcode,
                taxes: action.payload.item.taxes,
                applied_coupon: state[itemAlreadyExistsWithCoupon].applied_coupon,
                modifier_set: action.payload.item.modifier_set,
                new_identity: action.payload.item.new_identity
                  ? action.payload.item.new_identity
                  : twofix(Math.floor(10000000 + Math.random() * 90000000)),
                total_modifiers_price: action.payload.item.total_modifiers_price,
                is_modifier_product: action.payload.item.is_modifier_product,
                discount_type: '',
                for_here:action.payload.stateProduct.for_here,
              }
              return [...state, newItem] // add new item in reducer state
            }
          } else {
            // manual
            let discount = 0
            let percentage = state[itemAlreadyExistsWithCoupon].discount_percentage // manual discount set in variable
            if (state[itemAlreadyExistsWithCoupon].discount_type == 'Percentage') {
              // manual discount in percentage
              discount = twofix(
                twofix(twofix(action.payload.item.sale_price) * twofix(action.payload.quantity)) *
                  parseFloat(percentage / 100)
              ) // cal in discount
              percentage = twofix(percentage)
            } else {
              // manual discount in amount
              discount = twofix(
                twofix(state[itemAlreadyExistsWithCoupon].discount_percentage) *
                  twofix(action.payload.quantity)
              ) // cal in discount
              percentage =
                parseFloat(
                  discount /
                    twofix(twofix(action.payload.item.sale_price) * twofix(action.payload.quantity))
                ) * 100 // cal discount percentage
            }
            let total = twofix(
              twofix(twofix(action.payload.item.sale_price) * twofix(action.payload.quantity)) -
                twofix(discount)
            ) // cal in total
            let taxCollected = twofix(
              twofix((twofix(total) * parseFloat(action.payload.item.tax_percentage)) / 100) +
                parseFloat(action.payload.item.tax_amount * twofix(action.payload.quantity))
            ) // cal in total tax collected
            var stateDiscount = twofix(discount) // set in discount variable
            if (twofix(stateDiscount) > twofix(action.payload.item.sale_price)) {
              // discount grater then sale price
              //cosider it as new product
              var newItem = {
                // new Product Object
                server_id: Number(response.server_id),
                product_id: Number(response.server_id),
                quantity: twofix(action.payload.quantity),
                name: response.name,
                product_description: response.product_description,
                description: response.description,
                tax_percentage: Number(response.tax_percentage),
                tax_amount: Number(response.tax_amount),
                tax_collected:(null != response.online_ordering_price && undefined != response.online_ordering_price && "" != response.online_ordering_price) ?
                  twofix(
                    twofix(
                      twofix(
                        twofix(response.online_ordering_price * action.payload.quantity) *
                          response.tax_percentage
                      ) / 100
                    )
                  ) + twofix(response.tax_amount * action.payload.quantity) : 
                  twofix(
                    twofix(
                      twofix(
                        twofix(response.sale_price * action.payload.quantity) *
                          response.tax_percentage
                      ) / 100
                    )
                  ) + twofix(response.tax_amount * action.payload.quantity)
                  ,
                ebt_tax: 0,
                cash_discount_amount: 0,
                regular_price: (null != response.online_ordering_price && undefined != response.online_ordering_price && "" != response.online_ordering_price) ? twofix(response.online_ordering_price) : twofix(response.sale_price),
                sale_price: (null != response.online_ordering_price && undefined != response.online_ordering_price && "" != response.online_ordering_price) ? twofix(response.online_ordering_price) : twofix(response.sale_price),
                reward_points: Number(response.reward_points),
                extra_item: response.extra_item ? Number(response.extra_item) : Number(0),
                discount_percentage: 0,
                sold_by_weight: Number(response.sold_by_weight),
                prompt_qty: Number(response.prompt_qty),
                total_discount: response.total_discount > 0.0 ? twofix(response.total_discount) : 0,
                discount_reason: '',
                exclude_ebt: Number(response.exclude_ebt),
                barcode: response.barcode,
                taxes: response.taxes,
                modifier_set: response.modifier_set,
                new_identity: response.new_identity
                  ? response.new_identity
                  : twofix(Math.floor(10000000 + Math.random() * 90000000)),
                total_modifiers_price: response.is_modifier_product
                  ? response.total_modifiers_price
                  : 0,
                is_modifier_product: response.is_modifier_product,
                discount_type: response.discount_type,
                for_here:action.payload.stateProduct.for_here,
              }
              return [...state, newItem] // new Product add in reducer
            } else {
              const newItem = {
                // create Product Object
                server_id: Number(action.payload.item.server_id),
                product_id: Number(action.payload.item.server_id),
                quantity: Number(action.payload.quantity),
                name: action.payload.item.name,
                product_description: action.payload.item.product_description,
                description: action.payload.item.description,
                tax_percentage: Number(action.payload.item.tax_percentage),
                tax_amount: Number(action.payload.item.tax_amount),
                tax_collected: twofix(taxCollected), //
                ebt_tax: 0,
                cash_discount_amount: 0,
                regular_price: action.payload.item.sale_price,
                sale_price: action.payload.item.sale_price,
                reward_points: action.payload.item.reward_points,
                extra_item: action.payload.item.extra_item
                  ? Number(action.payload.item.extra_item)
                  : Number(0),
                discount_percentage: twofix(state[itemAlreadyExistsWithCoupon].discount_percentage), //
                sold_by_weight: action.payload.item.sold_by_weight,
                prompt_qty: action.payload.item.prompt_qty,
                total_discount: twofix(stateDiscount), //
                discount_reason: state[itemAlreadyExistsWithCoupon].discount_reason,
                exclude_ebt: action.payload.item.exclude_ebt,
                barcode: action.payload.item.barcode,
                taxes: action.payload.item.taxes,
                applied_coupon: state[itemAlreadyExistsWithCoupon].applied_coupon,
                modifier_set: action.payload.item.modifier_set,
                new_identity: action.payload.item.new_identity
                  ? action.payload.item.new_identity
                  : twofix(Math.floor(10000000 + Math.random() * 90000000)),
                total_modifiers_price: action.payload.item.total_modifiers_price,
                is_modifier_product: action.payload.item.is_modifier_product,
                discount_type: state[itemAlreadyExistsWithCoupon].discount_type,
                for_here: action.payload.stateProduct.for_here,
              }
              return [...state, newItem] // new Product add in reducer
            }
          }
        } else {
          //normal product don't apply coupon when new product is added
          var newItem = {
            // create new Product Object
            server_id: Number(response.server_id),
            product_id: Number(response.server_id),
            quantity: twofix(action.payload.quantity),
            name: response.name,
            product_description: response.product_description,
            description: response.description,
            tax_percentage: Number(response.tax_percentage),
            tax_amount: Number(response.tax_amount),
            tax_collected: (null != response.online_ordering_price && undefined != response.online_ordering_price && "" != response.online_ordering_price) ? 
            twofix(
              twofix(
                twofix(
                  twofix(Number(response.online_ordering_price) * action.payload.quantity) * response.tax_percentage
                ) / 100
              ) + twofix(response.tax_amount * action.payload.quantity)
            ) : 
            twofix(
              twofix(
                twofix(
                  twofix(Number(response.sale_price) * action.payload.quantity) * response.tax_percentage
                ) / 100
              ) + twofix(response.tax_amount * action.payload.quantity)
            )
            ,
            ebt_tax: 0,
            cash_discount_amount: 0,
            regular_price: (null != response.online_ordering_price && undefined != response.online_ordering_price && "" != response.online_ordering_price) ? twofix(response.online_ordering_price) : twofix(response.sale_price),
            sale_price: (null != response.online_ordering_price && undefined != response.online_ordering_price && "" != response.online_ordering_price) ? twofix(response.online_ordering_price) : twofix(response.sale_price),
            reward_points: Number(response.reward_points),
            extra_item: response.extra_item ? Number(response.extra_item) : Number(0),
            discount_percentage: 0,
            sold_by_weight: Number(response.sold_by_weight),
            prompt_qty: Number(response.prompt_qty),
            total_discount: response.total_discount > 0.0 ? twofix(response.total_discount) : 0,
            discount_reason: '',
            exclude_ebt: Number(response.exclude_ebt),
            barcode: response.barcode,
            taxes: response.taxes,
            modifier_set: response.modifier_set,
            new_identity: response.new_identity
              ? response.new_identity
              : twofix(Math.floor(10000000 + Math.random() * 90000000)),
            total_modifiers_price: response.is_modifier_product
              ? response.total_modifiers_price
              : 0,
            is_modifier_product: response.is_modifier_product,
            discount_type: '',
            for_here: stateProduct.for_here,
          }
          return [...state, newItem] // set new Product in reducer
        }
      }
    }

    case MINUS_ITEM: {
      // using for minus Quantity in order line
      let itemAlreadyExists = _.findIndex(state, function(o) {
        return o.new_identity == action.payload.item.new_identity
      }) // item Exists or not get
      if (itemAlreadyExists > -1) {
        // item Exists in order line state
        let iteminQuestion = state[itemAlreadyExists] // Existting item set in Variable
        iteminQuestion.quantity = twofix(twofix(iteminQuestion.quantity) - 1) // minus one Quantity in Existting item
        if (
          'applied_coupon' in state[itemAlreadyExists] &&
          state[itemAlreadyExists].applied_coupon != undefined
        ) {
          // Existting item applied coupon
          var maxQty
          if (
            state[itemAlreadyExists].quantity >=
            state[itemAlreadyExists].applied_coupon.max_qty_per_coupon
          ) {
            // check item new Quantity is grater then coupon max_qty_per_coupon then set max_qty_per_coupon in maxQty
            maxQty = state[itemAlreadyExists].applied_coupon.max_qty_per_coupon
          } else {
            // set item Quantity in maxQty
            maxQty = state[itemAlreadyExists].quantity
          }
          if (state[itemAlreadyExists].applied_coupon.coupon_discount_type == 'amou') {
            // coupon discount type is amount
            var discount = twofix(state[itemAlreadyExists].applied_coupon.coupon_discount_value) // set discount value in variable
            if (discount > iteminQuestion.sale_price) {
              // discount value grater then Existting item
              iteminQuestion.total_discount = twofix(
                twofix(iteminQuestion.sale_price) * twofix(maxQty)
              ) // cal item total discount
            } else {
              iteminQuestion.total_discount = twofix(twofix(discount) * twofix(maxQty)) // cal item total discount
            }
          } else {
            // coupon discount type is Percentage
            iteminQuestion.total_discount = twofix(
              twofix(twofix(maxQty) * twofix(iteminQuestion.sale_price)) *
                parseFloat(iteminQuestion.discount_percentage / 100)
            ) // cal Existting item total discount
          }
        } else {
          // Existting item not applied coupon
          if (iteminQuestion.discount_percentage >= 0.01) {
            // manual discount applied
            if (iteminQuestion.discount_type == 'Percentage') {
              // discount type per
              iteminQuestion.total_discount = twofix(
                parseFloat(iteminQuestion.discount_percentage / 100) *
                  twofix(twofix(iteminQuestion.sale_price) * twofix(iteminQuestion.quantity))
              ) // cal total discount in Existting item
            } else {
              // discount type Amount
              iteminQuestion.total_discount = parseFloat(
                twofix(iteminQuestion.quantity) * twofix(iteminQuestion.discount_percentage)
              ) // cal total discount in Existting item
            }
          } else {
            // no discount
            iteminQuestion.total_discount = twofix(
              twofix(twofix(iteminQuestion.quantity) * twofix(iteminQuestion.sale_price)) *
                parseFloat(iteminQuestion.discount_percentage / 100)
            ) // cal total discount in Existting item
          }
        }
        iteminQuestion.tax_collected = twofix(
          twofix(
            twofix(
              twofix(twofix(iteminQuestion.sale_price) * twofix(iteminQuestion.quantity)) -
                twofix(iteminQuestion.total_discount)
            ) * parseFloat(iteminQuestion.tax_percentage / 100)
          ) + parseFloat(iteminQuestion.tax_amount * twofix(iteminQuestion.quantity))
        ) // cal Existting item total tax collected
        let newState = [...state.slice(0, itemAlreadyExists), ...state.slice(itemAlreadyExists + 1)] // remove Existting item in reducer state
        if (iteminQuestion.quantity <= 0) {
          // Existting item quantity is 0 then remove item in state
          //think last item
          return newState
        } else {
          //think not last item
          return [
            // Existting item quantity is not 0 then updated item in state set
            ...state.slice(0, itemAlreadyExists),
            iteminQuestion,
            ...state.slice(itemAlreadyExists + 1),
          ]
        }
      } else {
        // item not Exist then same state return
        return state
      }
    }

    case ADD_ITEM: {
      // using for add Quantity in order line
      let itemAlreadyExists = _.findIndex(state, function(o) {
        return o.new_identity == action.payload.item.new_identity
      }) // item Exists or not get
      if (itemAlreadyExists > -1) {
        // item Exists in order line state
        let iteminQuestion = state[itemAlreadyExists] // Existting item set in Variable
        if (
          'applied_coupon' in state[itemAlreadyExists] &&
          state[itemAlreadyExists].applied_coupon != undefined
        ) {
          // Existting item applied coupon
          var quantity = twofix(state[itemAlreadyExists].quantity) + 1 // add One Quantity in Existting item

          var maxQty
          if (quantity >= state[itemAlreadyExists].applied_coupon.max_qty_per_coupon) {
            // check item new Quantity is grater then coupon max_qty_per_coupon then set max_qty_per_coupon in maxQty
            maxQty = state[itemAlreadyExists].applied_coupon.max_qty_per_coupon
          } else {
            // set item Quantity in maxQty
            maxQty = quantity
          }
          if (state[itemAlreadyExists].applied_coupon.coupon_discount_type == 'amou') {
            // coupon discount type is amount
            iteminQuestion.quantity = twofix(twofix(iteminQuestion.quantity) + 1) // add One Quantity in Existting item
            var discount = twofix(state[itemAlreadyExists].applied_coupon.coupon_discount_value) // set coupon discount in variable
            if (discount > iteminQuestion.sale_price) {
              // Existting item sale Price less then discount
              iteminQuestion.total_discount = twofix(
                twofix(iteminQuestion.sale_price) * twofix(maxQty)
              ) // cal Existting item total discount
            } else {
              // item discount less then sale Price
              iteminQuestion.total_discount = twofix(twofix(discount) * twofix(maxQty)) // cal Existting item total discount
            }
          } else {
            // coupon discount type in Percentage
            iteminQuestion.quantity = twofix(twofix(iteminQuestion.quantity) + 1) // add One Quantity in Existting item
            iteminQuestion.total_discount = twofix(
              parseFloat(iteminQuestion.discount_percentage / 100) *
                twofix(twofix(iteminQuestion.sale_price) * twofix(maxQty))
            ) // cal Existting item total discount
          }
        } else {
          // not applied in coupon
          if (iteminQuestion.discount_percentage >= 0.01) {
            // manual discount apply in order item
            if (iteminQuestion.discount_type == 'Percentage') {
              // manual discount type Percentage
              iteminQuestion.quantity = twofix(twofix(iteminQuestion.quantity) + 1) // add One Quantity in Existting item
              iteminQuestion.total_discount = twofix(
                parseFloat(iteminQuestion.discount_percentage / 100) *
                  twofix(twofix(iteminQuestion.sale_price) * twofix(iteminQuestion.quantity))
              ) // cal Existting item total discount
            } else {
              // manual discount type Amount
              iteminQuestion.quantity = twofix(twofix(iteminQuestion.quantity) + 1) // add One Quantity in Existting item
              iteminQuestion.total_discount = parseFloat(
                twofix(iteminQuestion.quantity) * twofix(iteminQuestion.discount_percentage)
              ) // cal Existting item total discount
            }
          } else {
            // no any discount applied
            iteminQuestion.quantity = twofix(twofix(iteminQuestion.quantity) + 1) // add One Quantity in Existting item
            iteminQuestion.total_discount = twofix(
              parseFloat(iteminQuestion.discount_percentage / 100) *
                twofix(twofix(iteminQuestion.sale_price) * twofix(iteminQuestion.quantity))
            ) // cal Existting item total discount
          }
        }
        iteminQuestion.tax_collected = twofix(
          twofix(
            parseFloat(iteminQuestion.tax_percentage / 100) *
              twofix(
                twofix(twofix(iteminQuestion.sale_price) * twofix(iteminQuestion.quantity)) -
                  twofix(iteminQuestion.total_discount)
              )
          ) + parseFloat(iteminQuestion.tax_amount * twofix(iteminQuestion.quantity))
        )
        // cal Existting item total tax collected
        return [
          ...state.slice(0, itemAlreadyExists),
          iteminQuestion,
          ...state.slice(itemAlreadyExists + 1),
        ] // updated Existting item related all change set and set in state
      } else {
        // item not exist then return state
        return state
      }
    }

    case UPDATE_ITEM_QTY: {
      // use for update existting item qty
      let new_QTY = action.payload.quantity // set new qty varible
      let itemAlreadyExists = _.findIndex(state, function(o) {
        return o.new_identity == action.payload.item.new_identity
      }) // item Existst or not set in variable
      if (itemAlreadyExists > -1) {
        // item  aleready Existting
        let iteminQuestion = state[itemAlreadyExists]
        if (
          'applied_coupon' in state[itemAlreadyExists] &&
          state[itemAlreadyExists].applied_coupon != undefined
        ) {
          // Existting item applied coupon
          var maxQty
          if (new_QTY >= state[itemAlreadyExists].applied_coupon.max_qty_per_coupon) {
            // check item new Quantity is grater then coupon max_qty_per_coupon then set max_qty_per_coupon in maxQty
            maxQty = state[itemAlreadyExists].applied_coupon.max_qty_per_coupon
          } else {
            // set item Quantity in maxQty
            maxQty = new_QTY
          }
          if (state[itemAlreadyExists].applied_coupon.coupon_discount_type == 'amou') {
            // Existting item applied coupon type amount
            iteminQuestion.quantity = twofix(new_QTY) // new qty set in Existting item
            var discount = twofix(state[itemAlreadyExists].applied_coupon.coupon_discount_value) // Existting item applied coupon discount value set in variable
            if (discount > iteminQuestion.sale_price) {
              // discount value grater then Existting item sale Price
              iteminQuestion.total_discount = twofix(
                twofix(iteminQuestion.sale_price) * twofix(maxQty)
              ) // cal total discount in existting item
            } else {
              iteminQuestion.total_discount = twofix(twofix(discount) * twofix(maxQty)) // cal total discount in existting item
            }
          } else {
            // discount in per
            iteminQuestion.quantity = twofix(new_QTY) // new qty set in Existting item
            iteminQuestion.total_discount = twofix(
              parseFloat(iteminQuestion.discount_percentage / 100) *
                twofix(twofix(iteminQuestion.sale_price) * twofix(maxQty))
            ) // cal total discount in existting item
          }
        } else {
          if (iteminQuestion.discount_percentage >= 0.01) {
            // manual discount applied
            if (iteminQuestion.discount_type == 'Percentage') {
              // manal discount in percentage
              iteminQuestion.quantity = twofix(new_QTY) // new qty set
              iteminQuestion.total_discount = twofix(
                parseFloat(iteminQuestion.discount_percentage / 100) *
                  twofix(twofix(iteminQuestion.sale_price) * twofix(iteminQuestion.quantity))
              ) // cal total discount in existting item
            } else {
              iteminQuestion.quantity = twofix(new_QTY) // new qty set
              iteminQuestion.total_discount = parseFloat(
                twofix(iteminQuestion.quantity) * twofix(iteminQuestion.discount_percentage)
              ) // cal total discount in existting item
            }
          } else {
            iteminQuestion.quantity = twofix(new_QTY) // new qty set
            // iteminQuestion.total_discount = twofix(
            //   parseFloat(iteminQuestion.discount_percentage / 100) *
            //     twofix(twofix(iteminQuestion.sale_price) * twofix(iteminQuestion.quantity))
            // ) // cal total discount in existting item
            iteminQuestion.total_discount = 0
          }
        }
        // console.log("iteminQuestion.tax_percentage ++++++++++++++++",iteminQuestion.tax_percentage);
        // console.log("iteminQuestion.tax_amount ++++++++++++++++",iteminQuestion.tax_amount);
        // console.log("iteminQuestion.quantity ++++++++++++++++",iteminQuestion.quantity);
        // console.log("iteminQuestion.total_discount ++++++++++++++++",iteminQuestion.total_discount);

        iteminQuestion.tax_collected = twofix(
          twofix(
            parseFloat(iteminQuestion.tax_percentage / 100) *
              twofix(
                twofix(twofix(iteminQuestion.sale_price) * twofix(iteminQuestion.quantity)) -
                  twofix(iteminQuestion.total_discount)
              )
          ) + parseFloat(iteminQuestion.tax_amount * twofix(iteminQuestion.quantity))
        ) // cal total discount in existting item
        iteminQuestion.description = action.payload.description

        return [
          ...state.slice(0, itemAlreadyExists),
          iteminQuestion,
          ...state.slice(itemAlreadyExists + 1),
        ] // set new qty and set in existting item reducer item
      } else {
        return state
      }
    }

    case DELETE_ITEM: {
      // use for delete item in order line reducer state
      let itemAlreadyExists = _.findIndex(state, function(o) {
        return o.new_identity == action.payload.item.new_identity
      }) // item already exists or not
      if (itemAlreadyExists > -1) {
        // item already exist
        let newState = [...state.slice(0, itemAlreadyExists), ...state.slice(itemAlreadyExists + 1)] // remove item in reducer state
        return newState // return new state
      } else {
        // item  not exists then return state
        return state
      }
    }


    case DISCOUNT_PERCENTAGE_ITEM: {
      // use for set item manual discount use
      let itemAlreadyExists = _.findIndex(state, function(o) {
        return o.new_identity == action.payload.item.new_identity
      }) // set item Aleready exists or not set in variable
      if (action.payload.discount.discount_type == 'Percentage') {
        // item discount type Percentage
        if (itemAlreadyExists > -1) {
          // item already exist in state
          let iteminQuestion = state[itemAlreadyExists] // item set in variable
          let discount_percentage = action.payload.discount.amount // set item discount amount in variable
          iteminQuestion.total_discount = twofix(
            twofix(twofix(twofix(iteminQuestion.sale_price) * twofix(iteminQuestion.quantity))) *
              parseFloat(action.payload.discount.amount / 100)
          ) // cal in item total discount
          if (iteminQuestion.tax_percentage > 0 || iteminQuestion.tax_amount > 0) {
            // item tax available
            iteminQuestion.tax_collected = twofix(
              twofix(
                twofix(
                  twofix(twofix(iteminQuestion.sale_price) * twofix(iteminQuestion.quantity)) -
                    twofix(iteminQuestion.total_discount)
                ) * parseFloat(iteminQuestion.tax_percentage / 100)
              ) + parseFloat(iteminQuestion.tax_amount * twofix(iteminQuestion.quantity))
            ) // cal total tax collected
          }
          iteminQuestion.discount_percentage = twofix(discount_percentage) // set discount amount
          iteminQuestion.discount_reason = action.payload.discount.reason // set discount reason
          iteminQuestion.discount_type = action.payload.discount.discount_type // set discount type

          return [
            ...state.slice(0, itemAlreadyExists),
            iteminQuestion,
            ...state.slice(itemAlreadyExists + 1),
          ] // Existing item discount set and add
        } else {
          // item not exist then return state
          return state
        }
      } else {
        // item discount type in amount
        if (itemAlreadyExists > -1) {
          // item already exists
          let iteminQuestion = state[itemAlreadyExists] // item set variable
          let discount_percentage = action.payload.discount.amount // set discount amount
          iteminQuestion.discount_type = action.payload.discount.discount_type // set discount type
          iteminQuestion.total_discount = twofix(
            parseFloat(action.payload.discount.amount) * parseFloat(iteminQuestion.quantity)
          ) // cal item total discount
          if (iteminQuestion.tax_percentage > 0 || iteminQuestion.tax_amount > 0) {
            // Existting item tax available
            iteminQuestion.tax_collected =
              twofix(
                twofix(
                  twofix(
                    twofix(twofix(iteminQuestion.sale_price) * twofix(iteminQuestion.quantity)) -
                      twofix(iteminQuestion.total_discount)
                  ) * parseFloat(iteminQuestion.tax_percentage / 100)
                )
              ) + parseFloat(iteminQuestion.tax_amount * twofix(iteminQuestion.quantity))
            //cal item total tax collect after aplied discount
          }

          iteminQuestion.discount_percentage = twofix(discount_percentage) //set discount amount
          iteminQuestion.discount_reason = action.payload.discount.reason // set discount resone
          iteminQuestion.discount_type = action.payload.discount.discount_type // set discount type

          return [
            ...state.slice(0, itemAlreadyExists),
            iteminQuestion,
            ...state.slice(itemAlreadyExists + 1),
          ] // Existing item discount set and add
        } else {
          // item not exist then return state
          return state
        }
      }
    }

    case REMOVE_DISCOUNT_PERCENTAGE_ITEM: {
      // use for remove item manual discount
      let itemAlreadyExists = _.findIndex(state, function(o) {
        return o.new_identity == action.payload.item.new_identity
      }) // set item Aleready exists or not set in variable
      if (itemAlreadyExists > -1) {
        // item already exist in state
        let iteminQuestion = state[itemAlreadyExists] // item set in variable
        if (iteminQuestion.tax_percentage > 0 || iteminQuestion.tax_amount > 0) {
          // item tax available
          iteminQuestion.tax_collected =
            twofix(
              twofix(
                parseFloat(iteminQuestion.tax_percentage / 100) *
                  twofix(twofix(iteminQuestion.sale_price) * twofix(iteminQuestion.quantity))
              )
            ) + parseFloat(iteminQuestion.tax_amount * twofix(iteminQuestion.quantity))
          // cal total tax collected
        }
        iteminQuestion.total_discount = 0 // set total discount 0
        iteminQuestion.discount_percentage = 0 // set discount Percentage 0
        iteminQuestion.discount_reason = '' // set discount reason empty
        return [
          ...state.slice(0, itemAlreadyExists),
          iteminQuestion,
          ...state.slice(itemAlreadyExists + 1),
        ] // remove discount and item set in state
      } else {
        return state
      }
    }

    case REMOVE_ITEM: {
      // remove item  in state
      let itemAlreadyExists = _.findIndex(state, function(o) {
        return o.new_identity == action.payload.item.new_identity
      }) // set item Aleready exists or not set in variable

      if (itemAlreadyExists > -1) {
        // item Already exist
        let newState = [...state.slice(0, itemAlreadyExists), ...state.slice(itemAlreadyExists + 1)] // remove item
        return newState // return state item remove
      } else {
        // item not exits in state
        return state
      }
    }

    case CLEAR_ITEMS: {
      // clear all state value
      return []
    }

    case COMPLETE_ORDER: {
      // clear all state value
      return []
    }

    case INSERT_EXTRA_ITEM: {
      // use for extra item add in order line
      var Exclude_EBT_Value = 0
      if (action.payload.item.Ebt_item.value != undefined && action.payload.item.Ebt_item.value == 'false') {
        Exclude_EBT_Value = 1 // not ebt item
      } else {
      if (action.payload.item.Ebt_item == 1) {
        Exclude_EBT_Value = 1 // not ebt item
      } else {
        Exclude_EBT_Value = 0 // ebt item
      }
    }
      let pushItem = {
        // create item new Object
        server_id: twofix(action.payload.item.server_id),
        product_id: twofix(action.payload.item.server_id),
        extra_item: action.payload.item.extra_item,
        quantity: 1,
        name: action.payload.item.name,
        regular_price: twofix(action.payload.item.sale_price),
        sale_price: twofix(action.payload.item.sale_price),
        tax_percentage: twofix(action.payload.item.tax_percentage),
        tax_amount: twofix(action.payload.item.tax_amount),
        tax_collected: action.payload.item.tax_collected,
        reward_points: 0,
        discount_percentage: 0,
        total_discount: 0,
        discount_reason: '',
        discount_type: '',
        sold_by_weight: 0,
        prompt_qty: 0,
        barcode: action.payload.item.server_id,
        taxes: action.payload.item.taxes,
        exclude_ebt: Exclude_EBT_Value,
        ebt_tax: 0,
        cash_discount_amount: 0,
        new_identity: twofix(Math.floor(10000000 + Math.random() * 90000000)),
        modifier_set: [],
        total_modifiers_price: 0,
        is_modifier_product: 0,
        for_here: action.payload.for_here,
        total_promotion_apply_product: [],
        barcode_scanned: [],
        gift_card:action.payload.item.gift_card,
        type:action.payload.item.type,
        email:action.payload.item.email,
        phone:action.payload.item.sms,
        print_receipt:action.payload.item.print_receipt,
        description:action.payload.item.product_description,
      }
      return [...state, pushItem] // Extra item push in reducer state
    }

    case CLEAR_COUPON: {
      // use for remove all coupon
      let total = 0
      let newItem = {}
      let newProduct = []
      action.payload.order_items.map((item) => {
        // get item one by one
        if ('applied_coupon' in item && Object.keys(item['applied_coupon']).length > 0) {
          //coupon applied
          total = twofix(twofix(item.sale_price) * twofix(item.quantity)) // cal total
          newItem = {
            // remove coupon then new Object create
            server_id: twofix(item.server_id),
            product_id: twofix(item.product_id),
            quantity: twofix(item.quantity),
            name: item.name,
            tax_percentage: twofix(item.tax_percentage),
            tax_amount: twofix(item.tax_amount),
            tax_collected:
              twofix(twofix(twofix(total) * parseFloat(item.tax_percentage / 100))) +
              parseFloat(item.tax_amount * twofix(item.quantity)),
            ebt_tax: 0,
            cash_discount_amount: twofix(item.cash_discount_amount),
            regular_price: twofix(item.regular_price),
            sale_price: twofix(item.sale_price),
            reward_points: twofix(item.reward_points),
            extra_item: twofix(item.extra_item),
            discount_percentage: 0,
            sold_by_weight: twofix(item.sold_by_weight),
            prompt_qty: twofix(item.prompt_qty),
            total_discount: 0,
            discount_reason: '',
            exclude_ebt: twofix(item.exclude_ebt),
            barcode: item.barcode,
            taxes: item.taxes,
            new_identity: item.new_identity,
            modifier_set: item.modifier_set,
            total_modifiers_price: item.total_modifiers_price,
            is_modifier_product: item.is_modifier_product,
            discount_type: item.discount_type,
            for_here:item.for_here
          }
        } else {
          // no coupon applied
          newItem = item
        }
        newProduct.push(newItem) // push new item state
      })
      return newProduct
    }

    case APPLY_REWARD: {
      // use for reward coupon applied
      let newProduct = []
      let coupon_found = 0
      action.payload.order_items.map((singleProduct) => {
        // get Product one by one
        action.payload.rewardsArray.rewardsArray.map((o) => {
          // get reward one by one
          if (o.reward.reward_api_reward_coupon.product.length > 0) {
            // reward inside Product attach
            if (o.reward.reward_api_reward_coupon.product[0].id == singleProduct.server_id) {
              // order line Product id and reward coupon Product server id match
              var maxQty
              if (singleProduct.quantity >= o.reward.reward_api_reward_coupon.max_qty_per_coupon) {
                // check item new Quantity is grater then coupon max_qty_per_coupon then set max_qty_per_coupon in maxQty
                maxQty = o.reward.reward_api_reward_coupon.max_qty_per_coupon
              } else {
                // set item Quantity in maxQty
                maxQty = singleProduct.quantity
              }
              coupon_found = 1 //set coupon found 1
              let discount = 0
              let percentage = o.reward.reward_api_reward_coupon.discount_value
              if (o.reward.reward_api_reward_coupon.discount_type == 'per') {
                // reward coupon type per
                discount = twofix(
                  twofix(singleProduct.sale_price) * twofix(maxQty) * parseFloat(percentage / 100)
                ) // cal to discount
                percentage = twofix(percentage)
              } else {
                // reward coupon type Amount
                discount = twofix(
                  twofix(o.reward.reward_api_reward_coupon.discount_value) * twofix(maxQty)
                ) // cal to discount
                percentage =
                  twofix(discount / twofix(twofix(singleProduct.sale_price) * twofix(maxQty))) * 100
              }
              if (
                discount > twofix(twofix(singleProduct.sale_price) * twofix(singleProduct.quantity))
              ) {
                // discount grater then item salePrice and Quantity
                discount = twofix(twofix(singleProduct.sale_price) * twofix(singleProduct.quantity))
              }

              let total = twofix(
                twofix(twofix(singleProduct.sale_price) * twofix(singleProduct.quantity)) -
                  twofix(discount)
              ) // cal total
              let taxCollected = twofix(
                twofix((twofix(total) * parseFloat(singleProduct.tax_percentage)) / 100) +
                  parseFloat(singleProduct.tax_amount * twofix(singleProduct.quantity))
              ) // cal total tax collected

              let productReward = {
                // reward Object create
                applied: 1,
                campaign_coupon_id: 0.1,
                reward_history_id: o.reward_history.id,
                campaign_id: null,
                coupon_discount_type: o.reward.reward_api_reward_coupon.discount_type,
                coupon_discount_value: o.reward.reward_api_reward_coupon.discount_value,
                coupon_id: o.reward.reward_api_reward_coupon.id,
                reward_id: o.reward_id,
                coupon_name: o.reward.name,
                product_id: o.reward.reward_api_reward_coupon.product[0].id,
                product_name: o.reward.reward_api_reward_coupon.product[0].name,
                reason: 'REWARD APPLIED',
                reject: 0,
                reward_card_id: null,
                reward_flag: true,
                max_qty_per_coupon: o.reward.reward_api_reward_coupon.max_qty_per_coupon,
              }
              var stateDiscount = twofix(discount)
              const newItem = {
                // reward applied item new object create
                server_id: singleProduct.server_id,
                product_id: singleProduct.product_id,
                quantity: singleProduct.quantity,
                name: singleProduct.name,
                product_description: singleProduct.product_description,
                description: singleProduct.description,
                tax_percentage: singleProduct.tax_percentage,
                tax_amount: singleProduct.tax_amount,
                tax_collected: twofix(taxCollected),
                ebt_tax: 0,
                cash_discount_amount: 0,
                regular_price: singleProduct.regular_price,
                sale_price: singleProduct.sale_price,
                reward_points: singleProduct.reward_points,
                extra_item: singleProduct.extra_item,
                discount_percentage: twofix(percentage * 1),
                sold_by_weight: singleProduct.sold_by_weight,
                prompt_qty: singleProduct.prompt_qty,
                total_discount: twofix(stateDiscount),
                discount_reason: 'REWARD APPLIED',
                exclude_ebt: singleProduct.exclude_ebt,
                barcode: singleProduct.barcode,
                taxes: singleProduct.taxes,
                applied_coupon: productReward,
                modifier_set: singleProduct.modifier_set,
                new_identity: singleProduct.new_identity,
                total_modifiers_price: singleProduct.total_modifiers_price,
                is_modifier_product: singleProduct.is_modifier_product,
                discount_type: singleProduct.discount_type,
                for_here: singleProduct.for_here
              }
              newProduct.push(newItem) // push in array
            }
            coupon_found = 0 // coupon not found
          }
        })
        if (!coupon_found) {
          const exists = _.findIndex(newProduct, function(pro) {
            return pro.new_identity == singleProduct.new_identity
          }) // coupon not find item set
          if (exists < 0) {
            // item not in newProduct array
            const newItem = singleProduct // item set in variable
            newProduct.push(newItem) // push item in array
          }
        }
      })
      return newProduct
    }
    case APPLY_COUPON: {
      // use for apply coupon
      let newProduct = []
      let coupon_found = 0
      action.payload.order_items.map((singleProduct) => {
        // order line item get one by one
        action.payload.coupon_status.products.map(function(o) {
          // coupon Product get
          if (
            o.product_id == singleProduct.server_id &&
            !o.reject &&
            singleProduct.total_discount == 0
          ) {
            // check item not reject , order line item and coupon item id same and Product total discount is 0
            o.reward_history_id = null
            o.reward_id = null
            coupon_found = 1
            let discount = 0
            let percentage = o.coupon_discount_value
            var maxQty
            if (singleProduct.quantity >= o.max_qty_per_coupon) {
              // check item new Quantity is grater then coupon max_qty_per_coupon then set max_qty_per_coupon in maxQty
              maxQty = o.max_qty_per_coupon
            } else {
              // set item Quantity in maxQty
              maxQty = singleProduct.quantity
            }
            if (o.coupon_discount_type == 'per') {
              // coupon discount type is Percentage
              discount = twofix(
                twofix(twofix(singleProduct.sale_price) * twofix(maxQty)) *
                  parseFloat(percentage / 100)
              ) // cal discount
              percentage = twofix(percentage)
            } else {
              // coupon discount type is Amount
              discount = twofix(twofix(o.coupon_discount_value) * twofix(maxQty)) // cal discount
              percentage =
                parseFloat(
                  discount /
                    twofix(twofix(singleProduct.sale_price) * twofix(singleProduct.quantity))
                ) * 100 //cal Percentage
            }
            let total = twofix(
              twofix(twofix(singleProduct.sale_price) * twofix(singleProduct.quantity)) -
                twofix(discount)
            ) // cal total
            let taxCollected = twofix(
              twofix((twofix(total) * parseFloat(singleProduct.tax_percentage)) / 100) +
                parseFloat(singleProduct.tax_amount * twofix(singleProduct.quantity))
            ) // cal tax collected
            var stateDiscount = twofix(discount)
            const newItem = {
              // applied coupon then item object create
              server_id: singleProduct.server_id,
              product_id: singleProduct.product_id,
              quantity: singleProduct.quantity,
              name: singleProduct.name,
              product_description: singleProduct.product_description,
              description: singleProduct.description,
              tax_percentage: singleProduct.tax_percentage,
              tax_amount: singleProduct.tax_amount,
              tax_collected: twofix(taxCollected),
              ebt_tax: 0,
              cash_discount_amount: 0,
              regular_price: singleProduct.regular_price,
              sale_price: singleProduct.sale_price,
              reward_points: singleProduct.reward_points,
              extra_item: singleProduct.extra_item,
              discount_percentage: twofix(percentage * 1),
              sold_by_weight: singleProduct.sold_by_weight,
              prompt_qty: singleProduct.prompt_qty,
              total_discount: twofix(stateDiscount),
              discount_reason: Constant.COUPON_APPLIED,
              exclude_ebt: singleProduct.exclude_ebt,
              barcode: singleProduct.barcode,
              taxes: singleProduct.taxes,
              applied_coupon: o,
              modifier_set: singleProduct.modifier_set,
              new_identity: singleProduct.new_identity,
              total_modifiers_price: singleProduct.total_modifiers_price,
              is_modifier_product: singleProduct.is_modifier_product,
              discount_type: singleProduct.discount_type,
              for_here:singleProduct.for_here
            }
            newProduct.push(newItem) // push in array
          }
          coupon_found = 0 // set coupon found 0
        })
        if (!coupon_found) {
          // not applied coupon
          const exists = _.findIndex(newProduct, function(pro) {
            return pro.new_identity == singleProduct.new_identity
          }) // check not coupon applied item exists in newProduct
          if (exists < 0) {
            // item not exists in array
            const newItem = singleProduct
            newProduct.push(newItem) // push item in array
          }
        }
      })
      return newProduct // return new array
    }

    case REMOVE_ITEM_COUPON: {
      // use for Perticular item coupon remove
      let itemAlreadyExists = _.findIndex(state, function(o) {
        return o.new_identity == action.payload.item.new_identity
      }) // item Exists or not set in variable
      if (itemAlreadyExists > -1) {
        // item Exists
        let iteminQuestion = state[itemAlreadyExists] // set item in variable
        let total_discount = twofix(state[itemAlreadyExists].total_discount) // set total discount in varible
        if (iteminQuestion.tax_percentage > 0 || iteminQuestion.tax_amount > 0) {
          // item tax available
          var statetaxCollected = twofix(
            twofix(
              parseFloat(iteminQuestion.tax_percentage / 100) *
                twofix(
                  twofix(twofix(iteminQuestion.sale_price) * twofix(iteminQuestion.quantity)) 
                )
            ) + parseFloat(iteminQuestion.tax_amount * twofix(iteminQuestion.quantity))
          ) // cal tax collected
          iteminQuestion.tax_collected = twofix(statetaxCollected) // set in Object
        }
        iteminQuestion.total_discount = 0 // set discount 0
        iteminQuestion.discount_percentage = 0 // set discount Percentage 0
        iteminQuestion.discount_reason = '' // set discount reason Empty
        delete iteminQuestion.applied_coupon // delete applied coupon object
        return [
          ...state.slice(0, itemAlreadyExists),
          iteminQuestion,
          ...state.slice(itemAlreadyExists + 1),
        ] // remove coupon item set in reducer state
      } else {
        // item not exists then return state
        return state
      }
    }

    case UPDATE_ITEM_MODIFIERS: {
      // Modifiers Product modifier Update then use
      let itemAlreadyExists = _.findIndex(state, function(o) {
        return o.new_identity == action.payload.item.new_identity
      }) // item Exists or not set in variable
      if (itemAlreadyExists > -1) {
        // item Exists
        let iteminQuestion = state[itemAlreadyExists] // set item in variable
        iteminQuestion.sale_price = action.payload.item.sale_price // set item sale Price
        iteminQuestion.modifier_set = action.payload.item.modifier_set // set item modifiers set in Variable
        iteminQuestion.total_modifiers_price = action.payload.item.total_modifiers_price // set total modifier set Price
        iteminQuestion.is_modifier_product = action.payload.item.is_modifier_product // item modifiers or not set in variable
        if (
          'applied_coupon' in state[itemAlreadyExists] &&
          state[itemAlreadyExists].applied_coupon != undefined
        ) {
          // check Existting item coupon applied or not
          var maxQty
          if (
            iteminQuestion.quantity >= state[itemAlreadyExists].applied_coupon.max_qty_per_coupon
          ) {
            // check item new Quantity is grater then coupon max_qty_per_coupon then set max_qty_per_coupon in maxQty
            maxQty = state[itemAlreadyExists].applied_coupon.max_qty_per_coupon
          } else {
            // set item Quantity in maxQty
            maxQty = iteminQuestion.quantity
          }
          if (state[itemAlreadyExists].applied_coupon.coupon_discount_type == 'amou') {
            // applied coupon type amount
            var discount = twofix(state[itemAlreadyExists].applied_coupon.coupon_discount_value) // cal item discount
            iteminQuestion.total_discount = twofix(twofix(discount) * twofix(maxQty))
          } else {
            // applied coupon type Percentage
            iteminQuestion.total_discount = twofix(
              parseFloat(iteminQuestion.discount_percentage / 100) *
                twofix(twofix(action.payload.item.sale_price) * twofix(maxQty))
            ) // cal total discount
          }
        } else {
          // coupon not applied
          if (iteminQuestion.discount_percentage >= 0.01) {
            // manual disount applied in item
            if (iteminQuestion.discount_type == 'Percentage') {
              // manual discount type Percentage
              iteminQuestion.total_discount = twofix(
                parseFloat(iteminQuestion.discount_percentage / 100) *
                  twofix(twofix(action.payload.item.sale_price) * twofix(iteminQuestion.quantity))
              ) // cal total discount
            } else {
              // manual discount type Amount
              iteminQuestion.total_discount = parseFloat(
                twofix(iteminQuestion.quantity) * twofix(iteminQuestion.discount_percentage)
              ) // cal total discount
            }
          } else {
            // iteminQuestion.total_discount = twofix(
            //   parseFloat(iteminQuestion.discount_percentage / 100) *
            //     twofix(twofix(action.payload.item.sale_price) * twofix(iteminQuestion.quantity))
            // ) // cal total discount
            iteminQuestion.total_discount = 0
          }
        }
        iteminQuestion.tax_collected =
          twofix(
            twofix(
              parseFloat(iteminQuestion.tax_percentage / 100) *
                twofix(
                  twofix(twofix(action.payload.item.sale_price) * twofix(iteminQuestion.quantity)) -
                    twofix(iteminQuestion.total_discount)
                )
            )
          ) + parseFloat(iteminQuestion.tax_amount * twofix(iteminQuestion.quantity)) // cal total tax collected

        return [
          ...state.slice(0, itemAlreadyExists),
          iteminQuestion,
          ...state.slice(itemAlreadyExists + 1),
        ] // new object return
      }
    }

    case TICKET_ORDER_ITEMS_COUPON_APPLY: {
      // ticket Order line item Coupon apply then use
      let newProduct = []
      let coupon_found = 0
      action.payload.order_items.map((singleProduct) => {
        // get order item one by one
        action.payload.coupon_status.products.map(function(o) {
          // get coupon add Product one by one
          if (o.product_id == singleProduct.server_id) {
            // order line item and coupon item same
            coupon_found = 1 // coupon found set 1
            let product = { ...singleProduct, applied_coupon: o } // set new Object in variable
            product.applied_coupon.applied = 0 // set applied coupon 0
            newProduct.push(product) // push in array
          }
          coupon_found = 0 // coupon not found set 0
        })
        if (!coupon_found) {
          // coupon not found
          const exists = _.findIndex(newProduct, function(pro) {
            return (
              pro.product_id == singleProduct.server_id &&
              _.isEqual(pro.modifier_set, singleProduct.modifier_set)
            )
          }) // item Exists or not set in variable
          if (exists < 0) {
            // item not exists in array
            const newItem = singleProduct
            newProduct.push(newItem) // push in array
          }
        }
      })
      return newProduct // return Order line array
    }

    case INSERT_TICKET_LINES_TO_ORDER_LINES: {
      
      // ticket line set in Order Line
      let newProduct = []
      action.payload.reOrder_item.map((line) => {
        // ticket line item get one by one
        // line['modifier_set'] = JSON.parse(line['modifier_set']) // Modifiers set
        // line['modifier_set'].map((single) => {
        //   // get modifiers one by one
        //   single['modifiers'] = single.modifiers // set all modifiers
        //   single['selected_modifiers'] = single.selected_modifiers // set selected all modifiers
        // })
        action.payload.product_data.map((item) => {
          let item_total_modifiers_price = 0
          if(item.id == line.product_id) {
            let sale_price = (null != item.online_ordering_price 
              && undefined != item.online_ordering_price 
              && "" != item.online_ordering_price) ? item.online_ordering_price 
              : item.sale_price
            const sortOrder = {
              modifiers_set_name: 1,
              modifiers_set_only_one: 2,
              modifiers_set_server_id: 3,
              modifiers: 4,
              selected_modifiers: 5,
            }
            let new_modifier_set = []
            if(item['modifier_set'].length > 0) {
              item['modifier_set'].map((o) => {
                if(line['modifier_set'].length > 0 ) {
                  line['modifier_set'].map((o1) => {
                    if(o.id === o1.server_id) {
                      let modifier = []
                      let selected_modifiers = []
                      o['modifiers'].map((mod) => {
                            o1['modifiers'].map((mod1) => {
                              if(mod.id === mod1.server_id) {
                                let modObj = {
                                  id: mod1.id,
                                  name: mod1.name,
                                  price: mod.price,
                                  sort: mod1.sort,
                                  modifier_set_id: mod1.modifier_set_id,
                                  deleted_at: mod1.deleted_at,
                                  server_id: mod1.server_id
                                }

                                  if(o1['selected_modifiers'].length > 0) {
                                    o1['selected_modifiers'].map((smod) => {
                                      if(smod.server_id == mod1.server_id) {
                                        selected_modifiers.push(modObj)
                                        // modifier.push(modObj)
                                      } else {
                                        // modifier.push(modObj)
                                      }
                                    })
                                  } 
                                  modifier.push(modObj)
                                
                              }
                            })
                      })
                      let total_modifiers_price = 0;
                        if (selected_modifiers.length > 0) {
                          let single_price = selected_modifiers
                            .map((item) => item.price)
                            .reduce((prev, next) => prev + next);
                          total_modifiers_price = total_modifiers_price + single_price;
                        } else {
                          total_modifiers_price = 0;
                        }
                        item_total_modifiers_price = item_total_modifiers_price + total_modifiers_price
                      let obj = {
                        "id": o['id'],
                        "server_id": o['id'],
                        "name": o['name'],
                        "only_one": o['only_one'],
                        "modifiers": modifier,
                        "selected_modifiers": selected_modifiers,
                        "total_modifiers_price": total_modifiers_price
                      }
                      new_modifier_set.push(obj)
                    } 
                   
                  })
                } else {
                  let obj = {
                    "id": o['id'],
                    "server_id": o['id'],
                    "name": o['name'],
                    "only_one": o['only_one'],
                    "modifiers": o['modifiers'],
                    "selected_modifiers": [],
                    "total_modifiers_price": 0
                  }
                  new_modifier_set.push(obj)
                }
                
              })
            }
            // modifiers sortting
            const res = new_modifier_set.map((o) =>
              Object.assign(
                {},
                ...Object.keys(o)
                  .sort((a, b) => sortOrder[a] - sortOrder[b])
                  .map((x) => {
                    return { [x]: o[x] }
                  })
              )
            )
            line['modifier_set'] = res // set sort wise modifiers
            let singleLine = {}
            let item_taxCollected = twofix(
              twofix(
                parseFloat(Number(item.tax_percentage) / 100) *
                  twofix(
                    twofix(twofix(twofix(sale_price) + twofix(item_total_modifiers_price)) * twofix(line.quantity)) -
                      twofix(line.total_discount)
                  )
              )
            ) + parseFloat(Number(item.tax_amount) * twofix(line.quantity)) 
            if (line.campaign_coupon_id != 0 && line.campaign_coupon_id != null) {
              // check coupon apply or not
              singleLine = {
                // create item coupon object
                coupon_name: line.coupon_name,
                coupon_discount_type: line.coupon_discount_type,
                coupon_discount_value: line.coupon_discount_value * 1,
                coupon_id: line.coupon_id,
                campaign_id: line.campaign_id,
                reward_card_id: line.reward_card_id,
                campaign_coupon_id: twofix(line.campaign_coupon_id),
                applied: line.applied,
                reject: line.reject,
                reason: line.reason,
                product_id: line.product_id,
                product_name: line.product_name,
                reward_history_id: line.reward_history_id,
                reward_id: twofix(line.reward_id),
                max_qty_per_coupon: twofix(line.max_qty_per_coupon),
              }
              var pushWithCouponItem = {
                // set item new object
                product_id: line.product_id,
                quantity: twofix(line.quantity),
                name: line.product_name,
                description: line.description,
                regular_price: twofix(line.regular_price),
                sale_price: twofix(line.sale_price),
                tax_percentage: Number(line.tax_percentage),
                tax_amount: Number(line.tax_amount),
                tax_collected: twofix(line.tax_collected),
                ebt_tax: line.ebt_tax,
                cash_discount_amount: line.cash_discount_amount,
                reward_points: Number(line.reward_points),
                extra_item: Number(line.extra_item),
                server_id: Number(line.product_id),
                barcode: line.barcode,
                sold_by_weight: Number(line.sold_by_weight),
                prompt_qty: Number(line.prompt_qty),
                exclude_ebt: Number(line.exclude_ebt),
                discount_percentage: Number(line.discount_percentage),
                total_discount: twofix(line.total_discount),
                discount_reason: line.discount_reason,
                taxes: line.ticket_taxes == undefined ? {} : line.ticket_taxes,
                applied_coupon: singleLine,
                modifier_set: line.modifier_set,
                new_identity: line.new_identity,
                total_modifiers_price: line.total_modifiers_price,
                is_modifier_product: line.is_modifier_product,
                discount_type: line.discount_type,
                for_here: line.for_here
              }
              newProduct.push(pushWithCouponItem) // push in array
            } else {
              // coupon not applied
              var pushWithoutCouponItem = {
                // create item new object
                product_id: line.product_id,
                quantity: twofix(line.quantity),
                name: line.name,
                description: line.description,
                regular_price: twofix(line.regular_price),
                sale_price: twofix(twofix(sale_price) + twofix(item_total_modifiers_price)),
                tax_percentage: Number(item.tax_percentage),
                tax_amount: Number(item.tax_amount),
                tax_collected: Number(item_taxCollected),
                ebt_tax: line.ebt_tax,
                cash_discount_amount: 0,
                reward_points: Number(line.reward_points),
                extra_item: Number(line.extra_item),
                server_id: line.server_id,
                barcode: line.barcode,
                sold_by_weight: Number(line.sold_by_weight),
                prompt_qty: Number(line.prompt_qty),
                exclude_ebt: Number(line.exclude_ebt),
                discount_percentage: Number(line.discount_percentage),
                total_discount: twofix(line.total_discount),
                discount_reason: line.discount_reason,
                taxes: (null != item.taxes && undefined != item.taxes && "" != item.taxes && item.taxes.length > 0) ? item.taxes : [],
                modifier_set: line.modifier_set,
                new_identity: twofix(Math.floor(10000000 + Math.random() * 90000000)),
                total_modifiers_price: twofix(item_total_modifiers_price),
                is_modifier_product: line.is_modifier_product,
                discount_type: line.discount_type,
                for_here:line.for_here
              }
              newProduct.push(pushWithoutCouponItem) // push in array
            }
          }
        })
        
      })
      return newProduct // return array
    }

    case UPDATE_IS_GO: {
      let itemAlreadyExists = _.findIndex(state, function(o) {
        return o.new_identity == action.payload.item.new_identity
      })
      if (itemAlreadyExists > -1) {
        let iteminQuestion = state[itemAlreadyExists]
        iteminQuestion.for_here = action.payload.for_here

        return [
          ...state.slice(0, itemAlreadyExists),
          iteminQuestion,
          ...state.slice(itemAlreadyExists + 1),
        ]
      }
    }

    case ALL_ITEM_FOR_HERE: {
      if(action.payload.order_Line.length > 0){
        action.payload.order_Line.map((item) => {
      let itemAlreadyExists = _.findIndex(state, function(o) {
        return o.new_identity == item.new_identity
      })
      if (itemAlreadyExists > -1) {
        let iteminQuestion = state[itemAlreadyExists]
        iteminQuestion.for_here = action.payload.for_here
      }
        })
        return state
      }else{
        return state
      } 
    }
  }
  return state
}
