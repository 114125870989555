import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import {
  currentItem,
  removeCurrentItem,
} from "../../../../actions/current_item";
import { insertItem } from "../../../../actions/order_items";
import { orderCalculation } from "../../../../actions/order_actions";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { updateOrdertype } from "../../../../actions/delivery_pickup_action";
import { Paper, Grid, Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { orderInstruction } from "../../../../actions/order_actions";
import * as Constant from "../../../../constants"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = (theme) => ({
  flexLayout: {
    flex: 10,
    flexDirection: "row",
    alignItems: "stretch",
    overflow: "hidden",
    marginBottom: 7,
    padding: 13,
  },
  buttonLayout: {
    display: "flex",
    flex: 0.6,
    padding: 8,
    marginBottom: 10,
    justifyContent: "center",
    backgroundColor: "#fff",
  },
});

class Instruction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      instruction: this.props.order.instruction,
      errors: {
        instruction: "",
        errorMessage: ""
      },
    };
  }

  componentDidMount() {
    if(null != this.props.order.instruction &&
      undefined != this.props.order.instruction &&
      "" != this.props.order.instruction) {
        this.setState({ instruction: this.props.order.instruction})
      }
  }

  componentWillUnmount() {}

  addInstruction = () => {
    if (
      null != this.state.instruction &&
      undefined != this.state.instruction
    ) {
      if(this.state.instruction.length <= 255) {
        this.props.orderInstruction(this.state.instruction);
        this.goBack(this.props.layout);
      } else {
        toast.error(Constant.VALID_ORDER_INSTRUCTION);
      }
    } else {
      toast.error(Constant.VALID_INSTRUCTION);
    }
  };

  handleInputChange(event, from) {
    event.preventDefault();
    this.setState({
      [from]: event.target.value,
    });
    if(event.target.value.length > 255) {
      this.setState({ errorMessage : Constant.VALID_ORDER_INSTRUCTION})
    }else {
      this.setState({ errorMessage : ""})

    }
  }

  goBack = (type) => {
    this.props.checkOutInsidePage(type);
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Paper elevation={1} square className={classes.flexLayout}>
          <Grid
            container
            direction="row"
            // justifyContent="center"
            alignItems="center"
          >
            <Grid xs={2} md={2} lg={2} item>
            <Link  to="#" className="text-dark font-bold">
              <ArrowBackIcon onClick={() => this.goBack(this.props.layout)} />
              </Link>
            </Grid>
            <Grid xs={8} md={8} lg={8} item>
              <h1 className="sidebar-layout-header-name">Instruction</h1>
            </Grid>
          </Grid>

          <div className="form-group">
            <textarea
              autoFocus
              className={this.state.instruction.length <= 255 ? "form-control" : 'form-control1'}
              id="exampleFormControlTextarea1"
              placeholder="Add Order Instruction"
              rows="5"
              // MaxLength="255"
              value={this.state.instruction}
              onChange={(e) => this.handleInputChange(e, "instruction")}
              // onKeyPress={event => {
              //   if (event.key === 'Enter') {
              //     this.addInstruction()
              //   }
              // }}
              style={{ width: "100%", marginTop: "5%" }}
            />
            <span style={{ color : 'red'}}>{this.state.errorMessage}</span>
          </div>
        </Paper>
        <Paper elevation={1} square className={classes.buttonLayout}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "100%", height: "93%" }}
            onClick={() => this.addInstruction()}
            // disabled={this.state.instruction == ""}
          >
            Submit
          </Button>
        </Paper>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      currentItem,
      removeCurrentItem,
      orderCalculation,
      insertItem,
      updateOrdertype,
      orderInstruction,
    },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    current_item: state.current_item,
    locations: state.locations,
    delivery_pickup: state.delivery_pickup,
    order: state.order,
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true })
)(Instruction);
