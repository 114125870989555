import { ADD_LOCATION,REMOVE_LOCATION } from '../actions/location_action';

export default function (state = [], action) {
    switch (action.type){
    case ADD_LOCATION:
        {
            return  action.payload;
        }
    case REMOVE_LOCATION:
        {
                return []
        }
    }
    
    return state;
}