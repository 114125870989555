import React from "react";
import { withStyles } from "@material-ui/styles";
import { orderUser } from "../../../../actions/user_actions";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import {
  Paper,
  Button ,
  TextField,Grid
} from "@material-ui/core";
import * as Constant from "../../../../constants"
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";
import { toast } from "react-toastify";

const useStyles = (theme) => ({
  flexLayout: {
    flex: 10,
    flexDirection: "row",
    alignItems: "stretch",
    overflow: "hidden",
    padding:13,
    marginBottom:8,
    backgroundColor:"#fff",
    overflowY: "scroll",
  },
  buttonLayout: {
    display: "flex",
    flex: 0.6,
    padding: 8,
    justifyContent: "center",
    backgroundColor:"#fff",
  },
});

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView();

  return { executeScroll, elRef };
};
class WhoIsThisFor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      item_qty: 1,
      number: "",
      isDelivery: false,
      isPickUp: false,
      deliveryTime: "",
      changePickup: false,
      first_name: "",
      last_name: "",
      loading: false,
      errors: {
        first_name: "",
        last_name: "",
        number: "",
      },
    };
    this.inputRefs = []
    this.elScroll = utilizeScroll();
  }

  componentDidMount() {
    if(null != this.props.user.whoIsThis && undefined != this.props.user.whoIsThis && "" != this.props.user.whoIsThis) {
      this.setState({ first_name: this.props.user.whoIsThis.first_name, last_name:this.props.user.whoIsThis.last_name, number:this.props.user.whoIsThis.phone})
    } else if (null != this.props.user.user && undefined != this.props.user.user && "" != this.props.user.user) {
      this.setState({ first_name: this.props.user.user.first_name, last_name:this.props.user.user.last_name, number:this.props.user.user.phone})
    }
  }

  componentWillUnmount() {
      this.setState = (state,callback)=>{
        return;
    };
  }

  AddOrderUser = () => {
    this.elScroll.executeScroll()
    this.setState({loading:true})
    if (
      null != this.state.first_name &&
      undefined != this.state.first_name &&
      "" != this.state.first_name.trim() &&
      "" == this.state.errors.first_name
    ) {
      if (
        null != this.state.last_name &&
        undefined != this.state.last_name &&
        "" != this.state.last_name.trim() &&
        "" == this.state.errors.last_name
      ) {
        if (
          null != this.state.number &&
          undefined != this.state.number &&
          "" != this.state.number &&
          "" == this.state.errors.number
        ) {
          
          let payload = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            phone: this.state.number,
          };
          setTimeout(() => {
            this.props.orderUser(payload);
          this.goBack("checkout");
          this.setState({loading:false})
          }, 500)
        } else {
          this.setState({loading:false})
      toast.error(Constant.USER_PHONE_NUMBER_REQUIRED)
        }
      } else {
        this.setState({loading:false})
      toast.error(Constant.USER_LAST_NAME_REQUIRED)
      }
    } else {
      this.setState({loading:false})
      toast.error(Constant.USER_FIRST_NAME_REQUIRED)
    }
  };

  validate = (phone) => {
    const regex = /^[0-9\b]+$/;
    return regex.test(phone)
  }
  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const that = this;
    let errors = this.state.errors;
    switch (name) {
      case "first_name":
        errors.first_name =
          value.length === 0
            ? Constant.USER_FIRST_NAME_REQUIRED
            : value.length > 255
            ? Constant.USER_FIRST_NAME_LENGTH_GREATE_THEN_255
            : "";
        break;
      case "last_name":
        errors.last_name =
          value.length === 0
            ? Constant.USER_LAST_NAME_REQUIRED
            : value.length > 255
            ? Constant.USER_LAST_NAME_LENGTH_GREATE_THEN_255
            : "";
        break;
      case "number":
        errors.number =
          value.length === 0
            ? Constant.USER_PHONE_NUMBER_REQUIRED
            : this.validate(value)
            ? value.length > 10
            ? Constant.USER_PHONE_NUMBER_GREATER_THEN_10
            :  value.length < 3
            ? Constant.USER_PHONE_NUMBER_MUST_BE_3_DIGITS
            :  ""
            : Constant.USER_PHONE_NUMBER__NOT_VALID;
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };
  _handleKeyPress = e => {
    const event = e;
    const { currentTarget } = e;
    if (event.key === "Enter") {
      let inputIndex = this.inputRefs.indexOf(currentTarget);
      if (inputIndex < this.inputRefs.length - 1) {
        this.inputRefs[inputIndex + 1].focus();
      } else {
        this.inputRefs[0].focus();
      }
      event.preventDefault();
    }
  };

  goBack = (type) => {
    this.props.checkOutInsidePage(type);
  };

  render() {
    const { classes } = this.props;
    return (
      <>
      <Paper elevation={1} className={classes.flexLayout}>
      <div ref={this.elScroll.elRef}></div>
        {this.state.loading ? <LinearProgress color="primary" /> : null}
      <Grid
            container
            direction="row"
            // justifyContent="center"
            alignItems="center"
          >
            <Grid xs={2} md={2} lg={2} item>
            <Link  to="#" className="text-dark font-bold">
          <ArrowBackIcon onClick={() => this.goBack("checkout")} />
          </Link>
            </Grid>
            <Grid xs={8} md={8} lg={8} item>
              <h1 className="sidebar-layout-header-name">{"Who is this for ?"}</h1>
            </Grid>
          </Grid>
      
          <TextField
          autoFocus
           style={{ margin: "2%",borderRadius:0 ,width: "96%"}}
            error={this.state.errors.first_name.length == 0 ? false : true}
            fullWidth
            size="small"
            helperText={this.state.errors.first_name}
            label="First Name *"
            name="first_name"
            onChange={this.handleInputChange}
            onBlur={this.handleInputChange}
            inputProps={{ onKeyPress: this._handleKeyPress }}
            inputRef={ref => this.inputRefs.push(ref)}
            type="text"
            value={this.state.first_name}
            variant="outlined"
          />
          <TextField
           style={{ margin: "2%",borderRadius:0 ,width: "96%"}}
            error={this.state.errors.last_name.length == 0 ? false : true}
            fullWidth
            size="small"
            helperText={this.state.errors.last_name}
            label="Last Name *"
            name="last_name"
            onChange={this.handleInputChange}
            onBlur={this.handleInputChange}
            inputRef={ref => this.inputRefs.push(ref)}
            ref={"input2"}
            inputProps={{ onKeyPress: this._handleKeyPress }}
            type="text"
            value={this.state.last_name}
            variant="outlined"
          />
          <TextField
            style={{ margin: "2%",borderRadius:0 ,width: "96%"}}
            error={this.state.errors.number.length == 0 ? false : true}
            fullWidth
            size="small"
            helperText={this.state.errors.number}
            label="Phone Number *"
            name="number"
            onChange={this.handleInputChange}
            onBlur={this.handleInputChange}
            inputRef={ref => this.inputRefs.push(ref)}
            ref={"input3"}
            // inputProps={{ onKeyPress: this._handleKeyPress }}
             onKeyPress= {(e) => {
              if (e.key === 'Enter' && this.state.first_name !== "" && this.state.errors.first_name.length === 0 && this.state.last_name !== "" && this.state.errors.last_name.length === 0 && this.state.number !== "" && this.state.errors.number.length === 0) {
                this.AddOrderUser()
              }
            }}
            type="text"
            value={this.state.number}
            variant="outlined"
          />
      </Paper>
      <Paper elevation={1} className={classes.buttonLayout}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "100%", height: "93%" }}
            onClick={() => this.AddOrderUser()}
            disabled={(this.state.first_name == "" || this.state.errors.first_name.length > 0 || this.state.last_name == "" || this.state.errors.last_name.length > 0 || this.state.number == "" || this.state.errors.number.length > 0)}
          >
            Continue
          </Button>
          </Paper>
    </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ orderUser },dispatch);
}
function mapStateToProps(state) {
  return {
    user: state.user
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true })
)(WhoIsThisFor);
