import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import {
  currentItem,
  removeCurrentItem,
} from "../../../../actions/current_item";
import { insertItem, addExtraItem } from "../../../../actions/order_items";
import { orderCalculation } from "../../../../actions/order_actions";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { updateOrdertype } from "../../../../actions/delivery_pickup_action";
import { Paper, Grid, Button, Typography, TextField } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { orderInstruction } from "../../../../actions/order_actions";
import * as Constant from "../../../../constants";
import { toast } from "react-toastify";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import { checkGiftCardNumber } from "../../../../services/order/order";
import { removeUserCardDetails } from "../../../../actions/card_Details_action";
import { orderUser, userLogout } from "../../../../actions/user_actions";
import "react-toastify/dist/ReactToastify.css";

const useStyles = (theme) => ({
  flexLayout: {
    flex: 10,
    flexDirection: "row",
    alignItems: "stretch",
    overflow: "hidden",
    marginBottom: 7,
    padding: 13,
  },
  buttonLayout: {
    display: "flex",
    flex: 0.6,
    padding: 8,
    marginBottom: 10,
    justifyContent: "flex-end",
    backgroundColor: "#fff",
  },
  buttonLayout1: {
    display: "flex",
    flex: 0.6,
    padding: 8,
    marginBottom: 10,
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  headerName: { fontSize: 25, fontWeight: "bold" },
  headerName1: { fontSize: 25, fontWeight: "bold",marginTop:"3%" },
  subtitleName: { fontSize: 20, fontWeight: "bold" },
});

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
);

class SellGiftCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      number: "",
      selectButtonAmount: 0.0,
      errors: {
        number: "",
        email: "",
        sms: "",
        message: "",
      },
      firstLayout: true,
      secondLayout: false,
      generateGiftNumber: 0,
      email: "",
      sms: "",
      message: "",
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleGiftCardAmount = (amount) => {
    this.setState({ selectButtonAmount: amount, number: "", errors: {
      number: "",
      email: "",
      sms: "",
      message: "",
    },});
  };
  validate = (number) => {
    const regex = /^\d+(\.\d{1,2})?$/;
    return regex.test(number.trim());
  };
  validatephone = (phone) => {
    const regex = /^[0-9\b]+$/;
    return regex.test(phone);
  };
  goBack = (type) => {
    this.props.checkOutInsidePage(type, "SellGiftCard");
  };
  handleSubmit = async () => {
    if (this.props.order_items?.length > 0) {
      toast.error(Constant.CAN_NOT_ADD_GIFT_CARD);
    } else {
      // this.setState({ submitDisable: true })
      let name = "Gift Card";
      let sale_price =
        parseFloat(this.state.number) > 0
          ? parseFloat(this.state.number)
          : this.state.selectButtonAmount;
      let barcode = this.state.generateGiftNumber;
      let type = "eGiftCard";
      let email = this.state.email;
      let sms = this.state.sms;
      let print_receipt = false;
      let custom_message = this.state.message;
      let emailid = true;

      // if (undefined != this.state.email && '' != this.state.email && null != this.state.email) {
      //   emailid = this.validateEmail(this.state.email.trim())
      // }
      if (!isNaN(this.state.generateGiftNumber)) {
        if (
          this.state.generateGiftNumber.length >= 4 &&
          this.state.generateGiftNumber.length <= 24
        ) {
          if (this.state.email.length > 0 || this.state.sms.length > 0) {
            if (this.state.sms.length == 0 || this.state.sms.length == 10) {
              checkGiftCardNumber(barcode) //  api call to send data to remote server
                .then(async (response) => {
                  if (response.data.error) {
                    if (response.data.error.code === 404) {
                      // const path = "/" + localStorage.getItem("tenant") + "/404";
                      this.props.history.push("/404");
                    }
                    // this.setState({ submitDisable: false })
                    toast.error(response.data.error.message.message.toString());
                  } else if (response.data.flag) {
                    let item = {
                      name: name,
                      sale_price: (Math.round(sale_price * 100) / 100).toFixed(
                        2,
                      ),
                      server_id: barcode,
                      tax_percentage: 0,
                      tax_amount: 0,
                      extra_item: 0,
                      Ebt_item: 1,
                      taxes: [],
                      tax_collected: 0,
                      gift_card: 1,
                      type: type,
                      email: email,
                      sms: sms,
                      print_receipt: print_receipt,
                      product_description: custom_message,
                    };
                    this.props.addExtraItem(item); // CALL REDUCER
                    this.props.orderCalculation(); // Reducer call to store order data
                    this.goBack("");
                    // this.props.navigation?.navigate(Constant.HOMESTACK)
                    // this.setState({ submitDisable: false })
                  } else {
                    if (response.data.code == 999) {
                      this.props.removeUserCardDetails();
                      this.props.userLogout();
                      localStorage.removeItem("token");
                      this.goBack("signIn");
                    }
                    toast.error(response.data.message);
                  }
                })
                .catch((error) => {
                  this.setState({ submitDisable: false });
                  // Error occured while being api called
                  if (
                    error.toString() == Constant.BACKEND_ERROR ||
                    error.toString() == Constant.BACKEND_ERROR_CODE
                  ) {
                    toast.error(Constant.SERVER_NOT_AVAILABLE);
                  } else {
                    toast.error(error.toString());
                  }
                });
            } else {
              this.setState({ submitDisable: false });
              toast.error(Constant.ENTER_VALID_PHONE_NUMBER);
            }
          } else {
            this.setState({ submitDisable: false });
            toast.error(Constant.VALIDATION_OF_EMAIL_OR_SMS);
          }
        } else {
          this.setState({ submitDisable: false });
          toast.error(Constant.VALID_GIFT_CARD_CODE_LESS_OR_GREATER);
        }
      } else {
        this.setState({ submitDisable: false });
        toast.error(Constant.VALID_GIFT_CARD_CODE);
      }
    }
  };
  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const that = this;
    let errors = this.state.errors;

    switch (name) {
      case "number":
        errors.number =
          value.length > 0
            ? !this.validate(value)
              ? Constant.VALID_GIFT_CARD_AMOUNT
              : value >= 0.01 && value <= 500
                ? ""
                : Constant.VALID_GIFT_CARD_AMOUNT_LESS_OR_GREATER
            : "";
        break;
      case "email":
        errors.email =
          value.length > 0
            ? validEmailRegex.test(value)
              ? value.length < 255
                ? ""
                : Constant.USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER
              : Constant.USER_EMAIL_NOT_VALID
            : "";
        break;
      case "sms":
        errors.sms =
          value.length > 0
            ? this.validatephone(value)
              ? value.length > 10
                ? Constant.USER_PHONE_NUMBER_GREATER_THEN_10
                : value.length < 3
                  ? Constant.USER_PHONE_NUMBER_MUST_BE_3_DIGITS
                  : ""
              : Constant.USER_PHONE_NUMBER__NOT_VALID
            : "";
        break;
      case "message":
        errors.message =
          value.length > 0
            ? value.length > 255
              ? Constant.GIFT_CARD_INSTRUCTION
              : ""
            : "";
        break;
      default:
        break;
    }
    if (name == "number") {
      this.setState({ selectButtonAmount: 0.0 });
    }
    this.setState({ errors, [name]: value });
  };
  handlenextLayout = () => {
    this.setState({
      firstLayout: false,
      secondLayout: true,
      generateGiftNumber:
        Math.floor(Math.random() * (999 - 100)) +
        101 +
        "" +
        (Math.floor(Math.random() * (999 - 100)) + 101) +
        "" +
        (Math.floor(Math.random() * (999 - 100)) + 101) +
        "" +
        (Math.floor(Math.random() * (999 - 100)) + 101),
    });
  };

  render() {
    const { classes } = this.props;
    var str = this.state.generateGiftNumber.toString();
    var giftCode = str.replace(/.(?=.{4,}$)/g, "*");
    let buttonDisable = false;
    if (this.state.email.length === 0 && this.state.sms.length === 0) {
      buttonDisable = true;
    } else if (
      this.state.email.length > 0 &&
      this.state.errors.email.length > 0
    ) {
      buttonDisable = true;
    } else if (this.state.sms.length > 0 && this.state.errors.sms.length > 0) {
      buttonDisable = true;
    } else if (
      this.state.message.length > 0 &&
      this.state.errors.message.length > 0
    ) {
      buttonDisable = true;
    }

    if (this.state.firstLayout) {
      return (
        <>
          <Paper elevation={1} square className={classes.flexLayout}>
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid xs={2} md={2} lg={2} item>
                <Link to="#" className="text-dark font-bold">
                  <ArrowBackIcon
                    onClick={() => this.goBack(this.props.layout)}
                  />
                </Link>
              </Grid>
              <Grid xs={8} md={8} lg={8} item>
                <h1 className="sidebar-layout-header-name">Sell a Gift Card</h1>
              </Grid>
              <Grid xs={12} md={12} lg={12} item>
                <h2 className={classes.headerName}>Select the Amount</h2>
              </Grid>
              <Grid xs={6} md={6} lg={6} item>
                <Button
                  variant={
                    this.state.selectButtonAmount == 10.0
                      ? "contained"
                      : "outlined"
                  }
                  color="primary"
                  size="large"
                  style={{ width: "100%" }}
                  onClick={() => this.handleGiftCardAmount(10.0)}
                >
                  $10.00
                </Button>
              </Grid>
              <Grid xs={6} md={6} lg={6} item>
                <Button
                  variant={
                    this.state.selectButtonAmount == 20.0
                      ? "contained"
                      : "outlined"
                  }
                  color="primary"
                  size="large"
                  style={{ width: "100%" }}
                  onClick={() => this.handleGiftCardAmount(20.0)}
                >
                  $20.00
                </Button>
              </Grid>
              <Grid xs={4} md={4} lg={4} item>
                <Button
                  variant={
                    this.state.selectButtonAmount == 50.0
                      ? "contained"
                      : "outlined"
                  }
                  color="primary"
                  size="large"
                  style={{ width: "100%" }}
                  onClick={() => this.handleGiftCardAmount(50.0)}
                >
                  $50.00
                </Button>
              </Grid>
              <Grid xs={4} md={4} lg={4} item>
                <Button
                  variant={
                    this.state.selectButtonAmount == 80.0
                      ? "contained"
                      : "outlined"
                  }
                  color="primary"
                  size="large"
                  style={{ width: "100%" }}
                  onClick={() => this.handleGiftCardAmount(80.0)}
                >
                  $80.00
                </Button>
              </Grid>
              <Grid xs={4} md={4} lg={4} item>
                <Button
                  variant={
                    this.state.selectButtonAmount == 100.0
                      ? "contained"
                      : "outlined"
                  }
                  color="primary"
                  size="large"
                  style={{ width: "100%" }}
                  onClick={() => this.handleGiftCardAmount(100.0)}
                >
                  $100.00
                </Button>
              </Grid>
              <Grid xs={12} md={12} lg={12} item>
                <h2 className="sidebar-layout-header-name">OR</h2>
              </Grid>
              <Grid xs={12} md={12} lg={12} item>
                <h2 className={classes.headerName}>
                  Custom Amount
                  <br />
                  <Typography className={classes.subheaderLength}>
                    {"Tap below to enter the custom amount."}
                  </Typography>
                </h2>
              </Grid>
              <Grid xs={12} md={12} lg={12} item>
                <TextField
                  style={{ margin: "2%", borderRadius: 0, width: "96%" }}
                  error={this.state.errors.number.length == 0 ? false : true}
                  fullWidth
                  size="small"
                  helperText={this.state.errors.number}
                  label="* Enter an amount"
                  name="number"
                  onChange={this.handleInputChange}
                  // onBlur={this.handleInputChange}
                  // onKeyPress= {(e) => {
                  //   if (e.key === 'Enter' && this.state.number !== "" && this.state.errors.number === "") {
                  //     this.rewadsEarn()
                  //   }
                  // }}
                  type="text"
                  value={this.state.number}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={1} square className={classes.buttonLayout}>
            <Button
              variant="contained"
              style={{
                width: "30%",
                backgroundColor: "#14ae5c",
                color: "#fff",
              }}
              onClick={() => this.handlenextLayout()}
              disabled={
                this.state.selectButtonAmount > 0.0  ? false :
                 this.state.number == "" || this.state.errors.number.length != ""
                  ? true
                  : false
              }
            >
              Continue
            </Button>
          </Paper>
        </>
      );
    } else if (this.state.secondLayout) {
      return (
        <>
          <Paper elevation={1} square className={classes.flexLayout}>
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid xs={2} md={2} lg={2} item>
                <Link to="#" className="text-dark font-bold">
                  <ArrowBackIcon
                    onClick={() => this.goBack(this.props.layout)}
                  />
                </Link>
              </Grid>
              {/* <Grid xs={0.5} md={0.8} lg={0.8} item>
                <CardGiftcardIcon fontSize="large" />
              </Grid> */}
              <Grid xs={8} md={8} lg={8} item>
                <h1 className="sidebar-layout-header-name">Sell a Gift Card</h1>
              </Grid>
              <Grid xs={12} md={12} lg={12} item>
                <h2 className={classes.headerName}>Gift Card Code</h2>
              </Grid>
              <Grid xs={12} md={12} lg={12} item>
                <Button
                  variant="contained"
                  style={{
                    width: "100%",
                    backgroundColor: "#757575",
                    color: "#fff",
                  }}
                  size="large"
                  // onClick={() => this.handleGiftCardAmount()}
                >
                  {giftCode}
                </Button>
              </Grid>
              <Grid xs={12} md={12} lg={12} item>
                <h2 className={classes.headerName1}>
                  Email this Gift Card
                  <br />
                  <Typography className={classes.subheaderLength}>
                    {"Enter the email address of the recipient."}
                  </Typography>
                </h2>
                <TextField
                  style={{ margin: "2%", borderRadius: 0, width: "96%" }}
                  error={this.state.errors.email.length == 0 ? false : true}
                  fullWidth
                  size="small"
                  helperText={this.state.errors.email}
                  label="* Enter an Email"
                  name="email"
                  onChange={this.handleInputChange}
                  // onBlur={this.handleInputChange}
                  // onKeyPress= {(e) => {
                  //   if (e.key === 'Enter' && this.state.number !== "" && this.state.errors.number === "") {
                  //     this.rewadsEarn()
                  //   }
                  // }}
                  type="text"
                  value={this.state.email}
                  variant="outlined"
                />
              </Grid>
              <Grid xs={12} md={12} lg={12} item>
                <h2 className="sidebar-layout-header-name">OR</h2>
              </Grid>
              <Grid xs={12} md={12} lg={12} item>
                <h2 className={classes.headerName}>
                  SMS this Gift Card
                  <br />
                  <Typography className={classes.subheaderLength}>
                    {"Enter the Phone Number of the recipient."}
                  </Typography>
                </h2>
                <TextField
                  style={{ margin: "2%", borderRadius: 0, width: "96%" }}
                  error={this.state.errors.sms.length == 0 ? false : true}
                  fullWidth
                  size="small"
                  helperText={this.state.errors.sms}
                  label="* Enter a Phone Number"
                  name="sms"
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputChange}
                  // onKeyPress= {(e) => {
                  //   if (e.key === 'Enter' && this.state.number !== "" && this.state.errors.number === "") {
                  //     this.rewadsEarn()
                  //   }
                  // }}
                  type="text"
                  value={this.state.sms}
                  variant="outlined"
                />
              </Grid>
              <Grid xs={12} md={12} lg={12} item>
                <h2 className={classes.headerName}>
                  Custom Message
                  <br />
                  <Typography className={classes.subheaderLength}>
                    {"Enter the Custom Message of the recipient."}
                  </Typography>
                </h2>
                <TextField
                  style={{ margin: "2%", borderRadius: 0, width: "96%" }}
                  error={this.state.errors.message.length == 0 ? false : true}
                  fullWidth
                  size="small"
                  helperText={this.state.errors.message}
                  label="Custom message"
                  name="message"
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputChange}
                  // onKeyPress= {(e) => {
                  //   if (e.key === 'Enter' && this.state.number !== "" && this.state.errors.number === "") {
                  //     this.rewadsEarn()
                  //   }
                  // }}
                  type="text"
                  value={this.state.message}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={1} square className={classes.buttonLayout1}>
            <Button
              variant="contained"
              style={{
                width: "30%",
                backgroundColor: "#9747ff",
                color: "#fff",
              }}
              onClick={() => {
                this.setState({
                  firstLayout: true,
                  secondLayout: false,
                  generateGiftNumber: 0,
                });
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              style={{
                width: "30%",
                backgroundColor: "#14ae5c",
                color: "#fff",
              }}
              onClick={() => this.handleSubmit()}
              disabled={buttonDisable}
            >
              Submit
            </Button>
          </Paper>
        </>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      currentItem,
      removeCurrentItem,
      orderCalculation,
      insertItem,
      updateOrdertype,
      orderInstruction,
      addExtraItem,
      removeUserCardDetails,
      userLogout,
    },
    dispatch,
  );
}
function mapStateToProps(state) {
  return {
    current_item: state.current_item,
    locations: state.locations,
    delivery_pickup: state.delivery_pickup,
    order: state.order,
    order_items: state.order_items,
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true }),
)(SellGiftCard);
