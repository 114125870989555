import React from "react";
import { Row,Col } from "antd";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  currentItem,
  removeCurrentItem,
} from "../../../../actions/current_item";
import { insertItem,updateSelectedModifiers,updateItemQty } from "../../../../actions/order_items";
import { orderCalculation } from "../../../../actions/order_actions";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import {
  Paper,
  Grid,
  Typography as MTypography,
  Button as MButton,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  IconButton,
  Radio,
  Checkbox,
  List,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CommentIcon from "@material-ui/icons/Comment";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import * as Constant from "../../../../constants";
import { twofix } from "../../../../helpers/calculation";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';

const useStyles = (theme) => ({
  expanIcon: {
    flexBasis: "10%",
    flexShrink: 0,
    color: "#3950b1",
    [theme.breakpoints.down('sm')]: {
      flexBasis: "15%",
    },
    [theme.breakpoints.up('sm')]: {
      flexBasis: "10%",
    },
    ['@media (device-width: 540px)']: {
      flexBasis: "10%",
    },
  },
  flexLayout: {
    flex: 10,
    flexDirection: "row",
    alignItems: "stretch",
    overflow: "hidden",
    marginBottom: 7,
    overflowY: "scroll",
  },
  buttonLayout: {
    display: "flex",
    flex: 0.6,
    padding: 8,
    justifyContent: "center",
    backgroundColor:"#fff",
  },
  headerName: {fontSize: 17,fontWeight: "bold" },
  subheaderLength: { fontSize: 15}
});

class OrderItemsDetails extends React.Component {
  constructor(props) {
    var selecte_mod = []
    super(props);
    this.state = {
      expanded: false,
      item_qty: null != (props.current_item[0].quantity && undefined != props.current_item[0].quantity && "" != props.current_item[0].quantity) ? props.current_item[0].quantity : 1,
      item: this.props.current_item[0],
      selected_modifiers: [],
      actual_selectedmodifiers: [],
      errorMessage: '',
      instruction: props.current_item[0].description && null != props.current_item[0].description && undefined != props.current_item[0].description && "" != props.current_item[0].description ? props.current_item[0].description : "",
      item_sale_price: twofix(
        twofix(props.current_item[0].sale_price) - twofix(props.current_item[0].total_modifiers_price)
      ),
    };
    if(this.state.item.name !== null && this.state.item.name != " " && window.innerWidth <= 991){
      this.props.openDrawer()
    }
    if (props.current_item[0].is_update) { 
    props.current_item[0].modifier_set.map((item) => {
      if (item.selected_modifiers.length > 0) {
        item.selected_modifiers.map((mod) => {
          selecte_mod.push(mod.server_id)
        })
      }
    })
  }
    this.state.selected_modifiers = selecte_mod
    this.state.actual_selectedmodifiers = selecte_mod
  }
  componentDidMount() {
  }

  componentWillUnmount() {}

  handleChange = (panel) => (event, isExpanded) => {
    isExpanded
      ? this.setState({ expanded: panel })
      : this.setState({ expanded: false });
  };

  addQty = () => {
    if (this.state.item_qty < 999999) {
      let qty = this.state.item_qty + 1;
      this.setState({ item_qty: qty });
    } else {
      toast.error(Constant.VALID_ITEM_QUANTITY)
    }
  };
  minusQty = () => {
    if (this.state.item_qty > 1) {
      let qty = this.state.item_qty - 1;
      this.setState({ item_qty: qty });
    }
  };

  modifierClick = (modifier_set, modifier) => {
    // this.setState({divHide:true})
    let toogleIndex = this.state.item.modifier_set.findIndex(
      //
      (o) => o.id == modifier_set.id
    );
    let c_modifier_set = this.state.item.modifier_set[toogleIndex]; // get index of modifier set
    if (c_modifier_set.only_one) {
      //radio input if modifer set is "Select One"
      //Let's check if this was already selected
      let rem_index = this.state.item.modifier_set[
        toogleIndex
      ].selected_modifiers.findIndex((o) => o.id == modifier.id);
      if (rem_index < 0) {
        // This was not selected
        if (modifier.id != Constant.NONE_MODIFIER.server_id) {
          let modifiers_id = [];
          this.state.item.modifier_set[toogleIndex].selected_modifiers = [];
          modifier_set.modifiers.map((mod) => {
            modifiers_id.push(mod.id);
          });
          let mySelectedArray = this.state.selected_modifiers.filter(
            (el) => !modifiers_id.includes(el)
          );
          this.setState({ selected_modifiers: mySelectedArray }, () => {
            this.setState({
              selected_modifiers: [
                ...this.state.selected_modifiers,
                modifier.id,
              ],
            });
          });
          this.state.item.modifier_set[toogleIndex].selected_modifiers.push(
            modifier
          );
        }
      } else {
        //THis was already selected so we will un select it
        this.state.item.modifier_set[toogleIndex].selected_modifiers = [];
        let modifiers_id = [];
        modifier_set.modifiers.map((mod) => {
          modifiers_id.push(mod.server_id);
        });
        let mySelectedArray = this.state.selected_modifiers.filter(
          (el) => !modifiers_id.includes(el)
        );
        this.setState({ selected_modifiers: mySelectedArray });
      }
    } else {
      // checkbox input
      let rem_index = this.state.item.modifier_set[
        toogleIndex
      ].selected_modifiers.findIndex((o) => o.id == modifier.id);
      if (rem_index < 0) {
        // This was not selected and multiple input we can check
        this.state.item.modifier_set[toogleIndex].selected_modifiers.push(
          modifier
        );
        this.setState({
          selected_modifiers: [...this.state.selected_modifiers, modifier.id],
        });
      } else {
        //THis was already selected so we will un select it
        let selected_modifiers = this.state.selected_modifiers.filter(
          (x) => x != modifier.id
        );
        this.setState({ selected_modifiers: selected_modifiers });
        this.state.item.modifier_set[toogleIndex].selected_modifiers.splice(
          rem_index,
          1
        );
      }
    }
    let total_modifiers_price = 0;
    this.state.item.modifier_set.map((item) => {
      if (item.selected_modifiers.length > 0) {
        let single_price = item.selected_modifiers
          .map((item) => item.price)
          .reduce((prev, next) => prev + next);
        total_modifiers_price = total_modifiers_price + single_price;
        item.total_modifiers_price = single_price;
      } else {
        item.total_modifiers_price = 0;
      }
    });
    this.state.item.total_modifiers_price = twofix(total_modifiers_price);
  };

  goBack = (type) => {
    this.state.item.modifier_set.map((modifier_set) => {
      modifier_set.selected_modifiers = []
      modifier_set.modifiers.map((modifier) => {
        if(this.state.actual_selectedmodifiers.indexOf(modifier.id) !== -1) {
          modifier_set.selected_modifiers.push(modifier)
        }
      })
    })
    let total_modifiers_price = 0;
    this.state.item.modifier_set.map((item) => {
      if (item.selected_modifiers.length > 0) {
        let single_price = item.selected_modifiers
          .map((item) => item.price)
          .reduce((prev, next) => prev + next);
        total_modifiers_price = total_modifiers_price + single_price;
        item.total_modifiers_price = single_price;
      } else {
        item.total_modifiers_price = 0;
      }
    });
    this.state.item.total_modifiers_price = twofix(total_modifiers_price);
    this.props.removeCurrentItem();
    if(!this.state?.item?.is_update && window.innerWidth <= 991) {
      this.props.onClose()
    } else {
    this.props.checkOutInsidePage(type)
    }
  };

  goBackOrderItems = (type) => {
    this.props.removeCurrentItem();
    this.props.checkOutInsidePage(type)
  };

  addToCartProduct = async () => {

    if (
      this.state.item.total_modifiers_price > 0 &&
      this.state.item.total_modifiers_price != undefined
    ) {
      //think added modifier product
      if(null != this.state.item.online_ordering_price && undefined != this.state.item.online_ordering_price && "" != this.state.item.online_ordering_price) {
        this.state.item.online_ordering_price = twofix(
          twofix(this.state.item.online_ordering_price) +
            twofix(this.state.item.total_modifiers_price)
        );
      } else {
        this.state.item.sale_price = twofix(
          twofix(this.state.item.sale_price) +
            twofix(this.state.item.total_modifiers_price)
        );
      }
    } else {
      //think normal or default modifier product
      this.state.item.total_modifiers_price = 0;
    }
    if(this.state.instruction.length <= 255) {
      this.state.item.description = this.state.instruction.trim()

      this.state.item.modifier_set.map((item) => {
        item.selected_modifiers.sort(function (a, b) {
          // Return selected modifiers with apply sorting
          return a.sort - b.sort;
        });
      });
      let item = this.state.item;
      const current_order = await localStorage.getItem("current_order");
      this.props.insertItem(
        item,
        this.state.item_qty,
        current_order,
        this.props.order.order_for_here
      );
      this.props.orderCalculation(); // Reducer call to store order data
      this.props.removeCurrentItem();
      this.goBackOrderItems('orderItemDetails');
    } else {
      toast.error(Constant.VALID_SPECIAL_INSTRUCTION);
    }
    
  };

  updateCartProduct = async () => {
    if (
      this.state.item.total_modifiers_price > 0 &&
      this.state.item.total_modifiers_price != undefined
    ) {
      //think added modifier product
      this.state.item.sale_price = twofix(
        twofix(this.state.item_sale_price) +
          twofix(this.state.item.total_modifiers_price)
      );
    } else {
      //think normal or default modifier product
      this.state.item.total_modifiers_price = 0;
      this.state.item.sale_price = twofix(this.state.item_sale_price)
    }
    this.state.item.modifier_set.map((item) => {
      item.selected_modifiers.sort(function (a, b) {
        // Return selected modifiers with apply sorting
        return a.sort - b.sort;
      });
    });
    if(this.state.instruction.length <= 255) {
      let item = { ...this.state.item }
    this.props.updateSelectedModifiers(item) // CALL Reducer
    this.props.updateItemQty(item,this.state.item_qty,this.state.instruction.trim())
    this.props.orderCalculation(); // Reducer call to store order data
    this.props.removeCurrentItem();
    } else {
      toast.error(Constant.VALID_SPECIAL_INSTRUCTION);
    }
    
    // }
  };
  handleInputChange(event, from) {
    event.preventDefault();
    // const { name, value } = event.target;
    this.setState({
      [from]: event.target.value,
    });
    if(event.target.value.length > 255) {
      this.setState({ errorMessage : Constant.VALID_SPECIAL_INSTRUCTION})
    }else {
      this.setState({ errorMessage : ""})

    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
          <Paper elevation={1} square className={classes.flexLayout}>
          <Grid
            container
            direction="row"
            // justifyContent="center"
            alignItems="center"
          >
            <Grid xs={1} md={1} lg={1} item>
            <Link  to="#" className="text-dark font-bold">
            <ArrowBackIcon onClick={() => this.goBack("")} />
              {/* {this.state.divHide ? null :<ArrowBackIcon onClick={() => this.goBack("")} />} */}
            </Link>
            </Grid>
            <Grid xs={10} md={10} lg={10} item>
              <h1 className="sidebar-layout-header-name">
              {this.state.item.name.length > 35 ? this.state.item.name.slice(0,33) + "..." : this.state.item.name}
              </h1>
            </Grid>
          </Grid>
          {/* <Divider /> */}
          {null != this.state.item.product_description && undefined != this.state.item.product_description && this.state.item.product_description != "N.A." ? 
            <Row justify="center" style={{ width: "95%",marginLeft:"3%",marginRight:"3%" }}>
            <MTypography className={classes.subheaderLength}>
                {this.state.item.product_description}
            </MTypography>
            </Row>
         : null}
          
          <>
            {this.state.item.modifier_set.map((set, index) => (
              <Accordion
                elevation={0}
                expanded={this.state.expanded === "panel" + index}
                onChange={this.handleChange("panel" + index)}
                key={index}
              >
                <AccordionSummary
                  // expandIcon={<ArrowForwardIosIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                   <Grid item xs={2}> {this.state.expanded === "panel" + index ? 
                        <KeyboardArrowDownSharpIcon
                        className={classes.expanIcon}
                        fontSize="large"
                      /> :
                        <ArrowForwardIosIcon
                        className={classes.expanIcon}
                        fontSize="small"
                      />}
                    </Grid>
                    {/* <Grid item xs={3}>
                      <ArrowForwardIosIcon
                        className={classes.expanIcon}
                        fontSize="small"
                      />
                    </Grid> */}
                    <Grid item xs={7}>
                      <MTypography className={classes.headerName}>
                        {set.name}
                      </MTypography>
                    </Grid>
                    <Grid item xs={3}>
                      {set.only_one === 1 ? (
                        <span>Select One</span>
                      ) : (
                        <span></span>
                      )}
                    </Grid>
                  </Grid>
                </AccordionSummary>
                {set.only_one === 1 ? (
                  <AccordionDetails>
                    <List
                      className={classes.list}
                      style={{
                        maxHeight: "130px",
                        overflow: "hidden",
                        overflowY: "scroll",
                        width: "100%",
                      }}
                    >
                      {set.modifiers.map((mod, innerIndex) => (
                        <ListItem key={innerIndex} button onClick={() => this.modifierClick(set, mod)}>
                          <ListItemText
                            d={`checkbox-list-secondary-label-${innerIndex}`}
                            primary={
                              mod.price != null &&
                              mod.price != undefined &&
                              mod.price != ""
                                ? mod.name + " ($" + twofix(mod.price).toFixed(2) + ")"
                                : mod.name
                            }
                          />
                          <ListItemSecondaryAction onClick={() => this.modifierClick(set, mod)}>
                            <Radio
                              checked={this.state.selected_modifiers.includes(
                                mod.id
                              )}
                              // onChange={() => this.modifierClick(set, mod)}
                              inputProps={{
                                "aria-labelledby": `checkbox-list-secondary-label-${innerIndex}`,
                              }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                ) : (
                  <AccordionDetails>
                    <List
                      className={classes.list}
                      style={{
                        maxHeight: "130px",
                        overflow: "hidden",
                        overflowY: "scroll",
                        width: "100%",
                      }}
                    >
                      {set.modifiers.map((mod, innerIndex) => (
                        <ListItem key={innerIndex} button onClick={() => this.modifierClick(set, mod)}>
                          <ListItemText
                            d={`checkbox-list-secondary-label-${innerIndex}`}
                            primary={
                              mod.price != null &&
                              mod.price != undefined &&
                              mod.price != ""
                                ? mod.name + " ($" + twofix(mod.price).toFixed(2) + ")"
                                : mod.name
                            }
                          />
                          <ListItemSecondaryAction>
                            <Checkbox
                              checked={this.state.selected_modifiers.includes(
                                mod.id
                              )}
                              onChange={() => this.modifierClick(set, mod)}
                              inputProps={{
                                "aria-labelledby": `checkbox-list-secondary-label-${innerIndex}`,
                              }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                )}
              </Accordion>
            ))}
          </>
          <Row justify="center">
            <textarea
              // autoFocus
              className={this.state.instruction.length <= 255 ? "form-control" : 'form-control1'}
              placeholder="Add Special Instruction"
              id="exampleFormControlTextarea1"
              rows="5"
              value={this.state.instruction}
              onChange={(e) => this.handleInputChange(e, "instruction")}
              style={{ width: "95%", marginTop: "5%" }}
            />
          </Row>
          <span style={{ color : 'red',marginLeft:'3%'}}>{this.state.errorMessage}</span>
              <Grid container justifyContent="center" spacing={2} style={{ marginTop:"1%"}}>
                <Grid item>
                  <IconButton
                    aria-label="add Quantity"
                    component="span"
                    size="small"
                    onClick={this.minusQty}
                  >
                    <RemoveOutlinedIcon
                      // fontSize="medium"
                      style={{
                        color: this.state.item_qty == 1 ? "#cccccc" : "#3950b1",
                      }}
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <h1 style={{ fontWeight: "bold", fontSize: "18px" }}>
                    {this.state.item_qty}
                  </h1>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="add Quantity"
                    component="span"
                    size="small"
                    onClick={this.addQty}
                  >
                    <AddOutlinedIcon
                      // fontSize="medium"
                      style={{
                        color:
                          this.state.item_qty === 99999 ? "#cccccc" : "#3950b1",
                      }}
                    />
                  </IconButton>
                </Grid>
          </Grid>
          </Paper>
        <Paper elevation={1} square className={classes.buttonLayout}>
          {this.props.current_item[0].is_update ? 
          <MButton
            variant="contained"
            color="primary"
            style={{ width: "100%",height:"93%" }}
            onClick={() => this.updateCartProduct()}
          >
            Update Item
          </MButton> :
          <MButton
          variant="contained"
          color="primary"
          style={{ width: "100%",height:"93%" }}
          onClick={() => this.addToCartProduct()}
        >
          Add to Order
        </MButton>}
        </Paper>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { currentItem, removeCurrentItem, orderCalculation, insertItem, updateSelectedModifiers,updateItemQty },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    current_item: state.current_item,
    order: state.order,
    order_items: state.order_items,
    coupon_status: state.coupon_status,
    weight: state.weight,
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true })
)(OrderItemsDetails);