
/**
 * tofixtwo().
 *
 * this function is used to do toFixed(2) after every calculation in order and order item calculation.
 *
 * @param {string or number} number Which number's toFixed(2) we have to do that number.
 *
 * @return {type} toFixed(2) value of requested value.
 */
export var twofix = number => {
  return Number(parseFloat(roundTo(number, 2)));
}
/**
 * randomNumber().
 *
 * this function is used to do generate 9 digit number for key in resact.
 *
 * @return {type} random 9 digit number.
 */


function roundTo(n, digits) {
  var negative = false;
 if (digits === undefined) {
     digits = 0;
 }
 if( n < 0) {
   negative = true;
   n = n * -1;
 }
 var multiplicator = Math.pow(10, digits);
 n = parseFloat((n * multiplicator).toFixed(11));
 n = (Math.round(n) / multiplicator).toFixed(2);
 if( negative ) {
   n = (n * -1).toFixed(2);
 }
 return n;
}


