import { MAKE_PAYMENT, COMPLETE_ORDER } from '../actions/order_actions'
import { CLEAR_ITEMS } from '../actions/order_items'

export default function(state = [], action) {
  switch (action.type) {
    case MAKE_PAYMENT: {
      // let multiple_cash_check = 1
      var timestamp = new Date().getTime()
      // if(action.payload.type == "EBT"){
      //   multiple_cash_check =0;
      // }
      let a = []
      state.forEach((item, index) => {
        var current = {
          id: item.id,
          amount: item.amount,
          type: item.type,
          external_reference: item.external_reference,
          payment_gateway: item.payment_gateway,
          aid: item.aid,
          aid_display_name: item.aid_display_name,
          pin_statement: item.pin_statement,
          cash_payment_check: item.cash_payment_check,
          multiple_cash: !item.cash_payment_check,
          cashback_amount: item.cashback_amount,
          tips_amount:item.tips_amount,
          external_reference_number:item.external_reference_number,
          gift_card_id: item.gift_card_id
        }

        a.push(current)
      })

      let pushPayment = {
        id: timestamp,
        amount: action.payload.amount,
        type: action.payload.type,
        external_reference: action.payload.external_reference,
        payment_gateway: action.payload.payment_gateway,
        aid: action.payload.aid,
        aid_display_name: action.payload.aid_display_name,
        pin_statement: action.payload.pin_statement,
        cash_payment_check: action.payload.cash_payment_check,
        multiple_cash: !action.payload.cash_payment_check,
        cashback_amount: action.payload.cashback_amount,
        tips_amount:action.payload.tips_amount,
        external_reference_number:action.payload.external_reference_number,
        gift_card_id: action.payload.gift_card_id
      }

      a.push(pushPayment)

      return a
    }
    case COMPLETE_ORDER: {
      return []
    }

    case CLEAR_ITEMS: {
      return []
    }
  }
  return state
}
