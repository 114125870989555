import React from "react";
// import {  Card } from "antd";
import { withStyles } from "@material-ui/styles";
import {
  currentItem,
  removeCurrentItem,
} from "../../../../actions/current_item";
import { insertItem } from "../../../../actions/order_items";
import { orderCalculation } from "../../../../actions/order_actions";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import {
  Paper,
  Grid,
  Typography as MTypography,
  Button,
  TextField,
  Radio,
  Checkbox,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "react-credit-cards/es/styles-compiled.css";
import { userLogout, addPaymentCardId } from "../../../../actions/user_actions";
import {
  addUserCardDetails,
  removeUserCardDetails,
} from "../../../../actions/card_Details_action";
import CreditCardInput from "react-credit-card-input";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "@material-ui/icons/Delete";
import { deleteCard } from "../../../../services/user/user";
import { Link } from "react-router-dom";
import * as Constant from "../../../../constants";
import { userLogin } from "../../../../actions/user_actions";
import { LinearProgress } from "@material-ui/core";
import { orderUser } from "../../../../actions/user_actions";
import moment from "moment";
import Card from "react-credit-cards";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData,
} from "./utils";
import "react-credit-cards/es/styles-compiled.css";

const useStyles = (theme) => ({
  flexLayout: {
    flex: 10,
    flexDirection: "row",
    alignItems: "stretch",
    overflow: "hidden",
    padding: 13,
    marginBottom: 8,
    backgroundColor: "#fff",
    overflowY: "scroll",
  },
  buttonLayout: {
    display: "flex",
    flex: 0.6,
    padding: 8,
    justifyContent: "center",
    backgroundColor: "#fff",
  },
  textLayout: {
    marginTop: "2%",
    marginBottom: "2%",
    width: "100%",
  },
});

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView();

  return { executeScroll, elRef };
};

const wifi = [
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 22.5 20.625"
    key={0}
  >
    <g id="wifi" transform="translate(0.75 0.75)">
      <circle
        id="Oval"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(9 16.875)"
        fill="#fff"
      ></circle>
      <path
        id="Path"
        d="M0,1.36a6.377,6.377,0,0,1,7.5,0"
        transform="translate(6.75 11.86)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      ></path>
      <path
        id="Path-2"
        data-name="Path"
        d="M14.138,2.216A12.381,12.381,0,0,0,0,2.216"
        transform="translate(3.431 6)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      ></path>
      <path
        id="Path-3"
        data-name="Path"
        d="M0,3.294a18.384,18.384,0,0,1,21,0"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      ></path>
    </g>
  </svg>,
];

class UserCardDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      item_qty: 1,
      pickUpAddress: "",
      isDelivery: false,
      isPickUp: false,
      // changePickup: false,
      name: "",
      expiry: "",
      cvc: "",
      issuer: "",
      focused: "",
      formData: null,
      errors: {
        cardNumber: "",
        // name: "",
        expiry: "",
        cvc: "",
      },
      cardNumber: "",
      user_selected_card_id: [],
      // userCardDetails: [],
      loading: false,
      buttonDisable: false,
    };
    this.inputRefs = [];
    this.elScroll = utilizeScroll();
  }

  componentDidMount() {
    if (
      null != this.props.card_details &&
      undefined != this.props.card_details &&
      "" != this.props.card_details
    ) {
      let cardNumber = "";
      let expiry =
        this.props.card_details.expiration_date.slice(5, 7) +
        "/" +
        this.props.card_details.expiration_date.slice(2, 4);

      if (this.props.card_details.card_number.length > 0) {
        cardNumber = formatCreditCardNumber(
          this.props.card_details.card_number
        );
      }
      this.setState({
        cvc: this.props.card_details.cvv_no,
        expiry: expiry,
        cardNumber: cardNumber,
        // changePickup: true,
        // user_selected_card_id: []
      });
    }
    if (
      null != this.props.user.user &&
      undefined != this.props.user.user &&
      "" != this.props.user.user
    ) {
      // if(null != this.props.user.user.selected_card_id && undefined != this.props.user.user.selected_card_id && "" != this.props.user.user.selected_card_id ) {
      //   if((null == this.props.card_details &&
      //     undefined == this.props.card_details &&
      //     "" == this.props.card_details) || this.props.card_details.length == 0) {
      //   this.setState({ user_selected_card_id: [this.props.user.user.selected_card_id], changePickup: false });
      //     }
      // }
      //   let UserCard = [];
      //   this.props.user.user.user_card_info.map((card) => {
      //     UserCard.push(card);
      //   });
      //   if (null != UserCard && (undefined != UserCard) & ("" != UserCard)) {
      //     this.setState({ userCardDetails: UserCard });
      //   }
    } else {
      toast.error(Constant.USER_CARD_DETAILS_USER_LOGOUT);
      this.goBack("signIn");
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  // handleChangeOrderType = (id) => {
  //   if (this.state.user_selected_card_id.includes(id)) {
  //     this.setState({ user_selected_card_id: [], changePickup: false });
  //   } else {
  //     this.setState({ user_selected_card_id: [id], changePickup: false });
  //   }
  // };

  // handleChangePickUp = () => {
  //   if (this.state.changePickup) {
  //     this.setState({ changePickup: false, user_selected_card_id: [], cardNumber: "", expiry: "",cvc: "" });
  //   } else {
  //     this.setState({ changePickup: true, user_selected_card_id: [] });
  //   }
  // };

  saveCardDetails = () => {
    this.elScroll.executeScroll();
    this.setState({ loading: true });
    if (
      null != this.props.user.user &&
      undefined != this.props.user.user &&
      "" != this.props.user.user
    ) {
      // if(this.state.changePickup || this.state.user_selected_card_id.length == 0 ) {
      if (
        null != this.state.cardNumber &&
        undefined != this.state.cardNumber &&
        "" != this.state.cardNumber.trim()
      ) {
        if (
          null != this.state.expiry &&
          undefined != this.state.expiry &&
          "" != this.state.expiry.trim()
        ) {
          if (
            null != this.state.cvc &&
            undefined != this.state.cvc &&
            "" != this.state.cvc.trim()
          ) {
            const usingSplit = this.state.cardNumber.split(" ");
            const date = this.state.expiry.split(" ");
            let year = moment().format("YYYY");
            let expiry =
              year.slice(0, 2) +
              date[0].slice(3, 5) +
              "-" +
              date[0].slice(0, 2);
            let cardNumber = "";
            // let expiry = ''

            if (usingSplit.length > 0) {
              usingSplit.map((code) => {
                cardNumber = cardNumber + code;
              });
            }
            // if(date.length > 0) {
            //   date.map((code) => {
            //     expiry = expiry + code
            //   })
            // }
            let user = this.props.user.user;
            user.selected_card_id = "";
            let payload = {
              card_number: cardNumber,
              expiration_date: expiry,
              cvv_no: this.state.cvc.trim(),
              // first_name: this.props.user.user.first_name,
              // last_name: this.props.user.user.last_name,
              address: this.props.user.user.address,
              // city: this.props.user.user.city,
              // state: this.props.user.user.state,
              zip: this.props.user.user.zipcode,
            };
            if (null != payload && undefined != payload && "" != payload) {
              this.props.addUserCardDetails(payload);
              this.props.addPaymentCardId(user);
              if (
                this.props.user.whoIsThis !== null &&
                this.props.user.whoIsThis != undefined &&
                this.props.user.whoIsThis != ""
              ) {
                if (
                  this.props.user.user.first_name !==
                    this.props.user.whoIsThis.first_name ||
                  this.props.user.user.last_name !==
                    this.props.user.whoIsThis.last_name ||
                  this.props.user.user.phone !== this.props.user.whoIsThis.phone
                ) {
                  let payload = {
                    first_name: this.props.user.whoIsThis.first_name,
                    last_name: this.props.user.whoIsThis.last_name,
                    phone: this.props.user.whoIsThis.phone,
                  };
                  this.props.orderUser(payload);
                }
              }
              setTimeout(() => {
                this.setState({ loading: false });
                this.goBack("checkout");
              }, 500);
            }
          } else {
            this.setState({ loading: false });
            toast.error(Constant.VALID_CVC);
          }
        } else {
          this.setState({ loading: false });
          toast.error(Constant.VALID_EXPIRY_DATE);
        }
      } else {
        this.setState({ loading: false });
        toast.error(Constant.VALID_CARD_NUMBER);
      }
      // } else {
      //     if(this.state.user_selected_card_id.length > 0) {
      //       let user = this.props.user.user
      //       user.selected_card_id = this.state.user_selected_card_id[0]
      //       this.props.addPaymentCardId(user)
      //       this.props.removeUserCardDetails()
      //       if(this.props.user.whoIsThis !== null && this.props.user.whoIsThis != undefined && this.props.user.whoIsThis != ""){
      //         if(this.props.user.user.first_name !== this.props.user.whoIsThis.first_name || this.props.user.user.last_name !== this.props.user.whoIsThis.last_name || this.props.user.user.phone !== this.props.user.whoIsThis.phone){
      //           let payload = {
      //             first_name: this.props.user.whoIsThis.first_name,
      //             last_name: this.props.user.whoIsThis.last_name,
      //             phone: this.props.user.whoIsThis.phone,
      //           };
      //           this.props.orderUser(payload);
      //         }
      //       }
      //       setTimeout(() => {
      //         this.setState({loading:false})
      //       this.goBack("checkout");
      //       }, 500);
      //     } else {
      //       this.setState({loading:false})
      //       toast.error(Constant.SELECTE_PAYMENT_CARD);
      //     }
      // }
    } else {
      // setTimeout(() => {
      this.setState({ loading: false });
      this.goBack("signIn");
      // }, 500);
    }
  };

  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    });
  };

  handleInputChange = ({ target }) => {
    if (target.name === "cardNumber") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }
    const date = target.value.split(" ");
    let year = moment().format("YYYY");
    let expiry =
      year.slice(0, 2) +
      date[0].slice(3, 5)
    // console.log("LLLLLLLLLLLL",expiry == year);
    // console.log("HHHHHHHHHHHH   JJJJJJJJJJ",moment(moment(expiry, ["YYYY"])).isAfter(new Date(), [
    //   "YYYY"]) || expiry == year);
    let errors = this.state.errors;
    switch (target.name) {
      case "cardNumber":
        errors.cardNumber =
          target.value.length === 0
            ? Constant.CARD_NUMBER_REQUIRED
            : this.validateCardNumber(target.value)
            ? ""
            : Constant.VALID_CARD_NUMBER;
        break;
      // case "name":
      //   errors.name =
      //     target.value.length === 0
      //       ? Constant.CARD_NAME_REQUIRED
      //       : target.value.length > 255
      //       ? Constant.VALID_CARD_NAME
      //       : "";
      //   break;
      case "expiry":
        errors.expiry =
          target.value.length === 0
            ? Constant.EXPIRY_DATE_REQUIRED
            : this.validateExpiryDate(target.value)
            ? moment(moment(target.value, ["MM/YY"])).isAfter(new Date(), [
                "MM/YY"
              ]) || moment(moment(expiry, ["YYYY"])).isAfter(new Date(), [
                "YYYY"])
              ? ""
              : Constant.PAST_EXPIRY_DATE
            : Constant.VALID_EXPIRY_DATE;
        break;
      case "cvc":
        errors.cvc =
          target.value.length === 0
            ? Constant.CVC_REQUIRED
            : this.validateCVCNumber(target.value)
            ? ""
            : Constant.VALID_CVC;
        break;
      default:
        break;
    }
    this.setState({ [target.name]: target.value });
  };
  validateCardNumber = (cardNumber1) => {
    const usingSplit = cardNumber1.split(" ");
    let cardNumber = "";
    if (usingSplit.length > 0) {
      usingSplit.map((code) => {
        cardNumber = cardNumber + code;
      });
    }
    // const regex = new RegExp(
    //   "^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35d{3})d{11})$"
    // );
    const regex = /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|(222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11}|62[0-9]{14})$/
    return regex.test(cardNumber);
  };
  validateExpiryDate = (date) => {
    const regex = new RegExp("(0[1-9]|1[0-2])/[0-9]{2}");
    return regex.test(date);
  };
  validateCVCNumber = (cvc) => {
    const regex = /^[0-9]{3,4}$/;
    return regex.test(cvc);
  };

  goBack = (type) => {
    this.props.checkOutInsidePage(type);
  };
  // handleDeleteRow = (item) => {
  //   this.setState({loading:true})
  //   let payload={
  //     user_id:item.user_id,
  //     card_id:item.id
  //   }
  //   deleteCard(payload)
  //   .then((res) => {
  //     if(res.data.flag){

  //       let UserCard = [];

  //                 if(res.data.data.user[0].user_card_info.length > 0){
  //                 res.data.data.user[0].user_card_info.map((card) => {
  //                   UserCard.push(card);
  //                 });
  //                 if (null != UserCard && (undefined != UserCard) & ("" != UserCard)) {
  //                   let user =res.data.data.user[0]
  //                 user.selected_card_id = this.props.user.user.selected_card_id
  //                 this.props.userLogin(user)
  //                 this.props.removeUserCardDetails();
  //                   this.setState({ userCardDetails: UserCard });
  //                 }
  //                 this.setState({loading:false})
  //                 }else{
  //                   let user =res.data.data.user[0]
  //                 user.selected_card_id = ""
  //                 this.props.userLogin(user)
  //                 this.props.removeUserCardDetails();
  //                   this.setState({ userCardDetails: [],user_selected_card_id:[] });
  //                   this.setState({loading:false})
  //                 }
  //       this.setState({loading:false})
  //       toast.success(res.data.data.message);
  //     }else{
  //       this.setState({loading:false})
  //       if (res.data.code === 999) {
  //         this.props.removeUserCardDetails();
  //         this.props.userLogout();
  //         localStorage.removeItem('token')
  //         this.goBack("signIn");
  //         toast.error(res.data.message);
  //       } else{
  //         toast.error(res.data.message);
  //       }
  //     }
  //   })
  //   .catch((err) => {
  //     this.setState({loading:false})
  //     toast.error(err.toString());});
  //   //Sore order data in reducer
  // };
  _handleKeyPress = (e) => {
    const event = e;
    const { currentTarget } = e;
    if (event.key === "Enter") {
      let inputIndex = this.inputRefs.indexOf(currentTarget);
      if (inputIndex < this.inputRefs.length - 1) {
        this.inputRefs[inputIndex + 1].focus();
      } else {
        this.inputRefs[0].focus();
      }
      event.preventDefault();
    }
  };
  render() {
    const { classes } = this.props;
    let buttonDisable =
      this.state.cardNumber == "" ||
      this.state.errors.cardNumber.length > 0 ||
      this.state.expiry == "" ||
      this.state.errors.expiry.length > 0 ||
      this.state.cvc == "" ||
      this.state.errors.cvc.length > 0;
    return (
      <>
        <Paper elevation={1} className={classes.flexLayout}>
          <div ref={this.elScroll.elRef}></div>
          {this.state.loading ? <LinearProgress color="primary" /> : null}
          <Grid
            container
            direction="row"
            // justifyContent="center"
            alignItems="center"
          >
            <Grid xs={2} md={2} lg={2} item>
              <Link to="#" className="text-dark font-bold">
                <ArrowBackIcon onClick={() => this.goBack("checkout")} />
              </Link>
            </Grid>
            <Grid xs={8} md={8} lg={8} item>
              <h1 className="sidebar-layout-header-name">{"Card Details"}</h1>
            </Grid>
          </Grid>

          {/* <div> {this.state.userCardDetails.length > 0
            ? this.state.userCardDetails.map((card, index) => (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  key={index}
                >
                  <Grid item xs={2}>
                    <Radio
                                  color="primary"

                      checked={this.state.user_selected_card_id[0] == card.id}
                      onChange={() => this.handleChangeOrderType(card.id)}
                      inputProps={{ "aria-label": "A" }}
                      value={card.id}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <div  onClick={() => this.handleChangeOrderType(card.id)}>
                    <Link to="#">
                  <Card
                title={wifi}
                bordered={false}
                className="card-credit header-solid h-ful"
              >
                <h5 className="card-number">{"XXXX XXXX XXXX " + card.last4}</h5>

                <div className="card-footer">
                  <div className="card-footer-col col-logo ml-auto">
                    <img src={"https://logo.clearbit.com/"+ card.brand.toLowerCase() +".com"} alt="mastercard" />
                  </div>
                </div>
              </Card>
              </Link>
              </div>
                  </Grid>
                  <Grid item xs={2}>
                    <Link  to="#">
                      <DeleteIcon
                        style={{ fontSize: 30 }}
                        color="secondary"
                        onClick={() => this.handleDeleteRow(card)}
                      />
                    </Link>
                    </Grid>
                </Grid>
              ))
            : null}
          </div>
          {this.state.userCardDetails.length > 0 ?
          <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={2}>
            <Checkbox
              checked={this.state.changePickup}
              onChange={() => this.handleChangePickUp()}
              name="Do you want to change your pick up address?"
              color="primary"
            />
          </Grid>
          <Grid item xs={10}>
            <MTypography >
              Do you want to use another card?  
            </MTypography>
          </Grid>
        </Grid> : null
          }
          {this.state.changePickup || this.state.userCardDetails.length == 0 ? ( */}
          <div id="PaymentForm">
            <Card
              number={this.state.cardNumber}
              name={this.state.name}
              expiry={this.state.expiry}
              cvc={this.state.cvc}
              focused={this.state.focused}
              placeholders={{name:""}}
              callback={this.handleCallback}
            />
            <TextField
              autoFocus
              style={{
                margin: "2%",
                marginTop: "5%",
                borderRadius: 0,
                width: "96%",
              }}
              error={this.state.errors.cardNumber.length == 0 ? false : true}
              fullWidth
              size="small"
              helperText={this.state.errors.cardNumber}
              label="Card Number *"
              name="cardNumber"
              onChange={this.handleInputChange}
              onBlur={this.handleInputChange}
              onFocus={this.handleInputFocus}
              inputProps={{
                onKeyPress: this._handleKeyPress,
                pattern: "[d| ]{16,22}",
              }}
              inputRef={(ref) => this.inputRefs.push(ref)}
              type="tel"
              value={this.state.cardNumber}
              variant="outlined"
            />
            {/* <TextField
              style={{ margin: "2%", borderRadius: 0, width: "96%" }}
              error={this.state.errors.name.length == 0 ? false : true}
              fullWidth
              size="small"
              helperText={this.state.errors.name}
              label="Name *"
              name="name"
              onChange={this.handleInputChange}
              onBlur={this.handleInputChange}
              onFocus={this.handleInputFocus}
              inputRef={(ref) => this.inputRefs.push(ref)}
              ref={"input2"}
              inputProps={{ onKeyPress: this._handleKeyPress }}
              type="text"
              value={this.state.name}
              variant="outlined"
            /> */}
            <Grid
              container
              direction="row"
              // justifyContent="center"
              // alignItems="center"
            >
              <Grid xs={6} md={6} lg={6} item style={{ padding: 6 }}>
                <TextField
                  style={{ margin: "2%", borderRadius: 0, width: "96%" }}
                  error={this.state.errors.expiry.length == 0 ? false : true}
                  fullWidth
                  size="small"
                  helperText={this.state.errors.expiry}
                  label="Valid Thru *"
                  name="expiry"
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                  inputProps={{
                    onKeyPress: this._handleKeyPress,
                    pattern: "dd/dd",
                  }}
                  ref={"input2"}
                  inputRef={(ref) => this.inputRefs.push(ref)}
                  type="tel"
                  value={this.state.expiry}
                  variant="outlined"
                />
              </Grid>
              <Grid xs={6} md={6} lg={6} item style={{ padding: 6 }}>
                <TextField
                  style={{ margin: "2%", borderRadius: 0, width: "96%" }}
                  error={this.state.errors.cvc.length == 0 ? false : true}
                  fullWidth
                  size="small"
                  helperText={this.state.errors.cvc}
                  label="CVC *"
                  name="cvc"
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                  inputProps={{ pattern: "d{3,4}" }}
                  inputRef={(ref) => this.inputRefs.push(ref)}
                  onKeyPress= {(e) => {
                    if (e.key === 'Enter' && this.state.cardNumber !== "" &&
                    this.state.errors.cardNumber.length === 0 &&
                    this.state.expiry !== "" &&
                    this.state.errors.expiry.length === 0 && this.state.cvc !== "" &&
                    this.state.errors.cvc.length === 0) {
                      this.saveCardDetails()
                    }
                  }}
                  ref={"input3"}
                  type="tel"
                  value={this.state.cvc}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </div>
          {/* ) : null} */}
        </Paper>
        <Paper elevation={1} className={classes.buttonLayout}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "100%", height: "93%" }}
            onClick={() => this.saveCardDetails()}
            disabled={buttonDisable || this.state.loading}
          >
            Pay with Card
          </Button>
        </Paper>
      </>
    );
  }
}

// export default withStyles(useStyles, { withTheme: true })(OrderItemsDetails);
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      currentItem,
      removeCurrentItem,
      orderCalculation,
      insertItem,
      userLogin,
      userLogout,
      addUserCardDetails,
      addPaymentCardId,
      removeUserCardDetails,
      orderUser,
    },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    current_item: state.current_item,
    locations: state.locations,
    delivery_pickup: state.delivery_pickup,
    user: state.user,
    card_details: state.card_details,
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true })
)(UserCardDetails);