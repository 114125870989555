import React from "react";
import { withStyles } from "@material-ui/styles";
import {
  currentItem,
  removeCurrentItem,
} from "../../../../actions/current_item";
import { insertItem } from "../../../../actions/order_items";
import { orderCalculation } from "../../../../actions/order_actions";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { Paper, TextField, Grid, Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import * as Constant from "../../../../constants";
import { userSignIn } from "../../../../services/user/user";
import { userLogin } from "../../../../actions/user_actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";

const useStyles = (theme) => ({
  flexLayout: {
    flex: 10,
    flexDirection: "row",
    alignItems: "stretch",
    overflow: "hidden",
    padding: 13,
    marginBottom: 8,
    backgroundColor: "#fff",
    overflowY: "scroll",
  },
  buttonLayout: {
    display: "flex",
    flex: 0.6,
    padding: 8,
    justifyContent: "center",
    // alignItems:"center",
    backgroundColor: "#fff",
  },
});

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView();

  return { executeScroll, elRef };
};

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      item_qty: 1,
      email: "",
      password: "",
      loading: false,
      errors: {
        email: "",
        password: "",
      },
    };
    this.inputRefs = []
    this.elScroll = utilizeScroll();
  }
  componentDidMount() {
  }

  componentWillUnmount() {
      this.setState = (state,callback)=>{
        return;
    };
  }

  handleChange = (panel) => (event, isExpanded) => {
    isExpanded
      ? this.setState({ expanded: panel })
      : this.setState({ expanded: false });
  };

  LogIn = () => {
    this.elScroll.executeScroll()
    this.setState({loading:true})
    if (
      null != this.state.email &&
      undefined != this.state.email &&
      "" != this.state.email.trim()
    ) {
      if (
        null != this.state.password &&
        undefined != this.state.password &&
        "" != this.state.password.trim()
      ) {
        let payload = {
          email: this.state.email,
          password: this.state.password,
        };

        userSignIn(payload)
          .then((res) => {
            if (res.data.error) {
              if (res.data.error.code === 404) {
                // const path = "/" + localStorage.getItem("tenant") + "/404";
                this.props.history.push('/404');
              }
            } else if (res.data.flag) {
              localStorage.setItem("token", res.data.data.token);
              this.props.userLogin(res.data.data.user[0]);
              if(undefined != this.props.layout && null != this.props.layout && "" != this.props.layout) {
                this.props.checkOutInsidePage(this.props.layout);
              } else {
                this.props.checkOutInsidePage("checkout");
              }
              this.setState({loading:false})
              // toast.success(res.data.message);
            } else {
              this.setState({loading:false})
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            this.setState({loading:false})
            toast.error(err.toString());
          });
      }
    }
  };

  onFinishFailed = () => {};

  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "email":
        errors.email =
          value.length === 0
            ? Constant.USER_EMAIL_REQUIRED
            : validEmailRegex.test(value)
            ? value.length < 255 
            ? ""
            : Constant.USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER
            : Constant.USER_EMAIL_NOT_VALID;
        break;
      case "password":
        errors.password =
          value.length === 0
            ? Constant.PASSWORD_FIELD_REQUIRED
            : value.length < 8
            ? Constant.PASSWORD_MUST_BE_8_CHARACHTER
            : "";
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };

  goBack = (type) => {
    this.props.checkOutInsidePage(type);
  };
  _handleKeyPress = e => {
    const event = e;
    const { currentTarget } = e;
    if (event.key === "Enter") {
      let inputIndex = this.inputRefs.indexOf(currentTarget);
      if (inputIndex < this.inputRefs.length - 1) {
        this.inputRefs[inputIndex + 1].focus();
      } else {
        this.inputRefs[0].focus();
      }
      event.preventDefault();
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <>
      <div ref={this.elScroll.elRef}></div>
       {this.state.loading ? <LinearProgress color="primary" /> : null}
        <Paper elevation={1} className={classes.flexLayout}>
          <Grid
            container
            direction="row"
            // justifyContent="center"
            alignItems="center"
          >
            <Grid xs={2} md={2} lg={2} item>
              <Link to="#" className="text-dark font-bold">
                <ArrowBackIcon onClick={() => this.goBack("userCreate")} />
              </Link>
            </Grid>
            <Grid xs={8} md={8} lg={8} item>
              <h1 className="sidebar-layout-header-name">{"Sign In"}</h1>
            </Grid>
          </Grid>
          <TextField
            autoFocus
            style={{ margin: "2%", borderRadius: 0, width: "96%" }}
            error={this.state.errors.email.length == 0 ? false : true}
            fullWidth
            size="small"
            helperText={this.state.errors.email}
            label="Email address *"
            name="email"
            onChange={this.handleInputChange}
            onBlur={this.handleInputChange}
            inputProps={{ onKeyPress: this._handleKeyPress }}
            inputRef={ref => this.inputRefs.push(ref)}
            type="email"
            value={this.state.email}
            variant="outlined"
          />
          <TextField
            style={{ margin: "2%", borderRadius: 0, width: "96%" }}
            error={this.state.errors.password.length == 0 ? false : true}
            fullWidth
            size="small"
            helperText={this.state.errors.password}
            label="Password *"
            name="password"
            onChange={this.handleInputChange}
            onBlur={this.handleInputChange}
            inputRef={ref => this.inputRefs.push(ref)}
            ref={"input2"}
            // inputProps={{ onKeyPress: this._handleKeyPress }}
             onKeyPress= {(e) => {
              if (e.key === 'Enter' && this.state.email !== "" &&
              this.state.errors.email.length === 0 &&
              this.state.password !== "" &&
              this.state.errors.password.length === 0) {
                this.LogIn()
              }
            }}
            type="password"
            value={this.state.password}
            variant="outlined"
          />
          <p className="font-semibold text-muted text-right lh-1">
            <Link
              to="#"
              onClick={() => this.goBack("forgotPassword")}
              className="text-dark font-bold"
            >
              Forgot Password?&nbsp;&nbsp;&nbsp;&nbsp;
            </Link>
          </p>
        </Paper>
        <Paper elevation={1} className={classes.buttonLayout}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "100%", height: "93%" }}
            onClick={() => this.LogIn()}
            disabled={
              this.state.email == "" ||
              this.state.errors.email.length > 0 ||
              this.state.password == "" ||
              this.state.errors.password.length > 0
            }
          >
            Continue
          </Button>
        </Paper>
      </>
    );
  }
}

// export default withStyles(useStyles, { withTheme: true })(OrderItemsDetails);
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { currentItem, removeCurrentItem, orderCalculation, insertItem, userLogin },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    current_item: state.current_item,
    order: state.order,
    order_items: state.order_items,
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true })
)(SignIn);
