import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Row, Col, Typography } from "antd";
import {
  Button,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
import * as Constant from "../constants";
import { bindActionCreators, compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { forgotPassword } from "../services/user/user";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LinearProgress } from "@material-ui/core";
import { userLogin } from "../actions/user_actions";
import logo from "../assets/images/Octopos-logo-blue.png";
import Footer from "../../src/components/layout/Footer";
import sideImage from "../assets/images/auth13.jpg"

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const { Title } = Typography;
const { Header, Content } = Layout;
const styles = (theme) => ({
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
      width: "0%",
    },
  },
  imageLogo:{
    [theme.breakpoints.down("md")]: {
      textAlign:'center',
    },
    // textAlign:'center',
    paddingBottom:16,
    width:"50%"
  }
});

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView();

  return { executeScroll, elRef };
};

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
      image_loc:'',
      backgroundImage:"",
      errors: {
        email: "",
        password: "",
      },
    };
    this.elScroll = utilizeScroll();
  }
componentDidMount(){
  let flag_image=false
  let online_location_image = false;
  if(null != this.props.locations.allLocations && undefined != this.props.locations.allLocations && '' != this.props.locations.allLocations) {
    this.props.locations.allLocations.map((locations, index) => {
      if(!flag_image && locations.image_url != null && locations.image_url != undefined && locations.image_url != ''){
        
        this.setState({image_loc:locations.image_url})
        flag_image=true
      }
      locations.location_online_order_settings.map((settings, i) => {
        if(!online_location_image && settings.image_url_online != null && settings.image_url_online != undefined && settings.image_url_online != ''){
          online_location_image=true
          this.setState({backgroundImage:settings.image_url_online})
        } else if(!online_location_image && locations.location_online_order_settings.length - 1 == i && this.state.backgroundImage == ""){
          this.setState({backgroundImage:sideImage })
          online_location_image=true
        }
      });
    })
  } else {
    this.setState({backgroundImage:sideImage })
  }
}
  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
  };
  }
  
  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "email":
        errors.email =
          value.length === 0
            ? Constant.USER_EMAIL_REQUIRED
            : validEmailRegex.test(value)
            ? value.length < 255 
            ? ""
            : Constant.USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER
            : Constant.USER_EMAIL_NOT_VALID;
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };
  backToHome = () => {
    window.location.href=process.env.REACT_APP_ACCESS_KEY +'/'+ localStorage.getItem('tenant')
    // this.props.history.push("/" + localStorage.getItem("tenant"));
  };
  ForgotPassword = () => {
    this.elScroll.executeScroll()
    this.setState({ loading: true });
    if (
      null != this.state.email &&
      undefined != this.state.email &&
      "" != this.state.email.trim()
    ) {
      let payload = {
        email: this.state.email,
        tenant: localStorage.getItem("tenant"),
        image: this.state.image_loc
      };
      forgotPassword(payload)
        .then((res) => {
          if (res.data.error) {
            if (res.data.error.code === 404) {
              // const path = "/" + localStorage.getItem("tenant") + "/404";
              this.props.history.push('/404');
            }
          } else if (res.data.flag) {
            toast.success(res.data.message);
            const path = "/" + localStorage.getItem("tenant") + "/sign-in";
            this.props.history.push(path);
            this.setState({ loading: false });
          } else {
            toast.error(res.data.message);
            this.setState({ loading: false });
          }
        })
        .catch((err) => {
          toast.error(err.toString());
          this.setState({ loading: false });
        });
    }
  };
  render() {
    const { classes } = this.props;

    return (
      <>
      <div ref={this.elScroll.elRef}></div>
      {this.state.loading ? <LinearProgress color="primary" /> : null}
        <Layout className="layout-default layout-signin">
          <Header>
            <div className="header-brand" key={"brandName"}>
              {/* <img src={logo} alt="" height={40} /> */}
            </div>
          </Header>
          <Content className="signin">
            <Row gutter={[24, 0]} justify="flex-start" flex-direction="column">
              <div className={classes.quoteContainer}>
              <Col
                  className="sign-img"
                  style={{
                    backgroundImage: `url("${this.state.backgroundImage}")`,
                    height: "300px",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '30%',
                     position: 'absolute',
                     left: '0px',
                     top:'0px',
                     height: '100%',
                     boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
                  }}             
                   >
                  {/* <img src="https://img.freepik.com/premium-photo/closeup-drop-water-leaf-flower-reflecting-surrounding-colors-textures_674594-4382.jpg" alt="" /> */}
                </Col>
              </div>
              <div className={classes.quote}>
                <Col className="select-order">
                <div className={classes.imageLogo}>
                  <img src={this.state.image_loc} alt=""  className="location-image" />
                  </div>
                  <Title className="font-regular text-muted" level={2}>
                    Forgot Password
                  </Title>
                  <div
                    layout="vertical"
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      // alignItems: "center",
                      justifyContent: "center",
                      // border: "1px solid rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <TextField
                      autoFocus
                      style={{ marginBottom: "2%" }}
                      error={this.state.errors.email.length == 0 ? false : true}
                      fullWidth
                      helperText={this.state.errors.email}
                      label="Email address *"
                      name="email"
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      onKeyPress= {(e) => {
                        if (e.key === 'Enter' && this.state.email !== "" &&
                        this.state.errors.email.length === 0 ) {
                          this.ForgotPassword()
                          // write your functionality here
                        }
                      }}
                      type="email"
                      value={this.state.email}
                      variant="outlined"
                      size="small"
                    />

                    <p className="font-semibold text-muted fw-normal lh-lg text-right">
                      <Link
                        to={`/${localStorage.getItem("tenant")}/sign-in`}
                        className="text-dark font-bold text-muted"
                      >
                        Login Now?&nbsp;&nbsp;&nbsp;&nbsp;
                      </Link>
                    </p>
                    <Row gutter={[24, 0]} justify="center">
                      <Col xl={14} xs={14} sm={14} md={14} lg={14}>
                        <Button
                         fullWidth
                          variant="contained"
                          color="primary"
                          onClick={this.ForgotPassword}
                          style={{ marginBottom: "7px" }}
                          disabled={
                            this.state.email == "" ||
                            this.state.errors.email.length > 0 
                          }
                        >
                          Forgot Password
                        </Button>
                      </Col>
                      <Col xl={10} xs={10} sm={10} md={10} lg={10}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={this.backToHome}
                          style={{ marginBottom: "7px" }}
                        >
                          Go Home
                        </Button>
                      </Col>
                    </Row>
             <Footer/>
                  </div>
                </Col>
              </div>
            </Row>
          </Content>
        </Layout>
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ userLogin }, dispatch);
}
function mapStateToProps(state) {
  return {
    user: state.user,
    locations:state.locations
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ForgotPassword);
