
export const ADD_LOCATION = 'ADD_LOCATION';
export const REMOVE_LOCATION = 'REMOVE_LOCATION';

export function addLocations(results,date) {
    let locations_payload = {
        data_store_date: date,
        allLocations: results
    }
    return {
        type: ADD_LOCATION,
        payload: locations_payload
    };

}

export function removeLocations() {
    return {

        type: REMOVE_LOCATION,
        payload: []
    };
}