export const productModifierSetup = (prod) => {
  return new Promise(async (resolve, reject) => {
    let single = JSON.parse(JSON.stringify(prod))
    // console.log("single   ++++++++++++   UUUUUUUUUUUUUUUUUUUUU");
    single.taxes = single.taxes
    single.modifier_set = single.modifier_set
    // single.modifier_set.server_id = single.modifier_set.id
    if (single.modifier_set.length > 0) {
      single.modifier_set.map((set) => {
        set.server_id = set.id
        set.modifiers = set.modifiers
        if(set.modifiers.length > 0) {
          set.modifiers.map((mod) => {
            mod.server_id = mod.id
          })
        }
        set.selected_modifiers = []
        set.total_modifiers_price = 0
        set.modifiers.sort(function(a, b) {
          return a.sort - b.sort
        })
      })
      single.total_modifiers_price = 0
      single.is_modifier_product = 1
    } else {
      single.modifier_set = []
      single.total_modifiers_price = 0
      single.is_modifier_product = 0
    }
    resolve(single)
  })
}
