import React from "react";
import { withStyles } from "@material-ui/styles";
import {
  currentItem,
  removeCurrentItem,
} from "../../../../actions/current_item";
import { insertItem } from "../../../../actions/order_items";
import { orderCalculation } from "../../../../actions/order_actions";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { Paper, Button, TextField, Grid } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import * as Constant from "../../../../constants";
import { userRegistration } from "../../../../services/user/user";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";

const useStyles = (theme) => ({
  flexLayout: {
    flex: 10,
    flexDirection: "row",
    alignItems: "stretch",
    overflow: "hidden",
    padding: 13,
    marginBottom: 7,
    backgroundColor: "#fff",
    overflowY: "scroll",
  },
  buttonLayout: {
    display: "flex",
    flex: 0.6,
    padding: 8,
    justifyContent: "center",
    backgroundColor: "#fff",
  },
});

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView();

  return { executeScroll, elRef };
};

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

class CreateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      item_qty: 1,
      name: "",
      last_name: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zip: "",
      email: "",
      phone: "",
      password: "",
      confirmpassword: "",
      addressObj: "",
      loading: false,
      errors: {
        name: "",
        last_name: "",
        address: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        email: "",
        phone: "",
        password: "",
        confirmpassword: "",
      },
      errorMessage: "",
    };
    this.inputRefs = []
    this.elScroll = utilizeScroll();
  }
  getAddressObject = (add_component) => {
    const ShouldBeComponent = {
      street_number: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      province: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      country: ["country"],
    };

    let address = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: "",
    };

    add_component.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });

    // Fix the shape to match our schema
    address.address = address.street_number + " " + address.street;
    delete address.street_number;
    delete address.street;
    if (address.country === "US") {
      address.state = address.province;
      delete address.province;
    }
    return address;
  };
  componentDidMount() {
  }

  componentWillUnmount() {
      this.setState = (state,callback)=>{
        return;
    };
  }

  handleChange = (panel) => (event, isExpanded) => {
    isExpanded
      ? this.setState({ expanded: panel })
      : this.setState({ expanded: false });
  };

  addUserInServer = () => {
    this.elScroll.executeScroll()
    this.setState({loading:true})
    if (
      null != this.state.email &&
      undefined != this.state.email &&
      "" != this.state.email.trim()
    ) {
      if (
        null != this.state.name &&
        undefined != this.state.name &&
        "" != this.state.name.trim()
      ) {
        if (
          null != this.state.last_name &&
          undefined != this.state.last_name &&
          "" != this.state.last_name.trim()
        ) {
          if (
            null != this.state.password &&
            undefined != this.state.password &&
            "" != this.state.password.trim() 
            // this.state.confirmpassword.trim() == this.state.password.trim()
          ) {
            if(this.state.confirmpassword.trim()  === this.state.password.trim()){
              let payload = {
                first_name: this.state.name,
                last_name: this.state.last_name,
                email: this.state.email,
                phone: this.state.phone,
                password: this.state.password,
                address: this.state.addressObj?.address,
                city: this.state.addressObj?.city,
                state: this.state.addressObj?.state,
                country: this.state.addressObj?.country,
                zipcode: parseInt(this.state.addressObj?.postal_code),
              };
  
              userRegistration(payload)
                .then((res) => {
                  if (res.data.error) {
                    if (res.data.error.code === 404) {
                      // const path = "/" + localStorage.getItem("tenant") + "/404";
                      this.props.history.push('/404');
                    }
                  } else if (res.data.flag) {
                    this.props.signIn();
                    toast.success(res.data.message);
                    this.setState({loading:false})
                  } else {
                    toast.error(res.data.message);
                    this.setState({loading:false})
                  }
                })
                .catch((err) => {
                  toast.error(err.toString());
                  this.setState({loading:false})
                });
            }else{
            toast.error(Constant.PASSWORD_CONFIRM_PASSWORD_NOT_SAME);
            this.setState({loading:false})
           }
          }
        }
      }
    }
  };

  validate = (phone) => {
    const regex = /^[0-9\b]+$/;
    return regex.test(phone);
  };

  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const that = this;
    let errors = this.state.errors;
    switch (name) {
      case "name":
        errors.name =
          value.length === 0
            ? Constant.USER_FIRST_NAME_REQUIRED
            : value.length > 255
            ? Constant.USER_FIRST_NAME_LENGTH_GREATE_THEN_255
            : "";
        break;
      case "last_name":
        errors.last_name =
          value.length === 0
            ? Constant.USER_LAST_NAME_REQUIRED
            : value.length > 255
            ? Constant.USER_LAST_NAME_LENGTH_GREATE_THEN_255
            : "";
        break;
      case "email":
        errors.email =
          value.length === 0
            ? Constant.USER_EMAIL_REQUIRED
            : validEmailRegex.test(value)
            ? value.length < 255 
            ? ""
            : Constant.USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER 
            : Constant.USER_EMAIL_NOT_VALID;
        break;
      case "phone":
        errors.phone =
          value.length === 0
            ? Constant.USER_PHONE_NUMBER_REQUIRED
            : this.validate(value)
            ? value.length > 10
            ? Constant.USER_PHONE_NUMBER_GREATER_THEN_10
            : value.length < 3
            ? Constant.USER_PHONE_NUMBER_MUST_BE_3_DIGITS
            :  ""
            : Constant.USER_PHONE_NUMBER__NOT_VALID;
        break;
      case "password":
        errors.password =
          value.length === 0
            ? Constant.PASSWORD_FIELD_REQUIRED
            : value.length < 8
            ? Constant.PASSWORD_MUST_BE_8_CHARACHTER
            : "";
        break;
      case "confirmpassword":
        errors.confirmpassword =
          value.length === 0
            ? Constant.CONFIRM_PASSWORD_FIELD_REQUIRED
            : value.length < 8
            ? Constant.CONFIRM_PASSWORD_MUST_BE_8_CHARACHTER
            : this.state.password != value
            ? Constant.PASSWORD_CONFIRM_PASSWORD_NOT_SAME
            : "";
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };

  goBack = (type) => {
    this.props.checkOutInsidePage(type);
  };
  setValue = async (val) => {
    this.setState({ address: val });
    if (null == val) {
      this.setState({ addressObj: ""})
    }
    const geocodeObj =
      val && val.value && (await geocodeByPlaceId(val.value.place_id));
    const addressObject =
      geocodeObj && this.getAddressObject(geocodeObj[0].address_components);

    if (
      null != addressObject &&
      undefined != addressObject &&
      "" != addressObject
    ) {
      if (
        null != addressObject.address &&
        undefined != addressObject.address &&
        " " != addressObject.address
      ) {
        if (
          null != addressObject.city &&
          undefined != addressObject.city &&
          "" != addressObject.city
        ) {
          if (
            null != addressObject.state &&
            undefined != addressObject.state &&
            "" != addressObject.state
          ) {
            if (
              null != addressObject.country &&
              undefined != addressObject.country &&
              "" != addressObject.country
            ) {
              if (
                null != addressObject.postal_code &&
                undefined != addressObject.postal_code &&
                "" != addressObject.postal_code
              ) {
                this.setState({ addressObj: addressObject, errorMessage: "" });
              } else {
                this.setState({
                  errorMessage: Constant.USER_ENTER_ADDRESS_ZIPCODE_NOT_FOUND,
                });
              }
            } else {
              this.setState({
                errorMessage: Constant.USER_ENTER_ADDRESS_COUNTRY_NOT_FOUND,
              });
            }
          } else {
            this.setState({
              errorMessage: Constant.USER_ENTER_ADDRESS_STATE_NOT_FOUND,
            });
          }
        } else {
          this.setState({
            errorMessage: Constant.USER_ENTER_ADDRESS_CITY_NOT_FOUND,
          });
        }
      } else {
        this.setState({ errorMessage: Constant.USER_ENTER_ADDRESS_NOT_FOUND });
      }
    }
  };
  _handleKeyPress = e => {
    const event = e;
    const { currentTarget } = e;
    if (event.key === "Enter") {
      let inputIndex = this.inputRefs.indexOf(currentTarget);
      if (inputIndex < this.inputRefs.length - 1) {
        this.inputRefs[inputIndex + 1].focus();
      } else {
        this.inputRefs[0].focus();
      }
      event.preventDefault();
    }
  };
  render() {
    const { classes } = this.props;
    let buttonDisable =
      this.state.name == "" ||
      this.state.errors.name.length > 0 ||
      this.state.last_name == "" ||
      this.state.errors.last_name.length > 0 ||
      this.state.email == "" ||
      this.state.errors.email.length > 0 ||
      this.state.phone == "" ||
      this.state.errors.phone.length > 0 ||
      this.state.password == "" ||
      this.state.errors.password.length > 0 ||
      this.state.confirmpassword == "" ||
      this.state.errors.confirmpassword.length > 0 ||
      null == this.state.addressObj ||
      undefined == this.state.addressObj ||
      "" == this.state.addressObj ||
      this.state.errorMessage.length > 0;
    return (
      <>
        <Paper elevation={1} square className={classes.flexLayout}>
        <div ref={this.elScroll.elRef}></div>
       {this.state.loading ? <LinearProgress color="primary" /> : null}
          <Grid
            container
            direction="row"
            // justifyContent="center"
            alignItems="center"
          >
            <Grid xs={2} md={2} lg={2} item>
              <Link to="#" className="text-dark font-bold">
                <ArrowBackIcon onClick={() => this.goBack("OrderItem")} />
              </Link>
            </Grid>
            <Grid xs={8} md={8} lg={8} item>
              <h1 className="sidebar-layout-header-name">{"Sign Up"}</h1>
            </Grid>
          </Grid>
          <TextField
            autoFocus
            style={{ margin: "2%", borderRadius: 0, width: "96%" }}
            error={this.state.errors.name.length == 0 ? false : true}
            fullWidth
            size="small"
            helperText={this.state.errors.name}
            label="First Name *"
            name="name"
            onChange={this.handleInputChange}
            onBlur={this.handleInputChange}
            inputProps={{ onKeyPress: this._handleKeyPress }}
            inputRef={ref => this.inputRefs.push(ref)}
            type="text"
            value={this.state.name}
            variant="outlined"
          />
          <TextField
            style={{ margin: "2%", borderRadius: 0, width: "96%" }}
            error={this.state.errors.last_name.length == 0 ? false : true}
            fullWidth
            size="small"
            helperText={this.state.errors.last_name}
            label="Last Name *"
            name="last_name"
            onChange={this.handleInputChange}
            onBlur={this.handleInputChange}
            inputRef={ref => this.inputRefs.push(ref)}
                      ref={"input2"}
                      inputProps={{ onKeyPress: this._handleKeyPress }}
            type="text"
            value={this.state.last_name}
            variant="outlined"
          />
          <TextField
            style={{ margin: "2%", borderRadius: 0, width: "96%" }}
            error={this.state.errors.email.length == 0 ? false : true}
            fullWidth
            size="small"
            helperText={this.state.errors.email}
            label="Email address *"
            name="email"
            onChange={this.handleInputChange}
            onBlur={this.handleInputChange}
            inputRef={ref => this.inputRefs.push(ref)}
                      ref={"input3"}
                      inputProps={{ onKeyPress: this._handleKeyPress }}
            type="email"
            value={this.state.email}
            variant="outlined"
          />
          <TextField
            style={{ margin: "2%", borderRadius: 0, width: "96%" }}
            error={this.state.errors.phone.length == 0 ? false : true}
            fullWidth
            size="small"
            helperText={this.state.errors.phone}
            label="Phone Number *"
            name="phone"
            onChange={this.handleInputChange}
            onBlur={this.handleInputChange}
            inputRef={ref => this.inputRefs.push(ref)}
                      ref={"input4"}
                      inputProps={{ onKeyPress: this._handleKeyPress }}
            type="text"
            value={this.state.phone}
            variant="outlined"
          />
          <TextField
            style={{ margin: "2%", borderRadius: 0, width: "96%" }}
            error={this.state.errors.password.length == 0 ? false : true}
            fullWidth
            size="small"
            helperText={this.state.errors.password}
            label="Password *"
            name="password"
            onChange={this.handleInputChange}
            onBlur={this.handleInputChange}
            inputRef={ref => this.inputRefs.push(ref)}
                      ref={"input5"}
                      inputProps={{ onKeyPress: this._handleKeyPress }}
            type="password"
            value={this.state.password}
            variant="outlined"
          />
          <TextField
            style={{ margin: "2%", borderRadius: 0, width: "96%" }}
            error={this.state.errors.confirmpassword.length == 0 ? false : true}
            fullWidth
            size="small"
            helperText={this.state.errors.confirmpassword}
            label="Confirm Password *"
            name="confirmpassword"
            onChange={this.handleInputChange}
            onBlur={this.handleInputChange}
            inputRef={ref => this.inputRefs.push(ref)}
                      ref={"input6"}
                      // inputProps={{ onKeyPress: this._handleKeyPress }}
            type="password"
            value={this.state.confirmpassword}
            variant="outlined"
          />
          <div
            style={{ width: "95%", marginLeft: "2.5%", marginBottom: "2.5px" }}
          >
            <GooglePlacesAutocomplete
              apiKey="AIzaSyBwCbMogALZHWTwVFKRiD_Oljb54a2aJ54"
              selectProps={{
                placeholder: "Address *",
                isClearable: true,
                value: this.state.address,
                onChange: (val) => {
                  this.setValue(val);
                },
              }}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ["us"],
                },
              }}
            />
            <span style={{ color: "red" }}>{this.state.errorMessage}</span>
          </div>

          <p className="font-semibold text-muted text-right lh-1">
            Already have an account?{" "}
            <Link
              to="#"
              onClick={this.props.signIn}
              className="text-dark font-bold"
            >
              Sign In&nbsp;&nbsp;&nbsp;&nbsp;
            </Link>
          </p>
        </Paper>
        <Paper elevation={1} square className={classes.buttonLayout}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "100%", height: "93%" }}
            onClick={() => this.addUserInServer()}
            disabled={buttonDisable}
          >
            Continue
          </Button>
        </Paper>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { currentItem, removeCurrentItem, orderCalculation, insertItem },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    current_item: state.current_item,
    order: state.order,
    order_items: state.order_items,
    coupon_status: state.coupon_status,
    weight: state.weight,
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true })
)(CreateUser);
