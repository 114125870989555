import { ADD_REWARD, ADD_REWARD_CARD_OBJECT } from '../actions/reward_actions';
import { COMPLETE_ORDER } from '../actions/order_actions';
import { CLEAR_ITEMS } from '../actions/order_items';

export default function (state = [], action) {
    switch (action.type){
    case ADD_REWARD:
        {
            return action.payload;
        }
    case ADD_REWARD_CARD_OBJECT:
        {
            return action.payload;
        }    
    case COMPLETE_ORDER:
      {
        return [];
      }

    case CLEAR_ITEMS:
      {
        return [];
      }    
    }
    return state;
}
