
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from '../reducer/index'
import { twofix } from '../helpers/calculation'
import _ from 'lodash'
import createEngine from 'redux-storage-engine-reactnativeasyncstorage'
import * as storage from 'redux-storage'

const PaymentOrderCheckMiddleware_OrderMinimumAmountMiddleware = (store) => (next) => (action) => { // Call middleware
  var storeVar = store.getState()// Get current state values
  var state = storeVar.order// Get current state values of Order
  var orderLine = storeVar.order_items // Get current state values of order lines
  if (action.type == 'INSERT_ITEM') { // Call Insert Item reducer
    let response = action.payload.item

    let itemAlreadyExists = _.findIndex(orderLine, function(o) {
      return o.server_id == response.server_id && _.isEqual(o.modifier_set, response.modifier_set)
    })
    if (itemAlreadyExists > -1) { // Item already exists in lines
      if (orderLine[itemAlreadyExists].sold_by_weight != 1) { // Normal Product
        if (orderLine[itemAlreadyExists].quantity + 1 > 999999 || parseFloat(orderLine[itemAlreadyExists].quantity) + parseFloat(action.payload.quantity) > 999999) {
          // showMessage({
          //   message: Constant.CANT_ADD_QUANTITY_999999,
          //   type: 'danger',
          // })
        } else {
          next(action)
        }
      } else {// Sold by Weight item
        if (
          parseFloat(orderLine[itemAlreadyExists].quantity) + parseFloat(action.payload.quantity) >
          999999.99
        ) {
          // showMessage({
          //   message: Constant.CANT_ADD_QUANTITY_999999_99,
          //   type: 'danger',
          // })
        } else {
          next(action)
        }
      }
    } else { // Perform Next action
      next(action)
    }
  } else if (action.type == 'ADD_ITEM') { // Call Add Item Reducer
    if (action.payload.item.sold_by_weight != 1) { // Normal Product
      if (action.payload.item.quantity + 1 > 999999) {
        // showMessage({
        //   message: Constant.CANT_ADD_QUANTITY_999999,
        //   type: 'danger',
        // })
      } else { // Perform next action
        next(action)
      }
    }
  } else if (storeVar.payments.length > 0) { // Payments Found
    if (
      action.type === 'VIEW_PRODUCTS' ||
      action.type === 'VIEW_ITEMS' ||
      action.type === 'CLEAR_ITEMS' ||
      action.type === 'MAKE_PAYMENT' ||
      action.type === 'GET_UNPAID_BALANCE' ||
      action.type === 'COMPLETE_ORDER' ||
      action.type === 'ADD_REWARD_CARD' ||
      action.type === 'ADD_REWARD_CARD_OBJECT' ||
      action.type === 'CLEAR_TICKET'||
      action.type === 'REDUX_STORAGE_SAVE'||
      action.type === 'UPDATE_TAXES' || 
      action.type === 'ORDER_FOR_HERE' ||
      action.type === 'ADD_USER_CARD_DETAILS' ||
      action.type === 'ADD_PAYMENT_CARD_ID' 
    ) { // we can perform next Actions 
      next(action)
    } else { // We can not perform next action
      // showMessage({
      //   message: Constant.PAYMENT_STARTED,
      //   type: 'danger',
      // })
    }
  } else if (action.type === 'UPDATE_ITEM_MODIFIERS') { // Call when Update modifiers
    var oldTotal = parseFloat(state.total) + parseFloat(state.order_discount)
    var total_discount = 0
    var orderLineState = storeVar.order_items
    var saleXqty = twofix(
      parseFloat(action.payload.item.sale_price) * parseFloat(action.payload.item.quantity)
    )

    if (
      'applied_coupon' in action.payload.item &&
      action.payload.item.applied_coupon != undefined &&
      action.payload.item.applied_coupon != ''
    ) { // Coupon applied
      var maxQty // calculate maximum quantity
      if (action.payload.item.quantity >= action.payload.item.applied_coupon.max_qty_per_coupon) {
        maxQty = action.payload.item.applied_coupon.max_qty_per_coupon
      } else {
        maxQty = action.payload.item.quantity
      }
      // coupon discount amountt
      if (action.payload.item.applied_coupon.coupon_discount_type == 'amou') {
        total_discount =
          twofix(action.payload.item.applied_coupon.coupon_discount_value) * twofix(maxQty)
      } else {
        //coupon discount percentage
        total_discount = twofix(
          (twofix(parseFloat(action.payload.item.sale_price) * parseFloat(maxQty)) *
            twofix(action.payload.item.applied_coupon.coupon_discount_value)) /
            100
        )
      }
    } else {
      // manual discount amount
      if (action.payload.item.discount_type == 'Amount') {
        total_discount =
          twofix(action.payload.item.discount_percentage) * twofix(action.payload.item.quantity)
      } else {
        // manual discount percentage
        total_discount = twofix(
          (twofix(saleXqty) * twofix(action.payload.item.discount_percentage)) / 100
        )
      }
    }
    if (parseFloat(saleXqty) < parseFloat(twofix(total_discount))) {//can not update item because discount is applied in product or order
      // showMessage({
      //   message: Constant.MODIFIER_UPDATE_ERROR,
      //   type: 'danger',
      // })
    } else {//order minimum amount check
      if (state.order_discount) {
        var total = 0
        orderLineState.map((item) => {
          if (item.new_identity != action.payload.item.new_identity) {
            total =
              total +
              twofix(
                twofix(twofix(item.sale_price) * twofix(item.quantity)) -
                  twofix(item.total_discount)
              )
          }
        })
        let new_prodCalc = twofix(
          twofix(twofix(action.payload.item.sale_price) * twofix(action.payload.item.quantity)) -
            twofix(total_discount)
        )
        let final_Total = total + new_prodCalc
        if (state.coupon_status != '' && state.coupon_status != undefined) { //for update modifier product coupon applied
          if (state.discount_type == 'Percentage') {  // Discount in Percentage
            // let discount = twofix(
            //   twofix(final_Total * state.coupon_status.coupon_discount_value) / 100
            // )
            if (
              twofix(twofix(final_Total)) >
              twofix(twofix(state.coupon_status.coupon_order_min_amount))
            ) { // Total is more than Order minimum amount
              next(action) // Perform next action
            } else { 
              // showMessage({
              //   message: Constant.MODIFIER_UPDATE_ERROR,
              //   type: 'danger',
              // })
            }
          } else { //Discount in amount
            if (
              twofix(twofix(final_Total)) >
              twofix(twofix(state.coupon_status.coupon_order_min_amount))
            ) {
              next(action)
            } else {
              // showMessage({
              //   message: Constant.MODIFIER_UPDATE_ERROR,
              //   type: 'danger',
              // })
            }
          }
        } else {
          // for modifier product manual discount
          if (state.discount_type == 'Percentage') {
            let new_order_discount = twofix(
              (twofix(final_Total) * twofix(state.order_percentage)) / 100
            )
            if (final_Total < new_order_discount) {//Checking Order total which is in minus or not
              // showMessage({
              //   message: Constant.MODIFIER_UPDATE_ERROR,
              //   type: 'danger',
              // })
            } else { // Perform Next action
              next(action)
            }
          } else {
            if (final_Total < state.order_discount) { //total is in minus
              // showMessage({
              //   message: Constant.MODIFIER_UPDATE_ERROR,
              //   type: 'danger',
              // })
            } else {// Perform Next action
              next(action)
            }
          }
        }
      } else {// Perform Next action
        next(action)
      }
    }
  } else if (action.type === 'UPDATE_ITEM_QTY') {//Action perform when Set Qty of item
    var oldTotal = parseFloat(state.total) + parseFloat(state.order_discount)
    var total_discount = 0
    var orderLineState = storeVar.order_items
    var saleXqty = twofix(
      parseFloat(action.payload.item.sale_price) * parseFloat(action.payload.quantity)
    )
    if (
      'applied_coupon' in action.payload.item &&
      action.payload.item.applied_coupon != undefined &&
      action.payload.item.applied_coupon != ''
    ) { // Coupon applied
      var maxQty // calculate Maximum quantity
      if (action.payload.quantity >= action.payload.item.applied_coupon.max_qty_per_coupon) {
        maxQty = action.payload.item.applied_coupon.max_qty_per_coupon
      } else {
        maxQty = action.payload.quantity
      }
      // coupon discount
      if (action.payload.item.applied_coupon.coupon_discount_type == 'amou') {
        // coupon amount
        total_discount =
          twofix(action.payload.item.applied_coupon.coupon_discount_value) * twofix(maxQty)
        if (total_discount > saleXqty) {
          total_discount = twofix(action.payload.item.sale_price) * twofix(maxQty)
        }
      } else {
        //coupon percentage
        total_discount = twofix(
          (twofix(parseFloat(action.payload.item.sale_price) * parseFloat(maxQty)) *
            twofix(action.payload.item.applied_coupon.coupon_discount_value)) /
            100
        )
      }
    } else {
      // manual discount
      if (action.payload.item.discount_type == 'Amount') {
        //manual amount
        total_discount =
          twofix(action.payload.item.discount_percentage) * twofix(action.payload.quantity)
      } else {
        // manual percentage
        total_discount = twofix(
          (twofix(saleXqty) * twofix(action.payload.item.discount_percentage)) / 100
        )
      }
    }
    if (parseFloat(saleXqty) < parseFloat(twofix(total_discount))) {// Total is in minus
      // showMessage({
      //   message: Constant.Quantity_UPDATE_ERROR,
      //   type: 'danger',
      // })
    } else {
      if (state.order_discount) { // Order discount
        var total = 0
        orderLineState.map((item) => {
          if (item.new_identity != action.payload.item.new_identity) {
            total =
              total +
              twofix(
                twofix(twofix(item.sale_price) * twofix(item.quantity)) -
                  twofix(item.total_discount)
              )
          }
        })
        let new_prodCalc = twofix(
          twofix(twofix(action.payload.item.sale_price) * twofix(action.payload.quantity)) -
            twofix(total_discount)
        )
        let final_Total = total + new_prodCalc
        if (state.coupon_status != '' && state.coupon_status != undefined) {
          //coupon applied
          if (state.discount_type == 'Percentage') { // Discount in percentage
            // let discount = twofix(
            //   twofix(final_Total * state.coupon_status.coupon_discount_value) / 100
            // )
            if (
              twofix(twofix(final_Total)) >
              twofix(twofix(state.coupon_status.coupon_order_min_amount)) // Total is in minus
            ) {
              // showMessage({
              //   message:
              //     action.payload.item.name + "'s quantity set as " + action.payload.quantity + '.',
              //   type: 'success',
              //   duration: 2500,
              // })
              next(action) // Perform next action
            } else { 
              // showMessage({
              //   message: Constant.Quantity_UPDATE_ERROR,
              //   type: 'danger',
              // })
            }
          } else { // Discount in amount
            if (
              twofix(twofix(final_Total)) > 
              twofix(twofix(state.coupon_status.coupon_order_min_amount)) // Total is in minus
            ) {
              // showMessage({
              //   message:
              //     action.payload.item.name + "'s quantity set as " + action.payload.quantity + '.',
              //   type: 'success',
              //   duration: 2500,
              // })
              next(action) // Perform next action
            } else {
              // showMessage({
              //   message: Constant.Quantity_UPDATE_ERROR,
              //   type: 'danger',
              // })
            }
          }
        } else {
          // manual discount
          if (state.discount_type == 'Percentage') {
            let new_order_discount = twofix(
              (twofix(final_Total) * twofix(state.order_percentage)) / 100
            )
            if (twofix(final_Total) < twofix(new_order_discount)) {//total is in minus
              // showMessage({
              //   message: Constant.Quantity_UPDATE_ERROR,
              //   type: 'danger',
              // })
            } else {
              // showMessage({
              //   message:
              //     action.payload.item.name + "'s quantity set as " + action.payload.quantity + '.',
              //   type: 'success',
              //   duration: 2500,
              // })
              next(action)
            }
          } else {
            if (final_Total < state.order_discount) {
              //total is in minus
              // showMessage({
              //   message: Constant.Quantity_UPDATE_ERROR,
              //   type: 'danger',
              // })
            } else {
              // showMessage({
              //   message:
              //     action.payload.item.name + "'s quantity set as " + action.payload.quantity + '.',
              //   type: 'success',
              //   duration: 2500,
              // })
              next(action)
            }
          }
        }
      } else {
        // showMessage({
        //   message: action.payload.item.name + "'s quantity set as " + action.payload.quantity + '.',
        //   type: 'success',
        //   duration: 2500,
        // })
        next(action)
      }
    }
  } else {
    if (
      action.type === 'MINUS_ITEM' ||
      action.type === 'DELETE_ITEM' ||
      action.type === 'DISCOUNT_PERCENTAGE_ITEM'
    ) {// Call Reducer when minus,remove item,add Discount
      var oldTotal = parseFloat(state.total) + parseFloat(state.order_discount)
      if (action.type === 'MINUS_ITEM') { //minus item 
        if (
          'applied_coupon' in action.payload.item &&
          action.payload.item.applied_coupon != undefined &&
          action.payload.item.applied_coupon != ''
        ) { // Applied Coupon
          var maxQty // Calculate maximum quanity
          if (action.payload.item.quantity >= action.payload.item.applied_coupon.max_qty_per_coupon) {
            maxQty = action.payload.item.applied_coupon.max_qty_per_coupon
          } else {
            maxQty = action.payload.item.quantity
          }
          if (action.payload.item.applied_coupon.coupon_discount_type == 'amou') { // Discount in amount
            var total_discount = parseFloat(action.payload.item.total_discount) / (maxQty * 1)
          } else { // Discount in percentage
            var total_discount =
              parseFloat(action.payload.item.sale_price) *
              parseFloat(action.payload.item.discount_percentage / 100)
          }
        } else {
          //manual discount
          if (action.payload.item.discount_type == 'Percentage') {
            var total_discount =
              parseFloat(action.payload.item.sale_price) *
              parseFloat(action.payload.item.discount_percentage / 100)
          } else {
            var total_discount = parseFloat(action.payload.item.discount_percentage)
          }
        }
        var newTotal =
          oldTotal - parseFloat(action.payload.item.sale_price) + parseFloat(total_discount)
      } else if (action.type === 'DELETE_ITEM') { // Delete Item
        var total_discount = parseFloat(action.payload.item.total_discount)
        var newTotal =
          oldTotal -
          parseFloat(action.payload.item.sale_price) * parseFloat(action.payload.item.quantity) +
          parseFloat(total_discount)
      } else { // Apply discount of product in percentage
        if (action.payload.discount.discount_type == 'Percentage') {
          var total_discount =
            parseFloat(action.payload.item.sale_price) *
            parseFloat(action.payload.item.quantity) *
            parseFloat(action.payload.discount.amount / 100)
        } else {// Apply discount of product in amount
          var total_discount =
            parseFloat(action.payload.item.quantity) * parseFloat(action.payload.discount.amount)
        }
        var newTotal = oldTotal - parseFloat(total_discount) // Calculate total
      }
      let order_discount_amount = 0
      if (
        'coupon_status' in state &&
        state.coupon_status != undefined &&
        state.coupon_status != ''
      ) {// Applied discount of order 
        if (state.coupon_status.coupon_discount_type == 'amou') {// Applied discount of order in amount
          order_discount_amount = parseFloat(state.coupon_status.coupon_discount_value)
        } else {// Applied discount of order in percentage
          // if (state.discount_reason == Constant.REWARD_REDEEMED) {
          //   order_discount_amount = parseFloat(25)
          // } else {
            order_discount_amount = newTotal * (state.order_percentage / 100)
          // }
        }
        if (
          parseFloat(newTotal).toFixed(2) < parseFloat(state.coupon_status.coupon_order_min_amount)// Total is less than Order minimum amount
        ) {
          if (action.type === 'DELETE_ITEM' || action.type === 'MINUS_ITEM') {// Perform Delete item or minus item
            if (state.coupon_status.coupon_discount_type == 'amou') { //Coupon discount in amount
              // showMessage({
              //   message:
              //     Constant.MINIMUM_ORDER_AMT_MUSTBE +
              //     twofix(parseFloat(
              //       parseFloat(state.coupon_status.coupon_order_min_amount) -
              //         parseFloat(state.coupon_status.coupon_discount_value)
              //     )).toFixed(2) +
              //     ' or more.',
              //   type: 'danger',
              // })
            } else { // Coupon discount in percentage
              if (
                twofix(
                  parseFloat(
                    parseFloat(state.coupon_status.coupon_order_min_amount) -
                      parseFloat(state.order_discount)
                  )
                ).toFixed(2) >= 0.01
              ) {
                // showMessage({
                //   message:
                //   Constant.MINIMUM_ORDER_AMT_MUSTBE +
                //   twofix(parseFloat(
                //       parseFloat(state.coupon_status.coupon_order_min_amount) -
                //         parseFloat(state.order_discount)
                //     )).toFixed(2) +
                //     ' or more.',
                //   type: 'danger',
                // })
              } else {
                // showMessage({
                //   message:
                //    Constant.MINIMUM_ORDER_AMT_MUSTBE_0ORMORE,
                //   type: 'danger',
                // })
              }
            }
          } else {
            // showMessage({
            //   message:
            //    Constant.MINIMUM_ORDER_AMT_DISCOUNT+
            //     parseFloat(
            //       parseFloat(state.coupon_status.coupon_order_min_amount) -
            //         parseFloat(state.coupon_status.coupon_discount_value)
            //     ) +
            //     ' or more.',
            //   type: 'danger',
            // })
          }
        } else {
          // if (
          //   state.discount_reason == Constant.REWARD_REDEEMED &&
          //   parseFloat(newTotal).toFixed(2) < parseFloat(25)
          // ) {
          //   if (action.type === 'DELETE_ITEM' || action.type === 'MINUS_ITEM') {
          //     showMessage({
          //       message:
          //         'Sorry, you can not remove this item as minimum order amount must be 0 or more.',
          //       type: 'danger',
          //     })
          //   } else {
          //     showMessage({
          //       message:
          //         'Sorry, you can not apply discount on this item as minimum order amount must be 0 or more.',
          //       type: 'danger',
          //     })
          //   }
          // } else {
            if (action.type === 'DISCOUNT_PERCENTAGE_ITEM') {
              // showMessage({
              //   message: 'Discount on ' + action.payload.item.name + ' is applied.',
              //   type: 'success',
              // })
            }
            next(action)
          // }
        }
      } else {
        // if (
        //   state.discount_reason == Constant.REWARD_REDEEMED &&
        //   parseFloat(newTotal).toFixed(2) < parseFloat(25)
        // ) {
        //   if (action.type === 'DELETE_ITEM' || action.type === 'MINUS_ITEM') {
        //     showMessage({
        //       message:
        //         'Sorry, you can not remove this item as minimum order amount must be 0 or more.',
        //       type: 'danger',
        //     })
        //   } else {
        //     showMessage({
        //       message:
        //         'Sorry, you can not apply discount on this item as minimum order amount must be 0 or more.',
        //       type: 'danger',
        //     })
        //   }
        // } else {
          if (state.discount_type == 'Amount') { // Manual discount of amount
            if (parseFloat(state.order_discount) <= parseFloat(newTotal).toFixed(2)) { // Order discount less than total
              if (action.type === 'DISCOUNT_PERCENTAGE_ITEM') {
                if (action.payload.discount.discount_type == 'Amount') { // Product manual discount in amount
                  var itemTotalDiscount =
                    parseFloat(action.payload.discount.amount) *
                    parseFloat(action.payload.item.quantity)
                } else {// Product manual discount in Percentage
                  var itemTotalDiscount =
                    (parseFloat(action.payload.item.quantity) *
                      parseFloat(action.payload.item.sale_price) *
                      parseFloat(action.payload.discount.amount)) /
                    100
                }
                if (state.total < itemTotalDiscount) {//Order total less than all lines discount total
                  var total = state.total
                  var discount = parseFloat(total) / action.payload.item.quantity
                  var num = discount.toFixed(3).slice(0, -1)
                  var Percentage = (parseFloat(num) * 100) / action.payload.item.sale_price
                  if (num >= 0.01 || Percentage >= 0.01) {//Apply Discount of product
                    if (action.payload.discount.discount_type == 'Percentage') {
                      // showMessage({
                      //   message:
                      //     Constant.DISCOUNT_MUST_BE_LESS +
                      //     Percentage.toFixed(3).slice(0, -1) +
                      //     ' %',
                      //   type: 'danger',
                      // })
                    } else {
                      // showMessage({
                      //   message: Constant.DISCOUNT_MUST_BE_LESS$ + num,
                      //   type: 'danger',
                      // })
                    }
                  } else { //Order discount already applied of 100%
                    // showMessage({
                    //   message:
                    //    Constant.ORDER_DICOUNT_100,
                    //   type: 'danger',
                    // })
                  }
                } else {// Successfully applied discount on item
                  // showMessage({
                  //   message: 'Discount on ' + action.payload.item.name + ' is applied.',
                  //   type: 'success',
                  // })
                  next(action)
                }
              } else {
                next(action) // Perform next action
              }
            } else {
              if (action.type === 'DISCOUNT_PERCENTAGE_ITEM') {//Call when apply discount
                if (state.discount_type == 'Amount') { // Discount in amount
                  if (action.payload.discount.discount_type == 'Amount') {
                    var itemTotalDiscount =
                      parseFloat(action.payload.discount.amount) *
                      parseFloat(action.payload.item.quantity)
                  } else { // Discount in Percentage
                    var itemTotalDiscount =
                      (parseFloat(action.payload.item.quantity) *
                        parseFloat(action.payload.item.sale_price) *
                        parseFloat(action.payload.discount.amount)) /
                      100
                  }
                  if (state.total < itemTotalDiscount) { // Order total less than Line's total discount
                    var total = state.total
                    var discount = parseFloat(total) / action.payload.item.quantity
                    var num = discount.toFixed(3).slice(0, -1)
                    var Percentage = (parseFloat(num) * 100) / action.payload.item.sale_price
                    if (num >= 0.01 || Percentage >= 0.01) {
                      if (action.payload.discount.discount_type == 'Percentage') {
                        // showMessage({
                        //   message:
                        //     Constant.DISCOUNT_MUST_BE_LESS +
                        //     Percentage.toFixed(3).slice(0, -1) +
                        //     ' %',
                        //   type: 'danger',
                        // })
                      } else {
                        // showMessage({
                        //   message:Constant.DISCOUNT_MUST_BE_LESS+ num,
                        //   type: 'danger',
                        // })
                      }
                    } else {// Order discount is already apply 100%
                      // showMessage({
                      //   message:
                      //     Constant.ORDER_DICOUNT_100,
                      //   type: 'danger',
                      // })
                    }
                  } else { // Successfully applied discount
                    // showMessage({
                    //   message: 'Discount on ' + action.payload.item.name + ' is applied.',
                    //   type: 'success',
                    // })
                    next(action)
                  }
                } else {
                  next(action)
                }
              } else {
                // showMessage({
                //   message:
                //    Constant.CANT_REMOVE_ITEM,
                //   type: 'danger',
                // })
              }
            }
          } else if (action.type === 'MINUS_ITEM') {
            next(action)
          } else if (action.type === 'DISCOUNT_PERCENTAGE_ITEM') { // Discount applied successfully
            // showMessage({
            //   message: 'Discount on ' + action.payload.item.name + ' is applied.',
            //   type: 'success',
            // })
            next(action)
          } else if (action.type == 'DELETE_ITEM') {
            next(action)
          }
      }
    } else {
      // if (
      //   state.discount_reason == Constant.REWARD_REDEEMED &&
      //   parseFloat(newTotal).toFixed(2) < parseFloat(25)
      // ) {
      //   if (action.type === 'DELETE_ITEM' || action.type === 'MINUS_ITEM') {
      //     showMessage({
      //       message:
      //         'Sorry, you can not remove this item as minimum order amount must be 0 or more.',
      //       type: 'danger',
      //     })
      //   } else {
      //     showMessage({
      //       message:
      //         'Sorry, you can not apply discount on this item as minimum order amount must be 0 or more.',
      //       type: 'danger',
      //     })
      //   }
      // } else {
        if (action.type === 'DISCOUNT_PERCENTAGE_ITEM') { // Discount applied successfully
          // showMessage({
          //   message: 'Discount on ' + action.payload.item.name + ' is applied.',
          //   type: 'danger',
          // })
        }
        next(action)
      // }
    }
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const engine = createEngine('my-save-key')
const middleware = storage.createMiddleware(engine)
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if(serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};

const persistedState = loadState();

const store = createStore(
  rootReducer,persistedState,
  composeEnhancers(
    applyMiddleware(thunk, middleware, PaymentOrderCheckMiddleware_OrderMinimumAmountMiddleware)
  )
)

store.subscribe(() => {
  saveState(store.getState());
});

const load = storage.createLoader(engine)
load(store)
load(store)
  .then((newState) => {})
  .catch(() => console.log('Failed to load previous state'))

export default store

