import { USER_LOGIN, USER_LOGOUT, WHO_IS_THIS_FOR_USER, ADD_PAYMENT_CARD_ID,REMOVE_WHO_IS_THIS_FOR_USER,UPDATE_USER } from '../actions/user_actions'

export default function(state = [], action) {
  switch (action.type) {
    case USER_LOGIN: {

      let newState = { ...state, user:action.payload }

      return newState
    }
    case WHO_IS_THIS_FOR_USER: {
      
      let newState = { ...state, whoIsThis:action.payload }

      return newState
    }
    case REMOVE_WHO_IS_THIS_FOR_USER: {
      
      let newState = { ...state }
      delete newState.whoIsThis

      return newState
    }
    case ADD_PAYMENT_CARD_ID: {
      
      let newState = { ...state, user:action.payload ,whoIsThis:action.payload }

      return newState
    }
    case USER_LOGOUT: {
      return []
    }
  }
  return state
}
