import React, { Component } from "react";
import {Button,Typography,Grid} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';
const styles = (theme) => ({
  root: {
    padding: 50
  },
  content: {
    // paddingTop: 150,
    textAlign: 'center'
  },
  image: {
    // marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560,
  }
  });

 class PageNotFound extends Component {
  constructor(props) {
    super(props);
    // this.backtoHome = this.backtoHome.bind(this, true);
  }
    
      // backtoHome=()=>{
      //   const path = "/" + localStorage.getItem("tenant")
      //   this.props.history.push(path);
      // }
      
  render() {
    
    const { classes } = this.props;
    return (
      <>
      <div className={classes.root}>
      <Grid
        container
      >
        <Grid
          item
          lg={12}
          xs={12}
        >
          <div className={classes.content}>
            
            <img
              alt="Under development"
              className={classes.image}
              src="https://thumbs.dreamstime.com/b/oops-words-reflective-white-background-concept-error-screens-49260938.jpg"
            />
           <Typography variant="h3">
              404
            </Typography>
            <Typography variant="h3">
              Page Not Found 
            </Typography>
             <Typography variant="subtitle2">
             We couldn't find the page you're looking for.
            </Typography>
            {/* <Button
                            variant="contained"
                            color="primary"
                            onClick={()=>this.backtoHome()}
                            style={{marginTop:'30px'}}
                          >
                            Go Back
                          </Button> */}
          </div>
        </Grid>
      </Grid>
    </div>
      </>
       
    );
  }
}


  export default withStyles(styles)(PageNotFound);