import { INSERT_TICKET, CLEAR_TICKET } from "../actions/tickets";

export default function(state = 0, action) {
  switch (action.type) {
    case INSERT_TICKET: {
      let newState = action.payload;
      return newState;
    }
    case CLEAR_TICKET: {
      let newOrderState = 0;
      return newOrderState;
    }
    default:
      return state;
  }
}
