import React from "react";
import { Route } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { clearItems } from "../../../../actions/order_items"
import {
  Button,
  List,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import { removeProducts ,removeFeaturedProducts} from "../../../../actions/menu_items"
import { removeDeliveryPickupDetails } from "../../../../actions/delivery_pickup_action";
import { twofix } from "../../../../helpers/calculation";
import { removeLocations } from "../../../../actions/location_action";
import { removeUserCardDetails } from "../../../../actions/card_Details_action";

import * as Constant from "../../../../constants";
const useStyles = (theme) => ({
  buttonLayout: {
    display: "flex",
    flex: 0.6,
    padding:8,
    justifyContent: "center",
    backgroundColor:"#fff",
  },
  orderTotalLayout: {
    display: "flex",
    flex: 2,
    flexDirection: "row",
    alignItems: "stretch",
    overflow: "hidden",
    overflowY: "scroll",
  },
  teaxName: {
    fontWeight: "bold"
  },
  removeButtonLayout: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop:"0%"
  },
  text: {
    fontWeight: "bold",
    fontSize:'1.3em',//Insert your required size
    textAlign:'right'

    },textBold:{
      fontWeight: "bold",
      fontSize:'1.3em',//Insert your required size
      textAlign:'left'
    },
    textLeft:{
    //   fontWeight: "bold",
    // fontSize:'1.3em',//Insert your required size,
    textAlign:'left'
    },
    textRight:{
      // fontWeight: "bold",
      // fontSize:'1.3em',//Insert your required size
      textAlign:'right'

    }
});

class OrderTotals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderModel: false,
      itemModel: false,
      tipAmount: 0
    };
  }
  componentDidMount() {
  }

  componentWillUnmount() {}

  removeAllItems = () => {
    this.setState({ itemModel: true})
  }

  handleOpenModel = () => {
     this.setState({ orderModel: true})
  }

  handleClose = () => {
    this.setState({ orderModel: false})
  }

  handleitemClose = () => {
    this.setState({ itemModel: false})
  }

  render() {
    const { classes } = this.props;
   
    return (
      <>
      <Dialog
        open={this.state.itemModel}
        onClose={() => this.handleitemClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Constant.CLEAR_ALL_ITEM}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleitemClose()} variant="contained" color="secondary">
            CANCEL
          </Button>
          <Route
            render={({ history }) => (
          <Button onClick={() => {
            this.props.clearItems()
            this.props.checkOutInsidePage("")
          }} variant="contained" color="primary" autoFocus>
            OK
          </Button>
          )}
          /> 
        </DialogActions>
      </Dialog>
      <Dialog
        open={this.state.orderModel}
        onClose={() => this.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Constant.CLEAR_ORDER}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()} variant="contained" color="secondary">
            CANCEL
          </Button>
          <Route
            render={({ history }) => (
          <Button onClick={() => {
            this.props.removeUserCardDetails();
            this.props.removeProducts();
            this.props.removeDeliveryPickupDetails();
            this.props.removeLocations();
            this.props.removeFeaturedProducts();

            this.props.clearItems();
            history.push("/"+localStorage.getItem('tenant'));
          }} variant="contained" color="primary" autoFocus>
            OK
          </Button>
          )}
          /> 
        </DialogActions>
      </Dialog>
      <Paper elevation={0} square className={classes.removeButtonLayout}>
              <Button
                color="secondary"
                style={{ width: "90%" }}
                onClick={() => this.removeAllItems()}
              >
                Remove All Item
              </Button>
              <Route
            render={({ history }) => (
              <Button
              color="secondary"
              style={{ width: "90%" }}
              onClick={() => this.handleOpenModel()}
              >
                Clear Order
              </Button>
           )}
          /> 
          </Paper>
          <Paper elevation={1} square className={classes.orderTotalLayout}>
          <List
            className={classes.list}
            style={{
            width:"100%",
            }}
          >
            <ListItem className="MuiListItem-rootTotal">
              <ListItemText primary="Subtotal"classes={{ primary: classes.textLeft }} />
              {/* <ListItemSecondaryAction> */}
                <ListItemText primary={"$" + this.props.order.total.toFixed(2)} classes={{ primary: classes.textRight }}/>
              {/* </ListItemSecondaryAction> */}
            </ListItem>
            <ListItem >
              <ListItemText primary="Total Tax" classes={{ primary: classes.textLeft }} />
              {/* <ListItemSecondaryAction> */}
                <ListItemText primary={"$" + this.props.order.tax_collected.toFixed(2)} classes={{ primary: classes.textRight }}/>
              {/* </ListItemSecondaryAction> */}
            </ListItem>
            {(
      null != this.props.order.tip_type &&
      undefined != this.props.order.tip_type &&
      "" != this.props.order.tip_type
    ) ? <ListItem >
    <ListItemText primary="Total Tip" classes={{ primary: classes.textLeft }}/>
    {/* <ListItemSecondaryAction> */}
      <ListItemText primary={"$" + twofix(
        twofix(this.props.order.balance) -
          [
            twofix(this.props.order.total) +
              twofix(this.props.order.tax_collected),
          ]
      )}  classes={{ primary: classes.textRight }}/>
    {/* </ListItemSecondaryAction> */}
  </ListItem> : null
            }
            <ListItem >
              <ListItemText primary="Total" classes={{ primary: classes.textBold }} />
              {/* <ListItemSecondaryAction> */}
                <ListItemText primary={"$" + this.props.order.balance.toFixed(2)} classes={{ primary: classes.text }} primaryTypographyProps={{fontSize: '24px'}} />
              {/* </ListItemSecondaryAction> */}
            </ListItem>
          </List>
          </Paper>
         <Paper elevation={1} square className={classes.buttonLayout}>
              <Button
                variant="contained"
                color="primary"
                style={{ width: "100%",height:"93%" }}
                onClick={this.props.continueCheckOut}
              >
                Continue to Checkout
              </Button>
        </Paper>
      </>
    );
  }
}
// export default OrderTotals;
function mapDispatchToProps(dispatch) {
      return bindActionCreators({ removeUserCardDetails,clearItems,removeProducts,removeDeliveryPickupDetails,removeLocations,removeFeaturedProducts
      }, dispatch);
}
function mapStateToProps(state) {
  return {
    order_items: state.order_items,
    order: state.order,
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true })
)(OrderTotals);
