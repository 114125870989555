import {
    REMOVE_DISCOUNT_PERCENTAGE_ITEM,
    MINUS_ITEM,
    ADD_ITEM,
    CLEAR_ITEMS,
    INSERT_EXTRA_ITEM,
    DISCOUNT_PERCENTAGE_ITEM,
    DELETE_ITEM,
    REMOVE_ITEM_COUPON,
} from "../actions/order_items";

import {
    GET_UNPAID_BALANCE,
    MAKE_PAYMENT,
    COMPLETE_ORDER,
    DISCOUNT_ORDER,
    REMOVE_ORDER_DISCOUNT,
    ADD_REWARD_CARD,
    ORDER_CALCULATION,
    ORDER_NAME,
    ORDER_FOR_HERE,
    ORDER_INSTRUCTION,
    ADD_TIP_IN_ORDER,
} from "../actions/order_actions";

import { DELETE_ORDER_COUPON, APPLY_COUPON } from "../actions/coupons_actions";
import {
    CLEAR_COUPON,
    TICKET_ORDER_COUPON_APPLY,
} from "../actions/coupons_actions";
import _ from "lodash";
import * as Constant from "../constants";
import { twofix } from "../helpers/calculation";
import { INSERT_TICKET_TO_ORDER } from "../actions/tickets";
import { APPLY_REWARD } from "../actions/reward_actions";

var Orderfunction = (state) => {
    // calculate Order total
    let order_items = state.order_items; // set order item in varibale
    var total = 0;
    var totalTax = 0;
    var balance = 0;

    order_items.forEach((item) => {
        // get order item one by one
        var saleXqty = item.sale_price * item.quantity; // cal item sale*Qty
        if (
            "applied_coupon" in item &&
            item.applied_coupon != "" &&
            item.applied_coupon != undefined
        ) {
            //coupon found
            var maxQty;
            if (item.quantity >= item.applied_coupon.max_qty_per_coupon) {
                // check Existting item new quantity is greater then Coupone max_qty_per_coupon
                maxQty = item.applied_coupon.max_qty_per_coupon; // maxQty set Coupone max_qty_per_coupon
            } else {
                maxQty = item.quantity; // maxQty set Existting item new quantity
            }
            if (item.applied_coupon.coupon_discount_type == "amou") {
                // coupon discount type in amount
                var totalDiscount = twofix(
                    twofix(maxQty) *
                        twofix(item.applied_coupon.coupon_discount_value)
                ); // cal item total discount
            } else {
                // coupon discount type in Per
                var totalDiscount = twofix(
                    twofix(
                        (twofix(twofix(item.sale_price) * twofix(maxQty)) *
                            parseFloat(item.discount_percentage)) /
                            100
                    )
                ); // cal item total discount
            }
            var texCollected = twofix(
                twofix(
                    twofix(
                        twofix(
                            twofix(
                                twofix(item.sale_price * item.quantity) -
                                    totalDiscount
                            )
                        ) * item.tax_percentage
                    ) / 100
                ) + parseFloat(item.tax_amount * item.quantity)
            ); // cal item total tax
            let singletotal = 0;
            singletotal = twofix(twofix(saleXqty) - twofix(totalDiscount)); // cal item total
            total = twofix(total) + twofix(singletotal); //  // cal Order total
            totalTax = twofix(totalTax) + twofix(texCollected); // cal order total tax
        } else {
            //coupon not found
            if (item.discount_type == "Percentage") {
                // manual discount type Percentage
                var totalDiscount = twofix(
                    twofix(
                        (twofix(
                            twofix(item.sale_price) * twofix(item.quantity)
                        ) *
                            parseFloat(item.discount_percentage)) /
                            100
                    )
                ); // cal item total discount
            } else {
                // manual discount type Amount
                var totalDiscount = twofix(
                    twofix(
                        twofix(twofix(item.quantity)) *
                            0
                    )
                ); // cal item total discount
            }
            var texCollected = twofix(
                twofix(
                    twofix(
                        twofix(
                            twofix(item.sale_price * item.quantity) -
                                totalDiscount
                        )
                    ) * parseFloat(item.tax_percentage / 100)
                ) + parseFloat(item.tax_amount * item.quantity)
            ); // cal total tax collected
            // console.log("+++++++++++++++++++++++++++++ item.quantity",texCollected);
            let singletotal = 0;
            singletotal = twofix(twofix(saleXqty) - twofix(totalDiscount)); // cal item total
            // console.log("singletotalsingletotal   ++++++++++++++++++",singletotal);
            total = twofix(total) + twofix(singletotal); // cal Order total
            // console.log("total   ++++++++++++++++++",singletotal);
            totalTax = twofix(totalTax) + twofix(texCollected); // cal Order total tax
            // console.log("totalTax   ++++++++++++++++++",singletotal);
        }
    });
    var order_discount_amount = 0;
    if (
        "coupon_status" in state.order &&
        state.order.coupon_status != undefined
    ) {
        // Order coupon apply
        if (state.order.coupon_status.coupon_discount_type == "amou") {
            // Order coupon type amount
            order_discount_amount = twofix(
                state.order.coupon_status.coupon_discount_value
            ); // set Order discount
        } else {
            // Order coupon type per
            order_discount_amount = twofix(
                twofix(
                    twofix(total) *
                        parseFloat(state.order.order_percentage / 100)
                )
            ); // cal Order discount
        }
    } else {
        if (state.order.discount_type == "Percentage") {
            // Order manual discount Percentage
            order_discount_amount = twofix(
                twofix(
                    twofix(total) *
                        parseFloat(state.order.order_percentage / 100)
                )
            ); // set Order discount
        } else {
            // Order manual discount amount
            order_discount_amount = twofix(
                parseFloat(state.order.order_percentage)
            ); // Order discount set
        }
    }
    var order_tip_amount = 0;
    if (state.order.tip_amount != 0 && state.order.tip_amount > 0) {
        if (state.order.tip_type == "Percentage") {
            order_tip_amount = twofix(
                twofix(
                    twofix(twofix(total) + twofix(totalTax)) *
                        parseFloat(state.order.tip_amount / 100)
                )
            ); // set Order Tip amount
        } else {
            order_tip_amount = twofix(state.order.tip_amount);
        }
    }
    total = twofix(twofix(total) - twofix(order_discount_amount)); // Order total discount calculate
    balance = twofix(twofix(total) + twofix(totalTax) + twofix(order_tip_amount)); // balance calculate
    let lastOrder = {
        // object create
        total: total,
        tax: totalTax,
        balance: balance,
        discount_amount: order_discount_amount,
        order_tip_amount: order_tip_amount
    };
    return lastOrder; // return object
};

export default function (
    state = {
        total: 0,
        balance: 0,
        tax_collected: 0,
        order_discount: 0,
        order_percentage: 0,
        discount_reason: "",
        reward_points: 0,
        reward_card_id: "",
        discount_type: "",
        instruction: "",
        tip_type: "",
        tip_amount: 0,
    },
    action
) {
    switch (action.type) {
        case ORDER_CALCULATION: {
            // Order final calculation calculate
            var orderFinalCalc = Orderfunction(action.payload.state); // call fund
            let newOrderState = {
                // set Order object
                total: orderFinalCalc.total,
                balance: orderFinalCalc.balance,
                tax_collected: orderFinalCalc.tax,
                order_discount: orderFinalCalc.discount_amount,
                order_percentage: state.order_percentage,
                discount_reason: state.discount_reason,
                reward_card_id:
                    action.payload.state.order_items.length != 0
                        ? state.reward_card_id
                        : "",
                reward_card_number: action.payload.state.order_items.length != 0 
                        ? state.reward_card_number
                        : "",
                coupon_status: state.coupon_status,
                discount_type: state.discount_type,
                reward_history_id: state.reward_history_id,
                order_name: state.order_name,
                order_for_here:
                    state.order_for_here == undefined
                        ? true
                        : state.order_for_here,
                instruction: state.instruction,
                tip_type: state.tip_type,
                tip_amount: state.tip_amount,
                order_tip_amount: orderFinalCalc.order_tip_amount,
            };
            return newOrderState; // return Order object
        }

        case ORDER_NAME: {
            var orderFinalCalc = Orderfunction(action.payload.state);
            let newOrderState = {
                total: orderFinalCalc.total,
                balance: orderFinalCalc.balance,
                tax_collected: orderFinalCalc.tax,
                order_discount: orderFinalCalc.discount_amount,
                order_percentage: state.order_percentage,
                discount_reason: state.discount_reason,
                reward_card_id: state.reward_card_id,
                reward_card_number: state.reward_card_number,
                coupon_status: state.coupon_status,
                discount_type: state.discount_type,
                reward_history_id: state.reward_history_id,
                order_for_here:
                    state.order_for_here == undefined
                        ? true
                        : state.order_for_here,
                order_name: action.payload.order_name,
                instruction: state.instruction,
                tip_type: state.tip_type,
                tip_amount: state.tip_amount,
                order_tip_amount: state.order_tip_amount,
            };
            return newOrderState;
        }
        case ORDER_FOR_HERE: {
            var orderFinalCalc = Orderfunction(action.payload.state);
            let newOrderState = {
                total: orderFinalCalc.total,
                balance: orderFinalCalc.balance,
                tax_collected: orderFinalCalc.tax,
                order_discount: orderFinalCalc.discount_amount,
                order_percentage: state.order_percentage,
                discount_reason: state.discount_reason,
                reward_card_id: state.reward_card_id,
                reward_card_number: state.reward_card_number,
                coupon_status: state.coupon_status,
                discount_type: state.discount_type,
                reward_history_id: state.reward_history_id,
                order_for_here:
                    action.payload.order_for_here == undefined
                        ? true
                        : action.payload.order_for_here,
                order_name: state.order_name,
                instruction: state.instruction,
                tip_type: state.tip_type,
                tip_amount: state.tip_amount,
                order_tip_amount: state.order_tip_amount,
            };
            return newOrderState;
        }
        case ORDER_INSTRUCTION: {
            var orderFinalCalc = Orderfunction(action.payload.state);
            let newOrderState = {
                total: orderFinalCalc.total,
                balance: orderFinalCalc.balance,
                tax_collected: orderFinalCalc.tax,
                order_discount: orderFinalCalc.discount_amount,
                order_percentage: state.order_percentage,
                discount_reason: state.discount_reason,
                reward_card_id: state.reward_card_id,
                reward_card_number: state.reward_card_number,
                coupon_status: state.coupon_status,
                discount_type: state.discount_type,
                reward_history_id: state.reward_history_id,
                order_for_here:
                    state.order_for_here == undefined
                        ? true
                        : state.order_for_here,
                order_name: state.order_name,
                instruction: action.payload.instruction,
                tip_type: state.tip_type,
                tip_amount: state.tip_amount,
                order_tip_amount: state.order_tip_amount,
            };
            return newOrderState;
        }
        case ADD_TIP_IN_ORDER: {
            let newOrderState = {
                total: state.total,
                balance: state.balance,
                tax_collected: state.tax,
                order_discount: state.discount_amount,
                order_percentage: state.order_percentage,
                discount_reason: state.discount_reason,
                reward_card_id: state.reward_card_id,
                reward_card_number: state.reward_card_number,
                coupon_status: state.coupon_status,
                discount_type: state.discount_type,
                reward_history_id: state.reward_history_id,
                order_for_here:
                    state.order_for_here == undefined
                        ? true
                        : state.order_for_here,
                order_name: state.order_name,
                instruction: state.instruction,
                tip_type: action.payload.type,
                tip_amount: action.payload.amount,
                order_tip_amount: state.order_tip_amount,
            };
            return newOrderState;
        }
        // case INSERT_ITEM: {
        //   let oldTotal = twofix(twofix(state.total) + twofix(state.order_discount))
        //   let total_discount = twofix(
        //     twofix(
        //       twofix(twofix(action.payload.item.sale_price) * twofix(action.payload.quantity)) *
        //         twofix(action.payload.stateProduct.discount_percentage)
        //     ) / 100
        //   )
        //   let newTotal =
        //     twofix(oldTotal) -
        //     twofix(total_discount) +
        //     twofix(twofix(action.payload.item.sale_price) * twofix(action.payload.quantity))
        //   let totalTax = twofix(
        //     twofix(
        //       twofix(
        //         twofix(twofix(action.payload.item.sale_price) * twofix(action.payload.quantity)) -
        //           twofix(total_discount)
        //       ) * twofix(action.payload.item.tax_percentage)
        //     ) / 100
        //   )
        //   let order_discount_amount = 0
        //   if ('coupon_status' in state && state.coupon_status != undefined) {
        //     if (state.coupon_status.coupon_discount_type == 'amou') {
        //       order_discount_amount = twofix(state.coupon_status.coupon_discount_value)
        //     } else {
        //       order_discount_amount = twofix(
        //         twofix(twofix(newTotal) * twofix(state.order_percentage)) / 100
        //       )
        //     }
        //   } else {
        //     if (state.discount_reason == Constant.REWARD_REDEEMED) {
        //       order_discount_amount = twofix(25)
        //     } else {
        //       order_discount_amount = twofix(
        //         twofix(twofix(newTotal) * twofix(state.order_percentage)) / 100
        //       )
        //     }
        //   }
        //   let newOrderState = {
        //     total: twofix(twofix(newTotal) - twofix(order_discount_amount)),
        //     balance: twofix(
        //       twofix(newTotal) +
        //         twofix(state.tax_collected) +
        //         twofix(totalTax) -
        //         twofix(order_discount_amount)
        //     ),
        //     tax_collected: twofix(twofix(state.tax_collected) + twofix(totalTax)),
        //     order_discount: twofix(order_discount_amount),
        //     order_percentage: twofix(state.order_percentage),
        //     discount_reason: state.discount_reason,
        //     discount_type: state.discount_type,
        //     reward_card_id: state.reward_card_id,
        //     coupon_status: state.coupon_status,
        //     discount_type: state.discount_type,
        //     reward_history_id:state.reward_history_id
        //   }
        //   return newOrderState
        // }

        case MINUS_ITEM: {
            // item qty minus then call
            let oldTotal = twofix(
                twofix(state.total) + twofix(state.order_discount)
            ); // cal old total
            let oldBalance = twofix(
                twofix(state.balance) + twofix(state.order_discount)
            ); // cal old balance
            let total_discount = twofix(
                twofix(action.payload.item.sale_price) *
                    parseFloat(action.payload.item.discount_percentage / 100)
            ); // cal total discount

            let tax_of_discounted_item = twofix(
                twofix(
                    twofix(
                        twofix(action.payload.item.sale_price) -
                            twofix(total_discount)
                    ) *
                        (parseFloat(action.payload.item.tax_percentage) / 100)
                ) + parseFloat(action.payload.item.tax_amount)
            ); // cal item tax
            let newTotal = twofix(
                twofix(oldTotal) -
                    twofix(action.payload.item.sale_price) +
                    twofix(total_discount)
            ); // cal new order total
            let order_discount_amount = 0;
            if ("coupon_status" in state && state.coupon_status != undefined) {
                // coupon apply in Order
                if (state.coupon_status.coupon_discount_type == "amou") {
                    // coupon type amount
                    order_discount_amount = twofix(
                        state.coupon_status.coupon_discount_value
                    ); // set discount amount
                } else {
                    // coupon type per
                    order_discount_amount = twofix(
                        twofix(newTotal) *
                            parseFloat(state.order_percentage / 100)
                    ); // cal Order total discount
                }
            } else {
                // if (state.discount_reason == Constant.REWARD_REDEEMED) {
                //   order_discount_amount = parseFloat(25)
                // } else {
                order_discount_amount = twofix(
                    twofix(newTotal) * parseFloat(state.order_percentage / 100)
                );
                // }
            }
            let newOrderState = {
                // item qty minus then Order object create
                total: twofix(twofix(newTotal) - twofix(order_discount_amount)),
                balance: twofix(
                    twofix(newTotal) +
                        twofix(state.tax_collected) -
                        twofix(tax_of_discounted_item) -
                        twofix(order_discount_amount)
                ),
                tax_collected: twofix(
                    twofix(state.tax_collected) - twofix(tax_of_discounted_item)
                ),
                order_discount: twofix(order_discount_amount),
                order_percentage: twofix(state.order_percentage),
                discount_reason: state.discount_reason,
                reward_card_id: state.reward_card_id,
                reward_card_number: state.reward_card_number,
                coupon_status: state.coupon_status,
                discount_type: state.discount_type,
                reward_history_id: state.reward_history_id,
                order_name: state.order_name,
                order_for_here: state.order_for_here,
                instruction: state.instruction,
                tip_type: state.tip_type,
                tip_amount: state.tip_amount,
                order_tip_amount: state.order_tip_amount,
            };
            return newOrderState; // retunrn new Object
        }

        case ADD_ITEM: {
            // add item 1 qty then Order calculation
            let oldTotal = twofix(
                twofix(state.total) + twofix(state.order_discount)
            ); // get Order old total
            let total_discount = twofix(
                twofix(action.payload.item.sale_price) *
                    parseFloat(action.payload.item.discount_percentage / 100)
            ); // cal item total discount
            let discount_tax = twofix(
                twofix(total_discount) *
                    parseFloat(action.payload.item.tax_percentage / 100)
            ); // cal item total tax
            let newTotal = twofix(
                twofix(oldTotal) -
                    twofix(total_discount) +
                    twofix(action.payload.item.sale_price)
            ); // cal new total
            let totalTax =
                twofix(
                    twofix(
                        twofix(action.payload.item.sale_price) *
                            parseFloat(
                                action.payload.item.tax_percentage / 100
                            ) -
                            twofix(discount_tax)
                    )
                ) + parseFloat(action.payload.item.tax_amount); // cal item total tax
            let order_discount_amount = 0;
            if ("coupon_status" in state && state.coupon_status != undefined) {
                // coupon apply in Order
                if (state.coupon_status.coupon_discount_type == "amou") {
                    // coupon type amount
                    order_discount_amount = twofix(
                        state.coupon_status.coupon_discount_value
                    ); // set amount in variable
                } else {
                    // coupon type Percentage
                    order_discount_amount = twofix(
                        twofix(newTotal) *
                            parseFloat(state.order_percentage / 100)
                    ); // cal order discount amount
                }
            } else {
                // coupon not apply
                // if (state.discount_reason == Constant.REWARD_REDEEMED) {
                //   order_discount_amount = parseFloat(25)
                // } else {
                order_discount_amount = twofix(
                    twofix(newTotal) * parseFloat(state.order_percentage / 100)
                );
                // }
            }
            let newOrderState = {
                // item add then set order object
                total: twofix(twofix(newTotal) - twofix(order_discount_amount)),
                balance: twofix(
                    twofix(newTotal) +
                        twofix(state.tax_collected) +
                        twofix(totalTax) -
                        twofix(order_discount_amount)
                ),
                tax_collected: twofix(
                    twofix(state.tax_collected) + twofix(totalTax)
                ),
                order_discount: twofix(order_discount_amount),
                order_percentage: twofix(state.order_percentage),
                discount_reason: state.discount_reason,
                reward_card_id: state.reward_card_id,
                reward_card_number: state.reward_card_number,
                coupon_status: state.coupon_status,
                discount_type: state.discount_type,
                reward_history_id: state.reward_history_id,
                order_name: state.order_name,
                order_for_here: state.order_for_here,
                instruction: state.instruction,
                tip_type: state.tip_type,
                tip_amount: state.tip_amount,
                order_tip_amount: state.order_tip_amount,
            };
            return newOrderState; // return object
        }

        case DELETE_ITEM: {
            // delet item Order line
            let oldTotal = twofix(
                twofix(state.total) + twofix(state.order_discount)
            ); // calcualte old total
            let oldBalance = twofix(
                twofix(state.balance) + twofix(state.order_discount)
            ); // calculate old balance
            let item_total_with_discount = twofix(
                twofix(action.payload.item.quantity) *
                    (twofix(action.payload.item.sale_price) -
                        twofix(action.payload.item.sale_price) *
                            parseFloat(
                                action.payload.item.discount_percentage / 100
                            ))
            ); // cal deleted item total
            let item_total_with_discount_tax =
                twofix(
                    twofix(item_total_with_discount) *
                        parseFloat(action.payload.item.tax_percentage / 100)
                ) +
                parseFloat(
                    action.payload.item.tax_amount *
                        twofix(action.payload.item.quantity)
                ); // cal delete item total tax
            let newTotal = twofix(
                twofix(oldTotal) - twofix(item_total_with_discount)
            ); // cal new total order
            let newBalance = oldBalance; // set new balance
            let totalTax = twofix(item_total_with_discount_tax); // set item tax
            let order_discount_amount = 0;
            if ("coupon_status" in state && state.coupon_status != undefined) {
                // coupon apply in order
                // applied coupon length
                if (state.coupon_status.coupon_discount_type == "amou") {
                    // coupon type amount
                    order_discount_amount = twofix(
                        state.coupon_status.coupon_discount_value
                    ); // set coupon discount
                } else {
                    // coupon type Percenatage
                    order_discount_amount = twofix(
                        twofix(newTotal) *
                            parseFloat(state.order_percentage / 100)
                    ); // set order discount
                }
            } else {
                // coupon not apply
                // if (state.discount_reason == Constant.REWARD_REDEEMED) {
                //   order_discount_amount = parseFloat(25)
                // } else {
                order_discount_amount = twofix(
                    twofix(newTotal) * parseFloat(state.order_percentage / 100) // cal Order discount
                );
                // }
            }
            if (state.tax_collected - totalTax <= 0) {
                // tax less then 0
                var ttax = 0; // set 0
            } else {
                var ttax = twofix(
                    twofix(state.tax_collected) - twofix(totalTax)
                ); // set tax
            }
            let newOrderState = {
                // minus item order object create
                total: twofix(twofix(newTotal) - twofix(order_discount_amount)),
                balance: twofix(
                    twofix(newBalance) -
                        twofix(order_discount_amount) -
                        twofix(item_total_with_discount) -
                        twofix(totalTax)
                ),
                tax_collected: twofix(
                    twofix(state.tax_collected) - twofix(totalTax)
                ),
                order_discount: twofix(order_discount_amount),
                order_percentage: twofix(state.order_percentage),
                discount_reason: state.discount_reason,
                reward_card_id: state.reward_card_id,
                reward_card_number: state.reward_card_number,
                coupon_status: state.coupon_status,
                discount_type: state.discount_type,
                reward_history_id: state.reward_history_id,
                order_for_here: state.order_for_here,
                order_name: state.order_name,
                instruction: state.instruction,
                tip_type: state.tip_type,
                tip_amount: state.tip_amount,
                order_tip_amount: state.order_tip_amount,
            };
            return newOrderState; // return Objct
        }

        case CLEAR_ITEMS: {
            // clear all item
            let newOrderState = {
                // Order object all value 0 and empty
                total: 0,
                balance: 0,
                tax_collected: 0,
                order_discount: 0,
                order_percentage: 0,
                discount_reason: "",
                reward_card_id: "",
                reward_card_number: "",
                reward_history_id: null,
                order_name: "",
                order_for_here: true,
                instruction: "",
                tip_type: "",
                tip_amount: "",
                order_tip_amount: 0,
            };
            return newOrderState; // return new object
        }

        case COMPLETE_ORDER: {
            // clear all value
            let newOrderState = {
                // set Order object value 0 and Empty
                total: 0,
                balance: 0,
                tax_collected: 0,
                order_discount: 0,
                order_percentage: 0,
                discount_reason: "",
                reward_card_id: "",
                reward_card_number: "",
                reward_history_id: null,
                order_name: "",
                order_for_here: true,
                instruction: "",
                tip_type: "",
                tip_amount: "",
                order_tip_amount: 0,
            };
            return newOrderState; // return new object
        }

        case INSERT_EXTRA_ITEM: {
            // add Extra item in order line
            // console.log('action.payload.for_here 111111111111111',action.payload.for_here)
            let oldTotal = twofix(
                twofix(state.total) + twofix(state.order_discount)
            ); // cal old order total
            let oldBalance = twofix(
                twofix(state.balance) + twofix(state.order_discount)
            ); // cal old order balance
            let newTotal = twofix(
                twofix(oldTotal) + twofix(action.payload.item.sale_price)
            ); // cal new total
            let newBalance = twofix(
                twofix(oldBalance) + twofix(action.payload.item.sale_price)
            ); // cal new balance
            let totalTax = twofix(
                twofix(
                    twofix(action.payload.item.sale_price) *
                        parseFloat(action.payload.item.tax_percentage / 100)
                ) + parseFloat(action.payload.item.tax_amount)
            ); //cal order new tax
            let order_discount_amount = 0;

            if ("coupon_status" in state && state.coupon_status != undefined) {
                // coupon apply in amount
                if (state.coupon_status.coupon_discount_type == "amou") {
                    // coupon type amount
                    order_discount_amount = twofix(
                        state.coupon_status.coupon_discount_value
                    ); // set discount value
                } else {
                    // coupon type Percentage
                    order_discount_amount = twofix(
                        twofix(newTotal) *
                            parseFloat(state.order_percentage / 100)
                    ); // cal order discount
                }
            } else {
                // coupon not apply
                // if (state.discount_reason == Constant.REWARD_REDEEMED) {
                //   order_discount_amount = parseFloat(25)
                // } else {
                order_discount_amount = twofix(
                    twofix(newTotal) * parseFloat(state.order_percentage / 100)
                ); // cal order total
                // }
            }
            let newOrderState = {
                // order new Object create
                total: twofix(twofix(newTotal) - twofix(order_discount_amount)),
                balance: twofix(
                    twofix(totalTax) +
                        twofix(newBalance) -
                        twofix(order_discount_amount)
                ),
                tax_collected: twofix(
                    twofix(state.tax_collected) + twofix(totalTax)
                ),
                order_discount: twofix(order_discount_amount),
                order_percentage: twofix(state.order_percentage),
                discount_reason: state.discount_reason,
                reward_card_id: state.reward_card_id,
                reward_card_number: state.reward_card_number,
                coupon_status: state.coupon_status,
                discount_type: state.discount_type,
                reward_history_id: state.reward_history_id,
                order_for_here: state.order_for_here,
                order_name: state.order_name,
                instruction: state.instruction,
                tip_type: state.tip_type,
                tip_amount: state.tip_amount,
                order_tip_amount: state.order_tip_amount,
            };
            return newOrderState; // return order object
        }

        case DISCOUNT_PERCENTAGE_ITEM: {
            // use for set item manual discount
            let oldTotal = twofix(
                twofix(state.total) + twofix(state.order_discount)
            ); // cal item manual discount
            var discount_item;
            if (action.payload.discount.discount_type == "Percentage") {
                // item discount type percentage
                discount_item = twofix(
                    twofix(action.payload.item.sale_price) *
                        parseFloat(action.payload.discount.amount / 100)
                ); // cal item discount
            } else {
                discount_item = twofix(
                    parseFloat(action.payload.discount.amount)
                ); // cal discount
            }
            let discount_total_item = twofix(
                twofix(action.payload.item.quantity) * twofix(discount_item)
            ); // item total discount

            let total_tax_of_disc_amount =
                twofix(discount_total_item) *
                parseFloat(action.payload.item.tax_percentage / 100); // cal item total tax

            let totalTax = 0;
            if (action.payload.item.tax_percentage > 0) {
                // item tax
                totalTax = twofix(total_tax_of_disc_amount); //set total tax
            }
            let newTotal = twofix(
                twofix(oldTotal) - twofix(twofix(discount_total_item))
            ); // cal new total
            let newBalance = twofix(
                twofix(oldTotal) - twofix(twofix(discount_total_item))
            ); // cal new balance
            let order_discount_amount = 0;
            if ("coupon_status" in state && state.coupon_status != undefined) {
                // Coupon apply in Order
                if (state.coupon_status.coupon_discount_type == "amou") {
                    // coupon type amount
                    order_discount_amount = twofix(
                        state.coupon_status.coupon_discount_value
                    ); // set discount amount
                } else {
                    // coupon type Percentage
                    order_discount_amount = twofix(
                        twofix(newTotal) *
                            parseFloat(state.order_percentage / 100) // cal discount
                    );
                }
            } else {
                // no coupon apply in Order
                // if (state.discount_reason == Constant.REWARD_REDEEMED) {
                //   order_discount_amount = parseFloat(25)
                // } else {
                order_discount_amount = twofix(
                    twofix(newTotal) * parseFloat(state.order_percentage / 100)
                ); //cal order discount
                // }
            }
            let newOrderState = {
                // new Order Object create
                total: twofix(twofix(newTotal) - twofix(order_discount_amount)),
                balance: twofix(
                    twofix(newBalance) +
                        twofix(state.tax_collected) -
                        twofix(totalTax) -
                        twofix(order_discount_amount)
                ),
                tax_collected: twofix(
                    twofix(state.tax_collected) - twofix(totalTax)
                ),
                order_discount: twofix(order_discount_amount),
                order_percentage: twofix(state.order_percentage),
                discount_reason: state.discount_reason,
                reward_card_id: state.reward_card_id,
                reward_card_number: state.reward_card_number,
                coupon_status: state.coupon_status,
                discount_type: state.discount_type,
                reward_history_id: state.reward_history_id,
                order_for_here: state.order_for_here,
                order_name: state.order_name,
                instruction: state.instruction,
                tip_type: state.tip_type,
                tip_amount: state.tip_amount,
                order_tip_amount: state.order_tip_amount,
            };
            return newOrderState; // return order Object
        }

        case REMOVE_DISCOUNT_PERCENTAGE_ITEM: {
            // use for remove item manual discount
            let oldTotal = twofix(
                twofix(state.total) + twofix(state.order_discount)
            ); // cal old total
            let discount_total_item = twofix(
                twofix(action.payload.item.quantity) *
                    twofix(action.payload.item.sale_price) *
                    parseFloat(action.payload.percentage / 100)
            ); // cal order item total discount
            let total_tax_of_disc_amount =
                twofix(discount_total_item) *
                parseFloat(action.payload.item.tax_percentage / 100); // cal item total tax
            let totalTax = 0;
            if (action.payload.item.tax_percentage > 0) {
                // item tax
                totalTax = twofix(total_tax_of_disc_amount); // set item tax
            }
            let newTotal = twofix(
                twofix(oldTotal) + twofix(discount_total_item)
            ); // cal new order total
            let newBalance = twofix(
                twofix(oldTotal) + twofix(discount_total_item)
            ); // cal new balance
            let order_discount_amount = 0;
            if ("coupon_status" in state && state.coupon_status != undefined) {
                // coupon apply in Order
                if (state.coupon_status.coupon_discount_type == "amou") {
                    // coupon type amount
                    order_discount_amount = twofix(
                        state.coupon_status.coupon_discount_value
                    ); // cal Order discount
                } else {
                    order_discount_amount = twofix(
                        twofix(newTotal) *
                            parseFloat(state.order_percentage / 100) // cal Order discount
                    );
                }
            } else {
                // coupon not apply
                // if (state.discount_reason == Constant.REWARD_REDEEMED) {
                //   order_discount_amount = parseFloat(25)
                // } else {
                order_discount_amount = twofix(
                    twofix(newTotal) * parseFloat(state.order_percentage / 100)
                );
                // }
            }
            let newOrderState = {
                // order new object create
                total: twofix(twofix(newTotal) - twofix(order_discount_amount)),
                balance: twofix(
                    twofix(newBalance) +
                        twofix(state.tax_collected) +
                        twofix(totalTax) -
                        twofix(order_discount_amount)
                ),
                tax_collected: twofix(
                    twofix(state.tax_collected) + twofix(totalTax)
                ),
                order_discount: twofix(order_discount_amount),
                order_percentage: state.order_percentage,
                discount_reason: state.discount_reason,
                reward_card_id: state.reward_card_id,
                reward_card_number: state.reward_card_number,
                coupon_status: state.coupon_status,
                discount_type: state.discount_type,
                reward_history_id: state.reward_history_id,
                order_for_here: state.order_for_here,
                order_name: state.order_name,
                instruction: state.instruction,
                tip_type: state.tip_type,
                tip_amount: state.tip_amount,
                order_tip_amount: state.order_tip_amount,
            };
            return newOrderState; // return order object
        }

        case GET_UNPAID_BALANCE: {
            return state;
        }

        case MAKE_PAYMENT: {
            let tax_collected = state.tax_collected;
            let ebt_tax = 0;
            // let percentage_ebt = 0
            let ratio_tax = 0;
            let per = 0;
            let ebt_eligible_amount = 0;
            let ebt_payment_amount = 0;
            let ebt_tax_amount = 0;
            let ebt_paid_amounts = 0;
            // let remaining_ebt_payment = 0
            let ebt_total_balance = 0;
            let tax_ratio = 0;
            let ebt_total_tax = 0;
            let ebt_paid_taxamount = 0;
            let ebt_tax_amounts = 0;
            let ebt_paid_tax = 0;
            let order_discount = 0;
            let taxCollected = 0;
            let order_discount_ebt = 0;
            // let tax_discount = 0
            let order_discount_tax = 0;
            // let check_ebt_flag_data = 0
            let check_ratio = 0;
            let cash_credit_total_afetr_ebt = 0;
            let total_tax_after_cash_discount_ratio = 0;
            let total_tax = 0;
            let total_tax_discount = 0;
            let total_tax_after_cash_discount = twofix(
                action.payload.ebt_total_tax -
                    action.payload.after_cash_discount_paid_ratio -
                    action.payload.after_cash_tax_paid_ratio
            ); // cal total tax after apply in discount
            if (
                state.total_tax_after_cash_discount >= 0.01 &&
                (action.payload.after_cash_discount_paid_ratio >= 0.01 ||
                    action.payload.after_cash_tax_paid_ratio >= 0.01)
            ) {
                // check condition
                total_tax =
                    action.payload.after_cash_discount_paid_ratio +
                    action.payload.after_cash_tax_paid_ratio +
                    state.total_tax_after_cash_discount; // cal ebt payment total tax
            } else if (
                action.payload.after_cash_discount_paid_ratio == undefined &&
                action.payload.after_cash_tax_paid_ratio == undefined
            ) {
                // no ebt payment
                total_tax = state.total_tax_after_cash_discount; // set total tax
            } else if (
                action.payload.after_cash_discount_paid_ratio >= 0.01 ||
                action.payload.after_cash_tax_paid_ratio >= 0.01
            ) {
                // ebt payment
                total_tax =
                    action.payload.after_cash_discount_paid_ratio +
                    action.payload.after_cash_tax_paid_ratio; // cal total tax
            } else if (state.total_tax_after_cash_discount >= 0.01) {
                // discount apply then total ebt tax
                total_tax = state.total_tax_after_cash_discount; // set total tax
            }
            let total = 0;
            if (
                action.payload.cash_credit_total_afetr_ebt >= 0.01 &&
                state.cash_credit_total_afetr_ebt >= 0.01
            ) {
                // after ebt payment cash creadit payment then cal cash credit total payment
                cash_credit_total_afetr_ebt =
                    action.payload.cash_credit_total_afetr_ebt +
                    state.cash_credit_total_afetr_ebt;
            } else if (action.payload.cash_credit_total_afetr_ebt >= 0.01) {
                // cash credit payment available
                cash_credit_total_afetr_ebt =
                    action.payload.cash_credit_total_afetr_ebt; // set payload value
            } else if (
                action.payload.cash_credit_total_afetr_ebt == undefined &&
                action.payload.cash_credit_total_afetr_ebt == null
            ) {
                // not define cash credit value
                cash_credit_total_afetr_ebt = state.cash_credit_total_afetr_ebt;
            } else if (state.cash_credit_total_afetr_ebt >= 0.01) {
                //cash credit payment is after ebt payment
                cash_credit_total_afetr_ebt = state.cash_credit_total_afetr_ebt; // set value
            } else {
                cash_credit_total_afetr_ebt = 0; //cash credit payment is not after ebt payment  set value 0
            }

            if (
                action.payload.after_cash_discount_paid_ratio >= 0.01 ||
                action.payload.after_cash_tax_paid_ratio >= 0.01
            ) {
                total_tax_after_cash_discount_ratio =
                    action.payload.after_cash_discount_paid_ratio +
                    action.payload.after_cash_tax_paid_ratio;
            }

            //first time order discount take from state and after it take 0
            if (
                state.order_discount != 0 &&
                state.ebt_paid_amount == undefined
            ) {
                order_discount_ebt = state.order_discount;
            } else {
                order_discount_ebt = 0;
            }

            if (action.payload.ebt_total_balance) {
                // ebt total payment available then set all value
                ebt_tax = parseFloat(action.payload.ebt_tax);
                per = action.payload.tax_ratio;
                ratio_tax = twofix((per * twofix(action.payload.amount)) / 100);
                ebt_eligible_amount = action.payload.ebt_total;
                ebt_payment_amount = action.payload.amount;
                ebt_tax_amount = action.payload.ebt_tax;
                ebt_total_balance = action.payload.ebt_total_balance;
                tax_ratio = action.payload.tax_ratio;
                ebt_total_tax = action.payload.ebt_total_tax;
            }

            if (action.payload.tax_ratio == 0) {
                //tax ratio 0
                tax_ratio = state.tax_ratio;
            } else {
                // tax ratio not 0
                tax_ratio = action.payload.tax_ratio; // set in variable tax ratio
            }

            if (
                undefined == action.payload.ebt_total_balance &&
                state.ebt_total_balance >= 0.01
            ) {
                ebt_total_balance = state.ebt_total_balance; // set ebt total total balance
            }
            if (
                undefined == action.payload.ebt_total_tax &&
                state.ebt_total_tax >= 0.01
            ) {
                ebt_total_tax = state.ebt_total_tax; // set ebt total tax
            }

            check_ratio = total_tax_after_cash_discount - ratio_tax;
            if (check_ratio <= 0) {
                ratio_tax = total_tax_after_cash_discount;
            }

            //order discount greater then ebt total balance  then if condition execute and total tax collected otherwise ratio minus from the tax collected
            if (state.order_discount >= action.payload.ebt_total_balance) {
                taxCollected = state.tax_collected;
                order_discount =
                    state.order_discount - action.payload.ebt_total_balance;
                ratio_tax = 0;
            } else {
                taxCollected = twofix(
                    parseFloat(state.tax_collected - ratio_tax).toFixed(2)
                );
            }

            if (state.ebt_paid_tax) {
                // ebt paid tax amount available then set value in variable
                ebt_paid_taxamount = state.ebt_paid_tax;
                ebt_paid_amounts = parseFloat(state.ebt_paid_amount);
                ebt_tax_amounts = parseFloat(state.ebt_tax_amount);
            }

            if (state.total_tax_after_cash_discount >= 0.01) {
                total_tax_discount = state.total_tax_after_cash_discount;
            }

            //tax minus from total tax so add in total tax and minus from ebt saved tax
            let total_paid_tax = Math.abs(ebt_paid_taxamount + ratio_tax);
            check_ratio = ebt_total_tax - total_tax_discount - total_paid_tax;
            if (check_ratio < 0) {
                ratio_tax = ratio_tax - twofix(Math.abs(check_ratio));
                taxCollected = taxCollected + twofix(Math.abs(check_ratio));
            }

            let ebt_eligible = 0;
            if (action.payload.ebt_eligible_amount == undefined) {
                //  ebt_eligible = ebt_total_balance - ebt_payment_amount - order_discount_ebt;
                // console.log("call is");
                ebt_eligible = 0;
                //  ebt_eligible = action.payload.ebt_eligible_amount;
            } else {
                ebt_eligible = action.payload.ebt_eligible_amount;
            }
            //  else if(state.ebt_eligible_amount == 0 &&  action.payload.ebt_eligible_amount != undefined ) {
            //     // ebt_eligible = ebt_total_balance - (state.ebt_paid_amount) - ebt_payment_amount;
            //     // console.log("call else");
            //     ebt_eligible = (action.payload.ebt_eligible_amount)-ebt_payment_amount;

            // }
            // else{
            //   ebt_eligible = state.ebt_eligible_amount -ebt_payment_amount;
            // }
            ebt_paid_tax = twofix(ratio_tax + ebt_paid_taxamount);

            let newOrderState = {
                // order Object created
                total:
                    Math.round(
                        parseFloat(state.total).toFixed(5) * Constant.TOFIXED5
                    ) / Constant.TOFIXED5,
                balance:
                    Math.round(
                        parseFloat(
                            state.balance - action.payload.amount - ratio_tax - action.payload.tips_amount
                        ).toFixed(5) * Constant.TOFIXED5
                    ) / Constant.TOFIXED5,
                tax_ratio: tax_ratio,
                tax_collected: taxCollected + order_discount_tax,
                // Math.round(
                //   twofix(parseFloat(state.tax_collected - ratio_tax).toFixed(2)) *
                //     Constant.TOFIXED5
                // ) / Constant.TOFIXED5,
                order_discount:
                    Math.round(
                        parseFloat(state.order_discount).toFixed(5) *
                            Constant.TOFIXED5
                    ) / Constant.TOFIXED5,
                order_percentage:
                    Math.round(
                        parseFloat(state.order_percentage).toFixed(5) *
                            Constant.TOFIXED5
                    ) / Constant.TOFIXED5,
                discount_reason: state.discount_reason,
                reward_card_id: state.reward_card_id,
                coupon_status: state.coupon_status,
                discount_type: state.discount_type,
                //ebt eligible amount after ebt transaction done.
                ebt_eligible_amount: twofix(ebt_eligible),
                //ebt approved amount
                ebt_payment_amount: parseFloat(ebt_payment_amount),
                //ebt paid tax
                ebt_paid_tax: twofix(ratio_tax + ebt_paid_taxamount),
                //ebt paid amount with add discount
                ebt_paid_amount: twofix(
                    parseFloat(
                        parseFloat(ebt_paid_amounts) +
                            parseFloat(ebt_payment_amount)
                    ) + parseFloat(order_discount_ebt)
                ),
                //ebt total balance
                ebt_total_balance: parseFloat(ebt_total_balance),
                //ebt total tax
                ebt_total_tax: parseFloat(ebt_total_tax),
                //paid ebt tax minus from ebt total tax
                ebt_tax_amount: twofix(
                    parseFloat(
                        parseFloat(ebt_total_tax) - parseFloat(ebt_paid_tax)
                    )
                ),
                cash_credit_total_afetr_ebt: twofix(
                    cash_credit_total_afetr_ebt
                ),
                total_tax_after_cash_discount: twofix(total_tax),
                order_for_here: action.payload.order_for_here,
                order_name: action.payload.order_name,
                instruction: state.instruction,
                tip_type: state.tip_type,
                tip_amount: state.tip_amount,
                order_tip_amount: state.order_tip_amount,
            };
            return newOrderState; // return Order Object
        }

        case DISCOUNT_ORDER: {
            //this is for reward card points flat discount
            let discount_amount = twofix(action.payload.amount);
            let new_total = twofix(
                twofix(state.total) - twofix(discount_amount)
            );
            let newOrderState = {
                // create new Order object
                // total: twofix(new_total),
                balance: twofix(
                    twofix(new_total) + twofix(state.tax_collected)
                ),
                tax_collected: twofix(twofix(state.tax_collected)),
                order_discount: twofix(twofix(discount_amount)),
                discount_reason: action.payload.reason,
                reward_card_id: state.reward_card_id,
                reward_card_number: state.reward_card_number,
                order_percentage: action.payload.Percentage,
                coupon_status: state.coupon_status,
                discount_type: action.payload.discount_type,
                reward_history_id: state.reward_history_id,
                order_for_here: state.order_for_here,
                order_name: state.order_name,
                instruction: state.instruction,
                tip_type: state.tip_type,
                tip_amount: state.tip_amount,
                order_tip_amount: state.order_tip_amount,
            };
            return newOrderState; // return order object
        }

        case REMOVE_ORDER_DISCOUNT: {
            // remove Order discount
            let new_total = twofix(
                twofix(state.total) + twofix(state.order_discount)
            );
            let newOrderState = {
                // create new order object
                total: twofix(new_total),
                balance: twofix(
                    twofix(new_total) + twofix(state.tax_collected)
                ),
                tax_collected: twofix(state.tax_collected),
                order_discount: 0,
                order_percentage: 0,
                discount_reason: "",
                discount_type: "",
                reward_card_id: state.reward_card_id,
                reward_card_number: state.reward_card_number,
                reward_history_id: state.reward_history_id,
                order_for_here: state.order_for_here,
                order_name: state.order_name,
                instruction: state.instruction,
                tip_type: state.tip_type,
                tip_amount: state.tip_amount,
                order_tip_amount: state.order_tip_amount,
            };
            return newOrderState; // return order object
        }

        case ADD_REWARD_CARD: {
            // reward card add in order
            let newOrderState = {
                // order object create
                total: twofix(state.total),
                balance: twofix(state.balance),
                tax_collected: twofix(state.tax_collected),
                order_discount: twofix(state.order_discount),
                order_percentage: twofix(state.order_percentage),
                discount_reason: state.discount_reason,
                reward_card_id: action.payload.id,
                reward_card_number: action.payload.number,
                coupon_status: state.coupon_status,
                discount_type: state.discount_type,
                reward_history_id: state.reward_history_id,
                order_for_here: state.order_for_here,
                order_name: state.order_name,
                instruction: state.instruction,
                tip_type: state.tip_type,
                tip_amount: state.tip_amount,
                order_tip_amount: state.order_tip_amount,
            };
            return newOrderState; // return order object
        }

        case DELETE_ORDER_COUPON: {
            // delete order coupon
            let new_total = twofix(
                twofix(state.total) + twofix(state.order_discount)
            ); // new order total calculate
            let newOrderState = {
                // create new order total
                total: twofix(new_total),
                balance: twofix(
                    twofix(new_total) + twofix(state.tax_collected)
                ),
                tax_collected: twofix(state.tax_collected),
                order_discount: 0,
                order_percentage: 0,
                discount_reason: "",
                reward_card_id: state.reward_card_id,
                reward_card_number: state.reward_card_number,
                reward_history_id: state.reward_history_id,
                order_for_here: state.order_for_here,
                order_name: state.order_name,
                instruction: state.instruction,
                tip_type: state.tip_type,
                tip_amount: state.tip_amount,
                order_tip_amount: state.order_tip_amount,
            };
            return newOrderState; // return order object
        }

        case CLEAR_COUPON: {
            // clear all coupon with order and item
            let total = 0;
            let orderTotal = 0;
            let totalTax = 0;
            action.payload.order_items.map((item) => {
                // get order item one by one
                var tax = 0;
                if (
                    "applied_coupon" in item &&
                    Object.keys(item["applied_coupon"]).length > 0
                ) {
                    //coupon applied in item
                    total = twofix(
                        twofix(item.sale_price) * twofix(item.quantity)
                    ); // cal total
                    if (item.tax_percentage > 0 || item.tax_amount > 0) {
                        // tax in item
                        tax =
                            twofix(
                                twofix(
                                    twofix(item.sale_price) *
                                        twofix(item.quantity)
                                ) * parseFloat(item.tax_percentage / 100)
                            ) + parseFloat(item.tax_amount); // cal item tax
                    }
                    totalTax = twofix(twofix(totalTax) + twofix(tax)); // add tax
                } else {
                    //coupon not applied
                    total = twofix(
                        twofix(
                            twofix(item.sale_price) * twofix(item.quantity)
                        ) - twofix(item.total_discount)
                    ); // cal total
                    tax = twofix(item.tax_collected);
                    let newItem = item;
                    totalTax = twofix(twofix(totalTax) + twofix(tax)); // add tax
                }
                orderTotal = twofix(twofix(orderTotal) + twofix(total)); // add total
            });
            let orderDiscount = 0;
            let balance = 0;
            let newOrder = {};
            if (
                "coupon_status" in action.payload.order &&
                action.payload.order.coupon_status != undefined &&
                action.payload.order.coupon_status != ""
            ) {
                //order coupon applied
                orderTotal = twofix(orderTotal);
                let balance = twofix(twofix(orderTotal) + twofix(totalTax)); // cal new balance
                newOrder = {
                    // create new object
                    balance: twofix(balance),
                    order_discount: 0,
                    order_percentage: 0,
                    reward_card_id: action.payload.order.reward_card_id,
                    tax_collected: twofix(totalTax),
                    total: twofix(orderTotal),
                    discount_reason: action.payload.order.discount_reason,
                    discount_type: "",
                    reward_history_id: state.reward_history_id,
                    reward_card_number: state.reward_card_number,
                    order_for_here: state.order_for_here,
                    order_name: state.order_name,
                    instruction: state.instruction,
                    tip_type: state.tip_type,
                    tip_amount: state.tip_amount,
                    order_tip_amount: state.order_tip_amount,
                };
            } else {
                orderDiscount = twofix(
                    twofix(orderTotal) *
                        parseFloat(action.payload.order.order_percentage / 100)
                ); // cal discount
                let balance = twofix(
                    twofix(orderTotal) -
                        twofix(orderDiscount) +
                        twofix(totalTax)
                ); // cal new balance
                orderTotal = twofix(twofix(orderTotal) - twofix(orderDiscount));
                newOrder = {
                    // create new order object
                    balance: twofix(balance),
                    order_discount: twofix(orderDiscount),
                    order_percentage: twofix(
                        action.payload.order.order_percentage
                    ),
                    reward_card_id: action.payload.order.reward_card_id,
                    reward_card_number: action.payload.order.reward_card_number,
                    tax_collected: twofix(totalTax),
                    total: twofix(orderTotal),
                    discount_reason: action.payload.order.discount_reason,
                    discount_type: action.payload.order.discount_type,
                    order_for_here: state.order_for_here,
                    order_name: state.order_name,
                    instruction: state.instruction,
                    tip_type: state.tip_type,
                    tip_amount: state.tip_amount,
                    order_tip_amount: state.order_tip_amount,
                };
            }
            return newOrder; // return new object
        }
        case APPLY_REWARD: {
            // apply reward in order
            let newOrder = {};
            let newProduct = [];
            let total = 0;
            let taxCollected = 0;
            let totalDiscount = 0;
            let coupon_found = 0;
            var orderReward = {};
            var reward_history_id;
            // var newEligibleRewardTotal = 0;
            action.payload.order_items.map((singleProduct) => {
                // get item one by one
                action.payload.rewardsArray.rewardsArray.map((o) => {
                    // get reward one by one
                    if (o.reward.reward_api_reward_coupon.product.length > 0) {
                        // reward coupon is Product coupon
                        if (
                            o.reward.reward_api_reward_coupon.product[0].id ==
                            singleProduct.server_id
                        ) {
                            // match coupon product and order line item

                            coupon_found = 1; // set coup[on found one
                            let discount = 0;
                            let percentage =
                                o.reward.reward_api_reward_coupon
                                    .discount_value;
                            var maxQty;
                            if (
                                singleProduct.quantity >=
                                o.reward.reward_api_reward_coupon
                                    .max_qty_per_coupon
                            ) {
                                // check Existting item new quantity is greater then Coupone max_qty_per_coupon
                                maxQty =
                                    o.reward.reward_api_reward_coupon
                                        .max_qty_per_coupon; // maxQty set Coupone max_qty_per_coupon
                            } else {
                                maxQty = singleProduct.quantity; // maxQty set Existting item new quantity
                            }
                            if (
                                o.reward.reward_api_reward_coupon
                                    .discount_type == "per"
                            ) {
                                // coupon type per
                                discount = twofix(
                                    twofix(singleProduct.sale_price) *
                                        twofix(maxQty) *
                                        parseFloat(percentage / 100)
                                ); // cal item discount
                                percentage = twofix(percentage);
                            } else {
                                // coupon discount amount
                                discount = twofix(
                                    twofix(
                                        o.reward.reward_api_reward_coupon
                                            .discount_value
                                    ) * twofix(maxQty)
                                ); // cal discount
                                percentage =
                                    twofix(
                                        discount /
                                            twofix(
                                                twofix(
                                                    singleProduct.sale_price
                                                ) * twofix(maxQty)
                                            )
                                    ) * 100;
                            }
                            total = twofix(
                                twofix(total) +
                                    twofix(singleProduct.sale_price) *
                                        twofix(singleProduct.quantity) -
                                    twofix(discount)
                            ); // cal order total
                            taxCollected = twofix(
                                twofix(taxCollected) +
                                    twofix(
                                        twofix(
                                            twofix(singleProduct.sale_price) *
                                                twofix(singleProduct.quantity) -
                                                twofix(discount)
                                        ) *
                                            (singleProduct.tax_percentage / 100)
                                    ) +
                                    twofix(
                                        singleProduct.tax_amount *
                                            twofix(singleProduct.quantity)
                                    )
                            ); // cal total tax collected
                            totalDiscount = twofix(
                                twofix(totalDiscount) + twofix(discount)
                            ); // cal total discount
                            let newItem = {
                                server_id: singleProduct.server_id,
                                product_id: singleProduct.server_id,
                            }; // set new item object
                            newProduct.push(newItem); // push in array
                        }
                        coupon_found = 0;
                    } else {
                        //order reward
                        reward_history_id = o.reward_history.id; // reward history id set
                        orderReward = {
                            // new reward object create
                            coupon_name: o.reward.name,
                            coupon_discount_type:
                                o.reward.reward_api_reward_coupon.discount_type,
                            coupon_discount_value:
                                o.reward.reward_api_reward_coupon
                                    .discount_value,
                            coupon_order_min_amount:
                                o.reward.reward_api_reward_coupon.min_amount,
                            coupon_id: o.reward.coupon_id,
                            reward_id: o.reward.id,
                            // campaign_id: 0,
                            reward_card_id: o.reward_card_id,
                            campaign_coupon_id: 0.1,
                            reward_history_id: o.reward_history.id,
                            applied: 1,
                            reject: 0,
                            reason: "REWARD APPLIED",
                            reward_flag: true,
                        };
                    }
                });
                if (!coupon_found) {
                    // coupon not add in Product
                    const exists = _.findIndex(newProduct, function (pro) {
                        return pro.product_id == singleProduct.server_id;
                    }); // item exist in array or not set
                    if (exists < 0) {
                        // item not set array
                        let newItem = {
                            // create object
                            server_id: singleProduct.server_id,
                            product_id: singleProduct.server_id,
                        };
                        total = twofix(
                            twofix(total) +
                                twofix(singleProduct.sale_price) *
                                    twofix(singleProduct.quantity) -
                                twofix(singleProduct.total_discount)
                        ); // cal total
                        taxCollected = twofix(
                            twofix(taxCollected) +
                                twofix(singleProduct.tax_collected)
                        ); // cal total tax collected
                        totalDiscount = twofix(
                            twofix(totalDiscount) +
                                twofix(singleProduct.total_discount)
                        ); // cal total discount
                        newProduct.push(newItem); // push in array
                    } else {
                    }
                }
            });
            //Order discount
            let orderDiscount = 0;
            let orderPercentage = 0;
            if (Object.keys(orderReward).length > 0) {
                // applied 0 rejected 0 coupon is applying
                if (orderReward.coupon_discount_type == "per") {
                    // reward order coupon type percentage
                    orderDiscount = twofix(
                        twofix(total) *
                            parseFloat(orderReward.coupon_discount_value / 100)
                    ); // cal order total discount
                    orderPercentage = twofix(orderReward.coupon_discount_value);
                } else {
                    // reward order coupon type Amount
                    orderDiscount = twofix(orderReward.coupon_discount_value); // cal order total discount
                }
                total = twofix(twofix(total) - twofix(orderDiscount)); // cal total
                taxCollected = twofix(taxCollected); // set tax collected
                newOrder = {
                    // order new object created
                    total: twofix(total),
                    balance: twofix(twofix(total) + twofix(taxCollected)),
                    tax_collected: twofix(taxCollected),
                    order_discount: twofix(orderDiscount),
                    order_percentage: twofix(orderPercentage),
                    reward_card_id: state.reward_card_id,
                    reward_card_number: state.reward_card_number,
                    coupon_status: orderReward,
                    reward_history_id: reward_history_id,
                    discount_reason: "REWARD APPLIED",
                    discount_type: state.discount_type,
                    order_for_here: state.order_for_here,
                    order_name: state.order_name,
                    instruction: state.instruction,
                    tip_type: state.tip_type,
                    tip_amount: state.tip_amount,
                    order_tip_amount: state.order_tip_amount,
                };
            } else {
                // reward coupon not apply
                orderDiscount = twofix(action.payload.order.order_discount);
                orderPercentage = twofix(action.payload.order.order_percentage);
                total = twofix(twofix(total) - twofix(orderDiscount));
                taxCollected = twofix(taxCollected);
                // if (
                //   'applied' in action.payload.coupon_status.order &&
                //   action.payload.coupon_status.order['applied'] == 0 &&
                //   action.payload.coupon_status.order['reject'] == 0
                // ) {
                //   newOrder = {
                //     total: twofix(total),
                //     balance: twofix(twofix(total) + twofix(taxCollected)),
                //     tax_collected: twofix(taxCollected),
                //     order_discount: twofix(orderDiscount),
                //     order_percentage: twofix(orderPercentage),
                //     reward_card_id: state.reward_card_id,
                //     coupon_status: action.payload.coupon_status.order,
                //     discount_reason: action.payload.coupon_status.order,
                //     discount_type: state.discount_type,
                //   }
                // } else if (action.payload.coupon_status.order['applied'] == 1) {
                //   newOrder = {
                //     total: twofix(total),
                //     balance: twofix(twofix(total) + twofix(taxCollected)),
                //     tax_collected: twofix(taxCollected),
                //     order_discount: twofix(orderDiscount),
                //     order_percentage: twofix(orderPercentage),
                //     reward_card_id: state.reward_card_id,
                //     discount_reason: state.discount_reason,
                //     coupon_status: state.coupon_status,
                //     discount_type: state.discount_type,
                //   }
                // } else {
                if (state.order_percentage > 0) {
                    // order discount apply
                    var totalOrdDiscount = twofix(
                        twofix(twofix(total) + twofix(orderDiscount)) *
                            parseFloat(state.order_percentage / 100)
                    ); // cal order total discount
                    total = twofix(
                        twofix(total) +
                            twofix(orderDiscount) -
                            twofix(totalOrdDiscount)
                    ); // cal total
                    newOrder = {
                        // create order object
                        total: twofix(total),
                        balance: twofix(twofix(total) + twofix(taxCollected)),
                        tax_collected: twofix(taxCollected),
                        order_discount: twofix(totalOrdDiscount),
                        reward_card_id: state.reward_card_id,
                        reward_card_number: state.reward_card_number,
                        order_percentage: twofix(orderPercentage),
                        discount_reason: state.discount_reason,
                        coupon_status: state.coupon_status,
                        reward_history_id: state.reward_history_id,
                        discount_type: state.discount_type,
                        order_for_here: state.order_for_here,
                        order_name: state.order_name,
                        instruction: state.instruction,
                        tip_type: state.tip_type,
                        tip_amount: state.tip_amount,
                        order_tip_amount: state.order_tip_amount,
                    };
                } else {
                    // no discount apply in order
                    newOrder = {
                        // create order object
                        total: twofix(total),
                        balance: twofix(twofix(total) + twofix(taxCollected)),
                        tax_collected: twofix(taxCollected),
                        order_discount: twofix(orderDiscount),
                        order_percentage: twofix(orderPercentage),
                        reward_card_id: state.reward_card_id,
                        reward_card_number: state.reward_card_number,
                        discount_reason: state.discount_reason,
                        coupon_status: state.coupon_status,
                        discount_type: state.discount_type,
                        reward_history_id: state.reward_history_id,
                        order_for_here: state.order_for_here,
                        order_name: state.order_name,
                        instruction: state.instruction,
                        tip_type: state.tip_type,
                        tip_amount: state.tip_amount,
                        order_tip_amount: state.order_tip_amount,
                    };
                }
            }
            return newOrder; // return order object
        }
        case APPLY_COUPON: {
            // use for apply coupon
            let newOrder = {};
            let newProduct = [];
            let total = 0;
            let taxCollected = 0;
            let totalDiscount = 0;
            let coupon_found = 0;
            action.payload.coupon_status.order.reward_id = 0.1;
            action.payload.order_items.map((singleProduct) => {
                // order line item get one by one
                action.payload.coupon_status.products.map(function (o) {
                    // coupon Product get
                    if (o.product_id == singleProduct.server_id && !o.reject) {
                        //first time apply coupon if coupon is product
                        coupon_found = 1;
                        let discount = 0;
                        let percentage = o.coupon_discount_value;
                        var maxQty;
                        if (singleProduct.quantity >= o.max_qty_per_coupon) {
                            // check item new Quantity is grater then coupon max_qty_per_coupon then set max_qty_per_coupon in maxQty
                            maxQty = o.max_qty_per_coupon;
                        } else {
                            // set item Quantity in maxQty
                            maxQty = singleProduct.quantity;
                        }
                        if (o.coupon_discount_type == "per") {
                            // coupon discount type is Percentage
                            discount = twofix(
                                twofix(singleProduct.sale_price) *
                                    twofix(maxQty) *
                                    parseFloat(percentage / 100)
                            ); // cal discount
                            percentage = twofix(percentage);
                        } else {
                            // coupon discount type is Amount
                            discount = twofix(
                                twofix(o.coupon_discount_value) * twofix(maxQty)
                            ); // cal discount
                            percentage =
                                twofix(
                                    discount /
                                        twofix(
                                            twofix(singleProduct.sale_price) *
                                                twofix(maxQty)
                                        )
                                ) * 100; //cal Percentage
                        }
                        total = twofix(
                            twofix(total) +
                                twofix(singleProduct.sale_price) *
                                    twofix(singleProduct.quantity) -
                                twofix(discount)
                        ); // cal total
                        taxCollected = twofix(
                            twofix(taxCollected) +
                                twofix(
                                    twofix(
                                        twofix(singleProduct.sale_price) *
                                            twofix(singleProduct.quantity) -
                                            twofix(discount)
                                    ) *
                                        parseFloat(
                                            singleProduct.tax_percentage / 100
                                        )
                                ) +
                                parseFloat(
                                    singleProduct.tax_amount *
                                        twofix(singleProduct.quantity)
                                )
                        ); // cal tax collected
                        totalDiscount = twofix(
                            twofix(totalDiscount) + twofix(discount)
                        );
                        let newItem = {
                            // apply coupon product object create
                            server_id: singleProduct.server_id,
                            product_id: singleProduct.server_id,
                        };
                        newProduct.push(newItem); // push object in array
                    }
                    coupon_found = 0;
                });
                if (!coupon_found) {
                    // coupon not apply
                    const exists = _.findIndex(newProduct, function (pro) {
                        return pro.product_id == singleProduct.server_id;
                    }); // set item exist or not in newProduct array
                    if (exists < 0) {
                        // not exists
                        let newItem = {
                            // product object create
                            server_id: singleProduct.server_id,
                            product_id: singleProduct.server_id,
                        };
                        total = twofix(
                            twofix(total) +
                                twofix(singleProduct.sale_price) *
                                    twofix(singleProduct.quantity) -
                                twofix(singleProduct.total_discount)
                        ); // cal total
                        taxCollected = twofix(
                            twofix(taxCollected) +
                                twofix(singleProduct.tax_collected)
                        ); // cal tax collected
                        totalDiscount = twofix(
                            twofix(totalDiscount) +
                                twofix(singleProduct.total_discount)
                        ); // cal total discount
                        newProduct.push(newItem);
                    } else {
                    }
                }
            });
            //Order discount
            let orderDiscount = 0;
            let orderPercentage = 0;
            if (
                "applied" in action.payload.coupon_status.order &&
                action.payload.coupon_status.order["applied"] == 0 &&
                action.payload.coupon_status.order["reject"] == 0
            ) {
                // applied 0 rejected 0 coupon is applying
                if (
                    action.payload.coupon_status.order[
                        "coupon_discount_type"
                    ] == "per"
                ) {
                    // Order coupon type per
                    orderDiscount = twofix(
                        twofix(total) *
                            parseFloat(
                                action.payload.coupon_status.order[
                                    "coupon_discount_value"
                                ] / 100
                            )
                    ); // order discount calculate
                    orderPercentage = twofix(
                        action.payload.coupon_status.order[
                            "coupon_discount_value"
                        ]
                    );
                } else {
                    // Order coupon type Amount
                    orderDiscount = twofix(
                        action.payload.coupon_status.order[
                            "coupon_discount_value"
                        ]
                    ); // order discount calculate
                }
                total = twofix(twofix(total) - twofix(orderDiscount)); // cal total
                taxCollected = twofix(taxCollected); // cal total tax
                newOrder = {
                    // create order object
                    total: twofix(total),
                    balance: twofix(twofix(total) + twofix(taxCollected)),
                    tax_collected: twofix(taxCollected),
                    order_discount: twofix(orderDiscount),
                    order_percentage: twofix(orderPercentage),
                    reward_card_id: state.reward_card_id,
                    reward_card_number: state.reward_card_number,
                    reward_history_id: state.reward_history_id,
                    coupon_status: action.payload.coupon_status.order,
                    discount_reason: Constant.COUPON_APPLIED,
                    discount_type: state.discount_type,
                    order_for_here: state.order_for_here,
                    order_name: state.order_name,
                    instruction: state.instruction,
                    tip_type: state.tip_type,
                    tip_amount: state.tip_amount,
                    order_tip_amount: state.order_tip_amount,
                };
            } else {
                orderDiscount = twofix(action.payload.order.order_discount); // cal order discount
                orderPercentage = twofix(action.payload.order.order_percentage); // cal order Percentage
                total = twofix(twofix(total) - twofix(orderDiscount)); // cal total
                taxCollected = twofix(taxCollected); // cal total tax collected
                if (
                    "applied" in action.payload.coupon_status.order &&
                    action.payload.coupon_status.order["applied"] == 0 &&
                    action.payload.coupon_status.order["reject"] == 0
                ) {
                    // order coupon not apply
                    newOrder = {
                        // create order object
                        total: twofix(total),
                        balance: twofix(twofix(total) + twofix(taxCollected)),
                        tax_collected: twofix(taxCollected),
                        order_discount: twofix(orderDiscount),
                        order_percentage: twofix(orderPercentage),
                        reward_card_id: state.reward_card_id,
                        reward_card_number: state.reward_card_number,
                        coupon_status: action.payload.coupon_status.order,
                        discount_reason: action.payload.coupon_status.order,
                        discount_type: state.discount_type,
                        reward_history_id: state.reward_history_id,
                        order_for_here: state.order_for_here,
                        order_name: state.order_name,
                        instruction: state.instruction,
                        tip_type: state.tip_type,
                        tip_amount: state.tip_amount,
                        order_tip_amount: state.order_tip_amount,
                    };
                } else if (action.payload.coupon_status.order["applied"] == 1) {
                    // order coupon apply
                    newOrder = {
                        // create order object
                        total: twofix(total),
                        balance: twofix(twofix(total) + twofix(taxCollected)),
                        tax_collected: twofix(taxCollected),
                        order_discount: twofix(orderDiscount),
                        order_percentage: twofix(orderPercentage),
                        reward_card_id: state.reward_card_id,
                        reward_card_number: state.reward_card_number,
                        discount_reason: state.discount_reason,
                        coupon_status: state.coupon_status,
                        discount_type: state.discount_type,
                        reward_history_id: state.reward_history_id,
                        order_for_here: state.order_for_here,
                        order_name: state.order_name,
                        instruction: state.instruction,
                        tip_type: state.tip_type,
                        tip_amount: state.tip_amount,
                        order_tip_amount: state.order_tip_amount,
                    };
                } else {
                    if (state.order_percentage > 0) {
                        // order manual discount
                        var totalOrdDiscount = twofix(
                            twofix(twofix(total) + twofix(orderDiscount)) *
                                parseFloat(state.order_percentage / 100)
                        ); // cal total discount
                        total = twofix(
                            twofix(total) +
                                twofix(orderDiscount) -
                                twofix(totalOrdDiscount)
                        ); // cal total
                        newOrder = {
                            // create order object
                            total: twofix(total),
                            balance: twofix(
                                twofix(total) + twofix(taxCollected)
                            ),
                            tax_collected: twofix(taxCollected),
                            order_discount: twofix(totalOrdDiscount),
                            reward_card_id: state.reward_card_id,
                            reward_card_number: state.reward_card_number,
                            order_percentage: twofix(orderPercentage),
                            discount_reason: state.discount_reason,
                            coupon_status: state.coupon_status,
                            discount_type: state.discount_type,
                            reward_history_id: state.reward_history_id,
                            order_for_here: state.order_for_here,
                            order_name: state.order_name,
                            instruction: state.instruction,
                            tip_type: state.tip_type,
                            tip_amount: state.tip_amount,
                            order_tip_amount: state.order_tip_amount,
                        };
                    } else {
                        newOrder = {
                            // create order object
                            total: twofix(total),
                            balance: twofix(
                                twofix(total) + twofix(taxCollected)
                            ),
                            tax_collected: twofix(taxCollected),
                            order_discount: twofix(orderDiscount),
                            order_percentage: twofix(orderPercentage),
                            reward_card_id: state.reward_card_id,
                            reward_card_number: state.reward_card_number,
                            discount_reason: state.discount_reason,
                            coupon_status: state.coupon_status,
                            discount_type: state.discount_type,
                            reward_history_id: state.reward_history_id,
                            order_for_here: state.order_for_here,
                            order_name: state.order_name,
                            instruction: state.instruction,
                            tip_type: state.tip_type,
                            tip_amount: state.tip_amount,
                            order_tip_amount: state.order_tip_amount,
                        };
                    }
                }
            }
            return newOrder; // return order object
        }

        case REMOVE_ITEM_COUPON: {
            // use for remove item coupon
            let oldTotal = twofix(
                twofix(state.total) + twofix(state.order_discount)
            ); // cal old total
            let discount_total_item = twofix(
                twofix(action.payload.item.quantity) *
                    twofix(action.payload.item.sale_price) *
                    parseFloat(action.payload.percentage / 100)
            ); // cal discount item
            let total_tax_of_disc_amount = twofix(
                twofix(discount_total_item) *
                    parseFloat(action.payload.item.tax_percentage / 100)
            ); // cal tax
            let totalTax = 0;
            if (action.payload.item.tax_percentage > 0) {
                // item tax applied
                totalTax = twofix(total_tax_of_disc_amount); // set tax
            }
            let newTotal = twofix(
                twofix(oldTotal) + twofix(discount_total_item)
            ); // cal new total
            let newBalance = twofix(
                twofix(oldTotal) + twofix(discount_total_item)
            ); // cal new balance
            let order_discount_amount = 0;
            if ("coupon_status" in state && state.coupon_status != undefined) {
                // coupon apply in order
                if (state.coupon_status.coupon_discount_type == "amou") {
                    // coupon discount type amount
                    order_discount_amount = twofix(
                        state.coupon_status.coupon_discount_value
                    ); // cal Order discount
                } else {
                    // coupon discount type Percentage
                    order_discount_amount = twofix(
                        twofix(newTotal) *
                            parseFloat(state.order_percentage / 100) // cal Order discount
                    );
                }
            } else {
                // if (state.discount_reason == Constant.REWARD_REDEEMED) {
                //   order_discount_amount = parseFloat(25)
                // } else {
                order_discount_amount = twofix(
                    twofix(newTotal) * parseFloat(state.order_percentage / 100)
                ); // cal discount
                // }
            }
            let newOrderState = {
                // create Order object
                total: twofix(twofix(newTotal) - twofix(order_discount_amount)),
                balance: twofix(
                    twofix(newBalance) +
                        twofix(state.tax_collected) +
                        twofix(totalTax) -
                        twofix(order_discount_amount)
                ),
                tax_collected: twofix(
                    twofix(state.tax_collected) + twofix(totalTax)
                ),
                order_discount: twofix(order_discount_amount),
                order_percentage: parseFloat(state.order_percentage),
                discount_reason: state.discount_reason,
                reward_card_id: state.reward_card_id,
                reward_card_number: state.reward_card_number,
                coupon_status: state.coupon_status,
                discount_type: state.discount_type,
                reward_history_id: state.reward_history_id,
                order_for_here: state.order_for_here,
                order_name: state.order_name,
                instruction: state.instruction,
                tip_type: state.tip_type,
                tip_amount: state.tip_amount,
                order_tip_amount: state.order_tip_amount,
            };
            return newOrderState; // return order object
        }

        case TICKET_ORDER_COUPON_APPLY: {
            // ticket order coupon apply
            if (Object.keys(action.payload.coupon_status.order).length != 0) {
                // apply coupon
                let newOrderState = {
                    // create object
                    ...state,
                    coupon_status: {
                        ...action.payload.coupon_status.order,
                        applied: 0,
                    },
                };
                return newOrderState; // return order object
            } else {
                let newOrderState = { ...state }; // create object
                return newOrderState; // return object
            }
        }

        case INSERT_TICKET_TO_ORDER: {
            // ticket insert in order
            if (
                action.payload.campaign_coupon_id != 0 &&
                action.payload.campaign_coupon_id != null
            ) {
                // coupon apply
                let orderCouponState = {
                    // order coupon object create
                    reward_history_id: action.payload.reward_history_id,
                    coupon_type: "ord",
                    coupon_name: action.payload.coupon_name,
                    coupon_discount_type: action.payload.coupon_discount_type,
                    coupon_discount_value:
                        action.payload.coupon_discount_value * 1,
                    coupon_order_min_amount:
                        action.payload.coupon_order_min_amount,
                    coupon_id: action.payload.coupon_id,
                    campaign_id: action.payload.campaign_id,
                    reward_card_id: action.payload.reward_card_id,
                    campaign_coupon_id: twofix(
                        action.payload.campaign_coupon_id
                    ),
                    applied: 1,
                    reject: action.payload.reject,
                    reason: action.payload.reason,
                    reward_id: twofix(action.payload.reward_id),
                };
                let newOrder = {
                    // order object create
                    reward_history_id: action.payload.reward_history_id,
                    total: twofix(action.payload.total),
                    balance: twofix(action.payload.balance),
                    tax_collected: twofix(action.payload.tax_collected),
                    order_discount: twofix(action.payload.order_discount),
                    reward_card_id: action.payload.reward_card_id,
                    reward_card_number: action.payload.reward_card_number,
                    order_percentage: twofix(action.payload.order_percentage),
                    discount_reason: action.payload.discount_reason,
                    coupon_status: orderCouponState,
                    discount_type: action.payload.discount_type,
                    order_for_here: action.payload.order_for_here,
                    order_name: action.payload.order_name,
                    instruction: state.instruction,
                    tip_type: state.tip_type,
                    tip_amount: state.tip_amount,
                    order_tip_amount: state.order_tip_amount,
                };
                return newOrder; // retunr order object
            } else {
                let newOrders = {
                    // order object create
                    // reward_history_id
                    total: twofix(action.payload.total),
                    balance: twofix(action.payload.balance),
                    tax_collected: twofix(action.payload.tax_collected),
                    order_discount: twofix(action.payload.order_discount),
                    reward_card_id: action.payload.reward_card_id,
                    reward_card_number: action.payload.reward_card_number,
                    order_percentage: twofix(action.payload.order_percentage),
                    discount_reason: action.payload.discount_reason,
                    discount_type: action.payload.discount_type,
                    order_for_here: action.payload.order_for_here,
                    order_name: action.payload.order_name,
                    instruction: state.instruction,
                    tip_type: state.tip_type,
                    tip_amount: state.tip_amount,
                    order_tip_amount: state.order_tip_amount,
                };
                return newOrders; // retunr order object
            }
        }
    }

    return state;
}
