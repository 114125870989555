
export const VIEW_TAXES = 'VIEW_TAXES';
export const UPDATE_TAXES = 'UPDATE_TAXES';

export function updateTaxes(results) {
    return {
        type: UPDATE_TAXES,
        payload: results
    };

}

export function viewTaxes() {
    return {
        type: VIEW_TAXES,
    };

}


