import React from "react";
import { Row, Col } from "antd";
import CommentIcon from "@material-ui/icons/Comment";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Button,
  ButtonGroup,
  TextField,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Divider,
} from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Constant from "../../../../constants";
import moment from "moment";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
const useStyles = (theme) => ({
  expanIcon: {
    flexBasis: "10%",
    flexShrink: 0,
    color: "#3950b1",
    [theme.breakpoints.down("sm")]: {
      flexBasis: "15%",
    },
    [theme.breakpoints.up("sm")]: {
      flexBasis: "10%",
    },
    ["@media (device-width: 540px)"]: {
      flexBasis: "10%",
    },
    // paddingTop: "1%",
  },
  flexLayout: {
    flex: 10,
    flexDirection: "row",
    alignItems: "stretch",
    overflow: "hidden",
    padding: 13,
    marginBottom: 10,
    backgroundColor: "#fff",
    overflowY: "scroll",
  },
  buttonLayout: {
    display: "flex",
    flex: 0.6,
    padding: 8,
    justifyContent: "center",
    // alignItems:"center",
    backgroundColor: "#fff",
  },
  buttonGroupLayout: {
    marginTop: "5%",
    marginBottom: "5%",
  },
  headerName: { fontSize: 17, fontWeight: "bold" },
  subheaderLength: { fontSize: 15 },
  textLayout: {
    marginTop: "2%",
    marginBottom: "2%",
  },
});

class DeliveryPickUp extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      streetNumber: "",
      line2: "",
      city: "",
      state: "",
      zipCode: "",
      expanded: false,
      isDelivery: false,
      isPickUp: false,
      deliveryTime: "",
    };
  }
  componentDidMount() {
    this._isMounted = true;
    if (
      null !== this.props.delivery_pickup &&
      undefined !== this.props.delivery_pickup &&
      this.props.delivery_pickup.length > 0
    ) {
      let time = "";
      if (
        this.props.delivery_pickup[0].location.location_online_order_settings
          .length > 0
      ) {
        this.props.delivery_pickup[0].location.location_online_order_settings.map(
          (settings) => {
            settings.location_hrs_sub_slot.map((time_slot) => {
              if (time_slot.id == this.props.delivery_pickup[0].DeliveryTime) {
                let deliverytime =
                  time_slot.from_time + " - " + time_slot.to_time;
                time = deliverytime;
              }
            });
          },
        );
      }

      if (this._isMounted) {
        this.setState({
          streetNumber: this.props.delivery_pickup[0].address,
          city: this.props.delivery_pickup[0].city,
          state: this.props.delivery_pickup[0].state,
          zipCode: this.props.delivery_pickup[0].zipCode,
          isDelivery:
            this.props.delivery_pickup[0].order_type === "delivery"
              ? true
              : false,
          isPickUp:
            this.props.delivery_pickup[0].order_type === "pickup"
              ? true
              : false,
          deliveryTime: time,
        });
      }
    }
  }

  changeDelivery = () => {
    if (this.props.order.balance < 0.01 && this.props.order_items.length == 0) {
      toast.error(Constant.ORDER_BALANCE_ZERO);
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (panel) => (event, isExpanded) => {
    isExpanded
      ? this.setState({ expanded: panel })
      : this.setState({ expanded: false });
  };

  changeLayout = (layout, key) => {
    if (layout == "Instructions" || layout == "SellGiftCard") {
      this.props.checkOutInsidePage(layout, key);
    } else {
      if (this.props.delivery_pickup[0]?.scheduleOrder !== "asap") {
        this.props.checkOutInsidePage(layout, key);
      }
    }
  };

  render() {
    let gift_Card = false
    let gift_card_index = this.props.delivery_pickup[0].location.location_settings.findIndex(x => x.key == 'gift_card')
    if(gift_card_index > -1) {
      if(this.props.delivery_pickup[0].location.location_settings[gift_card_index].value.toUpperCase() == 'YES') {
        gift_Card = true
      }
    }
    const { classes } = this.props;
    if (
      null != this.props.delivery_pickup &&
      undefined != this.props.delivery_pickup &&
      "" != this.props.delivery_pickup &&
      this.props.delivery_pickup.length > 0
    ) {
      return (
        <>
          <Paper elevation={1} square className={classes.flexLayout}>
            <Row justify="center">
              <h1 className="sidebar-layout-header-name">
                {this.state.isDelivery ? "Delivery Details" : "Pickup Details"}
              </h1>
            </Row>
            <Row
              gutter={[24, 0]}
              justify="center"
              className={classes.buttonGroupLayout}
            >
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
              >
                <Button
                  style={{ width: "100%" }}
                  variant={this.state.isDelivery ? "contained" : ""}
                  color={this.state.isDelivery ? "primary" : ""}
                >
                  Delivery
                </Button>
                <Button
                  style={{ width: "100%" }}
                  variant={this.state.isPickUp ? "contained" : ""}
                  color={this.state.isPickUp ? "primary" : ""}
                >
                  Pick Up
                </Button>
              </ButtonGroup>
            </Row>
            {null != this.state.streetNumber &&
            undefined != this.state.streetNumber &&
            "" != this.state.streetNumber ? (
              <TextField
                fullWidth
                className={classes.textLayout}
                id="outlined-secondary"
                label="Address *"
                variant="outlined"
                value={this.state.streetNumber}
                size="small"
                disabled
              />
            ) : null}
            {null != this.state.city &&
            undefined != this.state.city &&
            "" != this.state.city ? (
              <TextField
                fullWidth
                className={classes.textLayout}
                id="outlined-secondary"
                label="City *"
                variant="outlined"
                value={this.state.city}
                size="small"
                disabled
              />
            ) : null}
            {null != this.state.state &&
            undefined != this.state.state &&
            "" != this.state.state ? (
              <TextField
                fullWidth
                className={classes.textLayout}
                id="outlined-secondary"
                label="State *"
                variant="outlined"
                value={this.state.state}
                size="small"
                disabled
              />
            ) : null}
            {null != this.state.zipCode &&
            undefined != this.state.zipCode &&
            "" != this.state.zipCode ? (
              <TextField
                fullWidth
                className={classes.textLayout}
                id="outlined-secondary"
                label="Zip Code *"
                variant="outlined"
                value={this.state.zipCode}
                size="small"
                disabled
              />
            ) : null}

            <Accordion
              expanded={this.state.expanded === "panel1"}
              onClick={() => this.changeLayout("Instructions", "")}
            >
              <AccordionSummary
                expandIcon={<ArrowForwardIosIcon className="icon-color" />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <CommentIcon className={classes.expanIcon} fontSize="large" />
                <Col xs="24" xl={24} style={{ marginLeft: "5%" }}>
                  <Typography className={classes.headerName}>
                    Instructions
                  </Typography>
                  <Typography className={classes.subheaderLength}>
                    {null != this.props.order.instruction &&
                    undefined != this.props.order.instruction &&
                    "" != this.props.order.instruction
                      ? this.props.order.instruction.trim().length > 0
                        ? this.props.order.instruction.trim().slice(0, 33) +
                          "..."
                        : "Add Instructions"
                      : "Add Instructions"}
                  </Typography>
                </Col>
              </AccordionSummary>
            </Accordion>
            <Accordion
              expanded={this.state.expanded === "panel2"}
              onClick={() => this.changeLayout("Time", "")}
            >
              <AccordionSummary
                expandIcon={
                  this.props.delivery_pickup[0]?.scheduleOrder !== "asap" ? (
                    <ArrowForwardIosIcon className="icon-color" />
                  ) : null
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <WatchLaterIcon
                  className={classes.expanIcon}
                  fontSize="large"
                />
                <Col xs="24" xl={24} style={{ marginLeft: "5%" }}>
                  <Typography className={classes.headerName}>
                    {this.props.delivery_pickup[0].order_type === "delivery"
                      ? "Delivery Time"
                      : "PickUp Time"}
                  </Typography>
                  <Typography className={classes.subheaderLength}>
                    {" "}
                    {moment().format("ddd") +
                      "," +
                      moment().format("MMMM Do YYYY")}
                    <br />
                    {this.props.delivery_pickup[0]?.scheduleOrder == "asap"
                      ? "ASAP"
                      : this.state.deliveryTime}
                  </Typography>
                </Col>
              </AccordionSummary>
              <AccordionDetails></AccordionDetails>
            </Accordion>
            {/* {gift_Card ? (
              <Accordion
              expanded={this.state.expanded === "panel3"}
              onClick={() => this.changeLayout("SellGiftCard", "")}
            >
              <AccordionSummary
                expandIcon={<ArrowForwardIosIcon className="icon-color" />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <CardGiftcardIcon
                  className={classes.expanIcon}
                  fontSize="large"
                />
                <Col xs="24" xl={24} style={{ marginLeft: "5%" }}>
                  <Typography className={classes.headerName}>
                    Sell Gift Card
                  </Typography>
                  <Typography className={classes.subheaderLength}>
                    {"Purchase a Gift Card"}
                  </Typography>
                </Col>
              </AccordionSummary>
            </Accordion>
            ) : null} */}
            
          </Paper>
          <Paper elevation={1} square className={classes.buttonLayout}>
            <Button
              variant="contained"
              color="primary"
              style={{ width: "100%", height: "93%" }}
              onClick={() => this.changeDelivery()}
            >
              Continue
            </Button>
          </Paper>
        </>
      );
    } else {
      return <></>;
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}
function mapStateToProps(state) {
  return {
    delivery_pickup: state.delivery_pickup,
    order_items: state.order_items,
    order: state.order,
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true }),
)(DeliveryPickUp);
