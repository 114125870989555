import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Row, Col, Typography } from "antd";
import { Button, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import * as Constant from "../constants";
import { userSignIn } from "../services/user/user";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeProducts, removeFeaturedProducts } from "../actions/menu_items";
import { removeDeliveryPickupDetails } from "../actions/delivery_pickup_action";
import { removeLocations } from "../actions/location_action";
import { clearItems } from "../actions/order_items";
import { updateOrdertype } from "../actions/delivery_pickup_action";
import { insertItem, addExtraItem } from "../actions/order_items";
import {
  orderCalculation,
  completeOrder,
  orderInstruction,
} from "../actions/order_actions";
import Card from "react-credit-cards";
import { currentItem, removeCurrentItem } from "../actions/current_item";
import { LinearProgress } from "@material-ui/core";
import logo from "../assets/images/Octopos-logo-blue.png";
import Footer from "../../src/components/layout/Footer";
import {
  addUserCardDetails,
  removeUserCardDetails,
} from "../actions/card_Details_action";
import {
  orderUser,
  userLogout,
  addPaymentCardId,
  removeOrderUser,
} from "../actions/user_actions";
import { checkGiftCardNumber, postOrder } from "../services/order/order";
import { twofix } from "../helpers/calculation";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData,
} from "../components/layout/orderItems/checkOut/utils";
import moment from "moment";
import sideImage from "../assets/images/auth13.jpg"

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
);
const { Title } = Typography;
const { Header, Content } = Layout;
const styles = (theme) => ({
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
      width: "0%",
    },
  },
  imageLogo: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    // textAlign:'center',
    paddingBottom: 16,
    width: "50%",
  },
  flexLayout: {
    flex: 10,
    flexDirection: "row",
    alignItems: "stretch",
    overflow: "hidden",
    marginBottom: 7,
    padding: 13,
  },
  buttonLayout: {
    display: "flex",
    flex: 0.6,
    padding: 8,
    marginBottom: 10,
    justifyContent: "flex-end",
    backgroundColor: "#fff",
  },
  buttonLayout1: {
    display: "flex",
    flex: 0.6,
    padding: 8,
    marginBottom: 10,
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  headerName: { fontSize: 22, fontWeight: "bold", margin: 0 },
  headerName1: { fontSize: 22, fontWeight: "bold", marginTop: "3%" },
  subtitleName: { fontSize: 18 },
});

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView();

  return { executeScroll, elRef };
};

class GiftCardSell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      number: "",
      selectButtonAmount: 0.0,
      name: "",
      expiry: "",
      cvc: "",
      issuer: "",
      focused: "",
      formData: null,
      cardNumber: "",
      backgroundImage:"",
      errors: {
        number: "",
        email: "",
        sms: "",
        message: "",
      },
      cardErrors: {
        cardNumber: "",
        expiry: "",
        cvc: "",
      },
      firstLayout: true,
      secondLayout: false,
      generateGiftNumber: 0,
      email: "",
      sms: "",
      message: "",
    };
    this.inputRefs = [];
    this.elScroll = utilizeScroll();
  }
  componentDidMount() {
    let flag_image = false;
    let online_location_image = false;
    // this.props.clearItems();
    if (
      null != this.props.locations.allLocations &&
      undefined != this.props.locations.allLocations &&
      "" != this.props.locations.allLocations
    ) {
      this.props.locations.allLocations.map((locations, index) => {
        if (
          !flag_image &&
          locations.image_url != null &&
          locations.image_url != undefined &&
          locations.image_url != ""
        ) {
          this.setState({ image_loc: locations.image_url });
          flag_image = true;
        }
        locations.location_online_order_settings.map((settings, i) => {
          if(!online_location_image && settings.image_url_online != null && settings.image_url_online != undefined && settings.image_url_online != ''){
            online_location_image=true
            this.setState({backgroundImage:settings.image_url_online})
          } else if(!online_location_image && locations.location_online_order_settings.length - 1 == i && this.state.backgroundImage == ""){
            this.setState({backgroundImage:sideImage })
            online_location_image=true
          }
        });
      });
      setTimeout(() => {
        this._handleImageshow();
      }, 3000);
    } else {
      this.setState({backgroundImage:sideImage })
    }
    if (
      undefined != this.props.current_item &&
      null != this.props.current_item &&
      this.props.current_item.length > 0
    ) {
      if (
        this.props.current_item[0].sale_price == 10 ||
        this.props.current_item[0].sale_price == 20 ||
        this.props.current_item[0].sale_price == 50 ||
        this.props.current_item[0].sale_price == 80 ||
        this.props.current_item[0].sale_price == 100
      ) {
        this.setState({
          generateGiftNumber: this.props.current_item[0].server_id,
          email: this.props.current_item[0].email,
          sms: this.props.current_item[0].sms,
          message: this.props.current_item[0].product_description,
          selectButtonAmount: this.props.current_item[0].sale_price,
          firstLayout: false,
          secondLayout: true,
        });
      } else {
        this.setState({
          generateGiftNumber: this.props.current_item[0].server_id,
          email: this.props.current_item[0].email,
          sms: this.props.current_item[0].sms,
          message: this.props.current_item[0].product_description,
          number: this.props.current_item[0].sale_price,
          firstLayout: false,
          secondLayout: true,
        });
      }
    }
  }
  _handleImageshow = () => {
    let flag_image = false;
    if (
      null != this.props.locations.allLocations &&
      undefined != this.props.locations.allLocations &&
      "" != this.props.locations.allLocations
    ) {
      this.props.locations.allLocations.map((locations, index) => {
        if (
          !flag_image &&
          locations.image_url != null &&
          locations.image_url != undefined &&
          locations.image_url != ""
        ) {
          this.setState({ image_loc: locations.image_url });
          flag_image = true;
        }
      });
    }
  };
  handlenextLayout = () => {
    this.setState({
      firstLayout: false,
      secondLayout: true,
      generateGiftNumber:
        Math.floor(Math.random() * (999 - 100)) +
        101 +
        "" +
        (Math.floor(Math.random() * (999 - 100)) + 101) +
        "" +
        (Math.floor(Math.random() * (999 - 100)) + 101) +
        "" +
        (Math.floor(Math.random() * (999 - 100)) + 101),
    });
  };
  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };
  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    });
  };
  handleonBlurInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const that = this;
    let errors = this.state.errors;
    let amount
    if(name == "number" && twofix(value).toFixed(2) > 0.00){
      amount = twofix(value).toFixed(2)
    } else {
      amount = value
    }
    switch (name) {
      case "number":
        errors.number =
          value.length > 0
            ? !this.validate(amount)
              ? Constant.VALID_GIFT_CARD_AMOUNT
              : amount >= 0.01 && amount <= 500
                ? ""
                : Constant.VALID_GIFT_CARD_AMOUNT_LESS_OR_GREATER
            : "";
        break;
      default:
        break;
    }
    if (name == "number") {
      this.setState({ selectButtonAmount: 0.0 });
    }
    this.setState({ errors, [name]: amount });
  };
  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const that = this;
    let errors = this.state.errors;
    // if(name == "number" && twofix(value).toFixed(2) > 0.00){
    //   value = twofix(value).toFixed(2)
    // }
    switch (name) {
      case "number":
        errors.number =
          value.length > 0
            ? !this.validate(value)
              ? Constant.VALID_GIFT_CARD_AMOUNT
              : value >= 0.01 && value <= 500
                ? ""
                : Constant.VALID_GIFT_CARD_AMOUNT_LESS_OR_GREATER
            : "";
        break;
      case "email":
        errors.email =
          value.length > 0
            ? validEmailRegex.test(value)
              ? value.length < 255
                ? ""
                : Constant.USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER
              : Constant.USER_EMAIL_NOT_VALID
            : "";
        break;
      case "sms":
        errors.sms =
          value.length > 0
            ? this.validatephone(value)
              ? value.length > 10
                ? Constant.USER_PHONE_NUMBER_GREATER_THEN_10
                : value.length < 3
                  ? Constant.USER_PHONE_NUMBER_MUST_BE_3_DIGITS
                  : ""
              : Constant.USER_PHONE_NUMBER__NOT_VALID
            : "";
        break;
      case "message":
        errors.message =
          value.length > 0
            ? value.length > 255
              ? Constant.GIFT_CARD_INSTRUCTION
              : ""
            : "";
        break;
      default:
        break;
    }
    if (name == "number") {
      this.setState({ selectButtonAmount: 0.0 });
    }
    this.setState({ errors, [name]: value });
  };
  handleCardInputChange = ({ target }) => {
    if (target.name === "cardNumber") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }
    const date = target.value.split(" ");
    let year = moment().format("YYYY");
    let expiry = year.slice(0, 2) + date[0].slice(3, 5);
    let errors = this.state.cardErrors;
    switch (target.name) {
      case "cardNumber":
        errors.cardNumber =
          target.value.length === 0
            ? Constant.CARD_NUMBER_REQUIRED
            : this.validateCardNumber(target.value)
              ? ""
              : Constant.VALID_CARD_NUMBER;
        break;
      case "expiry":
        errors.expiry =
          target.value.length === 0
            ? Constant.EXPIRY_DATE_REQUIRED
            : this.validateExpiryDate(target.value)
              ? moment(moment(target.value, ["MM/YY"])).isAfter(new Date(), [
                  "MM/YY",
                ]) ||
                moment(moment(expiry, ["YYYY"])).isAfter(new Date(), ["YYYY"])
                ? ""
                : Constant.PAST_EXPIRY_DATE
              : Constant.VALID_EXPIRY_DATE;
        break;
      case "cvc":
        errors.cvc =
          target.value.length === 0
            ? Constant.CVC_REQUIRED
            : this.validateCVCNumber(target.value)
              ? ""
              : Constant.VALID_CVC;
        break;
      default:
        break;
    }
    this.setState({ [target.name]: target.value });
  };
  validateCardNumber = (cardNumber1) => {
    const usingSplit = cardNumber1.split(" ");
    let cardNumber = "";
    if (usingSplit.length > 0) {
      usingSplit.map((code) => {
        cardNumber = cardNumber + code;
      });
    }
    const regex =
      /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|(222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11}|62[0-9]{14})$/;
    return regex.test(cardNumber);
  };
  validateExpiryDate = (date) => {
    const regex = new RegExp("(0[1-9]|1[0-2])/[0-9]{2}");
    return regex.test(date);
  };
  validateCVCNumber = (cvc) => {
    const regex = /^[0-9]{3,4}$/;
    return regex.test(cvc);
  };

  handleGiftCardAmount = (amount) => {
    this.setState({
      selectButtonAmount: amount,
      number: "",
      errors: {
        number: "",
        email: "",
        sms: "",
        message: "",
      },
    });
  };
  validate = (number) => {
    const regex = /^\d+(\.\d{1,2})?$/;
    return regex.test(number.trim());
  };
  validatephone = (phone) => {
    const regex = /^[0-9\b]+$/;
    return regex.test(phone);
  };
  backToHome = () => {
    this.props.removeCurrentItem()
    window.location.href =
      process.env.REACT_APP_ACCESS_KEY + "/" + localStorage.getItem("tenant");
  };
  saveCardDetails = () => {
    this.elScroll.executeScroll();
    this.setState({ loading: true });
    if (
      null != this.props.user.user &&
      undefined != this.props.user.user &&
      "" != this.props.user.user
    ) {
      if (
        null != this.state.cardNumber &&
        undefined != this.state.cardNumber &&
        "" != this.state.cardNumber.trim()
      ) {
        if (
          null != this.state.expiry &&
          undefined != this.state.expiry &&
          "" != this.state.expiry.trim()
        ) {
          if (
            null != this.state.cvc &&
            undefined != this.state.cvc &&
            "" != this.state.cvc.trim()
          ) {
            const usingSplit = this.state.cardNumber.split(" ");
            const date = this.state.expiry.split(" ");
            let year = moment().format("YYYY");
            let expiry =
              year.slice(0, 2) +
              date[0].slice(3, 5) +
              "-" +
              date[0].slice(0, 2);
            let cardNumber = "";
            // let expiry = ''

            if (usingSplit.length > 0) {
              usingSplit.map((code) => {
                cardNumber = cardNumber + code;
              });
            }
            let user = this.props.user.user;
            user.selected_card_id = "";
            let payload = {
              card_number: cardNumber,
              expiration_date: expiry,
              cvv_no: this.state.cvc.trim(),
              address: this.props.user.user.address,
              zip: this.props.user.user.zipcode,
            };
            if (null != payload && undefined != payload && "" != payload) {
              this.props.addUserCardDetails(payload);
              this.props.addPaymentCardId(user);
              if (
                this.props.user.whoIsThis !== null &&
                this.props.user.whoIsThis != undefined &&
                this.props.user.whoIsThis != ""
              ) {
                if (
                  this.props.user.user.first_name !==
                    this.props.user.whoIsThis.first_name ||
                  this.props.user.user.last_name !==
                    this.props.user.whoIsThis.last_name ||
                  this.props.user.user.phone !== this.props.user.whoIsThis.phone
                ) {
                  let payload = {
                    first_name: this.props.user.whoIsThis.first_name,
                    last_name: this.props.user.whoIsThis.last_name,
                    phone: this.props.user.whoIsThis.phone,
                  };
                  this.props.orderUser(payload);
                }
              }
              setTimeout(() => {
                this.onOrderSubmit();
              }, 500);
            }
          } else {
            this.setState({ loading: false });
            toast.error(Constant.VALID_CVC);
          }
        } else {
          this.setState({ loading: false });
          toast.error(Constant.VALID_EXPIRY_DATE);
        }
      } else {
        this.setState({ loading: false });
        toast.error(Constant.VALID_CARD_NUMBER);
      }
    } else {
      this.setState({ loading: false });
      const path = "/" + localStorage.getItem("tenant") + "/sign-in";
      // this.props.history.push(path,{gift_card:true});
      this.props.history.push({
        pathname: path,
        state: {
          gift_card: true,
        },
      });
    }
  };
  handleSubmit = async () => {
    if (this.props.order_items?.length > 0) {
      toast.error(Constant.CAN_NOT_ADD_GIFT_CARD);
    } else {
      // this.setState({ submitDisable: true })
      let name = "Gift Card";
      let sale_price =
        parseFloat(this.state.number) > 0
          ? parseFloat(this.state.number)
          : this.state.selectButtonAmount;
      let barcode = this.state.generateGiftNumber;
      let type = "eGiftCard";
      let email = this.state.email;
      let sms = this.state.sms;
      let print_receipt = false;
      let custom_message = this.state.message;
      let emailid = true;

      if (!isNaN(this.state.generateGiftNumber)) {
        if (
          this.state.generateGiftNumber.length >= 4 &&
          this.state.generateGiftNumber.length <= 24
        ) {
          if (this.state.email.length > 0 || this.state.sms.length > 0) {
            if (this.state.sms.length == 0 || this.state.sms.length == 10) {
              checkGiftCardNumber(barcode) //  api call to send data to remote server
                .then(async (response) => {
                  if (response.data.error) {
                    if (response.data.error.code === 404) {
                      this.props.history.push("/404");
                    }
                    toast.error(response.data.error.message.message.toString());
                  } else if (response.data.flag) {
                    let item = {
                      name: name,
                      sale_price: (Math.round(sale_price * 100) / 100).toFixed(
                        2,
                      ),
                      server_id: barcode,
                      tax_percentage: 0,
                      tax_amount: 0,
                      extra_item: 0,
                      Ebt_item: 1,
                      taxes: [],
                      tax_collected: 0,
                      gift_card: 1,
                      type: type,
                      email: email,
                      sms: sms,
                      print_receipt: print_receipt,
                      product_description: custom_message,
                    };
                    this.props.currentItem(item);
                    this.props.addExtraItem(item); // CALL REDUCER
                    this.props.orderCalculation(); // Reducer call to store order data
                    this.setState({ firstLayout: false, secondLayout: false });
                  } else {
                    if (response.data.code == 999) {
                      let product = {
                        name: name,
                        sale_price: (
                          Math.round(sale_price * 100) / 100
                        ).toFixed(2),
                        server_id: barcode,
                        tax_percentage: 0,
                        tax_amount: 0,
                        extra_item: 0,
                        Ebt_item: 1,
                        taxes: [],
                        tax_collected: 0,
                        gift_card: 1,
                        type: type,
                        email: email,
                        sms: sms,
                        print_receipt: print_receipt,
                        product_description: custom_message,
                      };
                      this.props.currentItem(product);
                      this.props.removeUserCardDetails();
                      this.props.userLogout();
                      localStorage.removeItem("token");
                      const path =
                        "/" + localStorage.getItem("tenant") + "/sign-in";
                      // this.props.history.push(path,{gift_card:true});
                      this.props.history.push({
                        pathname: path,
                        state: {
                          gift_card: true,
                        },
                      });
                    }
                    toast.error(response.data.message);
                  }
                })
                .catch((error) => {
                  this.setState({ submitDisable: false });
                  // Error occured while being api called
                  if (
                    error.toString() == Constant.BACKEND_ERROR ||
                    error.toString() == Constant.BACKEND_ERROR_CODE
                  ) {
                    toast.error(Constant.SERVER_NOT_AVAILABLE);
                  } else {
                    toast.error(error.toString());
                  }
                });
            } else {
              this.setState({ submitDisable: false });
              toast.error(Constant.ENTER_VALID_PHONE_NUMBER);
            }
          } else {
            this.setState({ submitDisable: false });
            toast.error(Constant.VALIDATION_OF_EMAIL_OR_SMS);
          }
        } else {
          this.setState({ submitDisable: false });
          toast.error(Constant.VALID_GIFT_CARD_CODE_LESS_OR_GREATER);
        }
      } else {
        this.setState({ submitDisable: false });
        toast.error(Constant.VALID_GIFT_CARD_CODE);
      }
    }
  };
  onOrderSubmit = () => {
    this.setState({ loading: true });
    var amount_t = this.props.order.balance;
    var totalTipPayment = 0;
    var totalPayment = 0;
    if (
      this.props.order.balance === "" ||
      this.props.order.balance === "0." ||
      "" === parseFloat(amount_t).toFixed(2) ||
      "undefined" === parseFloat(amount_t).toFixed(2) ||
      isNaN(amount_t) ||
      0 === parseFloat(amount_t).toFixed(2) ||
      0.0 === parseFloat(amount_t).toFixed(2)
    ) {
      // 'Input value is undefined,not a number,empty,0.,0.00 or 0'
      toast.error(Constant.ORDER_BALANCE_ZERO);
      this.setState({ loading: false });
    } else {
      this.processOrder(totalTipPayment, totalPayment);
    }
  };
  processOrder = (order_tip_amount, totalPayment) => {
    this.elScroll.executeScroll();
    var current_order = localStorage.getItem("current_order"); // Get current order
    if (
      current_order === null ||
      current_order === undefined ||
      current_order === ""
    ) {
      current_order =
        new Date().valueOf().toString() +
        "" +
        (Math.floor(Math.random() * (999 - 100)) + 101);
    }
    var balance = twofix(
      this.props.order.total + this.props.order.tax_collected,
    ).toFixed(2);
    let first_name = "";
    let last_name = "";
    let phone = "";
    if (
      null !== this.props.user.whoIsThis &&
      undefined !== this.props.user.whoIsThis &&
      "" !== this.props.user.whoIsThis
    ) {
      first_name = this.props.user.whoIsThis.first_name;
      last_name = this.props.user.whoIsThis.last_name;
      phone = this.props.user.whoIsThis.phone;
    } else {
      if (
        null !== this.props.user.user &&
        undefined !== this.props.user.user &&
        "" !== this.props.user.user
      ) {
        first_name = this.props.user.user.first_name;
        last_name = this.props.user.user.last_name;
        phone = this.props.user.user.phone;
      }
    }
    if (
      null !== this.props.user.user &&
      undefined !== this.props.user.user &&
      "" !== this.props.user.user
    ) {
      if (
        null !== this.props.card_details &&
        undefined !== this.props.card_details &&
        this.props.card_details &&
        0 !== this.props.card_details.length
      ) {
        var timestamp = new Date().getTime();
        var order_payload = {
          //Create payment payload
          campaign_coupon_id: "",
          reward_history_id: "",
          email: this.props.user.user.email,
          paid: 1,
          pos_station_id: "",
          reward_card_id: this.props.order.reward_card_id,
          total: this.props.order.total,
          local_id: current_order,
          balance: balance,
          discount_amount: this.props.order.order_discount,
          discount_reason: this.props.order.discount_reason,
          lines: this.props.order_items,
          grub_user_id: this.props.user.user.id,
          payment: [
            {
              aid: undefined,
              aid_display_name: undefined,
              amount: twofix(
                twofix(this.props.order.total) +
                  twofix(this.props.order.tax_collected),
              ),
              cash_payment_check: 0,
              cashback_amount: undefined,
              external_reference: undefined,
              external_reference_number: undefined,
              id: timestamp,
              multiple_cash: true,
              payment_gateway: undefined,
              pin_statement: undefined,
              tips_amount: order_tip_amount,
              type: "CARD",
            },
          ],
          ebt_flag: 0,
          created_by: "",
          order_for_here: this.props.order.order_for_here,
          card_details: this.props.card_details,
          is_gift_card_create_from_grub: true,
        };
        // console.log(
        //   "KKKKKKKKKKKKK   GGGGGGGGGGGGGGGGGGG",
        //   JSON.stringify(order_payload),
        // );
        postOrder(order_payload)
          .then((res) => {
            // console.log(
            //   "HHHHHHHHHHHHH  FFFFFFFFF   res",
            //   JSON.stringify(res.data),
            // );
            if (res.data.flag) {
              // this.changeLayout("orderStatus", res.data.data.responsePayload);
              this.props.completeOrder();
              //Complete order
              localStorage.removeItem("current_order");
              this.props.removeOrderUser();
              this.props.removeUserCardDetails();
              this.props.removeProducts();
              this.props.removeFeaturedProducts();
              this.props.removeCurrentItem()
              this.props.removeDeliveryPickupDetails();
              this.props.removeLocations();
              toast.success(Constant.BUY_GIFT_CARD_SUCCESS);
              this.setState({ loading: false });
              this.backToHome();
            } else if (res.data.data.error) {
              if (res.data.data.error.code === 400) {
                toast.error(res.data.data.error.message[0]);
                this.setState({ loading: false });
              } else if (res.data.data.error.code === 404) {
                toast.error(res.data.data.error.message);
                this.setState({ loading: false });
              }
            } else {
              if (res.data.code === 999) {
                this.props.removeUserCardDetails();
                this.props.userLogout();
                localStorage.removeItem("token");
                const path = "/" + localStorage.getItem("tenant") + "/sign-in";
                this.props.history.push({
                  pathname: path,
                  state: {
                    gift_card: true,
                  },
                });
                toast.error(res.data.message);
                this.setState({ loading: false });
              } else {
                toast.error(res.data.message);
                this.setState({ loading: false });
              }
            }
          })
          .catch((error) => {
            toast.error(error.toString());
            this.setState({ loading: false });
          });
      } else {
        toast.error(Constant.USER_CARD_DETAILS_NOT_FOUND);
        this.setState({ loading: false });
      }
    } else {
      toast.error(Constant.CHECKOUT_PAGE_USER_LOGOUT);
      this.setState({ loading: false });
    }
  };
  _handleKeyPress = (e) => {
    const event = e;
    const { currentTarget } = e;
    if (event.key === "Enter") {
      let inputIndex = this.inputRefs.indexOf(currentTarget);
      if (inputIndex < this.inputRefs.length - 1) {
        this.inputRefs[inputIndex + 1].focus();
      } else {
        this.inputRefs[0].focus();
      }
      event.preventDefault();
    }
  };

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    const { classes } = this.props;
    var str = this.state.generateGiftNumber.toString();
    var giftCode = str.replace(/.(?=.{4,}$)/g, "*");
    let buttonDisable = false;
    if (this.state.email.length === 0 && this.state.sms.length === 0) {
      buttonDisable = true;
    } else if (
      this.state.email.length > 0 &&
      this.state.errors.email.length > 0
    ) {
      buttonDisable = true;
    } else if (this.state.sms.length > 0 && this.state.errors.sms.length > 0) {
      buttonDisable = true;
    } else if (
      this.state.message.length > 0 &&
      this.state.errors.message.length > 0
    ) {
      buttonDisable = true;
    }
    let PaybuttonDisable =
      this.state.cardNumber == "" ||
      this.state.cardErrors.cardNumber.length > 0 ||
      this.state.expiry == "" ||
      this.state.cardErrors.expiry.length > 0 ||
      this.state.cvc == "" ||
      this.state.cardErrors.cvc.length > 0;
    return (
      <>
        <div ref={this.elScroll.elRef}></div>
        {this.state.loading ? <LinearProgress color="primary" /> : null}
        <Layout className="layout-default layout-signin">
          <Header>
            <div className="header-brand" key={"brandName"}></div>
          </Header>
          <Content className="signin">
            <Row gutter={[24, 0]} justify="flex-start" flex-direction="column">
              <div className={classes.quoteContainer}>
              <Col
                  className="sign-img"
                  style={{
                    backgroundImage: `url("${this.state.backgroundImage}")`,
                    height: "300px",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '30%',
                     position: 'absolute',
                     left: '0px',
                     top:'0px',
                     height: '100%',
                     boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
                  }}             
                   >
                  {/* <img src="https://img.freepik.com/premium-photo/closeup-drop-water-leaf-flower-reflecting-surrounding-colors-textures_674594-4382.jpg" alt="" /> */}
                </Col>
              </div>
              <div className={classes.quote}>
                <Col className="select-order">
                  <div className={classes.imageLogo}>
                    <img
                      src={this.state.image_loc}
                      alt=""
                      className="location-image"
                    />
                  </div>
                  <Title className="font-regular text-muted" level={2}>
                    Buy a Gift Card
                  </Title>
                  {this.state.firstLayout ? (
                    <div
                      layout="vertical"
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Row gutter={[24, 0]} justify="center">
                        <Col xl={24} xs={24} sm={24} md={24} lg={24}>
                          <h2 className={classes.headerName1}>
                            Select the Amount
                          </h2>
                        </Col>
                      </Row>
                      <Row gutter={[24, 16]} justify="center">
                        <Col xl={12} xs={12} sm={12} md={12} lg={12}>
                          <Button
                            variant={
                              this.state.selectButtonAmount == 10.0
                                ? "contained"
                                : "outlined"
                            }
                            color="primary"
                            size="large"
                            style={{ width: "100%" }}
                            onClick={() => this.handleGiftCardAmount(10.0)}
                          >
                            $10.00
                          </Button>
                        </Col>
                        <Col xl={12} xs={12} sm={12} md={12} lg={12}>
                          <Button
                            variant={
                              this.state.selectButtonAmount == 20.0
                                ? "contained"
                                : "outlined"
                            }
                            color="primary"
                            size="large"
                            style={{ width: "100%" }}
                            onClick={() => this.handleGiftCardAmount(20.0)}
                          >
                            $20.00
                          </Button>
                        </Col>
                        <Col xl={8} xs={8} sm={6} md={8} lg={8}>
                          <Button
                            variant={
                              this.state.selectButtonAmount == 50.0
                                ? "contained"
                                : "outlined"
                            }
                            color="primary"
                            size="large"
                            style={{ width: "100%" }}
                            onClick={() => this.handleGiftCardAmount(50.0)}
                          >
                            $50.00
                          </Button>
                        </Col>
                        <Col xl={8} xs={8} sm={6} md={8} lg={8}>
                          <Button
                            variant={
                              this.state.selectButtonAmount == 80.0
                                ? "contained"
                                : "outlined"
                            }
                            color="primary"
                            size="large"
                            style={{ width: "100%" }}
                            onClick={() => this.handleGiftCardAmount(80.0)}
                          >
                            $80.00
                          </Button>
                        </Col>
                        <Col xl={8} xs={8} sm={6} md={8} lg={8}>
                          <Button
                            variant={
                              this.state.selectButtonAmount == 100.0
                                ? "contained"
                                : "outlined"
                            }
                            color="primary"
                            size="large"
                            style={{ width: "100%" }}
                            onClick={() => this.handleGiftCardAmount(100.0)}
                          >
                            $100.00
                          </Button>
                        </Col>
                        <Col xl={24} xs={24} sm={24} md={24} lg={24}>
                          <h2 className="sidebar-layout-header-name">OR</h2>
                        </Col>
                      </Row>
                      <Row gutter={[24, 0]} justify="center">
                        <Col xl={24} xs={24} sm={24} md={24} lg={24}>
                          <h2 className={classes.headerName}>
                            Custom Amount
                            <br />
                            <Typography className={classes.subtitleName}>
                              {"Tap below to enter the custom amount."}
                            </Typography>
                          </h2>
                          <TextField
                            style={{
                              marginTop: "1%",
                              marginBottom: "2%",
                              borderRadius: 0,
                              width: "96%",
                            }}
                            error={
                              this.state.errors.number.length == 0
                                ? false
                                : true
                            }
                            fullWidth
                            size="small"
                            helperText={this.state.errors.number}
                            label="* Enter an amount"
                            name="number"
                            onChange={this.handleInputChange}
                            onBlur={this.handleonBlurInputChange}
                            // onKeyPress= {(e) => {
                            //   if (e.key === 'Enter' && this.state.number !== "" && this.state.errors.number === "") {
                            //     this.rewadsEarn()
                            //   }
                            // }}
                            type="text"
                            value={this.state.number}
                            variant="outlined"
                          />
                        </Col>
                      </Row>
                      <br></br>
                      <Row gutter={[16, 16]} justify="space-between">
                        <Col xl={8} xs={8} sm={8} md={8} lg={8}>
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={this.backToHome}
                            style={{ marginBottom: "7px" }}
                          >
                            Go Home
                          </Button>
                        </Col>
                        <Col xl={8} xs={8} sm={8} md={8} lg={8}>
                          <Button
                            fullWidth
                            variant="contained"
                            style={{
                              backgroundColor: "#14ae5c",
                              color: "#fff",
                              marginBottom: "7px",
                            }}
                            onClick={() => this.handlenextLayout()}
                            disabled={
                              this.state.selectButtonAmount > 0.0
                                ? false
                                : this.state.number == "" ||
                                    this.state.errors.number.length != ""
                                  ? true
                                  : false
                            }
                          >
                            Continue
                          </Button>
                        </Col>
                      </Row>
                      <Footer />
                    </div>
                  ) : this.state.secondLayout ? (
                    <div
                      layout="vertical"
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Row gutter={[24, 0]} justify="center">
                        <Col xl={8} xs={24} sm={24} md={8} lg={8}>
                          <h2 className={classes.headerName1}>
                            Gift Card Code
                          </h2>
                        </Col>
                        <Col xl={16} xs={24} sm={24} md={16} lg={16}>
                          <Button
                            variant="contained"
                            style={{
                              width: "100%",
                              backgroundColor: "#757575",
                              color: "#fff",
                              fontSize: 22,
                              fontWeight: 'bold'
                            }}
                            size="large"
                          >
                            {giftCode}
                          </Button>
                        </Col>
                      </Row>
                      <br></br>
                      <Row gutter={[24, 0]} justify="center">
                        <Col xl={24} xs={24} sm={24} md={24} lg={24}>
                          <h2 className={classes.headerName}>
                            Email this Gift Card
                            {/* <br />
                            <Typography className={classes.subtitleName}>
                              {"Enter the email address of the recipient."}
                            </Typography> */}
                          </h2>
                          <TextField
                            style={{
                              marginTop: "1%",
                              marginBottom: "2%",
                              borderRadius: 0,
                              width: "96%",
                            }}
                            error={
                              this.state.errors.email.length == 0 ? false : true
                            }
                            fullWidth
                            size="small"
                            helperText={this.state.errors.email}
                            label="* Enter the email address of the recipient"
                            InputLabelProps={{
                              style: {
                                textOverflow: 'ellipsis',
                                fontSize: window.innerWidth <= 767 ? '13px': '',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                width: "92%",
                              } }}
                            name="email"
                            onChange={this.handleInputChange}
                            type="text"
                            value={this.state.email}
                            variant="outlined"
                          />
                        </Col>
                        <Col xl={24} xs={24} sm={24} md={24} lg={24}>
                          <h2 className="sidebar-layout-header-name">OR</h2>
                        </Col>
                        <Col xl={24} xs={24} sm={24} md={24} lg={24}>
                          <h2 className={classes.headerName}>
                            SMS this Gift Card
                            {/* <br />
                            <Typography className={classes.subtitleName}>
                              {"Enter the Phone Number of the recipient."}
                            </Typography> */}
                          </h2>
                          <TextField
                            style={{
                              marginTop: "1%",
                              marginBottom: "2%",
                              borderRadius: 0,
                              width: "96%",
                            }}
                            error={
                              this.state.errors.sms.length == 0 ? false : true
                            }
                            fullWidth
                            size="small"
                            helperText={this.state.errors.sms}
                            label="* Enter the Phone Number of the recipient"
                            InputLabelProps={{
                              style: {
                                textOverflow: 'ellipsis',
                                fontSize: window.innerWidth <= 767 ? '13px': '',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                width: "92%",
                              } }}
                            name="sms"
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputChange}
                            type="text"
                            value={this.state.sms}
                            variant="outlined"
                          />
                        </Col>
                        <Col xl={24} xs={24} sm={24} md={24} lg={24}>
                          <h2 className={classes.headerName}>
                            Custom Message
                            {/* <br />
                            <Typography className={classes.subtitleName}>
                              {"Enter the Custom Message of the recipient."}
                            </Typography> */}
                          </h2>
                          <TextField
                            style={{
                              marginTop: "1%",
                              marginBottom: "2%",
                              borderRadius: 0,
                              width: "96%",
                            }}
                            error={
                              this.state.errors.message.length == 0
                                ? false
                                : true
                            }
                            fullWidth
                            size="small"
                            helperText={this.state.errors.message}
                            label="Enter the Custom Message of the recipient"
                            InputLabelProps={{
                              style: {
                                textOverflow: 'ellipsis',
                                fontSize: window.innerWidth <= 767 ? '13px': '',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                width: "92%",
                              } }}
                            name="message"
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputChange}
                            type="text"
                            value={this.state.message}
                            variant="outlined"
                          />
                        </Col>
                      </Row>
                      <br></br>
                      <Row gutter={[16, 16]} justify="space-between">
                        <Col xl={8} xs={8} sm={7} md={8} lg={8}>
                          <Button
                            fullWidth
                            variant="contained"
                            style={{
                              backgroundColor: "#9747ff",
                              color: "#fff",
                              marginBottom: "7px",
                            }}
                            onClick={() => {
                              this.setState({
                                firstLayout: true,
                                secondLayout: false,
                                generateGiftNumber: 0,
                              });
                            }}
                          >
                            Back
                          </Button>
                        </Col>
                        <Col xl={8} xs={8} sm={7} md={8} lg={8}>
                          <Button
                            fullWidth
                            variant="contained"
                            style={{
                              backgroundColor: "#14ae5c",
                              color: "#fff",
                              marginBottom: "7px",
                            }}
                            onClick={() => this.handleSubmit()}
                            disabled={
                              this.state.errors.email.length != "" ||
                              this.state.errors.sms.length != ""
                            }
                          >
                            Continue
                          </Button>
                        </Col>
                      </Row>
                      <Footer />
                    </div>
                  ) : (
                    <div
                      layout="vertical"
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Row gutter={[24, 0]} justify="center">
                        <Col xl={24} xs={24} sm={24} md={24} lg={24}>
                          <h2 className={classes.headerName1}>Card Details</h2>
                        </Col>
                        <Col xl={24} xs={24} sm={24} md={24} lg={24}>
                          <Card
                            number={this.state.cardNumber}
                            name={this.state.name}
                            expiry={this.state.expiry}
                            cvc={this.state.cvc}
                            focused={this.state.focused}
                            placeholders={{ name: "" }}
                            callback={this.handleCallback}
                          />
                          <TextField
                            autoFocus
                            style={{
                              margin: "2%",
                              marginTop: "5%",
                              borderRadius: 0,
                              width: "96%",
                            }}
                            error={
                              this.state.cardErrors.cardNumber.length == 0
                                ? false
                                : true
                            }
                            fullWidth
                            size="small"
                            helperText={this.state.cardErrors.cardNumber}
                            label="Card Number *"
                            name="cardNumber"
                            onChange={this.handleCardInputChange}
                            onBlur={this.handleCardInputChange}
                            onFocus={this.handleInputFocus}
                            inputProps={{
                              onKeyPress: this._handleKeyPress,
                              pattern: "[d| ]{16,22}",
                            }}
                            inputRef={(ref) => this.inputRefs.push(ref)}
                            type="tel"
                            value={this.state.cardNumber}
                            variant="outlined"
                          />
                        </Col>
                      </Row>
                      <br></br>
                      <Row gutter={[24, 6]} justify="center">
                        <Col xl={12} xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            style={{
                              margin: "2%",
                              borderRadius: 0,
                              width: "96%",
                            }}
                            error={
                              this.state.cardErrors.expiry.length == 0
                                ? false
                                : true
                            }
                            fullWidth
                            size="small"
                            helperText={this.state.cardErrors.expiry}
                            label="Valid Thru *"
                            name="expiry"
                            onChange={this.handleCardInputChange}
                            onBlur={this.handleCardInputChange}
                            onFocus={this.handleInputFocus}
                            inputProps={{
                              onKeyPress: this._handleKeyPress,
                              pattern: "dd/dd",
                            }}
                            ref={"input2"}
                            inputRef={(ref) => this.inputRefs.push(ref)}
                            type="tel"
                            value={this.state.expiry}
                            variant="outlined"
                          />
                        </Col>
                        <Col xl={12} xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            style={{
                              margin: "2%",
                              borderRadius: 0,
                              width: "96%",
                            }}
                            error={
                              this.state.cardErrors.cvc.length == 0
                                ? false
                                : true
                            }
                            fullWidth
                            size="small"
                            helperText={this.state.cardErrors.cvc}
                            label="CVC *"
                            name="cvc"
                            onChange={this.handleCardInputChange}
                            onBlur={this.handleCardInputChange}
                            onFocus={this.handleInputFocus}
                            inputProps={{ pattern: "d{3,4}" }}
                            inputRef={(ref) => this.inputRefs.push(ref)}
                            onKeyPress={(e) => {
                              if (
                                e.key === "Enter" &&
                                this.state.cardNumber !== "" &&
                                this.state.cardErrors.cardNumber.length === 0 &&
                                this.state.expiry !== "" &&
                                this.state.cardErrors.expiry.length === 0 &&
                                this.state.cvc !== "" &&
                                this.state.cardErrors.cvc.length === 0
                              ) {
                                this.saveCardDetails();
                              }
                            }}
                            ref={"input3"}
                            type="tel"
                            value={this.state.cvc}
                            variant="outlined"
                          />
                        </Col>
                      </Row>
                      <br></br>
                      <Row gutter={[24, 0]} justify="center">
                        <Col xl={12} xs={24} sm={24} md={12} lg={12}>
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              this.props.clearItems();
                              this.setState({
                                firstLayout: false,
                                secondLayout: true,
                              });
                            }}
                            style={{ marginBottom: "7px" }}
                            disabled={this.state.loading}
                          >
                            Back
                          </Button>
                        </Col>
                        <Col xl={12} xs={24} sm={24} md={12} lg={12}>
                          <Button
                            variant="contained"
                            fullWidth
                            color="primary"
                            onClick={() => this.saveCardDetails()}
                            disabled={PaybuttonDisable || this.state.loading}
                            style={{ marginBottom: "7px" }}
                          >
                            {"Submit & Pay      " +
                              "( $ " +
                              this.props.order.balance.toFixed(2) +
                              " )"}
                          </Button>
                        </Col>
                      </Row>
                      <Footer />
                    </div>
                  )}
                </Col>
              </div>
            </Row>
          </Content>
        </Layout>
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      currentItem,
      removeCurrentItem,
      orderCalculation,
      insertItem,
      updateOrdertype,
      orderInstruction,
      addExtraItem,
      removeUserCardDetails,
      userLogout,
      clearItems,
      addUserCardDetails,
      addPaymentCardId,
      orderUser,
      removeLocations,
      removeDeliveryPickupDetails,
      removeFeaturedProducts,
      removeProducts,
      removeOrderUser,
      completeOrder,
    },
    dispatch,
  );
}
function mapStateToProps(state) {
  return {
    current_item: state.current_item,
    locations: state.locations,
    delivery_pickup: state.delivery_pickup,
    order: state.order,
    order_items: state.order_items,
    user: state.user,
    card_details: state.card_details,
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(GiftCardSell);
