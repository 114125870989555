import {
  Accordion, AccordionSummary, Button as MButton, Grid, List, ListItem, ListItemText, Paper, Tab, Tabs, Typography as MTypography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AccountBoxSharpIcon from "@material-ui/icons/AccountBoxSharp";
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import CommentIcon from "@material-ui/icons/Comment";
import CreditCardSharpIcon from "@material-ui/icons/CreditCardSharp";
import { Col, Row } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { removeUserCardDetails } from "../../../../actions/card_Details_action";
import { removeDeliveryPickupDetails } from "../../../../actions/delivery_pickup_action";
import { removeLocations } from "../../../../actions/location_action";
import {
  removeFeaturedProducts, removeProducts
} from "../../../../actions/menu_items";
import { removeOrderUser, userLogout } from "../../../../actions/user_actions";

const useStyles = (theme) => ({
  expanIcon: {
    flexBasis: "10%",
    flexShrink: 0,
    color: "#3950b1",
    [theme.breakpoints.down('sm')]: {
      flexBasis: "15%",
    },
    [theme.breakpoints.up('sm')]: {
      flexBasis: "10%",
    },
    ['@media (device-width: 540px)']: {
      flexBasis: "10%",
    },
  },
  flexLayout: {
    flex: 10,
    flexDirection: "row",
    alignItems: "stretch",
    overflow: "hidden",
    marginBottom: 7,
    overflowY: "scroll",
  },
  buttonLayout: {
    display: "flex",
    flex: 0.6,
    padding: 8,
    justifyContent: "center",
    backgroundColor: "#fff",
  },
  adressText: {
    textAlign: "center",
    color: "#c6c5c6",
    fontSize: "15px",
    fontWeight: 500,
  },
  adressText1: {
    textAlign: "center",
    color: "#000",
    fontSize: "15px",
  },
  addressLayout: {
    padding: "2%",
  },
  headerName: { fontSize: 17, fontWeight: "bold" },
  subheaderLength: { fontSize: 15 },
  text: {
    fontWeight: "bold",
    fontSize:'1.3em',//Insert your required size
  }
});
function TabContainer(props) {
  return (
    <MTypography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </MTypography>
  );
}
class OrderStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      fullDate: "",
      payload: this.props.payload,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {}

  renderOrderItem = (item) => {
    var final = "";
    var single_price = "";
    if (item.is_modifier_product) {
      item.modifier_set.map((single, index) => {
        if (single.selected_modifiers.length > 0) {
          single_price = single.selected_modifiers
            .map((item) => item.name)
            .reduce((prev, next) => prev + ", " + next);
          if (single_price != "") {
            if (final == "") {
              final = final + "" + single_price;
            } else {
              final = final + ", " + single_price;
            }
          }
        }
      });
    }
    if (final) {
      final = item.name + " (" + final + ")";
      return (
        <ListItemText
         primary={final}
         style={{ textAlign: "justify" }}
        />
      );
    } else {
      return (
        <ListItemText
         primary={item.name}
         style={{ textAlign: "justify" }}
        />
      );
    }
  };

  handleTabChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  changeLayout = (layout) => {
    this.props.checkOutInsidePage(layout);
  };

  render() {
    let weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ][new Date().getDay()];
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][new Date().getMonth()];
    var current_date = new Date();
    let date = current_date.getDate();
    let full_year = current_date.getFullYear();
    let full_Data = "";
    // let last_refreshed_at = moment().format("HH:mm:ss");
    if (
      null != this.props.payload &&
      undefined != this.props.payload &&
      "" != this.props.payload
    ) {
      full_Data =
        weekday + ", " + monthNames + " " + date + ", " + full_year + ", ";
    }
    let time =
      this.props.payload.order_online_details.dispatch_slot_start_time.slice(
        10
      ) +
      " - " +
      this.props.payload.order_online_details.dispatch_slot_end_time.slice(10);

    const { classes } = this.props;
    return (
      <>
        <Paper elevation={1} square className={classes.flexLayout}>
          <Row justify="center">
            <h1 className="sidebar-layout-header-name">Order Status</h1>
          </Row>

          <Row justify="center">
            <Tabs
              value={this.state.value}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              {this.props.delivery_pickup[0].order_type === "delivery" ? (
                <Tab label="Delivery Details" />
              ) : (
                <Tab label="Pickup Details" />
              )}
              <Tab label="Receipt" />
            </Tabs>
          </Row>
          {this.state.value === 0 && (
            <div style={{ paddingTop: "2%" }}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <MTypography
                    style={{
                      textAlign: "center",
                      color: "#000",
                      fontSize: "20px",
                      fontWeight: 500,
                    }}
                  >
                    {this.props.delivery_pickup[0].order_type === "delivery"
                      ? "Delivery Order Place"
                      : "Pickup Order Place"}
                  </MTypography>
                </Grid>
                <Grid item>
                  <MTypography className={classes.adressText}>
                    {this.props.delivery_pickup[0].order_type === "delivery"
                      ? "Estimated Delivery Time"
                      : "Estimated Pickup Time"}
                  </MTypography>
                </Grid>
                <Grid item>
                  <MTypography
                    style={{
                      textAlign: "center",
                      color: "#000",
                      fontSize: "17px",
                      fontWeight: "bold",
                    }}
                  >
                    {full_Data}
                    <br />
                    {time}
                  </MTypography>
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.addressLayout}
              >
                <Grid item style={{ marginBottom: "3%" }}>
                  <MTypography className={classes.adressText1}>
                    {this.props.delivery_pickup[0].order_type === "delivery"
                      ? "Delivery To"
                      : "Pickup From"}
                  </MTypography>
                </Grid>
                <Grid item>
                  <MTypography className={classes.adressText1}>
                    {this.props.delivery_pickup[0].address +
                      " " +
                      this.props.delivery_pickup[0].city +
                      ", "}
                  </MTypography>
                </Grid>
                <Grid item>
                  {" "}
                  <MTypography className={classes.adressText1}>
                    {this.props.delivery_pickup[0].state +
                      " " +
                      this.props.delivery_pickup[0].zipCode}
                  </MTypography>
                </Grid>
              </Grid>

              <Accordion
                expanded={this.state.expanded === "panel1"}
                // onChange={this.handleChange("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <CommentIcon className={classes.expanIcon} fontSize="large" />
                  <Col xs="24" xl={24} style={{ marginLeft: "5%" }}>
                    <MTypography className={classes.headerName}>
                      Instructions
                    </MTypography>
                    <MTypography className={classes.subheaderLength}>
                      {null != this.props.payload &&
                      undefined != this.props.payload &&
                      "" != this.props.payload
                        ? null !=
                            this.props.payload.order_online_details
                              .dispatch_instruction &&
                          undefined !=
                            this.props.payload.order_online_details
                              .dispatch_instruction &&
                          "" !=
                            this.props.payload.order_online_details
                              .dispatch_instruction
                          ? this.props.payload.order_online_details
                              .dispatch_instruction
                          : "No notes for order"
                        : "No notes for order"}
                    </MTypography>
                  </Col>
                </AccordionSummary>
              </Accordion>

              <Accordion
                expanded={this.state.expanded === "panel2"}
                // onChange={this.handleChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <AccountBoxSharpIcon
                    className={classes.expanIcon}
                    fontSize="large"
                  />
                  <Col xs="24" xl={24} style={{ marginLeft: "5%" }}>
                    <MTypography className={classes.headerName}>
                      Who is this for?
                    </MTypography>
                    {this.props.user.whoIsThis != null &&
                    this.props.user.whoIsThis != undefined &&
                    this.props.user.whoIsThis != "" ? (
                      <MTypography className={classes.subheaderLength}>
                        {this.props.user.whoIsThis.first_name +
                          " " +
                          this.props.user.whoIsThis.last_name}
                        <br />
                        {this.props.user.whoIsThis.phone}
                      </MTypography>
                    ) : this.props.user.user != null &&
                      this.props.user.user != undefined &&
                      this.props.user.user != "" ? (
                      <MTypography >
                        {this.props.user.user.first_name + " " +
                          this.props.user.user.last_name}
                        <br />
                        {this.props.user.user.phone}
                      </MTypography>
                    ) : null}
                  </Col>
                </AccordionSummary>
              </Accordion>
              {this.props.payload.payment.length == 2 ? (
                <>
                <Accordion
                expanded={this.state.expanded === "panel2"}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <CreditCardSharpIcon
                    className={classes.expanIcon}
                    fontSize="large"
                  />
                  <Col xs="24" xl={24} style={{ marginLeft: "5%" }}>
                    <MTypography className={classes.headerName}>
                      Paid with Card
                    </MTypography>
                    <MTypography className={classes.subheaderLength}>
                      {null != this.props.payload &&
                      undefined != this.props.payload &&
                      "" != this.props.payload
                        ? "$" +
                            parseFloat(this.props.payload.payment[1].amount +
                                this.props.payload.payment[1].tips_amount).toFixed(2)
                        : "$0.00"}
                    </MTypography>
                  </Col>
                </AccordionSummary>
              </Accordion>
              <Accordion
                expanded={this.state.expanded === "panel2"}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <CardGiftcardIcon
              className={classes.expanIcon}
              fontSize="large"
            />
                  <Col xs="24" xl={24} style={{ marginLeft: "5%" }}>
                    <MTypography className={classes.headerName}>
                      Paid with Gift Card
                    </MTypography>
                    <MTypography className={classes.subheaderLength}>
                      {null != this.props.payload &&
                      undefined != this.props.payload &&
                      "" != this.props.payload
                        ? "$" +
                            parseFloat(this.props.payload.payment[0].amount +
                                this.props.payload.payment[0].tips_amount).toFixed(2)
                        : "$0.00"}
                    </MTypography>
                  </Col>
                </AccordionSummary>
              </Accordion>
                </>
              ) : (
                <Accordion expanded={this.state.expanded === "panel2"}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  {this.props.payload.payment[0].type == "GIFT_CARD_PAYMENT" ? (
                    <CardGiftcardIcon
              className={classes.expanIcon}
              fontSize="large"
            />
                  ) : (
                <CreditCardSharpIcon
                    className={classes.expanIcon}
                    fontSize="large"
                  />
                  )}
                  
                  <Col xs="24" xl={24} style={{ marginLeft: "5%" }}>
                    <MTypography className={classes.headerName}>
                    {this.props.payload.payment[0].type == "GIFT_CARD_PAYMENT" ? "Paid with Gift Card" : "Paid with Card"}
                    </MTypography>
                    <MTypography className={classes.subheaderLength}>
                      {null != this.props.payload &&
                      undefined != this.props.payload &&
                      "" != this.props.payload
                        ? "$" +
                            parseFloat(this.props.payload.payment[0].amount +
                                this.props.payload.payment[0].tips_amount).toFixed(2)
                        : "$0.00"}
                    </MTypography>
                  </Col>
                </AccordionSummary>
              </Accordion>
              )} 
            </div>
          )}
          {this.state.value === 1 && (
            <div style={{ paddingTop: "2%" }}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <MTypography
                    style={{
                      textAlign: "center",
                      color: "#000",
                      fontSize: "20px",
                      fontWeight: 500,
                    }}
                  >
                    {this.state.payload.local_id}
                  </MTypography>
                </Grid>
                <Grid item>
                  <MTypography
                    style={{
                      textAlign: "center",
                      color: "#000",
                      fontSize: "17px",
                      fontWeight: "bold",
                    }}
                  >
                    {full_Data}
                    <br />
                    {time}
                  </MTypography>
                </Grid>
              </Grid>
              <Col xs="24" xl={24} style={{ marginLeft: "5%" }}>
                <List className="item-list-order-status">
                  {this.state.payload.lines.map((item, index) => (
                    <React.Fragment key={index}>
                      <ListItem>
                        <Grid container spacing={1}>
                          <Grid item xs={2}>
                            <ListItemText>{item.quantity}</ListItemText>
                          </Grid>
                          <Grid item xs={6}>
                            {this.renderOrderItem(item)}
                          </Grid>
                          <Grid item xs={4}>
                            <ListItemText style={{ textAlign: "center" }}>
                              {item.online_ordering_price != null &&
                              undefined != item.online_ordering_price &&
                              "" != item.online_ordering_price
                                ? "$" + parseFloat(item.online_ordering_price).toFixed(2)
                                : "$" + parseFloat(item.sale_price).toFixed(2)}
                            </ListItemText>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </React.Fragment>
                  ))}
                  <React.Fragment>
                    <ListItem>
                      <Grid container spacing={1}>
                        <Grid item xs={9}>
                          <ListItemText classes={{ primary: classes.text }}>Total</ListItemText>
                        </Grid>
                        <Grid item xs={3}>
                          <ListItemText
                            primary={"$" + parseFloat(this.props.payload.payment[0].amount +
                              this.props.payload.payment[0].tips_amount).toFixed(2)}
                            // primary={"$" + parseFloat(this.state.payload.balance).toFixed(2)}
                            classes={{ primary: classes.text }}
                            // classes={classes.textotal}
                            // style={{ textAlign: "justify",fontWeight: "bold" }}
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                  </React.Fragment>
                </List>
              </Col>
            </div>
          )}
        </Paper>
        <Paper elevation={1} square className={classes.buttonLayout}>
          <Route
            render={({ history }) => (
              <MButton
                variant="contained"
                color="primary"
                style={{ width: "100%", height: "93%" }}
                onClick={() => {
                  this.props.removeOrderUser();
                  this.props.removeUserCardDetails();
                  this.props.removeProducts();
                  this.props.removeFeaturedProducts();
                  this.props.removeDeliveryPickupDetails();
                  this.props.removeLocations();
                  history.push("/" + localStorage.getItem("tenant"));
                }}
              >
                Done
              </MButton>
            )}
          />
        </Paper>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeProducts,
      removeDeliveryPickupDetails,
      removeLocations,
      userLogout,
      removeOrderUser,
      removeUserCardDetails,
      removeFeaturedProducts,
    },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    user: state.user,
    delivery_pickup: state.delivery_pickup,
    card_details: state.card_details,
    payments: state.payments,
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true })
)(OrderStatus);
