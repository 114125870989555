import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import { withStyles } from "@material-ui/core/styles";
import {
  currentItem,
  removeCurrentItem,
} from "../../../../actions/current_item";
import { insertItem } from "../../../../actions/order_items";
import { orderCalculation } from "../../../../actions/order_actions";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import moment from "moment";
import {
  Paper,
  Grid,
  Typography as MTypography,
  Button as MButton,
  ButtonGroup as MButtonGroup,
  AccordionSummary,
  Accordion,
  Checkbox,
  TextField,
} from "@material-ui/core";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import * as Constant from "../../../../constants";
import { updateScheduleTime } from "../../../../actions/delivery_pickup_action";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = (theme) => ({
  expanIcon: {
    flexBasis: "10%",
    flexShrink: 0,
    color: "#3950b1",
    [theme.breakpoints.down('sm')]: {
      flexBasis: "15%",
    },
    [theme.breakpoints.up('sm')]: {
      flexBasis: "10%",
    },
    ['@media (device-width: 540px)']: {
      flexBasis: "10%",
    },
  },
  flexLayout: {
    flex: 10,
    flexDirection: "row",
    alignItems: "stretch",
    overflow: "hidden",
    marginBottom: 7,
    padding: 13,
    overflowY: "scroll",
  },
  buttonGroupLayout: {
    marginTop: "5%",
    marginBottom: "5%",
  },
  headerName: { fontSize: 17, fontWeight: "bold" },
  subheaderLength: { fontSize: 15 },
  buttonLayout: {
    display: "flex",
    flex: 0.6,
    padding: 8,
    justifyContent: "center",
    backgroundColor: "#fff",
  },
  subLayout: {
    marginTop: "2%",
    marginBottom: "4%",
  },
});

class DeliveryTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      item_qty: 1,
      pickUpAddress: "",
      isDelivery: false,
      isPickUp: false,
      changePickup: false,
      zipCode: "",
      timeSlot: [],
      deliveryTime: "",
      currentTime: "",
      deliveryLocation: {},
    };
  }

  changeDelivery = () => {};
  componentDidMount() {
    if (
      null !== this.props.delivery_pickup &&
      undefined !== this.props.delivery_pickup &&
      this.props.delivery_pickup.length > 0
    ) {
      let time = "";
      this.props.delivery_pickup[0].location.location_online_order_settings.map(
        (settings) => {
          settings.location_hrs_sub_slot.map((time_slot) => {
            if (time_slot.id == this.props.delivery_pickup[0].DeliveryTime) {
              let deliverytime =
                time_slot.from_time + " - " + time_slot.to_time;
              time = deliverytime;
            }
          });
        }
      );
      this.setState({
        isDelivery:
          this.props.delivery_pickup[0].order_type === "delivery"
            ? true
            : false,
        isPickUp:
          this.props.delivery_pickup[0].order_type === "pickup" ? true : false,
        currentTime: time,
        zipCode: this.props.delivery_pickup[0].zipCode,
      });

      if (this.props.delivery_pickup[0].order_type === "delivery") {
        this.setTimeSlotDelivery(this.props.delivery_pickup[0].zipCode);
      } else if (this.props.delivery_pickup[0].order_type === "pickup") {
        this.setTimeSlotPickup(this.props.delivery_pickup[0].zipCode);
      }
    }
  }

  componentWillUnmount() {}

  selectDeliveryTime = (newValue) => {
    if(null != newValue) {
      this.setState({ deliveryTime: newValue.id });
    }else{
      this.setState({ deliveryTime: "" });

    }
  };

  setTimeSlotPickup = (zipcode) => {
    this.props.locations.allLocations.map((location) => {
      if (location.zipcode == zipcode) {
        location.location_online_order_settings.map((online_settings) => {
          let final_time;
          let addtimeToday;
          if (this.props.delivery_pickup[0].order_type === "pickup") {
            let array = online_settings.pickup_lead_time.split(":");
            addtimeToday = moment()
              .add(array[2], "seconds")
              .add(array[1], "minutes")
              .add(array[0], "hours")
              .format("ddd");
            final_time = moment()
              .add(array[2], "seconds")
              .add(array[1], "minutes")
              .add(array[0], "hours")
              .format("hh:mm A");
          }
          if (online_settings.location_hrs_sub_slot.length > 0) {
            let time_slot = [];
            if (
              online_settings.location_hrs_sub_slot[0].day ==
              addtimeToday.toUpperCase()
            ) {
              online_settings.location_hrs_sub_slot.map((timeSlot) => {
                let dateIsAfter = moment(timeSlot.from_time, [
                  "h:mm A",
                ]).isAfter(moment(final_time, ["h:mm A"]));
                if (dateIsAfter) {
                  var obj_time = {};
                  obj_time.time = timeSlot.from_time + " - " + timeSlot.to_time;
                  obj_time.id = timeSlot.id;
                  time_slot.push(obj_time);
                }
              });
              if (time_slot.length != 0) {
                this.setState({ timeSlot: time_slot });
              }
            } else {
              toast.error(Constant.PICKUP_TIME_SLOTE_NOT_FOUND);
              this.setState({ timeSlot: [] });
            }
          }
        });
      }
    });
  };

  setTimeSlotDelivery = (zipcode) => {
    let j = 1;
    let without_delivery = 0;
    let deliverylocation = "";
    let deliveryZip = "";
    this.props.locations.allLocations.map((location) => {
      if (j === 1) {
        location.location_online_order_settings.map((location_settings) => {
          if (location_settings.is_delivery == 1) {
            if (location_settings.delivery_zips.length > 0) {
              location_settings.delivery_zips.map((zips) => {
                if (zips.zipcode.toString() == zipcode) {
                  deliveryZip = zips.zipcode;
                  deliverylocation = location;
                  let final_time;
                  let addtimeToday;
                  if (this.props.delivery_pickup[0].order_type === "delivery") {
                    let array = location_settings.delivery_lead_time.split(":");
                    addtimeToday = moment()
                      .add(array[2], "seconds")
                      .add(array[1], "minutes")
                      .add(array[0], "hours")
                      .format("ddd");
                    final_time = moment()
                      .add(array[2], "seconds")
                      .add(array[1], "minutes")
                      .add(array[0], "hours")
                      .format("hh:mm A");
                  }
                  if (location_settings.location_hrs_sub_slot.length > 0) {
                    let time_slot = [];
                    let i = 1;
                    if (
                      location_settings.location_hrs_sub_slot[0].day ==
                      addtimeToday.toUpperCase()
                    ) {
                      location_settings.location_hrs_sub_slot.map(
                        (timeSlot) => {
                          let dateIsAfter = moment(timeSlot.from_time, [
                            "h:mm A",
                          ]).isAfter(moment(final_time, ["h:mm A"]));
                          if (dateIsAfter) {
                            if (i == 1) {
                              this.setState({ asapTimeSlot: timeSlot.id });
                              i = 0;
                            }
                            var obj_time = {};
                            obj_time.time =
                              timeSlot.from_time + " - " + timeSlot.to_time;
                            obj_time.id = timeSlot.id;
                            // let obj = {
                            //   id: timeSlot.id,
                            //   time:
                            //     timeSlot.from_time +
                            //     " - " +
                            //     timeSlot.to_time,
                            // };
                            time_slot.push(obj_time);
                          }
                        }
                      );
                      if (
                        null != zipcode &&
                        undefined != zipcode &&
                        "" != zipcode &&
                        time_slot.length != 0
                      ) {
                        this.setState({
                          timeSlot: time_slot,
                          deliveryLocation: location,
                        });
                      } else {
                        toast.error(Constant.DELIVERY_TIME_SLOTE_NOT_FOUND);
                        this.setState({
                          timeSlot: [],
                          deliveryLocation: location,
                        });
                      }
                    } else {
                      toast.error(Constant.DELIVERY_TIME_SLOTE_NOT_FOUND);
                      this.setState({
                        timeSlot: [],
                        deliveryLocation: location,
                      });
                    }
                  }
                  j = 0;
                }
              });
            }
          } else {
            without_delivery += 1;
          }
        });
      }
    });
  };

  handleChangePickUp = () => {
    if (this.state.changePickup) {
      this.setState({ changePickup: false, deliveryTime: "" });
    } else {
      this.setState({ changePickup: true });
    }
  };

  goBack = (type) => {
    this.props.checkOutInsidePage(type);
  };

  changeDeliveryPickupTime = () => {
    if (this.state.changePickup && this.state.deliveryTime != "") {
      let delivery_Location = {};
      if (this.props.delivery_pickup[0].order_type === "delivery") {
        if (
          null != this.state.deliveryLocation &&
          undefined != this.state.deliveryLocation &&
          "" != this.state.deliveryLocation
        ) {
          let deliverydetails = {
            order_type: this.props.delivery_pickup[0].order_type,
            location: this.state.deliveryLocation,
            address: this.props.delivery_pickup[0]?.address,
            city: this.props.delivery_pickup[0]?.city,
            state: this.props.delivery_pickup[0]?.state,
            country: this.props.delivery_pickup[0]?.country,
            zipCode: this.props.delivery_pickup[0]?.zipCode,
            scheduleOrder: this.props.delivery_pickup[0].scheduleOrder,
            DeliveryTime: this.state.deliveryTime,
          };
          this.props.updateScheduleTime(deliverydetails);
          this.goBack(this.props.layout);
        }
      } else if (this.props.delivery_pickup[0].order_type === "pickup") {
        this.props.locations.allLocations.map((location) => {
          if (location.zipcode == this.props.delivery_pickup[0].zipCode) {
            delivery_Location = location;
          }
        });
        let deliverydetails = {
          order_type: this.props.delivery_pickup[0].order_type,
          location: delivery_Location,
          address: delivery_Location.address1,
          city: delivery_Location.city,
          state: delivery_Location.state,
          zipCode: delivery_Location.zipcode,
          country: delivery_Location.country,
          scheduleOrder: this.props.delivery_pickup[0].scheduleOrder,
          DeliveryTime: this.state.deliveryTime,
        };
        this.props.updateScheduleTime(deliverydetails);
        this.goBack(this.props.layout);
      }
    } else {
      toast.error(Constant.SELECT_TIME_SLOTE);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Paper elevation={1} square className={classes.flexLayout}>
          <Grid
            container
            direction="row"
            // justifyContent="center"
            alignItems="center"
          >
            <Grid xs={2} md={2} lg={2} item>
            <Link  to="#" className="text-dark font-bold">
              <ArrowBackIcon onClick={() => this.goBack(this.props.layout)} />
              </Link>
            </Grid>
            <Grid xs={8} md={8} lg={8} item>
              <h1 className="sidebar-layout-header-name">
                {this.state.isDelivery ? "Delivery Time" : "Pickup Time"}
              </h1>
            </Grid>
          </Grid>

          <Row
            gutter={[24, 0]}
            justify="center"
            className={classes.buttonGroupLayout}
          >
            <MButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <MButton
                variant={this.state.isDelivery ? "contained" : ""}
                color={this.state.isDelivery ? "primary" : ""}
                // onClick={() => this.changeDelivery("Delivery")}
              >
                Delivery
              </MButton>
              <MButton
                variant={this.state.isPickUp ? "contained" : ""}
                color={this.state.isPickUp ? "primary" : ""}
                // onClick={() => this.changeDelivery("PickUp")}
              >
                Pick Up
              </MButton>
            </MButtonGroup>
          </Row>

          <Accordion expanded={this.state.expanded === "panel2"}>
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <WatchLaterIcon className={classes.expanIcon} fontSize="large" />
              <Col xs="24" xl={24} style={{ marginLeft: "5%" }}>
                <MTypography className={classes.headerName}>
                  {this.state.isDelivery ? "Delivery Time" : "Pickup Time"}
                </MTypography>
                <MTypography className={classes.subheaderLength}>
                  {" "}
                  {moment().format("ddd") +
                    "," +
                    moment().format("MMMM Do YYYY")}
                  <br />
                  {this.state.currentTime}
                </MTypography>
              </Col>
            </AccordionSummary>
            {/* <AccordionDetails></AccordionDetails> */}
          </Accordion>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            className={classes.subLayout}
          >
            <Grid item xs={2}>
              <Checkbox
                checked={this.state.changePickup}
                onChange={() => this.handleChangePickUp()}
                name="Do you want to change your pick up address?"
                color="primary"
              />
            </Grid>
            <Grid item xs={10}>
              <MTypography >
                {this.state.isDelivery
                  ? "Do you want to change your delivery time?"
                  : "Do you want to change your pickup time?"}
              </MTypography>
            </Grid>
          </Grid>

          {this.state.changePickup ? (
            <>
              {this.state.timeSlot.length > 0 ? (
                (this.props.delivery_pickup[0].order_type === "delivery") ? 
                <Autocomplete
                  id="size-small-outlined"
                  size="small"
                  options={this.state.timeSlot}
                  style={{ marginTop: "5px" }}
                  getOptionLabel={(option) => option.time}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Delivery time"
                      placeholder="Select Delivery time"
                    />
                  )}
                  onChange={(event, newValue) => {
                    this.selectDeliveryTime(newValue);
                  }}
                /> :
                <Autocomplete
                  id="size-small-outlined"
                  size="small"
                  options={this.state.timeSlot}
                  style={{ marginTop: "5px" }}
                  getOptionLabel={(option) => option.time}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Pickup time"
                      placeholder="Select Pickup time"
                    />
                  )}
                  onChange={(event, newValue) => {
                    this.selectDeliveryTime(newValue);
                  }}
                />
              ) : <MTypography variant="subtitle2"  style={{ marginTop:'1%',color:'red',whiteSpace: 'pre-wrap'}}>
              {Constant.DAY_CHANGE_TIME_SLOTE_NOT_AVAILABLE}
            </MTypography>}
            </>
          ) : null}
        </Paper>
        <Paper elevation={1} square className={classes.buttonLayout}>
          <MButton
            variant="contained"
            color="primary"
            style={{ width: "100%", height: "93%" }}
            onClick={() => this.changeDeliveryPickupTime()}
            disabled={
              this.state.changePickup == false || this.state.deliveryTime == ""
            }
          >
            Submit
          </MButton>
        </Paper>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      currentItem,
      removeCurrentItem,
      orderCalculation,
      insertItem,
      updateScheduleTime,
    },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    current_item: state.current_item,
    locations: state.locations,
    delivery_pickup: state.delivery_pickup,
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true })
)(DeliveryTime);
