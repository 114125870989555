
export const CURRENT_ITEMS = 'CURRENT_ITEMS';
export const REMOVE_CURRENT_ITEM = 'REMOVE_CURRENT_ITEM';

export function currentItem(results) {
    let result1 = [results]
    return {
        type: CURRENT_ITEMS,
        payload: result1
    };

}

export function removeCurrentItem() {
    return {

        type: REMOVE_CURRENT_ITEM,
        payload: []
    };
}