import { combineReducers } from 'redux'
import order_items from './reducer_order_items'
import featured_item from './reducer_category_items'
import order from './reducer_order'
import category_products from './reducer_category_products'
import user from './reducer_users'
import payments from './reducer_payments'
import rewards from './reducer_reward'
import taxes from './reducer_taxes'
import coupon_status from './reducer_coupons'
import ticket from './reducer_ticket'
import weight from './reducer_weight'
import current_item from './reducer_current_item'
import delivery_pickup from './reducer_delivery_pickup'
import locations from './reducer_locations'
import card_details from './reducer_card_details'
import * as storage from 'redux-storage'

const rootReducer = storage.reducer(
  combineReducers({
    order_items,
    featured_item,
    order,
    category_products,
    card_details,
    user,
    current_item,
    delivery_pickup,
    locations,
    payments,
    rewards,
    taxes,
    coupon_status,
    ticket,
    weight,
  })
)

export default rootReducer
