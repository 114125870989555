import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Row, Col, Typography } from "antd";
import { Button, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import * as Constant from "../constants";
import { userSignIn } from "../services/user/user";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userLogin } from "../actions/user_actions";
import { removeProducts, removeFeaturedProducts } from "../actions/menu_items";
import { removeDeliveryPickupDetails } from "../actions/delivery_pickup_action";
import { removeLocations } from "../actions/location_action";
import { clearItems } from "../actions/order_items";
import { LinearProgress } from "@material-ui/core";
import logo from "../assets/images/Octopos-logo-blue.png";
import Footer from "../../src/components/layout/Footer";
import sideImage from "../assets/images/auth13.jpg"

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const { Title } = Typography;
const { Header, Content } = Layout;
const styles = (theme) => ({
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
      width: "0%",
    },
  },
  imageLogo:{
    [theme.breakpoints.down("md")]: {
      textAlign:'center',
    },
    // textAlign:'center',
    paddingBottom:16,
    width:"50%"
  }
});

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView();

  return { executeScroll, elRef };
};

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
      image_loc:'',
      backgroundImage:"",
      errors: {
        email: "",
        password: "",
      },
    };
    this.inputRefs = []
    this.elScroll = utilizeScroll();
  }
  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "email":
        errors.email =
          value.length === 0
            ? Constant.USER_EMAIL_REQUIRED
            : validEmailRegex.test(value)
            ? value.length < 255 
            ? ""
            : Constant.USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER
            : Constant.USER_EMAIL_NOT_VALID;
        break;
      case "password":
        errors.password =
          value.length === 0
            ? Constant.PASSWORD_FIELD_REQUIRED
            : value.length < 8
            ? Constant.PASSWORD_MUST_BE_8_CHARACHTER
            : "";
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };
  backToHome = () => {
    window.location.href=process.env.REACT_APP_ACCESS_KEY +'/'+ localStorage.getItem('tenant')
    // this.props.history.push("/" + localStorage.getItem("tenant"));
  };
  componentDidMount(){
    let flag_image=false
    let online_location_image = false
    if(null != this.props.locations.allLocations && undefined != this.props.locations.allLocations && '' != this.props.locations.allLocations) {
      this.props.locations.allLocations.map((locations, index) => {
        if(!flag_image && locations.image_url != null && locations.image_url != undefined && locations.image_url != ''){
          this.setState({image_loc:locations.image_url})
          flag_image=true
        }
        locations.location_online_order_settings.map((settings, i) => {
          if(!online_location_image && settings.image_url_online != null && settings.image_url_online != undefined && settings.image_url_online != ''){
            this.setState({backgroundImage:settings.image_url_online})
            online_location_image=true
          } else if(!online_location_image && locations.location_online_order_settings.length - 1 == i && this.state.backgroundImage == ""){
            online_location_image=true
            this.setState({backgroundImage:sideImage })
          }
        });
      })
      setTimeout(() => {
        this._handleImageshow()
      }, 3000);
    }  else {
      this.setState({backgroundImage:sideImage })
    }
  }

  _handleImageshow = () => {
    let flag_image=false
    if(null != this.props.locations.allLocations && undefined != this.props.locations.allLocations && '' != this.props.locations.allLocations) {
      this.props.locations.allLocations.map((locations, index) => {
        if(!flag_image && locations.image_url != null && locations.image_url != undefined && locations.image_url != ''){
          this.setState({image_loc:locations.image_url})
          flag_image=true
        }
      })
    }
  }
  _handleKeyPress = e => {
    const event = e;
    const { currentTarget } = e;
    if (event.key === "Enter") {
      let inputIndex = this.inputRefs.indexOf(currentTarget);
      if (inputIndex < this.inputRefs.length - 1) {
        this.inputRefs[inputIndex + 1].focus();
      } else {
        this.inputRefs[0].focus();
      }
      event.preventDefault();
    }
  };
  LogIn = () => {
    this.elScroll.executeScroll()
    this.setState({ loading: true });
    if (
      null != this.state.email &&
      undefined != this.state.email &&
      "" != this.state.email.trim()
    ) {
      if (
        null != this.state.password &&
        undefined != this.state.password &&
        "" != this.state.password.trim()
      ) {
        let payload = {
          email: this.state.email,
          password: this.state.password,
        };
        userSignIn(payload)
          .then((res) => {
            if (res.data.error) {
              if (res.data.error.code === 404) {
                // const path = "/" + localStorage.getItem("tenant") + "/404";
                this.props.history.push('/404');
              }
            } else if (res.data.flag) {
              this.setState({ loading: false });
              localStorage.setItem("token", res.data.data.token);
              this.props.userLogin(res.data.data.user[0]);
              // toast.success(res.data.message);

              if (
                localStorage.getItem("tenant") !== "" &&
                localStorage.getItem("tenant") !== null &&
                localStorage.getItem("tenant") !== undefined
              ) {
                // var tenant= localStorage.getItem("tenant")
                this.setState({ email: "", password: "" });
                const path = "/" + localStorage.getItem("tenant");
                this.props.removeProducts();
                this.props.removeDeliveryPickupDetails();
                this.props.removeFeaturedProducts();
                this.props.removeLocations();
                this.props.clearItems();
                if(this.props.history.location.state?.gift_card){
                  window.location.href=process.env.REACT_APP_ACCESS_KEY +'/'+ localStorage.getItem('tenant') +'/gift-card'
                } else {
                  window.location.href=process.env.REACT_APP_ACCESS_KEY +'/'+ localStorage.getItem('tenant') 
                }
                // this.props.history.push("/" + localStorage.getItem("tenant"));
              } else {
                this.setState({ email: "", password: "" });
                this.setState({ loading: false });
              }
            } else {
              toast.error(res.data.message);
              this.setState({ loading: false });
            }
          })
          .catch((err) => {
            toast.error(err.toString());
            this.setState({ loading: false });
          });
      }
    }
  };
  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
  };
  }
  render() {
    const { classes } = this.props;
    return (
      <>
      <div ref={this.elScroll.elRef}></div>
      {this.state.loading ? <LinearProgress color="primary" /> : null}
        <Layout className="layout-default layout-signin">
          <Header>
            <div className="header-brand" key={"brandName"}>
            </div>
          </Header>
          <Content className="signin">
            <Row gutter={[24, 0]} justify="flex-start" flex-direction="column">
              <div className={classes.quoteContainer}>
              <Col
                  className="sign-img"
                  style={{
                    backgroundImage: `url("${this.state.backgroundImage}")`,
                    height: "300px",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '30%',
                     position: 'absolute',
                     left: '0px',
                     top:'0px',
                     height: '100%',
                     boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
                  }}             
                   >
                  {/* <img src="https://img.freepik.com/premium-photo/closeup-drop-water-leaf-flower-reflecting-surrounding-colors-textures_674594-4382.jpg" alt="" /> */}
                </Col>
              </div>
              <div className={classes.quote}>
                <Col
                  className="select-order"
                >
                  <div className={classes.imageLogo}>
                  <img src={this.state.image_loc} alt=""  className="location-image" />
                  </div>
                  <Title className="font-regular text-muted" level={2}>
                    Sign In
                  </Title>
                  <div
                    layout="vertical"
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      autoFocus
                      style={{ marginBottom: "2%" }}
                      error={this.state.errors.email.length == 0 ? false : true}
                      fullWidth
                      helperText={this.state.errors.email}
                      label="Email address *"
                      name="email"
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      inputProps={{ onKeyPress: this._handleKeyPress }}
                      inputRef={ref => this.inputRefs.push(ref)}
                      // onKeyPress= {(e) => {
                      //   if (e.key === 'Enter') {

                      //     console.log('Enter key pressed');
                      //     // write your functionality here
                      //   }
                      // }}
                      type="email"
                      value={this.state.email}
                      variant="outlined"
                      size="small"
                    />
                    <TextField
                      size="small"
                      style={{ marginBottom: "2%" }}
                      error={
                        this.state.errors.password.length == 0 ? false : true
                      }
                      fullWidth
                      helperText={this.state.errors.password}
                      label="Password *"
                      name="password"
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      inputRef={ref => this.inputRefs.push(ref)}
                      ref={"input2"}
                      // inputProps={{ onKeyPress: this._handleKeyPress }}
                       onKeyPress= {(e) => {
                        if (e.key === 'Enter' && this.state.email !== "" &&
                        this.state.errors.email.length === 0 &&
                        this.state.password !== "" &&
                        this.state.errors.password.length === 0) {
                          this.LogIn()
                        }
                      }}
                      type="password"
                      value={this.state.password}
                      variant="outlined"
                    />
                    <p className="font-semibold text-muted fw-normal lh-lg text-right">
                      <Link
                        to={`/${localStorage.getItem("tenant")}/forgot`}
                        className="text-dark font-bold"
                      >
                        Forgot Password ?&nbsp;&nbsp;&nbsp;
                      </Link>
                    </p>
                    <Row gutter={[24, 0]} justify="center">
                      <Col xl={12} xs={12} sm={12} md={12} lg={12}>
                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          onClick={this.LogIn}
                          style={{ marginBottom: "7px" }}
                          disabled={
                            this.state.email == "" ||
                            this.state.errors.email.length > 0 ||
                            this.state.password == "" ||
                            this.state.errors.password.length > 0
                          }
                        >
                          Login
                        </Button>
                      </Col>
                      <Col xl={12} xs={12} sm={12} md={12} lg={12}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={this.backToHome}
                          style={{ marginBottom: "7px" }}
                          // disabled={
                          //   this.state.loading ||
                          //   (this.state.pickUpAddress == "" &&
                          //     (this.state.addressObj == "" ||
                          //       this.state.addressObj == null))
                          // }
                        >
                          Go Home
                        </Button>
                      </Col>
                    </Row>

                    <p className="font-semibold text-muted text-center fw-normal lh-lg">
                      Don't have an account?{" "}
                      <Link
                        to={{pathname:`/${localStorage.getItem("tenant")}/sign-up`, state: {gift_card: this.props.history.location.state?.gift_card }}}
                        className="text-dark font-bold fw-normal"
                      >
                        Sign Up
                      </Link>
                    </p>
             <Footer/>

                  </div>
                </Col>
              </div>
            </Row>
          </Content>
        </Layout>
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      userLogin,
      removeFeaturedProducts,
      clearItems,
      removeProducts,
      removeDeliveryPickupDetails,
      removeLocations,
    },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    user: state.user,
    category_products: state.category_products,
    locations:state.locations

  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SignIn);