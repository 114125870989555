import {
  Button,
  Grid,
  LinearProgress,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withStyles } from "@material-ui/styles";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { bindActionCreators, compose } from "redux";
import { removeUserCardDetails } from "../../../../actions/card_Details_action";
import moment from "moment";
import {
  makePayment,
  completeOrder
} from "../../../../actions/order_actions";
import { userLogout } from "../../../../actions/user_actions";
import * as Constant from "../../../../constants";
import { applyGiftCard, postOrder } from "../../../../services/order/order";
import { twofix } from "../../../../helpers/calculation";

const useStyles = (theme) => ({
  flexLayout: {
    flex: 10,
    flexDirection: "row",
    alignItems: "stretch",
    overflow: "hidden",
    padding: 13,
    marginBottom: 8,
    backgroundColor: "#fff",
    overflowY: "scroll",
  },
  buttonLayout: {
    display: "flex",
    flex: 0.6,
    padding: 8,
    justifyContent: "center",
    // alignItems:"center",
    backgroundColor: "#fff",
  },
});

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView();

  return { executeScroll, elRef };
};

class GiftCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      item_qty: 1,
      number: "",
      isDelivery: false,
      isPickUp: false,
      deliveryTime: "",
      loading: false,
      changePickup: false,
      giftCardBalance: 0,
      remainingGiftCardAmount: 0.0,
      showGiftCardBalance: false,
      errors: {
        number: "",
      },
    };
    this.elScroll = utilizeScroll();
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  handleApplyGiftCard = (code) => {
    this.elScroll.executeScroll();
    // this.setState({ loading: true });
    if (
      code.substring(0, 7) == "SQGC://" ||
      code.substring(0, 7) == "SQGC;//"
    ) {
      code = code.slice(7);
    }
    if (code.length <= 24) {
      applyGiftCard(code) //  api call to send data to remote server
        .then(async (response) => {
          if (response.data.error) {
            if (response.data.error.code === 404) {
              // const path = "/" + localStorage.getItem("tenant") + "/404";
              this.props.history.push("/404");
            }
          } else if (response.data.flag) {
            if (parseFloat(response.data.data?.gift_card_balance) > 0) {
              this.setState({
                showGiftCardBalance: true,
                giftCardBalance: response.data?.data,
              });
            } else {
              toast.error(Constant.INSUFFICIENT_BALANCE);
            }
          } else {
            if (response.data.code == 999) {
              this.setState({ loading: false });
              this.props.removeUserCardDetails();
              this.props.userLogout();
              localStorage.removeItem("token");
              this.goBack("signIn");
              toast.error(response.data.message);
            } else {
              this.setState({ loading: false });
              toast.error(response.data.message);
            }
          }
        })
        .catch((error) => {
          // Error occured while being api called
          if (
            error.toString() == Constant.BACKEND_ERROR ||
            error.toString() == Constant.BACKEND_ERROR_CODE
          ) {
            toast.error(Constant.SERVER_NOT_AVAILABLE);
          } else {
            toast.error(error.toString());
          }
        });
    } else {
      toast.error("enter valid code");
    }
  };

  validate = (phone) => {
    const regex = /^[0-9\b]+$/;
    return regex.test(phone);
  };
  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const that = this;
    let errors = this.state.errors;
    if (
      value.substring(0, 7) == "SQGC://" ||
      value.substring(0, 7) == "SQGC;//"
    ) {
      value = value.slice(7);
    }
    switch (name) {
      case "number":
        errors.number =
          value.length === 0
            ? Constant.GIFT_CARD_CODE_REQUIRED
            : value.length > 24
              ? Constant.GIFT_CARD_CODE_LENGHT_GREATER_THAN_24
              : "";
        errors.number =
          value.length === 0
            ? Constant.GIFT_CARD_CODE_REQUIRED
            : this.validate(value)
              ? value.length > 24
                ? Constant.GIFT_CARD_CODE_LENGHT_GREATER_THAN_24
                : ""
              : Constant.USER_GIFTCARD__NOT_VALID;
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };
  giftCardPayment = () => {
    this.setState({ loading: true });
    var amount_t = this.props.order.balance;
    if (
      this.props.order.balance === "" ||
      this.props.order.balance === "0." ||
      "" === parseFloat(amount_t).toFixed(2) ||
      "undefined" === parseFloat(amount_t).toFixed(2) ||
      isNaN(amount_t) ||
      0 === parseFloat(amount_t).toFixed(2) ||
      0.0 === parseFloat(amount_t).toFixed(2)
    ) {
      // 'Input value is undefined,not a number,empty,0.,0.00 or 0'
      toast.error(Constant.ORDER_BALANCE_ZERO);
      this.setState({ loading: false });
    } else {
      if (this.props.delivery_pickup[0].order_type === "delivery") {
        if (
          this.props.order.balance >=
          this.props.delivery_pickup[0].location
            .location_online_order_settings[0].minimum_order_amount
        ) {
          let order_tip_amount = twofix(
            twofix(this.props.order.balance) -
              [
                twofix(this.props.order.total) +
                  twofix(this.props.order.tax_collected),
              ],
          );
          this.processOrder(order_tip_amount); // Order is ready to store at backend side
        } else {
          toast.error(
            "Minimum order amount for Delivery is $" +
              this.props.delivery_pickup[0].location
                .location_online_order_settings[0].minimum_order_amount,
          );
          this.setState({ loading: false });
        }
      } else {
        let order_tip_amount = twofix(
          twofix(this.props.order.balance) -
            [
              twofix(this.props.order.total) +
                twofix(this.props.order.tax_collected),
            ],
        ); // tip calculation

        this.processOrder(order_tip_amount); // Order is ready to store at backend side
      }
    }
  };
  processOrder = (order_tip_amount) => {
    this.elScroll.executeScroll();
    var current_order = localStorage.getItem("current_order"); // Get current order
    if (
      current_order === null ||
      current_order === undefined ||
      current_order === ""
    ) {
      current_order =
        new Date().valueOf().toString() +
        "" +
        (Math.floor(Math.random() * (999 - 100)) + 101);
    }
    var balance = twofix(
      this.props.order.total + this.props.order.tax_collected,
    ).toFixed(2);
    let first_name = "";
    let last_name = "";
    let phone = "";
    if (
      null !== this.props.user.whoIsThis &&
      undefined !== this.props.user.whoIsThis &&
      "" !== this.props.user.whoIsThis
    ) {
      first_name = this.props.user.whoIsThis.first_name;
      last_name = this.props.user.whoIsThis.last_name;
      phone = this.props.user.whoIsThis.phone;
    } else {
      if (
        null !== this.props.user.user &&
        undefined !== this.props.user.user &&
        "" !== this.props.user.user
      ) {
        first_name = this.props.user.user.first_name;
        last_name = this.props.user.user.last_name;
        phone = this.props.user.user.phone;
      }
    }
    let time = "";
    let start_time = "";
    let end_time = "";
    this.props.delivery_pickup[0].location.location_online_order_settings.map(
      (settings) => {
        let final_time;
        let addtimeToday;
        if (this.props.delivery_pickup[0].order_type === "delivery") {
          let array = settings.delivery_lead_time.split(":");
          addtimeToday = moment()
            .add(array[2], "seconds")
            .add(array[1], "minutes")
            .add(array[0], "hours")
            .format("ddd");
          final_time = moment()
            .add(array[2], "seconds")
            .add(array[1], "minutes")
            .add(array[0], "hours")
            .format("hh:mm A");
        } else if (this.props.delivery_pickup[0].order_type === "pickup") {
          let array = settings.pickup_lead_time.split(":");
          addtimeToday = moment()
            .add(array[2], "seconds")
            .add(array[1], "minutes")
            .add(array[0], "hours")
            .format("ddd");
          final_time = moment()
            .add(array[2], "seconds")
            .add(array[1], "minutes")
            .add(array[0], "hours")
            .format("hh:mm A");
        }
        let i = 1;
        if (
          settings.location_hrs_sub_slot[0].day === addtimeToday.toUpperCase()
        ) {
          settings.location_hrs_sub_slot.map((time_slot) => {
            let dateIsAfter = moment(time_slot.from_time, ["h:mm A"]).isAfter(
              moment(final_time, ["h:mm A"]),
            );
            if (this.props.delivery_pickup[0].scheduleOrder === "asap") {
              if (dateIsAfter) {
                if (i == 1) {
                  let deliverytime =
                    time_slot.from_time + " - " + time_slot.to_time;
                  time = deliverytime;
                  start_time = time_slot.from_time;
                  end_time = time_slot.to_time;
                  i = 0;
                }
              }
            } else {
              if (dateIsAfter) {
                if (
                  time_slot.id === this.props.delivery_pickup[0].DeliveryTime
                ) {
                  let deliverytime =
                    time_slot.from_time + " - " + time_slot.to_time;
                  time = deliverytime;
                  start_time = time_slot.from_time;
                  end_time = time_slot.to_time;
                }
              }
            }
          });
        } else {
          toast.error(Constant.NO_SLOTES);
          this.setState({ timeSlot: [], loading: false });
        }
      },
    );
    var todayDate = new Date().toISOString().slice(0, 10);
    if (
      null !== this.props.user.user &&
      undefined !== this.props.user.user &&
      "" !== this.props.user.user
    ) {
      if (
        null !== this.props.delivery_pickup[0] &&
        undefined !== this.props.delivery_pickup[0] &&
        "" !== this.props.delivery_pickup[0]
      ) {
          let current_time = moment().format("h:mm A");
          let orderCreateorNot = moment(start_time, ["h:mm A"]).isAfter(
            moment(current_time, ["h:mm A"]),
          );
          if (orderCreateorNot) {
            let order_online_details = {
              pos_location_id: this.props.delivery_pickup[0].location.id,
              grub_user_id: this.props.user.user.id,
              dispatch_first_name: first_name,
              dispatch_last_name: last_name,
              grub_user_first_name: this.props.user.user?.first_name,
              grub_user_last_name: this.props.user.user?.last_name,
              dispatch_address1: this.props.delivery_pickup[0].address,
              dispatch_address2: "",
              dispatch_city: this.props.delivery_pickup[0].city,
              dispatch_state: this.props.delivery_pickup[0].state,
              dispatch_zip: this.props.delivery_pickup[0].zipCode,
              dispatch_country: this.props.delivery_pickup[0].country,
              dispatch_user_phone: phone,
              order_dispatch_type: this.props.delivery_pickup[0].order_type,
              dispatch_instruction:
                this.props.order.instruction !== null &&
                this.props.order.instruction !== undefined &&
                this.props.order.instruction !== ""
                  ? this.props.order.instruction
                  : "",
              dispatch_slot_start_time: todayDate + " " + start_time,
              dispatch_slot_end_time: todayDate + " " + end_time,
            };
            var timestamp = new Date().getTime();
            var order_payload = {
              //Create payment payload
              campaign_coupon_id: "",
              reward_history_id: "",
              email: this.props.user.user.email,
              paid: 1,
              pos_station_id:
                this.props.delivery_pickup[0].location.online_pos_station[0].id,
              reward_card_id: this.props.order.reward_card_id,
              total: this.props.order.total,
              local_id: current_order,
              balance: balance,
              discount_amount: this.props.order.order_discount,
              discount_reason: this.props.order.discount_reason,
              lines: this.props.order_items,
              payment: [
                {
                  aid: undefined,
                  aid_display_name: undefined,
                  amount: twofix(
                    twofix(this.props.order.total) +
                      twofix(this.props.order.tax_collected),
                  ),
                  cash_payment_check: 0,
                  cashback_amount: undefined,
                  external_reference: undefined,
                  external_reference_number: undefined,
                  id: timestamp,
                  multiple_cash: true,
                  payment_gateway: undefined,
                  pin_statement: undefined,
                  tips_amount: order_tip_amount,
                  type: "GIFT_CARD_PAYMENT",
                  gift_card_id: this.state.giftCardBalance?.id,
                },
              ],
              ebt_flag: 0,
              created_by: "",
              // card_id:
              //   this.props.user.user.selected_card_id !== null &&
              //   this.props.user.user.selected_card_id !== undefined &&
              //   this.props.user.user.selected_card_id !== ""
              //     ? this.props.user.user.selected_card_id
              //     : "",
              order_online_details: order_online_details,
              order_for_here: this.props.order.order_for_here,
              card_details: [],
            };
            // this.changeLayout("orderStatus");
            postOrder(order_payload)
              .then((res) => {
                if (res.data.flag) {
                  this.props.checkOutInsidePage(
                    "orderStatus",
                    res.data.data.responsePayload,
                  );
                  this.props.completeOrder()
                    localStorage.removeItem("current_order");
                  // let user = res.data.data.responsePayload.user[0]
                  // user.selected_card_id = this.props.user.user.selected_card_id
                  // this.props.userLogin(user)
                  this.props.removeUserCardDetails();
                  toast.success(res.data.data.message);
                  this.setState({ loading: false });
                } else if (res.data.data.error) {
                  if (res.data.data.error.code === 400) {
                    toast.error(res.data.data.error.message[0]);
                    this.setState({ loading: false });
                  } else if (res.data.data.error.code === 404) {
                    toast.error(res.data.data.error.message);
                    this.setState({ loading: false });
                  }
                } else {
                  if (res.data.code === 999) {
                    this.props.removeUserCardDetails();
                    this.props.userLogout();
                    localStorage.removeItem("token");
                    this.props.checkOutInsidePage("signIn");
                    toast.error(res.data.message);
                    this.setState({ loading: false });
                  } else {
                    toast.error(res.data.message);
                    this.setState({ loading: false });
                  }
                }
              })
              .catch((error) => {
                toast.error(error.toString());
                this.setState({ loading: false });
              });
          } else {
            toast.error(Constant.SELECTE_TIME_SLOTE_IS_BEFORE_CURRUNT_TIME);
            this.setState({ loading: false });
          }
      } else {
        toast.error(Constant.CHECKOUT_PAGE_ADDRESS_NOT_FOUND);
        this.setState({ loading: false });
      }
    } else {
      toast.error(Constant.CHECKOUT_PAGE_USER_LOGOUT);
      this.setState({ loading: false });
    }
  };
  modalOkShowCheckButton = () => {
    if (
      this.state.giftCardBalance?.gift_card_balance > this.props.order.balance
    ) {
      this.giftCardPayment();
    } else {
      this.handlegiftCardPayment();
    }
    this.setState({
      showGiftCardBalance: false,
    });
    let remainingGiftCardAmount =
      this.state.giftCardBalance?.gift_card_balance -
      twofix(this.props.order.balance).toFixed(2);
    if (remainingGiftCardAmount > 0) {
      this.setState({ remainingGiftCardAmount: remainingGiftCardAmount });
    } else {
      this.setState({ remainingGiftCardAmount: 0.0 });
    }
  };
  handlegiftCardPayment = () => {
    this.setState({ loading: true });
    var amount_t = this.state.giftCardBalance?.gift_card_balance;
    if (
      this.state.giftCardBalance?.gift_card_balance === "" ||
      this.state.giftCardBalance?.gift_card_balance === "0." ||
      "" === parseFloat(amount_t).toFixed(2) ||
      "undefined" === parseFloat(amount_t).toFixed(2) ||
      isNaN(amount_t) ||
      0 === parseFloat(amount_t).toFixed(2) ||
      0.0 === parseFloat(amount_t).toFixed(2)
    ) {
      // 'Input value is undefined,not a number,empty,0.,0.00 or 0'
      toast.error(Constant.ORDER_BALANCE_ZERO);
      this.setState({ loading: false });
    } else {
      let order_tip_amount = twofix(
            twofix(this.props.order.balance) -
              [
                twofix(this.props.order.total) +
                  twofix(this.props.order.tax_collected),
              ]
          );
      let payment
      if(order_tip_amount > 0.0) {
        if((twofix(this.props.order.total) +
        twofix(this.props.order.tax_collected)) > parseFloat(amount_t)) {
          payment = {
            // Create request payload
            aid: undefined,
            aid_display_name: undefined,
            amount: parseFloat(amount_t).toFixed(2),
            cash_payment_check: 0,
            cashback_amount: undefined,
            external_reference: undefined,
            external_reference_number: undefined,
            id: new Date().getTime(),
            multiple_cash: true,
            payment_gateway: undefined,
            pin_statement: undefined,
            tips_amount: 0,
            type: "GIFT_CARD_PAYMENT",
            gift_card_id: this.state.giftCardBalance?.id,
          };
        } else {
          let tip = (parseFloat(amount_t) - (twofix(this.props.order.total) + twofix(this.props.order.tax_collected))).toFixed(2)
          payment = {
            // Create request payload
            aid: undefined,
            aid_display_name: undefined,
            amount: (twofix(this.props.order.total) + twofix(this.props.order.tax_collected)).toFixed(2),
            cash_payment_check: 0,
            cashback_amount: undefined,
            external_reference: undefined,
            external_reference_number: undefined,
            id: new Date().getTime(),
            multiple_cash: true,
            payment_gateway: undefined,
            pin_statement: undefined,
            tips_amount: tip,
            type: "GIFT_CARD_PAYMENT",
            gift_card_id: this.state.giftCardBalance?.id,
          };
        }
      } else {
        payment = {
          // Create request payload
          aid: undefined,
          aid_display_name: undefined,
          amount: parseFloat(amount_t).toFixed(2),
          cash_payment_check: 0,
          cashback_amount: undefined,
          external_reference: undefined,
          external_reference_number: undefined,
          id: new Date().getTime(),
          multiple_cash: true,
          payment_gateway: undefined,
          pin_statement: undefined,
          tips_amount: 0,
          type: "GIFT_CARD_PAYMENT",
          gift_card_id: this.state.giftCardBalance?.id,
        };
      }
      if((twofix(this.props.order.total) +
      twofix(this.props.order.tax_collected)) > parseFloat(amount_t)) {
        payment = {
          // Create request payload
          aid: undefined,
          aid_display_name: undefined,
          amount: parseFloat(amount_t).toFixed(2),
          cash_payment_check: 0,
          cashback_amount: undefined,
          external_reference: undefined,
          external_reference_number: undefined,
          id: new Date().getTime(),
          multiple_cash: true,
          payment_gateway: undefined,
          pin_statement: undefined,
          tips_amount: 0,
          type: "GIFT_CARD_PAYMENT",
          gift_card_id: this.state.giftCardBalance?.id,
        };
      } else if((twofix(this.props.order.total) +
      twofix(this.props.order.tax_collected)) < parseFloat(amount_t)){
      }
      
      if (this.props.delivery_pickup[0].order_type === "delivery") {
        if (
          this.props.order.balance >=
          this.props.delivery_pickup[0].location
            .location_online_order_settings[0].minimum_order_amount
        ) {
          this.props.makePayment(payment)
            this.goBack("checkout")
          
        } else {
          toast.error(
            "Minimum order amount for Delivery is $" +
              this.props.delivery_pickup[0].location
                .location_online_order_settings[0].minimum_order_amount,
          );
          this.setState({ loading: false });
        }
      } else {
        this.props.makePayment(payment)
          this.goBack("checkout")
       
      }
    }
  };
  goBack = (type) => {
    this.props.checkOutInsidePage(type);
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div ref={this.elScroll.elRef}></div>
        {this.state.loading ? <LinearProgress color="primary" /> : null}
        <Paper elevation={1} className={classes.flexLayout}>
          <Dialog
            // onClose={handleClose}
            // aria-labelledby="customized-dialog-title"
            open={this.state.showGiftCardBalance}
            // open={true}
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              {"Transaction Detail"}
            </DialogTitle>
            {/* <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton> */}
            <DialogContent dividers>
              <Typography gutterBottom>
                {"Gift Card Balance: $" +
                  this.state.giftCardBalance?.gift_card_balance}
              </Typography>
              <Typography gutterBottom>
                {"Order Payment Amount: $" +
                  twofix(this.props.order.balance).toFixed(2)}
              </Typography>
              <Typography gutterBottom>
                {"After Transaction remaining gift card balance: $" +
                  `${
                    (
                      parseFloat(
                        this.state.giftCardBalance?.gift_card_balance,
                      ) - twofix(this.props.order.balance).toFixed(2)
                    ).toFixed(2) > 0.0
                      ? parseFloat(this.state.giftCardBalance?.gift_card_balance -
                        twofix(this.props.order.balance).toFixed(2)).toFixed(2)
                      : "0.00"
                  }`}
              </Typography>
              <Typography gutterBottom>
                {"Are you sure you want to proceed further?"}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {this.setState({ showGiftCardBalance: false })}}
                variant="contained"
                color="secondary"
              >
                NO
              </Button>
              <Button
                onClick={() => {
                  this.modalOkShowCheckButton();
                  // this.props.removeUserCardDetails();
                  // this.props.removeProducts();
                  // this.props.removeDeliveryPickupDetails();
                  // this.props.removeLocations();
                  // this.props.removeFeaturedProducts();
                  // this.props.clearItems();
                  // history.push("/"+localStorage.getItem('tenant'));
                }}
                variant="contained"
                color="primary"
                autoFocus
              >
                YES
              </Button>
            </DialogActions>
          </Dialog>
          <Grid
            container
            direction="row"
            // justifyContent="center"
            alignItems="center"
          >
            <Grid xs={2} md={2} lg={2} item>
              <Link to="#" className="text-dark font-bold">
                <ArrowBackIcon onClick={() => this.goBack("checkout")} />
              </Link>
            </Grid>
            <Grid xs={8} md={8} lg={8} item>
              <h1 className="sidebar-layout-header-name">{"Gift Card"}</h1>
            </Grid>
          </Grid>
          <TextField
            autoFocus
            style={{ margin: "2%", borderRadius: 0, width: "96%" }}
            error={this.state.errors.number.length == 0 ? false : true}
            fullWidth
            size="small"
            helperText={this.state.errors.number}
            label="Gift Card Number *"
            name="number"
            onChange={this.handleInputChange}
            onBlur={this.handleInputChange}
            onKeyPress={(e) => {
              if (
                e.key === "Enter" &&
                this.state.number !== "" &&
                this.state.errors.number === ""
              ) {
                this.handleApplyGiftCard(this.state.number);
              }
            }}
            type="text"
            value={this.state.number}
            variant="outlined"
          />
        </Paper>
        <Paper elevation={1} className={classes.buttonLayout}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "100%", height: "93%" }}
            onClick={() => this.handleApplyGiftCard(this.state.number)}
            disabled={this.state.number == "" || this.state.errors.number != ""}
          >
            Pay via Gift Card
          </Button>
        </Paper>
      </React.Fragment>
    );
  }
}

// export default withStyles(useStyles, { withTheme: true })(OrderItemsDetails);
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeUserCardDetails,
      userLogout,
      makePayment,
      completeOrder
    },
    dispatch,
  );
}
function mapStateToProps(state) {
  return {
    order: state.order,
    order_items: state.order_items,
    user: state.user,
    delivery_pickup: state.delivery_pickup,
    card_details: state.card_details,
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true }),
)(GiftCard);
