import { SCAN_COUPON, APPLY_COUPON } from '../actions/coupons_actions';
import { CANCLE_COUPON } from '../actions/coupons_actions';
import { DELETE_ORDER_COUPON } from '../actions/coupons_actions';
import { CLEAR_COUPON } from '../actions/coupons_actions';
import { TICKET_COUPON_STATUS } from '../actions/coupons_actions';
import { REMOVE_ITEM_COUPON, CLEAR_ITEMS, MINUS_ITEM, DELETE_ITEM } from '../actions/order_items';
import { COMPLETE_ORDER} from '../actions/order_actions';
import _ from 'lodash';
import * as Constant from '../constants';

export default function (state = { order: {}, products: [] }, action) {
  switch (action.type) {
    case SCAN_COUPON: {
      let order = action.payload.appState.order; // receive Order data
      let order_items = action.payload.appState.order_items;// Received Lines data
      let coupon = action.payload.response.coupon;  // Received coupon data
      let campaign_id = action.payload.response.id;
      let productCouponMatch = [];
      let flag = 0;
      let product_coupon = [];
      let dodo=-1;
      let newProduct = [];
      action.payload.response.coupon.map(function (pro_coupon) { // Coupon Found
        if (pro_coupon.hasOwnProperty("coupon_product") && pro_coupon.coupon_product.length > 0) { // Product Coupon Available
          product_coupon.push(pro_coupon);
        }
      });
      product_coupon.sort((a,b) => a.discount_value - b.discount_value); // Sort the discount value (note: Smallest value first display)
      order_items.map(function (item) { // Orderlines Found
        product_coupon.map(function (productCoupon) {// Multiple product coupons Available
          if (productCoupon.coupon_product.length > 0 && productCoupon.coupon_type == "prod") { //Check Type of Coupon(note: ord for ordera and prod for product)
            productCoupon.coupon_product.map(function (single_product) { //Get  One by one Coupon 
            const checkCouponEligibleOneTime = _.findIndex(productCouponMatch, function (oneTimeOneCoupon) { //Returns Eligible Product Coupon 
             return oneTimeOneCoupon.coupon_id==productCoupon.id;
            });
            if (single_product.id == item.server_id && checkCouponEligibleOneTime<0) { // coupon's product id and Line's product id match and Eligible coupon(note:checkCouponEligibleOneTime<0 = -1, it considers as true means coupon eligible)
              let dodo = _.findIndex(productCouponMatch, function (valuesOfItems) {
               if(valuesOfItems.product_id == item.server_id && valuesOfItems.reject==1){
                const index= productCouponMatch.indexOf(valuesOfItems);
                productCouponMatch.splice(index,1);
               }
               return valuesOfItems.product_id == item.server_id && valuesOfItems.reject!=1;
              });
              flag = 1;
              const itemCouponAlreadyApplied = _.findIndex(state.products, function (existingCouponProduct) {
                return existingCouponProduct.product_id == single_product.id && existingCouponProduct.applied == 0;
              });
            if(dodo<0 && dodo!="" && null!=dodo){
              if (itemCouponAlreadyApplied <= 0) { // Product Coupon not applied 
                if (item.total_discount <= 0) {// Product Manual Discount Not Applied
                  let usageCheck = productCoupon.max_usage - productCoupon.times_used; //Check Maximum Usage Of coupon 
                  if (usageCheck >= 1) { //Coupon usage Remaining from maximum Usage
                    // We can Apply Coupon
                   if(state.order.hasOwnProperty("applied") && state.order.applied == 1){ //when order coupon already applied
                    let total = 0;
                    let remainingSum=0;
                    let taxCollected = 0;
                    let totalDiscount = 0;
                    let coupon_found = 0;
                    let finalTotal=0;
                    let coupon_discount_exist=0;
                    order_items.map((singleProduct) => { //Get Order lines 
                        let checkfortotalamountdiscount=0;
                        let checkcoupon_discount_type="";
                        if (single_product.id == singleProduct.server_id) { //when order line and coupon's product ids matched
                          coupon_found = 1;
                          let discount = 0;
                          let percentage = productCoupon.discount_value;
                          var maxQty;
                          if (item.quantity >= productCoupon.max_qty_per_coupon){ // Check Maximum Quantity 
                            maxQty = productCoupon.max_qty_per_coupon; 
                          }else {
                            maxQty = item.quantity;
                          }
                          if (productCoupon.discount_type == 'per') { // Discount type is Percentage
                            discount = parseFloat(singleProduct.sale_price) * parseFloat(maxQty) * parseFloat(percentage) / 100;
                            percentage = percentage; // calculate the discount value
                          } else { // Discount type is Amount
                            discount = productCoupon.discount_value * parseFloat(maxQty) ;
                          }
                          total = parseFloat(singleProduct.sale_price) * parseFloat(singleProduct.quantity)- parseFloat(discount); // Calculate the total
                          let newItem = { 'server_id': singleProduct.server_id, 'product_id': singleProduct.server_id ,'total':total}
                          remainingSum=parseFloat(remainingSum)+parseFloat(total);
                          newProduct.push(newItem);
                        }else{ //when order line and product coupon ids not matched
                          const checkfortotal = _.findIndex(productCouponMatch, function (existingCouponProduct) { // Check order total
                            if (existingCouponProduct.product_id == singleProduct.server_id && existingCouponProduct.applied == 0) { // Existing Coupon's product id, Received product id match and Existing product coupon not applied  
                              if (null != existingCouponProduct.coupon_discount_value && undefined != existingCouponProduct.coupon_discount_value) { // Existing Product id is not null and not undefined then get the Discount value and type of that coupon
                                checkfortotalamountdiscount=existingCouponProduct.coupon_discount_value; // Store discount amount 
                                checkcoupon_discount_type=existingCouponProduct.coupon_discount_type;// Store discount type
                              }
                            }
                            return existingCouponProduct.product_id==singleProduct.server_id && existingCouponProduct.applied==0; // Return 0,-1 or 1
                          });
                          var maxQty;
                          if (singleProduct.quantity >= productCoupon.max_qty_per_coupon){ // Check maximum quantity
                            maxQty = productCoupon.max_qty_per_coupon;
                            
                          }else {
                            maxQty = singleProduct.quantity;
                          }
                          if (checkfortotal >=0) { // Existing Product discount calculation
                            if (checkcoupon_discount_type == 'per') { // Discount type percentage
                              let discount = parseFloat(singleProduct.sale_price) * parseFloat(maxQty) * parseFloat(checkfortotalamountdiscount) / 100;
                              coupon_discount_exist = parseFloat(singleProduct.sale_price) * parseFloat(maxQty)- parseFloat(discount);
                            }else{ // Discount type Amount
                              let discountamount = parseFloat(checkfortotalamountdiscount )* parseFloat(maxQty) ;
                              coupon_discount_exist = parseFloat(singleProduct.sale_price) * parseFloat(maxQty)- parseFloat(discountamount);
                            }
                          }else{// Received Product discount calculation
                            coupon_discount_exist = parseFloat(singleProduct.sale_price) * parseFloat(maxQty) - parseFloat(singleProduct.total_discount);
                          }
 
                          remainingSum =parseFloat(remainingSum)+parseFloat(coupon_discount_exist); // Calculate Total
                          total=0;
                          checkfortotalamountdiscount=0;

                        }
                        finalTotal = parseFloat(remainingSum); // Store total in final total
                    });

                    if (finalTotal >= order.coupon_status.coupon_order_min_amount) { //Current total is Greater then order coupon's minimum amount
                      const CouponMatch = { // Apply Coupon
                        'coupon_name': productCoupon.title, // Coupon name
                        'coupon_discount_type': productCoupon.discount_type,// Coupon discount type
                        'coupon_discount_value': parseFloat(productCoupon.discount_value),// Coupon discount value
                        'coupon_id': productCoupon.id,
                        'campaign_id': campaign_id,
                        'product_id': single_product.id,
                        'product_name': item.name,
                        'reward_card_id': action.payload.response.reward_card_id,
                        'campaign_coupon_id': productCoupon.campaign_coupon_id,
                        'applied': 0,
                        "reason": "-",
                        'reject': 0,
                        'max_qty_per_coupon':productCoupon.max_qty_per_coupon
                      };
                      productCouponMatch.push(CouponMatch);
                      finalTotal=0;
                      total=0;
                  } else { 
                    const rejectedCoupon = { //Reject Coupon 
                      'applied': 0,//Applied coupon set 0
                      'coupon_name': productCoupon.title,//Coupon name
                      'product_id': item.server_id,//Product id
                      'product_name': item.name,//product name
                      'reason': Constant.COUPON_PRODUCT_ELIGIBLE_ORDER_AMOUNT_LESS,//Error message set
                      'reject': 1//Reject coupon set to 1
                    };
                    productCouponMatch.push(rejectedCoupon);
                    finalTotal=0;
                    total=0;
                  }
                  }else{//Check order manual discount 
                    let total = 0;
                    let remainingSum=0;
                    let taxCollected = 0;
                    let totalDiscount = 0;
                    let coupon_found = 0;
                    let finalTotal=0;
                    let subTotal=0;
                    order_items.map((singleProduct) => { // Order lines found

                        let checkfortotalamountdiscount=0;
                        let checkcoupon_discount_type="";
                        if (single_product.id == singleProduct.server_id) {//Coupon product id and received product from line's id matched

                          coupon_found = 1;
                          let discount = 0;
                          let percentage = productCoupon.discount_value;
                          var maxQty;
                          if (singleProduct.quantity >= productCoupon.max_qty_per_coupon){ // Check maximum quantity
                            maxQty = productCoupon.max_qty_per_coupon;
                            
                          }else {
                            maxQty = singleProduct.quantity;
                          }
                          if (productCoupon.discount_type == 'per') { // Discount type percentage
                            discount = parseFloat(singleProduct.sale_price) * parseFloat(maxQty) * parseFloat(percentage) / 100;
                            percentage = percentage;
                          } else {// Discount type amount
                            discount = productCoupon.discount_value * parseFloat(maxQty) ;
                          }
                          total = parseFloat(singleProduct.sale_price) * parseFloat(singleProduct.quantity)- parseFloat(discount);// Calculate the total
                          let newItem = { 'server_id': singleProduct.server_id, 'product_id': singleProduct.server_id ,'total':total}
                          remainingSum=parseFloat(remainingSum)+parseFloat(total);
                          newProduct.push(newItem);
                        }
                        else{//Coupon product id and received product from line's id not matched
                          const checkfortotal = _.findIndex(productCouponMatch, function (existingCouponProduct) {// Check order total
                            if (existingCouponProduct.product_id == singleProduct.server_id && existingCouponProduct.applied == 0) { // Existing Coupon's product id, Received product id match and Existing product coupon not applied  
                              if (null != existingCouponProduct.coupon_discount_value && undefined != existingCouponProduct.coupon_discount_value) {// Existing Product id is not null and not undefined then get the Discount value and type of that coupon
                                checkfortotalamountdiscount=existingCouponProduct.coupon_discount_value;// Store discount amount 
                                checkcoupon_discount_type=existingCouponProduct.coupon_discount_type;// Store discount type 
                              }
                            }
                            return existingCouponProduct.product_id==singleProduct.server_id && existingCouponProduct.applied==0;// Return 0,-1 or 1
                          });
                          if (checkfortotal >=0) {// Existing Product discount calculation
                            var maxQty;
                            if (singleProduct.quantity >= productCoupon.max_qty_per_coupon){// Check maximum quantity
                              maxQty = productCoupon.max_qty_per_coupon;
                            }else {
                              maxQty = singleProduct.quantity;
                            }
                            if (checkcoupon_discount_type == 'per') {// Discount type percentage
                              let discount = parseFloat(singleProduct.sale_price) * parseFloat(maxQty) * parseFloat(checkfortotalamountdiscount) / 100;
                              subTotal = parseFloat(singleProduct.sale_price) * parseFloat(maxQty)- parseFloat(discount);
                             
                            }else{// Discount type Amount
                              let discountamount = parseFloat(checkfortotalamountdiscount )* parseFloat(maxQty) ;
                              subTotal = parseFloat(singleProduct.sale_price) * parseFloat(maxQty)- parseFloat(discountamount);
                            }
                          }else{// Received Product discount calculation
                            subTotal = parseFloat(singleProduct.sale_price) * parseFloat(singleProduct.quantity) - parseFloat(singleProduct.total_discount);
                          }
                          remainingSum =parseFloat(remainingSum)+parseFloat(subTotal); // Calculate the total
                          total=0;
                          checkfortotalamountdiscount=0;

                        }
                        finalTotal = parseFloat(remainingSum);

                    });
                    if(order.hasOwnProperty("coupon_status") && null!=order.coupon_status && undefined!=order.coupon_status){// Order Coupon available
                      if (finalTotal >= order.coupon_status.coupon_order_min_amount) { // Current Total Greater than Coupon of order minimum amount
                      const CouponMatch = { // Apply Coupon
                        'coupon_name': productCoupon.title,
                        'coupon_discount_type': productCoupon.discount_type,
                        'coupon_discount_value': parseFloat(productCoupon.discount_value),
                        'coupon_id': productCoupon.id,
                        'campaign_id': campaign_id,
                        'product_id': single_product.id,
                        'product_name': item.name,
                        'reward_card_id': action.payload.response.reward_card_id,
                        'campaign_coupon_id': productCoupon.campaign_coupon_id,
                        'applied': 0,
                        "reason": "-",
                        'reject': 0,
                        'max_qty_per_coupon':productCoupon.max_qty_per_coupon

                      };
                      productCouponMatch.push(CouponMatch);
                      finalTotal=0;
                      total=0;
                  } else { // Reject Coupon
                    const rejectedCoupon = {
                      'applied': 0,
                      'coupon_name': productCoupon.title,
                      'product_id': item.server_id,
                      'product_name': item.name,
                      'reason': Constant.COUPON_PRODUCT_ELIGIBLE_ORDER_AMOUNT_LESS,
                      'reject': 1
                    };
                    productCouponMatch.push(rejectedCoupon);
                    finalTotal=0;
                    total=0;
                  }
                    }else{ // Manual Order Discount Available
                      if (finalTotal >= order.order_discount) { // current total greater than Order's manual discount
                      const CouponMatch = { // Apply Coupon
                        'coupon_name': productCoupon.title,
                        'coupon_discount_type': productCoupon.discount_type,
                        'coupon_discount_value': parseFloat(productCoupon.discount_value),
                        'coupon_id': productCoupon.id,
                        'campaign_id': campaign_id,
                        'product_id': single_product.id,
                        'product_name': item.name,
                        'reward_card_id': action.payload.response.reward_card_id,
                        'campaign_coupon_id': productCoupon.campaign_coupon_id,
                        'applied': 0,
                        "reason": "-",
                        'reject': 0,
                        'max_qty_per_coupon':productCoupon.max_qty_per_coupon

                      };
                      productCouponMatch.push(CouponMatch);
                      finalTotal=0;
                      total=0;
                  } else { // Reject a coupon
                    const rejectedCoupon = {
                      'applied': 0,
                      'coupon_name': productCoupon.title,
                      'product_id': item.server_id,
                      'product_name': item.name,
                      'reason': Constant.COUPON_PRODUCT_ELIGIBLE_ORDER_AMOUNT_LESS,
                      'reject': 1
                    };
                    productCouponMatch.push(rejectedCoupon);
                    finalTotal=0;
                    total=0;
                  }
                    }
                  }
                  } else { // Reject Coupon because Max usage Complete
                    const rejectedCoupon = { // create payload 
                      'applied': 0, // Applied coupon set to 0
                      'coupon_name': productCoupon.title, // Coupon name
                      'product_id': item.server_id,// Product id
                      'product_name': item.name,// Product name
                      'reason': Constant.COUPON_MAX_USAGE, // Add Message
                      'reject': 1 // Reject coupon set to 1
                    };
                    productCouponMatch.push(rejectedCoupon);
                  }
                } else if (item.total_discount > 0 && item.hasOwnProperty("applied_coupon") && item.applied_coupon != '' && item.applied_coupon != undefined) { // Product Manual Discount and Coupon applied
                  if (single_product.id == item.server_id) {// Match Coupon's product id and Lines product id
                    const CouponMatch = {
                      'coupon_name': productCoupon.title, //Coupon name
                      'coupon_discount_type': item.applied_coupon.coupon_discount_type,//Discount tpe
                      'coupon_discount_value': parseFloat(item.applied_coupon.coupon_discount_value),//Discount Value
                      'coupon_id': item.applied_coupon.coupon_id,//Coupon id
                      'campaign_id': item.applied_coupon.campaign_id,//Campaign id
                      'product_id': item.applied_coupon.product_id,//Product id
                      'product_name': item.applied_coupon.product_name,//product name
                      'reward_card_id': item.applied_coupon.reward_card_id,// Reward card id
                      'campaign_coupon_id': item.applied_coupon.campaign_coupon_id,// Capaign_coupon_id
                      'applied': 1, //Applied coupon set to 1
                      "reason": Constant.COUPON_ALREADY_APPLIED, // Set error message like "Coupon is already applied"
                      'reject': 0,//Reject Coupon set to 0
                      'max_qty_per_coupon':item.applied_coupon.max_qty_per_coupon// Maximum qty per coupon

                    };
                    productCouponMatch.push(CouponMatch);
                  }

                } else { // Reject Coupon When try to add Coupon but Manual Discount is already Applied in that product

                  const rejectedCoupon = {
                    'applied': 0, //Applied Coupon set to 0
                    'coupon_name': productCoupon.title, // Coupon name
                    'product_id': item.server_id,// Product id
                    'product_name': item.name,//Product name
                    'reason': Constant.MANUAL_DISCOUNT_ALREADY_APPLIED,// Set message 
                    'reject': 1// Reject Coupon set to 1
                  };
                  productCouponMatch.push(rejectedCoupon);

                }
              } else {//Product Coupon already applied
                let CouponMatch = {
                  'coupon_name': productCoupon.title,// Coupon name
                  'coupon_discount_type': productCoupon.discount_type,// Discount type Amount or percentage
                  'coupon_discount_value': parseFloat(productCoupon.discount_value),//Discoupont value
                  'coupon_id': productCoupon.id,//Coupon id
                  'campaign_id': campaign_id,//Campaign Id
                  'product_id': single_product.id,// Product id
                  'product_name': item.name,//Product name
                  'reward_card_id': action.payload.response.reward_card_id,// Reward card id
                  'campaign_coupon_id': productCoupon.campaign_coupon_id,//Campaign coupon id
                  'applied': 0,// Apllied coupon set to 0
                  "reason": "-",//Reason "-"
                  'reject': 0,//Reject coupon set to 0
                  'max_qty_per_coupon':productCoupon.max_qty_per_coupon // Maximum qty per coupon

                };
                productCouponMatch.push(CouponMatch);
              }
            }
            }
          });
          }
          flag = 0;
        });
      });
      const orderTypeCouponCheckForAlreadyApplied = coupon.filter(function (value) {//Return Order Coupon that already apllied in order
        return value.coupon_type == "ord";
      });
      if (order.hasOwnProperty("coupon_status") && undefined!=order.coupon_status && ''!=order.coupon_status && orderTypeCouponCheckForAlreadyApplied.length>=1) {//Order coupon already applied
        let newCouponState = { 
          'coupon_name': order.coupon_status.coupon_name,
          'coupon_discount_type': order.coupon_status.coupon_discount_type,
          'coupon_discount_value': parseFloat(order.coupon_status.coupon_discount_value),
          'coupon_order_min_amount': parseFloat(order.coupon_status.coupon_order_min_amount),
          'coupon_id': order.coupon_status.coupon_id,
          'campaign_id': order.coupon_status.campaign_id,
          'reward_card_id': order.coupon_status.reward_card_id,
          'campaign_coupon_id': order.coupon_status.campaign_coupon_id,
          'applied': 1,
          'reason': Constant.COUPON_ALREADY_APPLIED, // Display reason "Coupon is already applied"
          'reject': 0
        };
        let newState = { ...state, order: newCouponState, products: productCouponMatch };
        return newState;
      } else {

        const orderTypeCoupon = coupon.filter(function (value) {
          return value.coupon_type == "ord";//Get Order Coupon of both discount type
        });
        const orderTypeAmount = coupon.filter(function (value) {
          return value.coupon_type == "ord" && value.discount_type == "amou";// Get Order Coupon of Amount Discount Type
        });
        const orderTypePer = coupon.filter(function (value) {
          return value.coupon_type == "ord" && value.discount_type == "per";// Get Order Coupon of Percentage Discount Type
        });
        let newProduct = [];

        let total = 0;
        let taxCollected = 0;
        let totalDiscount = 0;
        let coupon_found = 0;

        order_items.map((singleProduct) => { // Orderlines
          productCouponMatch.map(function (o) {
            if (o.product_id == singleProduct.server_id && !o.reject) {//Product Found whcih has apply
              coupon_found = 1;
              let discount = 0;
              let percentage = o.coupon_discount_value;
              var maxQty;
              if (singleProduct.quantity >= o.max_qty_per_coupon){ // Calculate maximum quantity
                maxQty = o.max_qty_per_coupon;
                
              }else {
                maxQty = singleProduct.quantity;
              }
              if (o.coupon_discount_type == 'per') { // Discount type Percentage
                discount = parseFloat(singleProduct.sale_price) * parseFloat(maxQty) * parseFloat(percentage) / 100;
                percentage = percentage;
                total = parseFloat(total) + parseFloat(singleProduct.sale_price) * parseFloat(singleProduct.quantity) - parseFloat(discount) 
              } else { // Discount type Amount
                discount = o.coupon_discount_value;
                percentage = parseFloat(discount / (singleProduct.sale_price * singleProduct.quantity)) * 100;
                total = parseFloat(total) + parseFloat(singleProduct.sale_price) * parseFloat(singleProduct.quantity) - parseFloat(discount) * parseFloat(maxQty);

              }
              let newItem = { 'server_id': singleProduct.server_id, 'product_id': singleProduct.server_id }
              newProduct.push(newItem);
            }
            coupon_found = 0;
          });
          if (!coupon_found) {  // Coupon not found
            const exists = _.findIndex(newProduct, function (pro) {
              return pro.product_id == singleProduct.server_id;
            });
            if (exists < 0) {// Product not exists
              total = parseFloat(total) + parseFloat(singleProduct.sale_price) * parseFloat(singleProduct.quantity) - parseFloat(singleProduct.total_discount);
            }
          }
        });
        if (order.order_discount <= 0 && orderTypeCoupon.length > 0 && orderTypeAmount.length > 0) { // Received order Coupon of amount type
          if (order.total >= parseFloat(orderTypeAmount[0].min_amount)) { // Order total more than Coupon minimum amount
            let usageCheck = orderTypeAmount[0].max_usage - orderTypeAmount[0].times_used; // Store the value how many time coupon we can use
            if (usageCheck >= 1) {
              if (parseFloat(total) >= parseFloat(orderTypeAmount[0].min_amount)) { //total is more than minimum amount of order coupon
                let newCouponState = { // Apply coupon
                  'coupon_name': orderTypeAmount[0].title,
                  'coupon_discount_type': orderTypeAmount[0].discount_type,
                  'coupon_discount_value': parseFloat(orderTypeAmount[0].discount_value),
                  'coupon_order_min_amount': parseFloat(orderTypeAmount[0].min_amount),
                  'coupon_id': orderTypeAmount[0].id,
                  'campaign_id': campaign_id,
                  'reward_card_id': action.payload.response.reward_card_id,
                  'campaign_coupon_id': orderTypeAmount[0].campaign_coupon_id,
                  'applied': 0,
                  'reason': "-",
                  'reject': 0
                };
                let newState = { ...state, order: newCouponState, products: productCouponMatch };
                return newState;
              } else {// Reject coupon
                let rejectStatus = {
                  'reject': 1,
                  'applied': 0,
                  'coupon_name': orderTypeAmount[0].title,
                  'reason': Constant.ORDER_MINIMUM_AMOUNT_LESS_PRODUCT_ELIGIBLE_CHECK
                };
                let newState = { ...state, order: rejectStatus, products: productCouponMatch };
                return newState;
              }
            } else {// Reject Coupon
              let rejectStatus = {
                'reject': 1,
                'applied': 0,
                'coupon_name': orderTypeAmount[0].title,
                'reason': Constant.COUPON_MAX_USAGE
              };
              let newState = { ...state, order: rejectStatus, products: productCouponMatch };
              return newState;
            }
          } else {//Reject Coupon
            let rejectStatus = {
              'reject': 1,
              'applied': 0,
              'coupon_name': orderTypeAmount[0].title,
              'reason':Constant.ORDER_MINIMUM_AMOUNT_LESS,
            };
            let newState = { ...state, order: rejectStatus, products: productCouponMatch };
            return newState;
          }
        } else if (order.order_discount <= 0 && orderTypeCoupon.length > 0 && orderTypePer.length > 0) {//Order coupon percentage type
          if (order.total >= parseFloat(orderTypePer[0].min_amount)) { //Order Total more than minimum amount
            let usageCheck = orderTypePer[0].max_usage - orderTypePer[0].times_used; // Store the value how many time coupon we can use
            if (usageCheck >= 1) {
              if (parseFloat(total) >= parseFloat(orderTypePer[0].min_amount)) { //Total more than coupon's minimum amount
                let newCouponState = { //Apply coupon
                  'coupon_name': orderTypePer[0].title,
                  'coupon_discount_type': orderTypePer[0].discount_type,
                  'coupon_discount_value': parseFloat(orderTypePer[0].discount_value),
                  'coupon_order_min_amount': parseFloat(orderTypePer[0].min_amount),
                  'coupon_id': orderTypePer[0].id,
                  'campaign_id': campaign_id,
                  'reward_card_id': action.payload.response.reward_card_id,
                  'campaign_coupon_id': orderTypePer[0].campaign_coupon_id,
                  'applied': 0,
                  'reject': 0,
                  'reason': "-",
                };
                let newState = { ...state, order: newCouponState, products: productCouponMatch };
                return newState;
              } else {//Reject coupon
                let rejectStatus = {
                  'reject': 1,
                  'applied': 0,
                  'coupon_name': orderTypePer[0].title,
                  'reason': Constant.ORDER_MINIMUM_AMOUNT_LESS_PRODUCT_ELIGIBLE_CHECK
                };
                let newState = { ...state, order: rejectStatus, products: productCouponMatch };
                return newState;
              }
            } else {//Reject coupon
              let rejectStatus = {
                'reject': 1,
                'applied': 0,
                'coupon_name': orderTypePer[0].title,
                'reason': Constant.COUPON_MAX_USAGE
              };
              let newState = { ...state, order: rejectStatus, products: productCouponMatch };
              return newState;
            }
          } else {//Reject coupon
            let rejectStatus = {
              'applied': 0,
              'reject': 1,
              'coupon_name': orderTypePer[0].title,
              'reason': Constant.ORDER_MINIMUM_AMOUNT_LESS
            };
            let newState = { ...state, order: rejectStatus, products: productCouponMatch };
            return newState;
          }
        }
        else if (order.order_discount <= 0 && orderTypeCoupon.length == 0) { // Manual or Coupon discount not Applied
          let newState = { ...state, products: productCouponMatch };
          return newState;
        }
        else if(orderTypeCoupon.length > 0) {// Order Coupon available but manual discount already applied
          let newCouponState = { //Reject coupon
            'applied': 0,
            'reject': 1,
            'coupon_name':orderTypeCoupon[0].title,
            'reason': Constant.MANUAL_DISCOUNT_ALREADY_APPLIED,
          };
          let newState = { ...state, order: newCouponState, products: productCouponMatch };

          return newState;
        }else{// Pass the Coupon payload
          let newState = {order: {}, products: productCouponMatch  }
          return newState;
        }
      }
    }

    case CANCLE_COUPON:
      {
        let newOrder = {};
        let newProduct = [];

        if (state.order.applied == 1) { // Order coupon applied 
          newOrder = state.order; // Retuen Order details
        }
        //  else {
        //   newOrder = {};
        // }


        state.products.map((singleProduct) => { // Products
          if (singleProduct.applied === 1) { //Product's coupon apllied
            newProduct.push(singleProduct); // Reurn lined details
          } 
          // else {
          // }

        })

        let newCouponsummaryState = {// Return Order, products data
          'order': newOrder,
          'products': newProduct
        };

        return newCouponsummaryState;
      }

    case DELETE_ORDER_COUPON: {

      let newOrder = {};
      let newProduct = state.products;

      let newCouponsummaryState = {
        'order': newOrder,
        'products': newProduct
      };
      return newCouponsummaryState;

    }

    case CLEAR_COUPON: {

      let newOrder = {};
      let newProduct = [];

      let newCouponsummaryState = {
        'order': newOrder,
        'products': newProduct
      };

      return newCouponsummaryState;

    }

    case APPLY_COUPON: {

      let newOrder = {};
      let newProduct = [];

      if (state.order.reject == 0) {// Order Coupon apply

        newOrder = {
          'coupon_type': 'ord',
          'coupon_name':state.order.coupon_name,
          'coupon_discount_type': state.order.coupon_discount_type,
          'coupon_discount_value': state.order.coupon_discount_value,
          'coupon_order_min_amount': state.order.coupon_order_min_amount,
          'coupon_id': state.order.coupon_id,
          'campaign_id': state.order.campaign_id,
          'reward_card_id': state.order.reward_card_id,
          'campaign_coupon_id':state.order.campaign_coupon_id,
          'applied': 1,
          'reason': state.order.reason,
          'reject': state.order.reject
        }

      } 
      // else {
      //   newOrder = {}
      // }

      let newSingleProduct = {};

      state.products.map((singleProduct) => {

        if (singleProduct.reject == 0) {//Product coupon apply

          newSingleProduct = {
            'coupon_type': 'prod',
            'coupon_name':singleProduct.coupon_name,
            'coupon_discount_type': singleProduct.coupon_discount_type,
            'coupon_discount_value': singleProduct.coupon_discount_value,
            'coupon_id': singleProduct.coupon_id,
            'campaign_id': singleProduct.campaign_id,
            'product_id': singleProduct.product_id,
            'product_name': singleProduct.product_name,
            'reward_card_id': singleProduct.reward_card_id,
            'campaign_coupon_id':singleProduct.campaign_coupon_id,
            'applied': 1,
            "reason": singleProduct.reason,
            'reject': singleProduct.reject
          };

          newProduct.push(newSingleProduct);
        }
        //  else {

        // }

      });
      let newCouponsummaryState = {
        'order': newOrder,
        'products': newProduct
      };

      return newCouponsummaryState;
    }

    case REMOVE_ITEM_COUPON: {

      let itemAlreadyExists = _.findIndex(state.products, function (o) {
        return o.coupon_id == action.payload.applied_coupon.coupon_id;
      });

      let newProducts = state.products.splice(itemAlreadyExists, 1);

      let newState = {
        'order': state.order,
        'products': state.products
      }
      return newState;

    }
    case CLEAR_ITEMS:
      {
        let newState = {
          'order': {},
          'products': []
        }
        return newState;
      }

    case MINUS_ITEM: {

      if ('applied_coupon' in action.payload.item && action.payload.item.applied_coupon != undefined) {
        let itemAlreadyExists = _.findIndex(state.products, function (o) { return o.coupon_id == action.payload.item.applied_coupon.coupon_id; });
        if (itemAlreadyExists > -1) {
          let actionItem = action.payload.item;
          // if ('applied_coupon' in action.payload.item && action.payload.item.applied_coupon != undefined) {
          var qty = actionItem.quantity;
          if (qty <= 0) {
            var newState = state;
            newState.products.splice(itemAlreadyExists, 1);
            return (newState);
          } else {
            return (state);
          }
          // } else {
          //   return (state);
          // }

        } else {
          return (state);
        }
      } else {
        return (state);
      }

    }
    case DELETE_ITEM: {
      if ('applied_coupon' in action.payload.item && action.payload.item.applied_coupon != undefined) {
        let itemAlreadyExists = _.findIndex(state.products, function (o) { return o.coupon_id == action.payload.item.applied_coupon.coupon_id; });

        if (itemAlreadyExists > -1) {
          let actionItem = action.payload.item;
          state.products.splice(itemAlreadyExists, 1);
          return (state);
        } else {
          return (state);
        }

      }
      else {
        return (state);
      }
    }
    case COMPLETE_ORDER: {
      let newState = {
        'order': {},
        'products': []
      }
      return newState;
    }
    case TICKET_COUPON_STATUS: {
      let newProduct=[];
      action.payload.coupon_status.products.map(function(o) {
          let newState = {...o,applied:1};
          newProduct.push(newState);
      });
      let newState = {order:action.payload.coupon_status.order,products:newProduct};
      return newState;
    }
  }
  return state;
}
