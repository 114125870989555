import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Row, Col, Typography } from "antd";
import { Button, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { confirmPassword } from "../services/user/user";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userLogin } from "../actions/user_actions";
import logo from "../assets/images/Octopos-logo-blue.png";
import { LinearProgress } from "@material-ui/core";
import * as Constant from "../constants";
import Footer from "../../src/components/layout/Footer";
import sideImage from "../assets/images/auth13.jpg"

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const { Title } = Typography;
const { Header, Content } = Layout;
const styles = (theme) => ({
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
      width: "0%",
    },
  },
  imageLogo:{
    [theme.breakpoints.down("md")]: {
      textAlign:'center',
    },
    // textAlign:'center',
    paddingBottom:16,
    width:'50%'
  }
});

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView();

  return { executeScroll, elRef };
};

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmpassword: "",
      password: "",
      loading: false,
      backgroundImage:"",
      image_loc:'',
      errors: {
        confirmpassword: "",
        password: "",
      },
    };
    this.inputRefs = []
    this.elScroll = utilizeScroll();
  }
  componentDidMount(){
    let flag_image=false
    let online_location_image = false
    if(null != this.props.locations.allLocations && undefined != this.props.locations.allLocations && '' != this.props.locations.allLocations) {
      this.props.locations.allLocations.map((locations, index) => {
        if(!flag_image && locations.image_url != null && locations.image_url != undefined && locations.image_url != ''){
          
          this.setState({image_loc:locations.image_url})
          flag_image=true
        }
        locations.location_online_order_settings.map((settings, i) => {
          if(!online_location_image && settings.image_url_online != null && settings.image_url_online != undefined && settings.image_url_online != ''){
            online_location_image=true
            this.setState({backgroundImage:settings.image_url_online})
          } else if(!online_location_image && locations.location_online_order_settings.length - 1 == i && this.state.backgroundImage == ""){
            this.setState({backgroundImage:sideImage })
            online_location_image=true
          }
        });
      })
    } else {
      this.setState({backgroundImage:sideImage })
    }
  }
  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "password":
        errors.password =
          value.length === 0
            ? Constant.PASSWORD_FIELD_REQUIRED
            : value.length < 8
            ? Constant.PASSWORD_MUST_BE_8_CHARACHTER
            : "";
        break;
      case "confirmpassword":
        errors.confirmpassword =
          value.length === 0
            ? Constant.CONFIRM_PASSWORD_FIELD_REQUIRED
            : value.length < 8
            ? Constant.CONFIRM_PASSWORD_MUST_BE_8_CHARACHTER
            : this.state.password != value
            ? Constant.PASSWORD_CONFIRM_PASSWORD_NOT_SAME
            : "";

        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };
  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
  };
  }
  backToHome = () => {
    let path=window.location.pathname.slice(1).split('/')
    localStorage.setItem('tenant',path[2])
    window.location.href=process.env.REACT_APP_ACCESS_KEY +'/'+ localStorage.getItem('tenant') 
  };
  LogIn = () => {
    this.elScroll.executeScroll()
    this.setState({ loading: true });
    let path=window.location.pathname.slice(1).split('/')
    localStorage.setItem('tenant',path[2])
    if (
      null != this.state.password &&
      undefined != this.state.password &&
      "" != this.state.password.trim()
    ) {
      if (
        null != this.state.confirmpassword &&
        undefined != this.state.confirmpassword &&
        "" != this.state.confirmpassword.trim() 
        // this.state.confirmpassword.trim() == this.state.password.trim()
      ) {
        if( this.state.confirmpassword.trim() === this.state.password.trim()){
          let payload = {
            password: this.state.password,
            confirmPassword: this.state.confirmpassword,
            token: path[4],
          };
  
          confirmPassword(payload)
            .then((res) => {
              if (res.data.flag) {
                this.setState({ loading: false });
                toast.success(res.data.message);
                window.location.href=process.env.REACT_APP_ACCESS_KEY +'/'+ localStorage.getItem('tenant') + "/sign-in"
                // const path = "/" + localStorage.getItem("tenant") + "/sign-in";
                // this.props.history.push(path);
              } else {
                this.setState({ loading: false });
                toast.error(res.data.message);
              }
            })
            .catch((err) => {
              this.setState({ loading: false });
              toast.error(err.toString());
            });
        }else{
          this.setState({ loading: false });
          toast.error(Constant.PASSWORD_CONFIRM_PASSWORD_NOT_SAME);
        }
        
      }
    }
  };
  _handleKeyPress = e => {
    const event = e;
    const { currentTarget } = e;
    if (event.key === "Enter") {
      let inputIndex = this.inputRefs.indexOf(currentTarget);
      if (inputIndex < this.inputRefs.length - 1) {
        this.inputRefs[inputIndex + 1].focus();
      } else {
        this.inputRefs[0].focus();
      }
      event.preventDefault();
    }
  };
  render() {
    const { classes } = this.props;

    return (
      <>
      <div ref={this.elScroll.elRef}></div>
        {this.state.loading ? <LinearProgress color="primary" /> : null}
        <Layout className="layout-default layout-signin">
          <Header>
            <div className="header-brand" key={"brandName"}>
              {/* <img src={logo} alt="" height={40} /> */}
            </div>
          </Header>
          <Content className="signin">
            <Row gutter={[24, 0]} justify="flex-start" flex-direction="column">
              <div className={classes.quoteContainer}>
              <Col
                  className="sign-img"
                  style={{
                    backgroundImage: `url("${this.state.backgroundImage}")`,
                    height: "300px",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '30%',
                     position: 'absolute',
                     left: '0px',
                     top:'0px',
                     height: '100%',
                     boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
                  }}             
                   >
                  {/* <img src="https://img.freepik.com/premium-photo/closeup-drop-water-leaf-flower-reflecting-surrounding-colors-textures_674594-4382.jpg" alt="" /> */}
                </Col>
              </div>
              <div className={classes.quote}>
                <Col className="select-order">
                <div className={classes.imageLogo}>
                  <img src={this.state.image_loc} alt=""  className="location-image" />
                  </div>
                  <Title className="font-regular text-muted" level={2}>
                    Reset Password
                  </Title>
                  <div
                    layout="vertical"
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      autoFocus
                      style={{ marginBottom: "2%" }}
                      error={
                        this.state.errors.password.length == 0 ? false : true
                      }
                      size="small"
                      helperText={this.state.errors.password}
                      label="Password *"
                      name="password"
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      inputProps={{ onKeyPress: this._handleKeyPress }}
                      inputRef={ref => this.inputRefs.push(ref)}
                      type="password"
                      value={this.state.password}
                      variant="outlined"
                    />
                    <TextField
                      style={{ marginBottom: "2%" }}
                      error={
                        this.state.errors.confirmpassword.length == 0
                          ? false
                          : true
                      }
                      size="small"
                      helperText={this.state.errors.confirmpassword}
                      label="Confirm Password *"
                      name="confirmpassword"
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      inputRef={ref => this.inputRefs.push(ref)}
                      ref={"input2"}
                      // inputProps={{ onKeyPress: this._handleKeyPress }}
                       onKeyPress= {(e) => {
                        if (e.key === 'Enter' && this.state.password !== "" &&
                        this.state.errors.password.length === 0 &&
                        this.state.confirmpassword !== "" &&
                        this.state.errors.confirmpassword.length === 0) {
                          this.LogIn()
                        }
                      }}
                      type="password"
                      value={this.state.confirmpassword}
                      variant="outlined"
                    />
                    <p className="font-semibold text-muted fw-normal lh-lg text-right">
                      <Link
                        to={`/${localStorage.getItem("tenant")}/sign-in`}
                        className="text-dark font-bold text-muted"
                      >
                        Login Now?&nbsp;&nbsp;&nbsp;&nbsp;
                      </Link>
                    </p>
                    <Row gutter={[24, 0]} justify="center">
                      <Col xl={14} xs={14} sm={14} md={14} lg={14}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={this.LogIn}
                          style={{ marginBottom: "7px" }}
                          disabled={
                            this.state.password == "" ||
                            this.state.errors.password.length > 0 || this.state.confirmpassword == "" ||
                            this.state.errors.confirmpassword.length > 0 
                          }
                        >
                          Reset Password
                        </Button>
                      </Col>
                      <Col xl={10} xs={10} sm={10} md={10} lg={10}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={this.backToHome}
                          style={{ marginBottom: "7px" }}
                        >
                          Go Home
                        </Button>
                      </Col>
                    </Row>
             <Footer/>
                  </div>
                </Col>
              </div>
            </Row>
          </Content>
        </Layout>
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ userLogin }, dispatch);
}
function mapStateToProps(state) {
  return {
    user: state.user,
    locations:state.locations

  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(PasswordReset);
