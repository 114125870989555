
export const ADD_CURRENT_DELIVERY_PICKUP = 'ADD_CURRENT_DELIVERY_PICKUP';
export const REMOVE_CURRENT_DELIVERY_PICKUP = 'REMOVE_CURRENT_DELIVERY_PICKUP';
export const UPDATE_ORDER_TYPE = 'UPDATE_ORDER_TYPE';
export const UPDATE_SCHEDULE_TIME = 'UPDATE_SCHEDULE_TIME';

export function addDeliveryPickupDetails(results) {
    let result1 = [results]
    return {
        type: ADD_CURRENT_DELIVERY_PICKUP,
        payload: result1
    };

}

export function removeDeliveryPickupDetails() {
    return {

        type: REMOVE_CURRENT_DELIVERY_PICKUP,
        payload: []
    };
}

export function updateOrdertype(data) {
    let result1 = [data]
    return {

        type: UPDATE_ORDER_TYPE,
        payload: result1
    };
}

export function updateScheduleTime(data) {
    let result1 = [data]
    return {
        type: UPDATE_SCHEDULE_TIME,
        payload: result1
    };
}