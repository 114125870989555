import React from "react";
import { withStyles } from "@material-ui/styles";
import {
  currentItem,
  removeCurrentItem,
} from "../../../../actions/current_item";
import { insertItem } from "../../../../actions/order_items";
import { orderCalculation } from "../../../../actions/order_actions";
import { orderUser , userLogout } from "../../../../actions/user_actions";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { addRewardCard } from "../../../../actions/order_actions"
import { updateOrdertype } from "../../../../actions/delivery_pickup_action";
import {
  Paper,
  Button ,
  TextField,
  Grid
} from "@material-ui/core";
import { getRewardCard } from "../../../../services/reward/reward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Constant from "../../../../constants";
import { Link } from "react-router-dom";
import { removeUserCardDetails } from "../../../../actions/card_Details_action";
import { LinearProgress } from "@material-ui/core";

const useStyles = (theme) => ({
  flexLayout: {
    flex: 10,
    flexDirection: "row",
    alignItems: "stretch",
    overflow: "hidden",
    padding:13,
    marginBottom:8,
    backgroundColor:"#fff",
    overflowY: "scroll",
  },
  buttonLayout: {
    display: "flex",
    flex: 0.6,
    padding: 8,
    justifyContent: "center",
    // alignItems:"center",
    backgroundColor:"#fff",
  },
});

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView();

  return { executeScroll, elRef };
};

class AddRewardCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      item_qty: 1,
      number: "",
      isDelivery: false,
      isPickUp: false,
      deliveryTime: "",
      loading: false,
      changePickup: false,
      errors: {
        number: "",
      },
    };
    this.elScroll = utilizeScroll();
  }

  componentDidMount() {}

  componentWillUnmount() {
      this.setState = (state,callback)=>{
        return;
    };
  }

  rewadsEarn = () => {
    this.elScroll.executeScroll()
    this.setState({ loading: true });
        if (
          null != this.state.number &&
          undefined != this.state.number &&
          "" != this.state.number.trim()
        ) {
          let phone = this.validate(this.state.number.trim())
          if(phone) {
            getRewardCard(this.state.number).then((res) => {
              if (res.data.error) {
                if (res.data.error.code === 404) {
                  // const path = "/" + localStorage.getItem("tenant") + "/404";
                  this.props.history.push('/404');
                }
              } else if(res.data.flag) {
                    this.goBack("checkout");
                    this.props.addRewardCard(res.data.data.id,res.data.data.phone)
                    toast.success(Constant.ADD_REWARD_SUCCESSFULL)
                    this.setState({ loading: false });
                } else {
                  if (res.data.code == 999) {
                    this.setState({ loading: false });
                    this.props.removeUserCardDetails();
                    this.props.userLogout();
                    localStorage.removeItem('token')
                    this.goBack("signIn");
                    toast.error(res.data.message);
                  } else {
                    this.setState({ loading: false });
                    toast.error(res.data.message);
                  }
                }
            })
            .catch((error) => {
              this.setState({ loading: false });
                toast.error(error.toString());
            })
          } else {
            this.setState({ loading: false });
            toast.error(Constant.USER_PHONE_NUMBER__NOT_VALID);
          }
        } else {
          this.setState({ loading: false });
            toast.error(Constant.ENTER_VALID_PHONE_NUMBER);
        }
  };

  validate = (phone) => {
    const regex = /^[0-9\b]+$/;
    return regex.test(phone)
  }
  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const that = this;
    let errors = this.state.errors;
   
    switch (name) {
      case "number":
        errors.number =
          value.length === 0
            ? Constant.USER_PHONE_NUMBER_REQUIRED
            : this.validate(value)
            ? value.length > 10
            ? Constant.USER_PHONE_NUMBER_GREATER_THEN_10
            :  value.length < 3
            ? Constant.USER_PHONE_NUMBER_MUST_BE_3_DIGITS
            :  ""
            : Constant.USER_PHONE_NUMBER__NOT_VALID;
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };

  goBack = (type) => {
    this.props.checkOutInsidePage(type);
  };

  render() {
    const { classes } = this.props;
    return (
      <>
      <div ref={this.elScroll.elRef}></div>
      {this.state.loading ? <LinearProgress color="primary" /> : null}
      <Paper elevation={1} className={classes.flexLayout}>
      <Grid
            container
            direction="row"
            // justifyContent="center"
            alignItems="center"
          >
            <Grid xs={2} md={2} lg={2} item>
            <Link  to="#" className="text-dark font-bold">
          <ArrowBackIcon onClick={() => this.goBack("checkout")} />
          </Link>
            </Grid>
            <Grid xs={8} md={8} lg={8} item>
              <h1 className="sidebar-layout-header-name">{"Reward Card"}</h1>
            </Grid>
          </Grid>
          
       
       <TextField
            autoFocus
            style={{ margin: "2%",borderRadius:0 ,width: "96%"}}
            error={this.state.errors.number.length == 0 ? false : true}
            fullWidth
            size="small"
            helperText={this.state.errors.number}
            label="Phone Number *"
            name="number"
            onChange={this.handleInputChange}
            onBlur={this.handleInputChange}
            onKeyPress= {(e) => {
              if (e.key === 'Enter' && this.state.number !== "" && this.state.errors.number === "") {
                this.rewadsEarn()
              }
            }}
            type="text"
            value={this.state.number}
            variant="outlined"
          />
      </Paper>
      <Paper elevation={1} className={classes.buttonLayout}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "100%", height: "93%" }}
            onClick={() => this.rewadsEarn()}
            disabled={this.state.number == "" || this.state.errors.number != ""}
          >
            Submit
          </Button>
          </Paper>
    </>
    );
  }
}

// export default withStyles(useStyles, { withTheme: true })(OrderItemsDetails);
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      currentItem,
      removeCurrentItem,
      removeUserCardDetails,
      orderCalculation,
      insertItem,
      userLogout,
      updateOrdertype,
      orderUser,
      addRewardCard
    },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    current_item: state.current_item,
    locations: state.locations,
    delivery_pickup: state.delivery_pickup,
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true })
)(AddRewardCard);
