import { VIEW_TAXES, UPDATE_TAXES } from '../actions/taxes';

export default function (state = [], action) {
    switch (action.type){
    case VIEW_TAXES:
        {
            return  state;
        }
    case UPDATE_TAXES:
        {
            return action.payload;
        }     
    }
    return state;
}
