export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'
export const WHO_IS_THIS_FOR_USER = 'WHO_IS_THIS_FOR_USER'
export const REMOVE_WHO_IS_THIS_FOR_USER = 'REMOVE_WHO_IS_THIS_FOR_USER'
export const ADD_PAYMENT_CARD_ID = 'ADD_PAYMENT_CARD_ID'

export function userLogin(user) {
  return {
    type: USER_LOGIN,
    payload: user,
  }
}

export function orderUser(user) {
  return {
    type: WHO_IS_THIS_FOR_USER,
    payload: user,
  }
}

export function removeOrderUser() {
  return {
    type: REMOVE_WHO_IS_THIS_FOR_USER,
  }
}

export function addPaymentCardId(id) {
  return {
    type: ADD_PAYMENT_CARD_ID,
    payload: id,
  }
}

export function userLogout() {
  return {
    type: USER_LOGOUT,
  }
}
