import React from "react";
import { Link } from "react-router-dom";
import { Row } from "antd";
// import { withStyles } from "@material-ui/styles";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import { deleteItem , clearItems} from "../../../../actions/order_items";
import { orderCalculation } from "../../../../actions/order_actions";
import * as Constant from "../../../../constants";
import { toast } from "react-toastify";
import {
  currentItem,
  removeCurrentItem,
} from "../../../../actions/current_item";
import {
  Grid,
  List,
  ListItemText,
  ListItem,
  Button,
  ButtonGroup,
  Paper,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = (theme) => ({
  flexLayout: {
    flex: 8,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "stretch",
    overflow: "hidden",
    marginBottom: 3,
    overflowY: "scroll",
    [theme.breakpoints.down("md")]: {
      flex: 6,
    },
  },
  buttonGroupLayout: {
    marginTop: "5%",
    marginBottom: "5%",
  },
});

class OrderItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDelivery: true,
      isPickUp: false,
    };
  }
  componentDidMount() {
    this.setState({
      isDelivery:
        this.props.delivery_pickup[0].order_type === "delivery" ? true : false,
      isPickUp:
        this.props.delivery_pickup[0].order_type === "pickup" ? true : false,
    });
  }

  handleDeleteRow = (item) => {
    this.props.deleteItem(item);
    this.props.orderCalculation(); // Reducer call to store order data
    //Sore order data in reducer
  };

  updateItem = (item) => {
    if(this.props.order_items[0]?.gift_card == 1) {
      toast.error(Constant.CAN_NOT_ADD_PRODUCT);
    } else if(this.props.payments.length > 0) {
      toast.error(Constant.HALF_PAYMENT);
    } else {
      this.props.removeCurrentItem();
      let updateItem = item;
      updateItem.is_update = true;
      this.props.currentItem(updateItem);
    }
  };

  renderProductName = (item) => {
    var final = "";
    var single_price = "";
    if (item.is_modifier_product) {
      item.modifier_set.map((single, index) => {
        if (single.selected_modifiers.length > 0) {
          single_price = single.selected_modifiers
            .map((item) => item.name)
            .reduce((prev, next) => prev + ", " + next);
          if (single_price != "") {
            if (final == "") {
              final = final + "" + single_price;
            } else {
              final = final + ", " + single_price;
            }
          }
        }
      });
    }
    if (final) {
      final = item.name + " (" + final + ")";
      return (
        <Link to="#">
        <ListItemText
          primary={final}
          style={{ color:"#000" }}
          onClick={() => this.updateItem(item)}
        />
        </Link>
      );
    } else {
      return (
        <Link to="#">
        <ListItemText
          primary={item.name}
          style={{ color:"#000" }}
          onClick={() => this.updateItem(item)}
        />
        </Link>
      );
    }
  };

  componentWillUnmount() {}


  render() {
    const { classes } = this.props;
    return (
      <>
        <Paper elevation={0} square className={classes.flexLayout}>
          <Row justify="center">
            <LocalMallOutlinedIcon fontSize="large" />
            &nbsp;&nbsp;
            <h1 className="sidebar-layout-header-name">Your Bag</h1>
          </Row>
          <Row
            gutter={[24, 0]}
            justify="center"
            className={classes.buttonGroupLayout}
          >
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <Button
                style={{ width: "100%" }}
                variant={this.state.isDelivery ? "contained" : ""}
                color={this.state.isDelivery ? "primary" : ""}
              >
                Delivery
              </Button>
              <Button
                style={{ width: "100%" }}
                variant={this.state.isPickUp ? "contained" : ""}
                color={this.state.isPickUp ? "primary" : ""}
              >
                Pick Up
              </Button>
            </ButtonGroup>
          </Row>
          <List
            className="item-list"
          >
            {this.props.order_items.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <ListItemText style={{ color:"#000" }}>{item.quantity}</ListItemText>
                    </Grid>
                    <Grid item xs={6}>
                      {this.renderProductName(item)}
                    </Grid>
                    <Grid item xs={2}>
                      <ListItemText style={{ textAlign: "center", color:"#000" }}>
                        {item.online_ordering_price != null &&
                        undefined != item.online_ordering_price &&
                        "" != item.online_ordering_price
                          ? "$" + item.online_ordering_price.toFixed(2)
                          : "$" + item.sale_price.toFixed(2)}
                      </ListItemText>
                    </Grid>
                    <Grid item xs={2}style={{ textAlign: "right"}}>
                    <Link  to="#">
                      <DeleteIcon
                        style={{ fontSize: 30 }}
                        color="secondary"
                        onClick={() => this.handleDeleteRow(item)}
                      />
                    </Link>
                    </Grid>
                  </Grid>
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { deleteItem, orderCalculation, currentItem, removeCurrentItem, clearItems },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    delivery_pickup: state.delivery_pickup,
    order_items: state.order_items,
    order: state.order,
    payments: state.payments,
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true })
)(OrderItems);
