
export const VIEW_FEATURED_PRODUCTS = 'VIEW_FEATURED_PRODUCTS';
export const VIEW_PRODUCTS = 'VIEW_PRODUCTS';
export const REMOVE_PRODUCTS_CATEGORIES = 'REMOVE_PRODUCTS_CATEGORIES';
export const REMOVE_FEATURED_PRODUCTS = 'REMOVE_FEATURED_PRODUCTS';



export function viewFeaturedProducts(results) {
    return {
        type: VIEW_FEATURED_PRODUCTS,
        payload: results
    };

}

export function viewProducts(results) {
    return {

        type: VIEW_PRODUCTS,
        payload: results
    };
}

export function removeProducts() {
    return {
        type: REMOVE_PRODUCTS_CATEGORIES,
        payload: []
    };
}

export function removeFeaturedProducts() {
    return {
        type: REMOVE_FEATURED_PRODUCTS,
        payload: []
    };
}
