import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Tables from "./pages/Tables";
import SignUp from "./pages/SignUp";
import PageNotFound from "./pages/PageNotFound";
import LogIn from "./pages/SignIn";
import DeliveryDetails from "./pages/DeliveryDetails";
import OrderStatus from "../src/components/layout/orderItems/orderStatus/orderStatus";
import Main from "./components/layout/Main";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { ToastContainer } from "react-toastify";
import OrderDetails from "./pages/OrderDetails";
import PasswordReset from "./pages/PasswordReset";
import ForgotPassword from "./pages/ForgotPassword";
import GiftCardSell from "./pages/GiftCardSell";
// const name = window.location.pathname.substring(1)
let name;
// if(localStorage.getItem('tenant')){
//   name=localStorage.getItem('tenant')
// }else{
//   if(null != window.location.search.slice(1) && '' != window.location.search.slice(1)){
//     name = window.location.search.slice(1)
//  }
// }

class App extends React.Component {
  constructor(props) {
    super(props);
    if (localStorage.getItem("tenant")) {
      let urlTenent = window.location.search.slice(1).split("/");
      if (
        null !== urlTenent[0] &&
        "" !== urlTenent[0] &&
        undefined !== urlTenent[0]
      ) {
        if ("app" == urlTenent[0] && "build" == urlTenent[1]) {
          name = urlTenent[2];
          if (urlTenent[2] != localStorage.getItem("tenant")) {
            localStorage.removeItem("tenant");
          }
        } else {
          name = window.location.search.slice(1);
          if (urlTenent[0] != localStorage.getItem("tenant")) {
            localStorage.removeItem("tenant");
          }
        }
      } else {
        name = localStorage.getItem("tenant");
      }
    } else {
      localStorage.removeItem("tenant");
      if (
        null != window.location.search.slice(1) &&
        "" != window.location.search.slice(1)
      ) {
        name = window.location.search.slice(1);
      }
    }
  }
  render() {
    if (name == "" || name == null || name == undefined) {
      return (
        <div className="App">
          <ToastContainer position="top-right" />
          <Switch>
            <Route path="/404" component={PageNotFound} />
            <Redirect from="/" to="/404" />
          </Switch>
        </div>
      );
    } else if (name.includes("/reset")) {
      return (
        <div className="App">
          <ToastContainer position="top-right" />
          <Switch>
            <Route
              path={`/${localStorage.getItem("tenant")}/sign-up`}
              exact
              component={SignUp}
            />
            <Route
              path={`/${localStorage.getItem("tenant")}/sign-in`}
              exact
              component={LogIn}
            />
            <Route
              path={`/${localStorage.getItem("tenant")}/forgot`}
              exact
              component={ForgotPassword}
            />
            <Route
              path={`/${localStorage.getItem("tenant")}/gift-card`}
              exact
              component={GiftCardSell}
            />
            <Route path={`/${name}`} exact component={PasswordReset} />
            <Redirect from="*" to={`/${name}`} />
          </Switch>
        </div>
      );
    } else if (name.includes("/sign-up")) {
      return (
        <div className="App">
          <ToastContainer position="top-right" />
          <Switch>
            <Route
              path={`/${localStorage.getItem("tenant")}/sign-in`}
              exact
              component={LogIn}
            />
            <Route
              path={`/${localStorage.getItem("tenant")}/forgot`}
              exact
              component={ForgotPassword}
            />
            <Route
              path={`/${localStorage.getItem("tenant")}/gift-card`}
              exact
              component={GiftCardSell}
            />
            <Route path={`/${name}`} exact component={SignUp} />
            <Redirect from="*" to={`/${name}`} />
          </Switch>
        </div>
      );
    } else if (name.includes("/sign-in")) {
      return (
        <div className="App">
          <ToastContainer position="top-right" />
          <Switch>
            <Route
              path={`/${localStorage.getItem("tenant")}/sign-up`}
              exact
              component={SignUp}
            />
            <Route
              path={`/${localStorage.getItem("tenant")}/forgot`}
              exact
              component={ForgotPassword}
            />
            <Route
              path={`/${localStorage.getItem("tenant")}/gift-card`}
              exact
              component={GiftCardSell}
            />
            <Route path={`/${name}`} exact component={LogIn} />
            <Redirect from="*" to={`/${name}`} />
          </Switch>
        </div>
      );
    } else if (name.includes("/forgot")) {
      return (
        <div className="App">
          <ToastContainer position="top-right" />
          <Switch>
            <Route
              path={`/${localStorage.getItem("tenant")}/sign-up`}
              exact
              component={SignUp}
            />
            <Route
              path={`/${localStorage.getItem("tenant")}/sign-in`}
              exact
              component={LogIn}
            />
            <Route
              path={`/${localStorage.getItem("tenant")}/gift-card`}
              exact
              component={GiftCardSell}
            />
            <Route path={`/${name}`} exact component={ForgotPassword} />
            <Redirect from="*" to={`/${name}`} />
          </Switch>
        </div>
      );
    } else if (name.includes("/gift-card")) {
      return (
        <div className="App">
          <ToastContainer position="top-right" />
          <Switch>
            <Route
              path={`/${localStorage.getItem("tenant")}/sign-up`}
              exact
              component={SignUp}
            />
            <Route
              path={`/${localStorage.getItem("tenant")}/sign-in`}
              exact
              component={LogIn}
            />
            <Route path={`/${name}`} exact component={GiftCardSell} />
            <Redirect from="*" to={`/${name}`} />
          </Switch>
        </div>
      );
    } else {
      return (
        <div className="App">
          <ToastContainer position="top-right" />
          <Switch>
            <Route
              path={`/${localStorage.getItem("tenant")}/sign-up`}
              exact
              component={SignUp}
            />
            <Route
              path={`/${localStorage.getItem("tenant")}/sign-in`}
              exact
              component={LogIn}
            />
            <Route
              path={`/${localStorage.getItem("tenant")}/forgot`}
              exact
              component={ForgotPassword}
            />
            <Route
              path={`/${localStorage.getItem("tenant")}/gift-card`}
              exact
              component={GiftCardSell}
            />
            <Route
              path={`/${localStorage.getItem("tenant")}/reset/:key`}
              exact
              component={PasswordReset}
            />
            <Route path="/404" component={PageNotFound} />
            <Route
              exact
              path={[
                `/${localStorage.getItem("tenant")}/orders`,
                `/${localStorage.getItem("tenant")}/products`,
                "/OrderStatus",
              ]}
            >
              <Main>
                <Switch>
                  <Route
                    path={`/${localStorage.getItem("tenant")}/orders`}
                    component={OrderDetails}
                  />
                  <Route
                    path={`/${localStorage.getItem("tenant")}/products`}
                    component={Tables}
                  />
                  <Route
                    path={`/${localStorage.getItem("tenant")}/OrderStatus`}
                    component={OrderStatus}
                  />
                </Switch>
              </Main>
            </Route>
            <Route
              exact
              path={`/${name}`}
              render={(routeProps) =>
                this.props.category_products.length > 0 &&
                null != localStorage.getItem("tenant") &&
                "" != localStorage.getItem("tenant") &&
                undefined != localStorage.getItem("tenant") ? (
                  <Redirect
                    to={`/${localStorage.getItem("tenant")}/products`}
                  />
                ) : (
                  <Route path={`/${name}`} exact component={DeliveryDetails} />
                )
              }
            />
            <Redirect from="*" to={`/${name}`} />
          </Switch>
        </div>
      );
    }
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}
function mapStateToProps(state) {
  return {
    delivery_pickup: state.delivery_pickup,
    featured_item: state.featured_item,
    category_products: state.category_products,
    current_item: state.current_item,
    user: state.user,
  };
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(App);
