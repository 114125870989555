import { ADD_USER_CARD_DETAILS, REMOVE_USER_CARD_DETAILS } from "../actions/card_Details_action";

export default function (state = [], action) {
  switch (action.type) {
    case ADD_USER_CARD_DETAILS: {
      return action.payload;
    }
    case REMOVE_USER_CARD_DETAILS: {
      return [];
    }
  }

  return state;
}
