import React from "react";
import { Row,  Col } from "antd";
import { withStyles } from "@material-ui/core/styles";
import {
  currentItem,
  removeCurrentItem,
} from "../../../../actions/current_item";
import { insertItem } from "../../../../actions/order_items";
import { orderCalculation } from "../../../../actions/order_actions";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { updateOrdertype } from "../../../../actions/delivery_pickup_action"
import {
  Paper,
  Grid,
  Typography as MTypography,
  Button as MButton,
  ButtonGroup as MButtonGroup,
  TextField,
  AccordionSummary,
  Accordion,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HomeSharpIcon from "@material-ui/icons/HomeSharp";

const useStyles = (theme) => ({
  expanIcon: {
    flexBasis: "10%",
    flexShrink: 0,
    color: "#3950b1",
    [theme.breakpoints.down('sm')]: {
      flexBasis: "15%",
    },
    [theme.breakpoints.up('sm')]: {
      flexBasis: "10%",
    },
    ['@media (device-width: 540px)']: {
      flexBasis: "10%",
    },
  },
  heading: {
    flexBasis: "53.33%",
    flexShrink: 0,
    fontWeight: "bold",
    fontSize: 17,
  },
  EndLayout: {
    flex: 1,
    border: "1px solid rgba(0, 0, 0, 0.2)",
  },
});

class PickUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      item_qty: 1,
      pickUpAddress: "",
      isDelivery: false,
      isPickUp: false,
      deliveryTime: "",
      changePickup: false,
      address: "",
      zipCode: "",
    };
  }

  changeDelivery = (type) => {
      if(type == "Delivery") {
          this.setState({ isDelivery: true,isPickUp: false })
      } else if (type == "PickUp") {
        this.setState({ isDelivery: false,isPickUp: true })
      }
  };
  componentDidMount() {
    if (
      null !== this.props.delivery_pickup &&
      undefined !== this.props.delivery_pickup &&
      this.props.delivery_pickup.length > 0
    ) {
      let time = "";
      this.props.delivery_pickup[0].location.location_online_order_settings.map(
        (settings) => {
          settings.hours_operations.map((time_slot) => {
            if (time_slot.id == this.props.delivery_pickup[0].DeliveryTime) {
              let deliverytime =
                time_slot.from_time + " - " + time_slot.to_time;
              time = deliverytime;
            }
          });
        }
      );
      this.setState({
        isDelivery:
          this.props.delivery_pickup[0].order_type === "delivery"
            ? true
            : false,
        isPickUp:
          this.props.delivery_pickup[0].order_type === "pickup" ? true : false,
        deliveryTime: time,
      });
    }
  }

  componentWillUnmount() {}


  selectPickAddress = (event) => {
    this.setState({ pickUpAddress: event.target.value });
  };

  handleChangePickUp = () => {
    if (this.state.changePickup) {
      this.setState({ changePickup: false });
    } else {
      this.setState({ changePickup: true });
    }
  };

  onFinish = () => {

  };

  changePickUpAddress = () => {
    if(this.state.isDelivery) {
      if (
        "" != this.state.address.trim().length &&
        null != this.state.address.length &&
        this.state.address.trim().length <= 191
      ) {
        if (
          "" != this.state.zipCode.trim().length &&
          null != this.state.zipCode.length &&
          this.state.zipCode.trim().length <= 191
        ) {
          let delivery_Location = {};
          this.props.locations.allLocations.map((location) => {
            location.location_online_order_settings.map((item) => {
              if (item.delivery_zips.length > 0) {
                item.delivery_zips.map((zip1) => {
                  if (zip1.zipcode == this.state.zipCode) {
                    delivery_Location = location
                  }
                });
              }
            });
          });
         
            let deliverydetails = {
              order_type: "delivery",
              location: delivery_Location,
              address: this.state.address,
              city: delivery_Location.city,
              state: delivery_Location.state,
              zipCode: this.state.zipCode,
              scheduleOrder: this.props.delivery_pickup[0].s_Order,
              DeliveryTime: this.props.delivery_pickup[0].deliveryTime,
            };

            this.props.updateOrdertype(deliverydetails)
            this.goBack("checkout")
          
        }
      }

    } else if (this.state.isPickUp) {
      let delivery_Location = {};
      this.props.locations.allLocations.map((location) => {
        if (location.zipcode == this.state.pickUpAddress) {
          delivery_Location = location;
        }
      });
        let deliverydetails = {
          order_type: "pickup" ,
          location: delivery_Location,
          address: delivery_Location.address1,
          city: delivery_Location.city,
          state: delivery_Location.state,
          zipCode: delivery_Location.zipcode,
          scheduleOrder: this.props.delivery_pickup[0].scheduleOrder,
          DeliveryTime: this.props.delivery_pickup[0].DeliveryTime,
        };
        this.props.updateOrdertype(deliverydetails)
        this.goBack("checkout")
    }
    
  };

  onFinishFailed = () => {};

  handleInputChange(e, from) {
    this.setState({
      [from]: e.target.value,
    });
  }

  goBack = (type) => {
    this.props.checkOutInsidePage(type)
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <div
          layout="vertical"
          style={{
            flex: 8,
            flexDirection: "row",
            alignItems: "stretch",
            overflow: "hidden",
            marginBottom: "1%",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <Paper variant="outlined" square style={{ marginBottom: "4%" }}>
            <Row justify="center">
              <Col span={8} style={{ paddingTop: "3%", paddingLeft: "3%" }}>
                <ArrowBackIcon onClick={() => this.goBack("checkout")}/>
              </Col>
              <Col span={16} style={{ paddingTop: "1%", paddingLeft: "3%" }}>
                <h2 style={{ fontWeight: "bold" }}>Pick up</h2>
              </Col>
            </Row>
          </Paper>

          <Row gutter={[24, 0]} justify="center" className="mb-2">
            <MButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <MButton
                variant={this.state.isDelivery ? "contained" : ""}
                color={this.state.isDelivery ? "primary" : ""}
                onClick={() => this.changeDelivery("Delivery")}
              >
                Delivery
              </MButton>
              <MButton
                variant={this.state.isPickUp ? "contained" : ""}
                color={this.state.isPickUp ? "primary" : ""}
                onClick={() => this.changeDelivery("PickUp")}
              >
                Pick Up
              </MButton>
            </MButtonGroup>
          </Row>

          <Accordion
            expanded={this.state.expanded === "panel1"}
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <HomeSharpIcon className={classes.expanIcon} fontSize="large" />
              <Col xs="24" xl={24} style={{ marginLeft: "5%" }}>
                <MTypography className={classes.heading}>
                  {this.props.delivery_pickup[0].order_type === "delivery"
                    ? "Delivery Address"
                    : "PickUp Address"}
                </MTypography>
                <MTypography >
                  {this.props.delivery_pickup[0].address +
                    " " +
                    this.props.delivery_pickup[0].city +
                    ", " +
                    this.props.delivery_pickup[0].state}
                </MTypography>
                <MTypography >
                  {" " + this.props.delivery_pickup[0].zipCode}
                </MTypography>
              </Col>
            </AccordionSummary>
          </Accordion>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            style={{ margin: "2%" }}
          >
            <Grid item xs={2}>
              <Checkbox
                checked={this.state.changePickup}
                onChange={() => this.handleChangePickUp()}
                name="Do you want to change your pick up address?"
                color="primary"
              />
            </Grid>
            <Grid item xs={10}>
              <MTypography >
                Do you want to change your pick up address?
              </MTypography>
            </Grid>
          </Grid>
           {/* {this.state.} */}
          {this.state.changePickup 
                ? this.state.isDelivery ? (
                  <Grid
                      className={classes.socialButtons}
                      container
                      spacing={2}
                    >
                      <Grid item>
                        <TextField
                          // fullWidth
                          style={{ margin: "2%", width: "95%" }}
                          id="outlined-secondary"
                          label="Delivery Address *"
                          variant="outlined"
                          color="secondary"
                          onChange={(e) => this.handleInputChange(e, "address")}
                        />
                        <TextField
                          style={{ margin: "2%", width: "95%" }}
                          id="outlined-secondary"
                          label="Zip Code *"
                          variant="outlined"
                          color="secondary"
                          onChange={(e) => this.handleInputChange(e, "zipCode")}
                        />
                      </Grid>
                    </Grid>
                ) : (
          <Grid  container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}>
            <Grid item>
              {this.props.locations.allLocations.length > 0 ? (
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Select Pick Up Station
                  </InputLabel>
                  <Select
                    native
                    value={this.state.pickUpAddress}
                    onChange={this.selectPickAddress}
                    label="Select Pick Up Station"
                  >
                    <option aria-label="None" value="" />
                    {this.props.locations.allLocations.map((location, index) => (
                      <option value={location.zipcode} key={index}>
                        {location.address1 + ", " + location.zipcode}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
            </Grid>
          </Grid> ) : null }

          
        </div>
        <div
          layout="vertical"
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            border: "1px solid rgba(0, 0, 0, 0.2)",
          }}
        >
          <MButton
            variant="contained"
            color="primary"
            style={{ width: "90%", height: "45%",marginTop:"7%" }}
            onClick={() => this.changePickUpAddress()}
          >
            Submit
          </MButton>
        </div>
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { currentItem, removeCurrentItem, orderCalculation, insertItem, updateOrdertype },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    current_item: state.current_item,
    locations: state.locations,
    delivery_pickup: state.delivery_pickup,
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true })
)(PickUp);
