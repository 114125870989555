import { Link, Route } from "react-router-dom";
import React from "react";
import {
  Row,
  Col,
  Avatar,
} from "antd";
import {
  Badge,Box,Menu ,MenuItem,ListItemIcon,Divider,IconButton,Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,Button
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import styled from "styled-components";
import Octoposlogo from "../../assets/images/Octopos-logo-blue.png";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { removeUserCardDetails } from "../../actions/card_Details_action";
import { userLogout } from "../../actions/user_actions";
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import { removeProducts ,removeFeaturedProducts } from "../../actions/menu_items";
import { removeDeliveryPickupDetails } from "../../actions/delivery_pickup_action";
import { removeLocations } from "../../actions/location_action";
import { clearItems } from "../../actions/order_items"
import * as Constant from "../../constants";
// import {Menu} from "antd";
// const { SubMenu ,Item, Divider} = Menu;
// const Box = styled.span`
//   position: relative;
//   display: flex;
//   align-items: center;
//   height: 64px;
// `;
const PopoverStyle = {
  top: '50px'
};
const useStyles = (theme) => ({
  purple: {
    fontSize: 35,
  },
});

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      sidenavType: "transparent",
      anchorEl:null,
      open:false,
      itemModel: false,
      image_loc:''
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  //   let flag_image=false
  // this.props.locations.allLocations.map((locations, index) => {
  //   if(!flag_image && locations.image_url != null && locations.image_url != undefined && locations.image_url != ''){
      
  //     this.setState({image_loc:locations.image_url})
  //     flag_image=true
  //   }
  // })
  }

  componentWillUnmount() {}
  transParentLayout = () => {
    this.props.handleSidenavType("transparent");
    this.setState({ sidenavType: "transparent" });
  };
  whiteLayout = () => {
    this.props.handleSidenavType("#fff");
    this.setState({ sidenavType: "white" });
  };
  showDrawer = () => {
    this.setState({ visible: true });
  };

  hideDrawer = () => {
    this.setState({ visible: false });
  };
 
//   handleMenuClick= e =>{
//      this.props.removeUserCardDetails();
//      this.props.userLogout();
//      localStorage.removeItem('token')
//      this.setState({open:false,anchorEl:false})
//        const path = "/" + localStorage.getItem("tenant") + "/products";
//        this.props.history.push(path);
//  }
 
//  openUserOrders = () => {
//   const path = "/" + localStorage.getItem("tenant") + "/orders";
//   this.props.history.push(path);
// };
handleClick1=(e)=>{
  this.setState({anchorEl:e.currentTarget,open:true})
}
handleClose=()=>{
  this.setState({anchorEl:null,open:false})
}
handleitemClose = () => {
  this.setState({ itemModel: false})
}
handleOpenModel = () => {
  this.setState({ itemModel: true})
}
  render() {
    
    const { classes } = this.props;
    return (
      <>
       <Dialog
        open={this.state.itemModel}
        onClose={() => this.handleitemClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Constant.CLEAR_ORDER}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleitemClose()} variant="contained" color="secondary">
            CANCEL
          </Button>
          <Route
            render={({ history }) => (
          <Button onClick={() => {
            this.props.removeUserCardDetails();
            this.props.removeProducts();
            this.props.removeDeliveryPickupDetails();
            this.props.removeLocations();
            this.props.removeFeaturedProducts();

            this.props.clearItems();
            history.push("/"+localStorage.getItem('tenant'));
          }} variant="contained" color="primary" autoFocus>
            OK
          </Button>
          )}
          /> 
        </DialogActions>
      </Dialog> 
        <Row>
          <Col span={24} md={4}  className="header-logo">
            {null != this.props.delivery_pickup && undefined != this.props.delivery_pickup && "" != this.props.delivery_pickup && this.props.delivery_pickup.length > 0 ?
            (<div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              paddingTop:'10px',
              paddingBottom: '10px',
            }}
          >
            <img src={this.props.delivery_pickup[0].location.image_url} alt="" height={40} />
          </div>) : (
            null
            ) }
            
          </Col>
          <Col span={24} md={20} className="header-control">
           
          {/* eslint-disable-next-line */}
            {localStorage.getItem("token") != null &&localStorage.getItem("token") != undefined && localStorage.getItem("token") != "" && (this.props.user?.user)? (
              <div
                style={{ display: "flex", flexDirection: "row" }}
                className="btn-sign-in"
              >
              <Box sx={{ display: 'flex',justifyContent:'flex-end'}}>
                    <IconButton
                      onClick={this.handleClick1}
                      size="small"
                      aria-controls={this.state.anchorEl ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={this.state.anchorEl ? 'true' : undefined}
                    >

                  {this.props.user.user.last_name != null &&
                        this.props.user.user.last_name != "" &&
                        this.props.user.user.last_name != undefined ? (
                          <Avatar sx={{ width: 32, height: 32 }}>
                            {this.props.user.user.first_name[0].toUpperCase() +
                              this.props.user.user.last_name[0].toUpperCase()}
                          </Avatar>
                        ) : (
                          <Avatar sx={{ width: 32, height: 32 }}>
                            {this.props.user.user.first_name[0].toUpperCase()}
                          </Avatar>
                        )}
                      {/* <Avatar sx={{ width: 32, height: 32 }}>M</Avatar> */}
                    </IconButton>
                </Box>
      <Menu
        id="simple-menu"
        anchorEl={this.state.anchorEl}
        className="popover_class"
        style={PopoverStyle}
        keepMounted
        open={Boolean(this.state.anchorEl)}
        onClose={this.handleClose}
      >
         <Route
            render={({ history }) => (
          <MenuItem   onClick={() => {
                const path = "/" + localStorage.getItem("tenant") + "/orders";
                history.push(path);
                this.setState({open:false,anchorEl:false})
              }} 
                > Order History</MenuItem>)}>

                </Route>
                <Route
            render={({ history }) => (
          <MenuItem   onClick={() => {
                this.props.removeUserCardDetails();
                this.props.userLogout();
                localStorage.removeItem('token')
                const path = "/" + localStorage.getItem("tenant") + "/products";
                history.push(path);
                this.setState({open:false,anchorEl:false})
              }} 
                > Logout</MenuItem>)}>

                </Route>
      </Menu> 
              </div>
            ) : (
              null
            )}
            {this.props.order_items.length > 0 ?<div
                style={{ display: "flex", flexDirection: "row" }}
                className="btn-sign-in"
              >
            <Badge
              badgeContent={this.props.order_items.length}
              style={{marginRight:'15px',marginTop:'5px'}}
              color="secondary"
              onClick={() => this.props.onPress()}
            >
              <Link  to="#">
              <ShoppingBasketIcon  onClick={() => this.props.onPress() }style={{color:'black'}}
             />
             </Link>
            </Badge>
            </div>:null}
            
            {this.props.order_items.length == 0? <div
                style={{ display: "flex", flexDirection: "row",marginRight:10 }}
                className="btn-sign-in"
              >
                <Link  to="#">
                <RemoveShoppingCartIcon style={{color:'black'}}  onClick={() => {
            this.handleOpenModel()
              }} 
                > </RemoveShoppingCartIcon>
                </Link>
            </div>:null}
           
          </Col>
        </Row>
      </>
    );
  }
}

// export default Header;
function mapDispatchToProps(dispatch) {
  return bindActionCreators({clearItems,removeUserCardDetails, userLogout,removeDeliveryPickupDetails,removeLocations,removeProducts,removeFeaturedProducts}, dispatch);
}
function mapStateToProps(state) {
  return {
    order_items: state.order_items,
    categories: state.categories,
    category_products: state.category_products,
    current_item: state.current_item,
    user: state.user,
    locations:state.locations,
    delivery_pickup:state.delivery_pickup
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true })
)(Header);
