import {
  Accordion, AccordionDetails, AccordionSummary, Button, Card, CardActionArea,
  CardContent,
  CardMedia, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Grid, Typography
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import KeyboardArrowDownSharpIcon from "@material-ui/icons/KeyboardArrowDownSharp";
import { withStyles } from "@material-ui/styles";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { removeUserCardDetails } from "../actions/card_Details_action";
import { currentItem, removeCurrentItem } from "../actions/current_item";
import { removeDeliveryPickupDetails } from "../actions/delivery_pickup_action";
import { removeLocations } from "../actions/location_action";
import {
  removeFeaturedProducts, removeProducts, viewProducts
} from "../actions/menu_items";
import { toast } from "react-toastify";
import { clearItems } from "../actions/order_items";
import image from "../assets/images/no-product1.png";
import * as Constant from "../constants";
import { productModifierSetup } from "../helpers/product/productModifier";

const useStyles = (theme) => ({
  categories: {
    width: "100%",
  },
  expanIcon: {
    // flexBasis: "3.33%",
    flexShrink: 0,
    color: "#3950b1",
    width: "1.8rem",
  },
  header: {
    marginTop: "4%",
    marginBottom: "1%",
  },
  productName: { fontSize: 12, fontWeight: "bold" },
  categoryName: { fontSize: 15, fontWeight: "bold" },
  menuName: { fontSize: 22, fontWeight: "bold" },
  categoryLength: { fontSize: 14 },
  layoutContainer1: {
    height: "88vh",
    width: "100%",
  },
});

class Tables extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      image_url:
        "https://s3-us-west-1.amazonaws.com/snapstics-staging-file-storage/images/product_logo/bf7a442d9e9e68679020893454305ba34ad60e949f52b2b5e8491750db48fa97dfdfxgfdgxfgxdfgxfgbvghhg-xcvbhxcvbcvbcvbcvxfgxfcg-xdfgdfgxfghxfghxfghxgfh-cfghfghcxfgvhcghcghgf-fghxgfh.jpg",
      expanded: false,
      categories: this.props.categories,
      category_products: this.props.category_products,
      giftCardSearchModal: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let date = moment().format("YYYY-MM-DD");
    // let date = '2022-04-17'
    if (
      null != this.props.locations &&
      undefined != this.props.locations &&
      "" != this.props.locations
    ) {
      if (this.props.locations.data_store_date !== date) {
        if (this._isMounted) {
          this.props.removeUserCardDetails();
          this.props.removeProducts();
          this.props.removeDeliveryPickupDetails();
          this.props.removeLocations();
          this.props.removeFeaturedProducts();
          this.props.removeCurrentItem();
          this.props.clearItems();
          setTimeout(() => {
            this.props.history.push("/" + localStorage.getItem("tenant"));
          }, 1000);
        }
      }
    }
    // this._isMounted = true;
    if (this.props.featured_item.length == 0) {
      if (this._isMounted) {
        this.setState({ expanded: "panel0" });
      }
    }
  }
  handleChange = (panel) => (event, isExpanded) => {
    isExpanded
      ? this.setState({ expanded: panel })
      : this.setState({ expanded: false });
  };

  itemSelect = async (item) => {
    if (this.props.order_items[0]?.gift_card == 1) {
      this.setState({ giftCardSearchModal: true });
    }  else if(this.props.payments.length > 0) {
      toast.error(Constant.HALF_PAYMENT);
    } else {
      this.props.removeCurrentItem();
      let product = "";
      await productModifierSetup(item).then((res) => {
        // To check Which type of product add by user.
        product = res;
      });
      product.is_update = false;
      this.props.currentItem(product);
    }
  };

  openUserOrders = () => {
    const path = "/" + localStorage.getItem("tenant") + "/orders";
    this.props.history.push(path);
  };
  handleitemClose = () => {
    this.setState({ giftCardSearchModal: false });
  };

  componentWillUnmount() {
    return () => {
      this._isMounted = false;
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.layoutContainer1}>
          <Dialog
            open={this.state.giftCardSearchModal}
            onClose={() => this.handleitemClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Notice"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {Constant.CAN_NOT_ADD_PRODUCT}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({ giftCardSearchModal: false });
                }}
                variant="contained"
                color="primary"
                autoFocus
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Grid container>
            <Grid item xs={12}>
              {this.props.featured_item.length > 0 ? (
                <>
                  <h1 className={classes.menuName}>Menu</h1>
                  <h1 style={{ fontWeight: "bold" }}>Featured Item</h1>

                  <Grid container>
                    {this.props.featured_item.map((featured_product, index) => (
                      <Grid
                        xs={6}
                        md={4}
                        lg={3}
                        item
                        key={index}
                        style={{ padding: 6 }}
                      >
                        <div onClick={() => this.itemSelect(featured_product)}>
                          <Card
                            className={classes.categoriesItem}
                            variant="outlined"
                          >
                            <CardActionArea>
                              <Grid container>
                                <Grid xs={12} md={12} lg={6} item>
                                  <CardMedia
                                    component="img"
                                    alt={
                                      featured_product.name.length > 32
                                        ? featured_product.name.slice(0, 32) +
                                          "..."
                                        : featured_product.name
                                    }
                                    height="96"
                                    image={
                                      featured_product.image_url
                                        ? featured_product.image_url
                                        : image
                                    }
                                    title={
                                      featured_product.name.length > 32
                                        ? featured_product.name.slice(0, 32) +
                                          "..."
                                        : featured_product.name
                                    }
                                  />
                                </Grid>
                                <Grid xs={12} md={12} lg={6} item>
                                  <CardContent>
                                    {/* <div className="card-tag"> */}
                                    <h6 className={classes.productName}>
                                      {featured_product.name.length > 32
                                        ? featured_product.name.slice(0, 32) +
                                          "..."
                                        : featured_product.name}
                                    </h6>
                                    {/* </div> */}
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      component="p"
                                    >
                                      $
                                      {featured_product.online_ordering_price !==
                                        null &&
                                      featured_product.online_ordering_price !==
                                        undefined &&
                                      featured_product.online_ordering_price !==
                                        ""
                                        ? parseFloat(
                                            featured_product.online_ordering_price,
                                          ).toFixed(2)
                                        : parseFloat(
                                            featured_product.sale_price,
                                          ).toFixed(2)}
                                    </Typography>
                                  </CardContent>
                                </Grid>
                              </Grid>
                            </CardActionArea>
                          </Card>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : null}

              {this.props.featured_item.length > 0 ? (
                <div className={classes.header}>
                  <h1 style={{ fontWeight: "bold", marginTop: "1%" }}>
                    Search by Category
                  </h1>
                </div>
              ) : null}
              <Grid container>
                <div className={classes.categories}>
                  {this.props.category_products.map((categorie, index) => (
                    <Accordion
                      expanded={this.state.expanded === "panel" + index}
                      onChange={this.handleChange("panel" + index)}
                      key={index}
                    >
                      <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        {this.state.expanded === "panel" + index ? (
                          <KeyboardArrowDownSharpIcon
                            className={classes.expanIcon}
                            fontSize="large"
                          />
                        ) : (
                          <ArrowForwardIosIcon
                            className={classes.expanIcon}
                            fontSize="small"
                          />
                        )}

                        <Grid item xs={12}>
                          <Typography className={classes.categoryName}>
                            {categorie.name}
                          </Typography>
                          <Typography className={classes.categoryLength}>
                            {categorie.products.length}
                          </Typography>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          {categorie.products.map((products, index) => (
                            <Grid
                              xs={6}
                              md={4}
                              lg={3}
                              item
                              key={index}
                              style={{ padding: 6 }}
                            >
                              <div onClick={() => this.itemSelect(products)}>
                                <Card
                                  className={classes.categoriesItem}
                                  variant="outlined"
                                >
                                  <CardActionArea>
                                    <Grid container>
                                      <Grid xs={12} md={12} lg={6} item>
                                        <CardMedia
                                          component="img"
                                          alt={
                                            products.name.length > 35
                                              ? products.name.slice(0, 32) +
                                                "..."
                                              : products.name
                                          }
                                          height="96"
                                          image={
                                            products.image_url
                                              ? products.image_url
                                              : image
                                          }
                                          title={
                                            products.name.length > 35
                                              ? products.name.slice(0, 32) +
                                                "..."
                                              : products.name
                                          }
                                        />
                                      </Grid>
                                      <Grid xs={12} md={12} lg={6} item>
                                        <CardContent>
                                          <h6 className={classes.productName}>
                                            {products.name.length > 35
                                              ? products.name.slice(0, 32) +
                                                "..."
                                              : products.name}
                                          </h6>

                                          <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                          >
                                            $
                                            {products.online_ordering_price !==
                                              null &&
                                            products.online_ordering_price !==
                                              undefined &&
                                            products.online_ordering_price !==
                                              ""
                                              ? parseFloat(
                                                  products.online_ordering_price,
                                                ).toFixed(2)
                                              : parseFloat(
                                                  products.sale_price,
                                                ).toFixed(2)}
                                          </Typography>
                                        </CardContent>
                                      </Grid>
                                    </Grid>
                                  </CardActionArea>
                                </Card>
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      viewProducts,
      currentItem,
      removeProducts,
      removeCurrentItem,
      removeUserCardDetails,
      clearItems,
      removeProducts,
      removeDeliveryPickupDetails,
      removeLocations,
      removeFeaturedProducts,
    },
    dispatch,
  );
}
function mapStateToProps(state) {
  return {
    delivery_pickup: state.delivery_pickup,
    locations: state.locations,
    featured_item: state.featured_item,
    category_products: state.category_products,
    current_item: state.current_item,
    user: state.user,
    order_items: state.order_items,
    payments: state.payments,
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true }),
)(Tables);
