import React from "react";
import { useState,useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Drawer, Affix } from "antd";
import Header from "./Header";
import OrderItemContainer from "./orderItems/OrderItemContainer";
import Footer from "./Footer";

import { connect } from "react-redux";
const { Header: AntHeader, Content, Sider } = Layout;

function Main(props) {
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("#1890FF");
  const [sidenavType, setSidenavType] = useState("#fff");
  const [fixed, setFixed] = useState(true);
  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);
  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");
  const removeItem=()=> {
    setVisible(false)
  props.dispatch({ type: 'REMOVE_CURRENT_ITEM' });
}

  useEffect(() => {
    if (pathname === "rtl") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }
  }, [pathname]);
  return (
    <Layout className={`layout-dashboard`}>
      <Affix>
        <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
          <Header
            onPress={openDrawer}
            name={pathname}
            subName={pathname}
            handleSidenavColor={handleSidenavColor}
            handleSidenavType={handleSidenavType}
            handleFixedNavbar={handleFixedNavbar}
          />
        </AntHeader>
      </Affix>
      <Layout>
        <Content className="content-ant">{props.children}</Content>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          trigger={null}
          width={420}
          theme="light"
          className={`sider-primary ant-layout-sider-primary ${
            sidenavType === "#fff" ? "active-route" : ""
          }`}
          style={{ background: sidenavType }}
        >
          <OrderItemContainer color={sidenavColor} onClose={() => removeItem()} onPress={() => setVisible(true)} onPressOut={()=>setVisible(false) }/>
        </Sider>
      </Layout>
      <Drawer
        title={false}
        placement={"right"}
        closable={true}
        onClose={() => removeItem()}
        // onClose={() => setVisible(false)}
        visible={visible}
        key={placement === "right" ? "left" : "right"}
        width="100%"
        className={`drawer-sidebar`}
        zIndex={1}
      >
        <Layout
          className={`layout-dashboard`}
        >
          <Sider
            trigger={null}
            width="100%"
            theme="light"
            className={`sider-primary ant-layout-sider-primary ${
              sidenavType === "#fff" ? "active-route" : ""
            }`}
            style={{ background: sidenavType }}
          >
            <OrderItemContainer onClose={() => removeItem()} onPress={() => setVisible(true)} onPressOut={()=>setVisible(false) }/>
          </Sider>
        </Layout>
      </Drawer>
     
      <Footer />
    </Layout>
    
  );
}

export default connect()(Main);