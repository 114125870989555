export const REQUEST_WEIGHT = 'REQUEST_WEIGHT'
export const RECEIVED_WEIGHT = 'RECEIVED_WEIGHT'
export const CLEAR_WEIGHT = 'CLEAR_WEIGHT'

export function requestWeight(modifier_product, item) {
  return {
    type: REQUEST_WEIGHT,
    payload: {
      product: modifier_product,
      item: item,
    },
  }
}

export function receivedWeight(weight) {
  return {
    type: RECEIVED_WEIGHT,
    payload: weight,
  }
}

export function clearWeight(user) {
  return {
    type: CLEAR_WEIGHT,
  }
}
