import { VIEW_PRODUCTS, REMOVE_PRODUCTS_CATEGORIES } from '../actions/menu_items';

export default function (state = [], action) {
    switch (action.type){
    case VIEW_PRODUCTS:
        {
            return  action.payload;
        }
    case REMOVE_PRODUCTS_CATEGORIES:
        {
            return  [];
        }
    }
    return state;
}
