import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import CommentIcon from "@material-ui/icons/Comment";
import { withStyles } from "@material-ui/core/styles";
import {
  currentItem,
  removeCurrentItem,
} from "../../../../actions/current_item";
import { insertItem } from "../../../../actions/order_items";
import { orderCalculation } from "../../../../actions/order_actions";
import { connect } from "react-redux";
import {  compose } from "redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AccountBoxSharpIcon from "@material-ui/icons/AccountBoxSharp";
import CreditCardSharpIcon from "@material-ui/icons/CreditCardSharp";
import FiberSmartRecordOutlinedIcon from "@material-ui/icons/FiberSmartRecordOutlined";
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import { userLogin, userLogout } from "../../../../actions/user_actions";
import moment from "moment";
import {
  Paper,
  Grid,
  Typography as MTypography,
  Button as MButton,
  ButtonGroup as MButtonGroup,
  TextField,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  LinearProgress,
} from "@material-ui/core";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import { removeUserCardDetails } from "../../../../actions/card_Details_action"
import { addTipInOrder } from "../../../../actions/order_actions";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import * as Constant from "../../../../constants";
import { twofix } from "../../../../helpers/calculation";
import HomeSharpIcon from "@material-ui/icons/HomeSharp";
import { postOrder } from "../../../../services/order/order";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const useStyles = (theme) => ({
  expanIcon: {
    flexBasis: "10%",
    flexShrink: 0,
    color: "#3950b1",
    [theme.breakpoints.down('sm')]: {
      flexBasis: "15%",
    },
    [theme.breakpoints.up('sm')]: {
      flexBasis: "10%",
    },
    ['@media (device-width: 540px)']: {
      flexBasis: "10%",
    },
    // paddingTop: "1%",
  },
  expanIcon1: {
    flexBasis: "10%",
    flexShrink: 0,
    color: "#3950b1",
    fontSize: 25,
    [theme.breakpoints.down('sm')]: {
      flexBasis: "15%",
    },
    [theme.breakpoints.up('sm')]: {
      flexBasis: "10%",
    },
    ['@media (device-width: 540px)']: {
      flexBasis: "10%",
    },
    // paddingTop: "1%",
  },
  flexLayout: {
    flex: 10,
    flexDirection: "row",
    alignItems: "stretch",
    overflow: "hidden",
    marginBottom: 7,
    // padding: 13,
    overflowY: "scroll",
  },
  buttonGroupLayout: {
    marginTop: "3%",
    marginBottom: "3%",
  },
  headerName: { fontSize: 17, fontWeight: "bold" },
  cardError: { 
    fontSize: 15, 
    color: "red" 
  },
  subheaderLength: { fontSize: 15 },
  buttonLayout: {
    display: "flex",
    flex: 0.6,
    padding: 8,
    justifyContent: "center",
    // alignItems:"center",
    backgroundColor: "#fff",
  },
  expantion: {
    backgroundColor: "#fff",
  },
});

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView();

  return { executeScroll, elRef };
};

class CheckOut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: "panel",
      item_qty: 1,
      isDelivery: false,
      isPickUp: false,
      deliveryTime: "",
      tipAmountPer: 0,
      tipAmountPer1: 0,
      customtip: false,
      tipInputshow: false,
      instruction: "",
      dispatch_slot_start_time: "",
      dispatch_slot_end_time: "",
      errors: {
        tipAmountPer: "",
      },
      loading: false,
    };
    this.elScroll = utilizeScroll();
  }

  changeDelivery = () => {};
  componentDidMount() {
    if (
      null !== this.props.delivery_pickup &&
      undefined !== this.props.delivery_pickup &&
      this.props.delivery_pickup.length > 0
    ) {
      let time = "";
      let start_time = "";
      let end_time = "";
      this.props.delivery_pickup[0].location.location_online_order_settings.map(
        (settings) => {
          let final_time;
          if (this.props.delivery_pickup[0].order_type === "delivery") {
            let array = settings.delivery_lead_time.split(":");
            final_time = moment()
              .add(array[2], "seconds")
              .add(array[1], "minutes")
              .add(array[0], "hours")
              .format("HH:mm:ss");
          } else if (this.props.delivery_pickup[0].order_type === "pickup") {
            let array = settings.pickup_lead_time.split(":");
            final_time = moment()
              .add(array[2], "seconds")
              .add(array[1], "minutes")
              .add(array[0], "hours")
              .format("HH:mm:ss");
          }
          let i = 1;
          settings.location_hrs_sub_slot.map((time_slot) => {
            if (this.props.delivery_pickup[0].scheduleOrder === "asap") {
              if (final_time < time_slot.from_time) {
                if (i === 1) {
                  let deliverytime =
                    time_slot.from_time + " - " + time_slot.to_time;
                  time = deliverytime;
                  start_time = time_slot.from_time;
                  end_time = time_slot.to_time;
                  i = 0;
                }
              }
            } else {
              if (time_slot.id === this.props.delivery_pickup[0].DeliveryTime) {
                let deliverytime =
                  time_slot.from_time + " - " + time_slot.to_time;
                time = deliverytime;
                start_time = time_slot.from_time;
                end_time = time_slot.to_time;
              }
            }
          });
         
        }
      );
      this.setState({
        isDelivery:
          this.props.delivery_pickup[0].order_type === "delivery"
            ? true
            : false,
        isPickUp:
          this.props.delivery_pickup[0].order_type === "pickup" ? true : false,
        deliveryTime: time,
        dispatch_slot_start_time: start_time,
        dispatch_slot_end_time: end_time,
      });
    }
    if (
      null !== this.props.order.tip_type &&
      undefined !== this.props.order.tip_type &&
      "" !== this.props.order.tip_type
    ) {
      if (this.props.order.tip_type === "Percentage") {
        if (this.props.order.tips_amount > 0) {
          this.setState({ tipAmountPer1: this.props.order.tips_amount });
        }
      } else if (this.props.order.tip_type === "Amount") {
        this.setState({
          tipAmountPer1: this.props.order.tips_amount,
          customtip: true,
        });
      }
    }
    if (
      null !== this.props.order.tip_type &&
      undefined !== this.props.order.tip_type &&
      "" !== this.props.order.tip_type
    ) {
      if (this.props.order.tip_type === "Percentage") {
        if (this.props.order.tip_amount > 0) {
          this.setState({ tipAmountPer1: this.props.order.tip_amount });
        }
      } else if (this.props.order.tip_type === "Amount") {
        this.setState({
          tipAmountPer: this.props.order.tip_amount,
          customtip: true,tipInputshow:false
        });
      }
    }
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
  };
  }

  selectTipAmount = (tip_type, amount) => {
    this.setState({ tipAmountPer1: amount, customtip: false, tipAmountPer: 0 });
    if (null !== amount && undefined !== amount) {
      if (amount <= 99999) {
        this.props.addTipInOrder(tip_type, amount);
        this.props.orderCalculation();
      } else {
        toast.error(Constant.TIPS_AMOUNT_VALIDATION);
      }
    }
  };

  customtip = () => {
    this.setState({ customtip: true, tipInputshow: true, tipAmountPer: "",  });
  };

  onOrderSubmit = () => {
    this.setState({ loading: true });
    var amount_t = this.props.order.balance;
    var totalTipPayment = 0
    var totalPayment = 0
    if (
      this.props.order.balance === "" ||
      this.props.order.balance === "0." ||
      "" === parseFloat(amount_t).toFixed(2) ||
      "undefined" === parseFloat(amount_t).toFixed(2) ||
      isNaN(amount_t) ||
      0 === parseFloat(amount_t).toFixed(2) ||
      0.0 === parseFloat(amount_t).toFixed(2)
    ) {
      // 'Input value is undefined,not a number,empty,0.,0.00 or 0'
      toast.error(Constant.ORDER_BALANCE_ZERO);
      this.setState({ loading: false });
    } else {
      if(this.props.payments.length > 0) {
        this.props.payments.map((payment) => {
          totalTipPayment = totalTipPayment + payment.tips_amount
          totalPayment = totalPayment + payment.amount
        })
      }
      if (this.props.delivery_pickup[0].order_type === "delivery") {
        if (
          this.props.order.balance >=
          this.props.delivery_pickup[0].location
            .location_online_order_settings[0].minimum_order_amount
        ) {
          let order_tip_amount = twofix(
            twofix(this.props.order.order_tip_amount) -
              [
                twofix(totalTipPayment),
              ]
          );
          this.processOrder(order_tip_amount,totalPayment); // Order is ready to store at backend side
        } else {
          toast.error(
            "Minimum order amount for Delivery is $" +
              this.props.delivery_pickup[0].location
                .location_online_order_settings[0].minimum_order_amount 
          );
          this.setState({ loading: false });
        }
      } else {
        let order_tip_amount = twofix(
          twofix(this.props.order.order_tip_amount) -
            [
              twofix(totalTipPayment),
            ]
        );

        this.processOrder(order_tip_amount,totalPayment); // Order,  is ready to store at backend side
      }
    }
  };
  processOrder = (order_tip_amount,totalPayment) => {
    this.elScroll.executeScroll()
    var current_order = localStorage.getItem("current_order"); // Get current order
    if (
      current_order === null ||
      current_order === undefined ||
      current_order === ""
    ) {
      current_order =
        new Date().valueOf().toString() +
        "" +
        (Math.floor(Math.random() * (999 - 100)) + 101);
    }
    var balance = twofix(
      this.props.order.total + this.props.order.tax_collected
    ).toFixed(2);
    let first_name = "";
    let last_name = "";
    let phone = "";
    if (
      null !== this.props.user.whoIsThis &&
      undefined !== this.props.user.whoIsThis &&
      "" !== this.props.user.whoIsThis
    ) {
      first_name = this.props.user.whoIsThis.first_name;
      last_name = this.props.user.whoIsThis.last_name;
      phone = this.props.user.whoIsThis.phone;
    } else {
      if (
        null !== this.props.user.user &&
        undefined !== this.props.user.user &&
        "" !== this.props.user.user
      ) {
        first_name = this.props.user.user.first_name;
        last_name = this.props.user.user.last_name;
        phone = this.props.user.user.phone;
      }
    }
    let time = "";
    let start_time = "";
    let end_time = "";
    this.props.delivery_pickup[0].location.location_online_order_settings.map(
      (settings) => {
        let final_time;
        let addtimeToday;
        if (this.props.delivery_pickup[0].order_type === "delivery") {
          let array = settings.delivery_lead_time.split(":");
          addtimeToday = moment()
            .add(array[2], "seconds")
            .add(array[1], "minutes")
            .add(array[0], "hours")
            .format("ddd");
          final_time = moment()
            .add(array[2], "seconds")
            .add(array[1], "minutes")
            .add(array[0], "hours")
            .format("hh:mm A");
        } else if (this.props.delivery_pickup[0].order_type === "pickup") {
          let array = settings.pickup_lead_time.split(":");
          addtimeToday = moment()
            .add(array[2], "seconds")
            .add(array[1], "minutes")
            .add(array[0], "hours")
            .format("ddd");
          final_time = moment()
            .add(array[2], "seconds")
            .add(array[1], "minutes")
            .add(array[0], "hours")
            .format("hh:mm A");
        }
        let i = 1;
        if (
          settings.location_hrs_sub_slot[0].day === addtimeToday.toUpperCase()
        ) {
          settings.location_hrs_sub_slot.map((time_slot) => {
            let dateIsAfter = moment(time_slot.from_time, ["h:mm A"]).isAfter(
              moment(final_time, ["h:mm A"])
            );
            if (this.props.delivery_pickup[0].scheduleOrder === "asap") {
              if (dateIsAfter) {
                if (i == 1) {
                  let deliverytime =
                    time_slot.from_time + " - " + time_slot.to_time;
                  time = deliverytime;
                  start_time = time_slot.from_time;
                  end_time = time_slot.to_time;
                  i = 0;
                }
              } 
            } else {
              if (dateIsAfter) {
              if (time_slot.id === this.props.delivery_pickup[0].DeliveryTime) {
                let deliverytime =
                  time_slot.from_time + " - " + time_slot.to_time;
                time = deliverytime;
                start_time = time_slot.from_time;
                end_time = time_slot.to_time;
              }
            }
          }
          });
        } else {
          toast.error(Constant.NO_SLOTES);
          this.setState({ timeSlot: [], loading: false });
        }
      }
    );
    var todayDate = new Date().toISOString().slice(0, 10);
    if (
      null !== this.props.user.user &&
      undefined !== this.props.user.user &&
      "" !== this.props.user.user
    ) {
      if (
        null !== this.props.delivery_pickup[0] &&
        undefined !== this.props.delivery_pickup[0] &&
        "" !== this.props.delivery_pickup[0]
      ) {
        if ((null !== this.props.card_details &&
            undefined !== this.props.card_details && this.props.card_details &&
            0 !== this.props.card_details.length)
        ) {
          let current_time = moment().format("h:mm A");
          let orderCreateorNot = moment(start_time, ["h:mm A"]).isAfter(
            moment(current_time, ["h:mm A"])
          );
          if (orderCreateorNot) {
            let order_online_details = {
              pos_location_id: this.props.delivery_pickup[0].location.id,
              grub_user_id: this.props.user.user.id,
              dispatch_first_name: first_name,
              dispatch_last_name: last_name,
              grub_user_first_name: this.props.user.user?.first_name,
              grub_user_last_name: this.props.user.user?.last_name,
              dispatch_address1: this.props.delivery_pickup[0].address,
              dispatch_address2: "",
              dispatch_city: this.props.delivery_pickup[0].city,
              dispatch_state: this.props.delivery_pickup[0].state,
              dispatch_zip: this.props.delivery_pickup[0].zipCode,
              dispatch_country: this.props.delivery_pickup[0].country,
              dispatch_user_phone: phone,
              order_dispatch_type: this.props.delivery_pickup[0].order_type,
              dispatch_instruction:
                this.props.order.instruction !== null &&
                this.props.order.instruction !== undefined &&
                this.props.order.instruction !== ""
                  ? this.props.order.instruction
                  : "",
              dispatch_slot_start_time: todayDate + " " + start_time,
              dispatch_slot_end_time: todayDate + " " + end_time,
            };
            var timestamp = new Date().getTime();
            var payment_obj = []
            if(this.props.payments.length > 0) {
              payment_obj = [...this.props.payments,
                {
                  aid: undefined,
                  aid_display_name: undefined,
                  amount: twofix(
                    [twofix(this.props.order.total) +
                      twofix(this.props.order.tax_collected)] - twofix(totalPayment)
                  ),
                  cash_payment_check: 0,
                  cashback_amount: undefined,
                  external_reference: undefined,
                  external_reference_number: undefined,
                  id: timestamp,
                  multiple_cash: true,
                  payment_gateway: undefined,
                  pin_statement: undefined,
                  tips_amount: order_tip_amount,
                  type: "CARD",
                },
              ]
            } else {
              payment_obj = [
                {
                  aid: undefined,
                  aid_display_name: undefined,
                  amount: twofix(
                    twofix(this.props.order.total) +
                      twofix(this.props.order.tax_collected)
                  ),
                  cash_payment_check: 0,
                  cashback_amount: undefined,
                  external_reference: undefined,
                  external_reference_number: undefined,
                  id: timestamp,
                  multiple_cash: true,
                  payment_gateway: undefined,
                  pin_statement: undefined,
                  tips_amount: order_tip_amount,
                  type: "CARD",
                },
              ]
            }
            var order_payload = {
              //Create payment payload
              campaign_coupon_id: "",
              reward_history_id: "",
              email: this.props.user.user.email,
              paid: 1,
              pos_station_id:
                this.props.delivery_pickup[0].location.online_pos_station[0].id,
              reward_card_id: this.props.order.reward_card_id,
              total: this.props.order.total,
              local_id: current_order,
              balance: balance,
              discount_amount: this.props.order.order_discount,
              discount_reason: this.props.order.discount_reason,
              lines: this.props.order_items,
              payment: payment_obj,
              ebt_flag: 0,
              created_by: "",
              // card_id:
              //   this.props.user.user.selected_card_id !== null &&
              //   this.props.user.user.selected_card_id !== undefined &&
              //   this.props.user.user.selected_card_id !== ""
              //     ? this.props.user.user.selected_card_id
              //     : "",
              order_online_details: order_online_details,
              order_for_here: this.props.order.order_for_here,
              card_details: this.props.card_details,
            };
            // this.changeLayout("orderStatus");
            postOrder(order_payload)
              .then((res) => {
                if (res.data.flag) {
                  this.changeLayout(
                    "orderStatus",
                    res.data.data.responsePayload
                  );
                  this.props.completeOrder().then(() => {
                    //Complete order
                    localStorage.removeItem("current_order");
                  });

                  // let user = res.data.data.responsePayload.user[0]
                  // user.selected_card_id = this.props.user.user.selected_card_id
                  // this.props.userLogin(user)
                  this.props.removeUserCardDetails();
                  toast.success(res.data.data.message);
                  this.setState({ loading: false });
                } else if (res.data.data.error) {
                  if (res.data.data.error.code === 400) {
                    toast.error(res.data.data.error.message[0]);
                    this.setState({ loading: false });
                  } else if (res.data.data.error.code === 404) {
                    toast.error(res.data.data.error.message);
                    this.setState({ loading: false });
                  }
                } else {
                  if (res.data.code === 999) {
                    this.props.removeUserCardDetails();
                    this.props.userLogout();
                    localStorage.removeItem('token')
                    this.changeLayout("signIn");
                    toast.error(res.data.message);
                    this.setState({ loading: false });
                  } else {
                    toast.error(res.data.message);
                    this.setState({ loading: false });
                  }
                }
              })
              .catch((error) => {
                toast.error(error.toString());
                this.setState({ loading: false });
              });
          } else {
            toast.error(Constant.SELECTE_TIME_SLOTE_IS_BEFORE_CURRUNT_TIME);
            this.setState({ loading: false });
          }
        } else {
          toast.error(Constant.USER_CARD_DETAILS_NOT_FOUND);
          this.setState({ loading: false });
        }
      } else {
        toast.error(Constant.CHECKOUT_PAGE_ADDRESS_NOT_FOUND);
        this.setState({ loading: false });
      }
    } else {
      toast.error(Constant.CHECKOUT_PAGE_USER_LOGOUT);
      this.setState({ loading: false });
    }
  };

  changeLayout = (layout, key) => {
    if (layout === "Instructions" || layout === "Time") {
      if (layout === "Instructions") {
        this.props.checkOutInsidePage(layout, key);
      } else {
        if (this.props.delivery_pickup[0]?.scheduleOrder !== "asap") {
          this.props.checkOutInsidePage(layout, key);
        }
      }
    } else if (layout === "orderStatus") {
      this.props.checkOutInsidePage(layout, key);
    } else if (layout === "rewardCardAdd") {
      if(null === this.props.order.reward_card_id ||
        undefined === this.props.order.reward_card_id ||
        "" === this.props.order.reward_card_id) {
          this.props.checkOutInsidePage(layout)
        }
    } else {
      this.props.checkOutInsidePage(layout);
    }
  };

  validate = (number) => {
    const regex = /^\d+(\.\d{1,2})?$/;
    return regex.test(number.trim());
  };

  tipSet = () => {
    if (
      null != this.state.tipAmountPer &&
      undefined != this.state.tipAmountPer &&
      "" != this.state.tipAmountPer
    ) {
      if (this.state.tipAmountPer <= 99999) {
        this.setState({tipAmountPer1:0})
        this.props.addTipInOrder("Amount", parseFloat(this.state.tipAmountPer).toFixed(2));
        this.props.orderCalculation();
        this.setState({tipInputshow:false})
      } else {
        toast.error(Constant.TIPS_AMOUNT_VALIDATION);
      }
    }
  };

  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "tipAmountPer":
        errors.tipAmountPer =
          parseFloat(value.trim()).toFixed(2).length === 0
            ? Constant.ENTER_TIPAMOUNT
            :  !this.validate(value)
            ? Constant.VALID_TIPAMOUNT
            : value > 99999 ? Constant.TIPS_AMOUNT_VALIDATION : "" ;
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value }, () => {
      // console.log(errors);
    });
  };

  render() {
    let card_number = ''
    let gift_Card = false
    let gift_card_index = this.props.delivery_pickup[0].location.location_settings.findIndex(x => x.key == 'gift_card')
    if(gift_card_index > -1) {
      if(this.props.delivery_pickup[0].location.location_settings[gift_card_index].value.toUpperCase() == 'YES') {
        gift_Card = true
      }
    }
      if (this.props.user.user && null !== this.props.card_details && undefined !== this.props.card_details && this.props.card_details &&
        0 !== this.props.card_details.length) {
          card_number = "Selected Card: XXXX XXXX XXXX " + this.props.card_details.card_number.substring(12, 16)
        }
    const { classes } = this.props;
    return (
      <>
        <Paper elevation={1} square className={classes.flexLayout}>
          <div ref={this.elScroll.elRef}></div>
          {this.state.loading ? <LinearProgress color="primary" /> : null}
          <Grid
            container
            direction="row"
            // justifyContent="center"
            alignItems="center"
          >
            <Grid xs={2} md={2} lg={2} item>
              <Link to="#" className="text-dark font-bold">
                <ArrowBackIcon onClick={() => this.changeLayout("")} />
              </Link>
            </Grid>
            <Grid xs={8} md={8} lg={8} item>
              <h1 className="sidebar-layout-header-name">Checkout</h1>
            </Grid>
          </Grid>
          <Row
            gutter={[24, 0]}
            justify="center"
            className={classes.buttonGroupLayout}
          >
            <MButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <MButton
                variant={this.state.isDelivery ? "contained" : ""}
                color={this.state.isDelivery ? "primary" : ""}
                // onClick={() => this.changeDelivery("Delivery")}
              >
                Delivery
              </MButton>
              <MButton
                variant={this.state.isPickUp ? "contained" : ""}
                color={this.state.isPickUp ? "primary" : ""}
                // onClick={() => this.changeDelivery("PickUp")}
              >
                Pick Up
              </MButton>
            </MButtonGroup>
          </Row>

          <Accordion
            // disabled
            className={classes.expantion}
            expanded={this.state.expanded === "panel1"}
            // onChange={this.handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <HomeSharpIcon className={classes.expanIcon} fontSize="large" />
              <Col xs="24" xl={20} style={{ marginLeft: "5%" }}>
                <MTypography className={classes.headerName}>
                  {this.props.delivery_pickup[0].order_type === "delivery"
                    ? "Delivery Address"
                    : "Pickup Address"}
                </MTypography>
                <MTypography >
                  {this.props.delivery_pickup[0].address +
                    " " +
                    this.props.delivery_pickup[0].city +
                    ", "}
                </MTypography>
                <MTypography className={classes.subheaderLength}>
                  {this.props.delivery_pickup[0].state +
                    " " +
                    this.props.delivery_pickup[0].zipCode}
                </MTypography>
              </Col>
            </AccordionSummary>
          </Accordion>

          <Accordion expanded={this.state.expanded === "panel1"} onClick={() => this.changeLayout("Time", "checkout")}>
            <AccordionSummary
              expandIcon={
                this.props.delivery_pickup[0]?.scheduleOrder !== "asap" ? (
                  <ArrowForwardIosIcon style={{ color: "#3950b1" }} />
                ) : null
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <WatchLaterIcon className={classes.expanIcon} fontSize="large" />
              <Col xs="24" xl={24} style={{ marginLeft: "5%" }}>
                <MTypography className={classes.headerName}>
                  {this.props.delivery_pickup[0].order_type === "delivery"
                    ? "Delivery Time"
                    : "Pickup Time"}
                </MTypography>
                <MTypography className={classes.subheaderLength}>
                  {" "}
                  {moment().format("ddd") +
                    "," +
                    moment().format("MMMM Do YYYY")}
                  <br />
                  {this.props.delivery_pickup[0]?.scheduleOrder === "asap"
                    ? "ASAP"
                    : this.state.deliveryTime}
                </MTypography>
              </Col>
            </AccordionSummary>
            {/* <AccordionDetails></AccordionDetails> */}
          </Accordion>

          <Accordion
            onClick={() => this.changeLayout("Instructions", "checkout")}
          >
            <AccordionSummary
              expandIcon={<ArrowForwardIosIcon style={{ color: "#3950b1" }} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <CommentIcon className={classes.expanIcon} fontSize="large" />
              <Col xs="24" xl={24} style={{ marginLeft: "5%" }}>
                <MTypography className={classes.headerName}>
                  Instructions
                </MTypography>
                <MTypography className={classes.subheaderLength}>
                {(null != this.props.order.instruction && undefined != this.props.order.instruction && "" != this.props.order.instruction )
                    ? (this.props.order.instruction.trim().length > 0) ?
                     this.props.order.instruction.trim().slice(0,33) + "..." : "Add Instructions" : "Add Instructions"}
                </MTypography>
              </Col>
            </AccordionSummary>
          </Accordion>
          <Accordion
            onClick={() => this.changeLayout("whoisthis")}
          >
            <AccordionSummary
              expandIcon={
                <ArrowForwardIosIcon
                  style={{ color: "#3950b1" }}
                />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <AccountBoxSharpIcon
                className={classes.expanIcon}
                fontSize="large"
              />
              <Col xs="24" xl={24} style={{ marginLeft: "5%" }}>
                <MTypography className={classes.headerName}>
                  Who is this for?
                </MTypography>
                {this.props.user.whoIsThis != null &&
                this.props.user.whoIsThis != undefined &&
                this.props.user.whoIsThis != "" ? (
                  <MTypography className={classes.subheaderLength}>
                    {this.props.user.whoIsThis.first_name +
                      " " +
                      this.props.user.whoIsThis.last_name}
                    <br />
                    {this.props.user.whoIsThis.phone}
                  </MTypography>
                ) : this.props.user.user != null &&
                  this.props.user.user != undefined &&
                  this.props.user.user != "" ? (
                  <MTypography >
                    {this.props.user.user.first_name + " " + this.props.user.user.last_name}
                    <br />
                    {this.props.user.user.phone}
                  </MTypography>
                ) : null}
              </Col>
            </AccordionSummary>
          </Accordion>
          <Accordion
            onClick={() => this.changeLayout("userCard")}
          >
            <AccordionSummary
              expandIcon={
                <ArrowForwardIosIcon
                  style={{ color: "#3950b1" }}
                />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <CreditCardSharpIcon
                className={classes.expanIcon}
                fontSize="large"
              />
              <Col xs="24" xl={24} style={{ marginLeft: "5%" }}>
                <MTypography className={classes.headerName}>
                  Pay with Card
                </MTypography>
                <MTypography className={classes.subheaderLength}>
                  {"$" + this.props.order.balance.toFixed(2) + " due"}
                </MTypography>
                {
            //     (this.props.user.user && this.props.user.user.selected_card_id !== null &&
            // this.props.user.user.selected_card_id !== undefined &&
            // this.props.user.user.selected_card_id !== "") ||
          (this.props.user.user && null !== this.props.card_details &&
            undefined !== this.props.card_details && this.props.card_details &&
            0 !== this.props.card_details.length) ? 
            <MTypography className={classes.subheaderLength}>
            {card_number}
          </MTypography> :
             <MTypography className={classes.cardError}>
                  {
                  // (this.props.user.user && this.props.user.user.user_card_info.length > 0) 
                  // ? "Please select a card." : 
                  "Please enter a card details."}
                </MTypography> 
          }
              </Col>
            </AccordionSummary>
          </Accordion>
          {(gift_Card)? (this.props.order_items[0]?.gift_card == 1 || this.props.payments.length > 0) ? (
              null
          ):  (<Accordion
          onClick={() => this.changeLayout("giftCard")}
         >
          <AccordionSummary
            expandIcon={
              <ArrowForwardIosIcon
                style={{ color: "#3950b1" }}
              />
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <CardGiftcardIcon
              className={classes.expanIcon}
              fontSize="large"
            />
            <Col xs="24" xl={24} style={{ marginLeft: "5%" }}>
              <MTypography className={classes.headerName}>
                Pay with Gift Card
              </MTypography>
              <MTypography className={classes.subheaderLength}>
                {"$" + this.props.order.balance.toFixed(2) + " due"}
              </MTypography>
              {/* {
         (this.props.user.user && null !== this.props.card_details &&
          undefined !== this.props.card_details && this.props.card_details &&
          0 !== this.props.card_details.length) ? 
          <MTypography className={classes.subheaderLength}>
          {card_number}
         </MTypography> :
           null
         } */}
            </Col>
          </AccordionSummary>
         </Accordion>) : null}
          {(this.props.delivery_pickup[0].location
            .location_online_order_settings[0].enable_reward_earn) ? (this.props.order_items[0]?.gift_card == 1) ? 
            null :(
            <Accordion
              expanded={this.state.expanded === "panel2"}
              onClick={() => this.changeLayout("rewardCardAdd")}>
              <AccordionSummary
                expandIcon={(null != this.props.order.reward_card_id &&
                  undefined != this.props.order.reward_card_id &&
                  "" != this.props.order.reward_card_id) ? null :
                  <ArrowForwardIosIcon
                    style={{ color: "#3950b1" }}
                  />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <AccountBoxSharpIcon
                  className={classes.expanIcon}
                  fontSize="large"
                />
                <Col xs="24" xl={24} style={{ marginLeft: "5%" }}>
                  <MTypography className={classes.headerName}>
                    Reward Card
                  </MTypography>
                  {(null != this.props.order.reward_card_id &&
                  undefined != this.props.order.reward_card_id &&
                  "" != this.props.order.reward_card_id) ? (null != this.props.order.reward_card_number &&
                    undefined != this.props.order.reward_card_number &&
                    "" != this.props.order.reward_card_number) ? this.props.order.reward_card_number : null :
                  <MTypography className={classes.subheaderLength}>
                  { "Add Reward Card"}
                </MTypography>}
                </Col>
              </AccordionSummary>
            </Accordion>
          ) : null}

          {this.props.delivery_pickup[0].location
            .location_online_order_settings[0].is_tip_enable ? (
            <Accordion
              expanded={this.state.expanded === "panel"}
              style={{ marginTop:'1px'}}
              // onChange={this.handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <FiberSmartRecordOutlinedIcon
                  className={classes.expanIcon1}
                  fontSize="large"
                  style={{ transform: "rotate(180deg)" }}
                />
                <Col xs="24" xl={24} style={{ marginLeft: "3%" }}>
                  <MTypography className={classes.headerName}>
                    Add Tip
                  </MTypography>
                  {this.state.tipAmountPer1 > 0  ? <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                  // style={{ margin: "2%" }}
                ><Grid item><MTypography className={classes.subheaderLength}>
                  { this.state.tipAmountPer1 + "% Tip is applied."}
                </MTypography></Grid></Grid> : this.props.order.tip_amount > 0 ?<Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                  // style={{ margin: "2%" }}
                ><Grid item><MTypography className={classes.subheaderLength}>
                  { '$'+parseFloat(this.props.order.tip_amount).toFixed(2) + " Tip is applied."}
                </MTypography></Grid></Grid> : null}
                {/* {this.state.tipAmountPer > 0  ? <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                  // style={{ margin: "2%" }}
                ><Grid item><MTypography className={classes.subheaderLength}>
                  { '$'+this.state.tipAmountPer + " Tip is applied."}
                </MTypography></Grid></Grid> : null} */}
                </Col>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                  // style={{ margin: "2%" }}
                >
                  <Grid item xs={12} md={6} lg={6}>
                    <MButtonGroup
                      color="primary"
                      aria-label="outlined secondary button group"
                      disabled={this.props.payments.length > 0 ? true : false}
                    >
                      <MButton
                        style={{ width: "20%" }}
                        variant={
                          this.state.tipAmountPer1 === 0 &&
                          this.state.customtip === false
                            ? "contained"
                            : ""
                        }
                        color={
                          this.state.tipAmountPer1 === 0 &&
                          this.state.customtip === false
                            ? "primary"
                            : ""
                        }
                        onClick={() => this.selectTipAmount("Percentage", 0)}
                      >
                        None
                      </MButton>
                      <MButton
                        style={{ width: "17%" }}
                        variant={
                          this.state.tipAmountPer1 === 10 ? "contained" : ""
                        }
                        color={this.state.tipAmountPer1 === 10 ? "primary" : ""}
                        onClick={() => this.selectTipAmount("Percentage", 10)}
                      >
                        10%
                      </MButton>
                      <MButton
                        style={{ width: "17%" }}
                        variant={
                          this.state.tipAmountPer1 === 20 ? "contained" : ""
                        }
                        color={this.state.tipAmountPer1 === 20 ? "primary" : ""}
                        onClick={() => this.selectTipAmount("Percentage", 20)}
                      >
                        20%
                      </MButton>
                      <MButton
                        style={{ width: "17%" }}
                        variant={
                          this.state.tipAmountPer1 === 30 ? "contained" : ""
                        }
                        color={this.state.tipAmountPer1 === 30 ? "primary" : ""}
                        onClick={() => this.selectTipAmount("Percentage", 30)}
                      >
                        30%
                      </MButton>
                    </MButtonGroup>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <MButtonGroup
                      color="primary"
                      aria-label="outlined secondary button group"
                      disabled={this.props.payments.length > 0 ? true : false}
                    >
                      <MButton
                        variant={this.state.customtip ? "contained" : ""}
                        color={this.state.customtip ? "primary" : ""}
                        onClick={() => this.customtip()}
                      >
                        Custom
                      </MButton>
                    </MButtonGroup>
                  </Grid>
                  {(this.state.customtip && this.state.tipInputshow) ? (
                    <Grid
                      container
                      direction="row"
                      // style={{ margin: "2%" }}
                    >
                      <Grid
                        item
                        xs={12}
                        md={9}
                        lg={9}
                        style={{ marginTop: "3%" }}
                      >
                        <TextField
                          autoFocus
                          style={{
                            margin: "2%",
                            width: "95%",
                          }}
                          error={
                            this.state.errors.tipAmountPer.length === 0
                              ? false
                              : true
                          }
                          fullWidth
                          helperText={this.state.errors.tipAmountPer}
                          label="Tip Amount  *"
                          name="tipAmountPer"
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputChange}
                          onKeyPress= {(e) => {
                            if (e.key === 'Enter' && this.state.tipAmountPer > 0 && this.state.errors.tipAmountPer.length == 0) {
                              this.tipSet()
                            }
                          }}
                          type="amount"
                          value={this.state.tipAmountPer.trim()}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={2}
                        lg={2}
                        style={{ marginTop: "5%",paddingLeft:"1%" }}
                      >
                        <MButton
                          variant="contained"
                          color="primary"
                          onClick={this.tipSet}
                          className={classes.button}
                          disabled={this.state.tipAmountPer > 0 && this.state.errors.tipAmountPer.length == 0 ? false :true}
                        >
                          ADD
                        </MButton>
                      </Grid>
                    </Grid>
                  ) : 
                  null
                //   (this.state.tipAmountPer > 0 ) 
                //   ? 
                //   <Grid
                //   container
                //   direction="row"
                //   justifyContent="center"
                //   alignItems="center"
                //   spacing={1}
                //   // style={{ margin: "2%" }}
                // ><Grid item><MTypography className={classes.subheaderLength}>
                //   { '$'+this.state.tipAmountPer + " Tip apply."}
                // </MTypography></Grid></Grid> 
               }
                </Grid>
              </AccordionDetails>
            </Accordion>
          ) : null}
        </Paper>
        <Paper elevation={1} square className={classes.buttonLayout}>
          <MButton
            variant="contained"
            color="primary"
            style={{ width: "100%", height: "93%" }}
            onClick={() => this.onOrderSubmit()}
          >
            {"Submit & Pay      " + "( $ " + this.props.order.balance.toFixed(2) + " )"}
          </MButton>
        </Paper>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  makePayment: (data) => {
    dispatch({
      type: "MAKE_PAYMENT",
      payload: data,
    });
    return Promise.resolve();
  },
  completeOrder: () => {
    dispatch({
      type: "COMPLETE_ORDER",
    });
    return Promise.resolve();
  },
  currentItem: () => dispatch(currentItem()),
  removeCurrentItem: () => dispatch(removeCurrentItem()),
  orderCalculation: () => dispatch(orderCalculation()),
  insertItem: () => dispatch(insertItem()),
  userLogin: (user) => dispatch(userLogin(user)),
  addTipInOrder: (type, amount) => dispatch(addTipInOrder(type, amount)),
  removeUserCardDetails: () => dispatch(removeUserCardDetails()),
  userLogout: () => dispatch(userLogout())
});
function mapStateToProps(state) {
  return {
    current_item: state.current_item,
    order: state.order,
    order_items: state.order_items,
    coupon_status: state.coupon_status,
    user: state.user,
    weight: state.weight,
    delivery_pickup: state.delivery_pickup,
    card_details: state.card_details,
    payments: state.payments,
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true })
)(CheckOut);