import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Typography as MTypography,Box,Avatar,Menu ,MenuItem,IconButton,
} from "@material-ui/core";
import {
  Layout,
  Menu as TMenu,
  Row,
  Col,
  Typography,
  // Avatar,
} from "antd";
import { getAllLocations } from "../services/location/locations";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import {
  addDeliveryPickupDetails,
  removeDeliveryPickupDetails,
} from "../actions/delivery_pickup_action";
import {
  removeProducts,
  viewFeaturedProducts,
  removeFeaturedProducts,
  viewProducts,
} from "../actions/menu_items";
import {
  currentItem,
  removeCurrentItem,
} from "../actions/current_item"
import { addLocations, removeLocations } from "../actions/location_action";
import { userLogout } from "../actions/user_actions";
import { syncWithServer } from "../services/product/syncProducts";
import { removeUserCardDetails } from "../actions/card_Details_action";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import moment from "moment";
import { Link } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";
import * as Constant from "../constants";
import { clearItems } from "../actions/order_items"
import Footer from "../../src/components/layout/Footer";
import sideImage from "../assets/images/auth13.jpg"

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
const PopoverStyle = {
  top: '50px'
};
// const Box = styled.span`
//   position: relative;
//   display: flex;
//   align-items: center;
//   height: 64px;
// `;
const {  Item } = TMenu;
const { Title } = Typography;
const { Header, Content } = Layout;
const profile = [
  <svg
    data-v-4ebdc598=""
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      data-v-4ebdc598=""
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
      fill="#111827"
      className="fill-muted"
    ></path>
  </svg>,
];
const styles = (theme) => ({
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
      width: "0%",
    },
  },
  quote: {
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
  },
  imageLogo:{
    [theme.breakpoints.down("md")]: {
      textAlign:'center',
    },
    // textAlign:'center',
    paddingBottom:16,
    width:"50%"
  }
});
class DeliveryDetails extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      value: "delivery",
      s_Order: "asap",
      pickUpAddress: "",
      deliveryAddress: "",
      selectedDeliveryLocation: "",
      deliveryTime: "",
      locationDetails: [],
      pickupLocations: [],
      DeliveryLocation: [],
      delivery_Area: [],
      zipCode: "",
      timeSlot: [],
      asapTimeSlot: "",
      address: null,
      addressObj: "",
      current: "app",
      loading: false,
      showMessage: "",
      disabledFlag:null,
      anchorEl:null,
      open:false,
      image_loc:'',
      backgroundImage:""
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let date = moment().format("YYYY-MM-DD");
    let weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ][new Date().getDay()];
    getAllLocations(date, weekday)
      .then((res) => {
        if (res.data.error) {
          if (res.data.error.code === 404) {
              setTimeout(() => {
              this.props.history.push('/404');
              }, 1000);
          }
        } else if (res.data.flag) {
          if (
            null !== res.data.data &&
            undefined !== res.data.data &&
            "" !== res.data.data &&
            res.data.data.length > 0
          ) {
            if (this._isMounted) {
              let pickup_array = [];
              let delivery_array = [];
              let delivery_zipcodes = []
              let flag_image = false;
              let online_location_image = false;
              res.data.data.map((locations, index) => {
                if(!flag_image && locations.image_url != null && locations.image_url != undefined && locations.image_url != ''){
                  
                  this.setState({image_loc:locations.image_url})
                  flag_image=true
                }
                locations.location_online_order_settings.map((settings, i) => {
                  if(!online_location_image && settings.image_url_online != null && settings.image_url_online != undefined && settings.image_url_online != ''){
                    online_location_image=true
                    this.setState({backgroundImage:settings.image_url_online})
                  } else if(!online_location_image && locations.location_online_order_settings.length - 1 == i && this.state.backgroundImage == ""){
                    this.setState({backgroundImage:sideImage })
                    online_location_image=true
                  }
                  if (settings.is_pickup == 1) {
                    pickup_array.push(locations);
                  }
                  if (settings.is_delivery == 1) {
                    if (settings.delivery_zips.length > 0) {
                      settings.delivery_zips.map((zips) => {
                        if(delivery_zipcodes.indexOf(zips.zipcode) === -1) {
                          delivery_zipcodes.push(zips.zipcode)
                        }
                      })
                    }
                    delivery_array.push(locations);
                  }
                });
              });
              if (
                null != pickup_array &&
                undefined != pickup_array &&
                null != delivery_array &&
                undefined != delivery_array
              ) {
                this.setState({
                  pickupLocations: pickup_array,
                  DeliveryLocation: delivery_array,
                  delivery_Area: delivery_zipcodes,
                });
                if(delivery_array.length == 0) {
                  this.setState({ value: "pickup"})
                }
              }
              this.props.addLocations(res.data.data,date);
              this.setState({ locationDetails: res.data.data });
              let route=window.location.pathname.split( '/' )
              let lastElement = route.slice(-1);
              localStorage.setItem(
                "tenant",
                lastElement[0]
              );
              localStorage.setItem("current_order", "");
              this.props.removeProducts();
              this.props.removeFeaturedProducts();
              this.props.removeDeliveryPickupDetails();
              this.props.removeCurrentItem();
              // this.props.removeLocations();
              this.props.clearItems();
            }
          }else {
            this.setState({backgroundImage:sideImage })
          }
        } else if (res == "error") {
          this.props.history.push('/404');
        } else {
          let route=window.location.pathname.split( '/' )
              let lastElement = route.slice(-1);
              localStorage.setItem(
                "tenant",
                lastElement[0]
              );
              localStorage.setItem("current_order", "");
              this.props.removeProducts();
              this.props.removeFeaturedProducts();
              this.props.removeDeliveryPickupDetails();
              this.props.removeCurrentItem();
              // this.props.removeLocations();
              this.props.clearItems();
          this.setState({ showMessage: res.data.message,backgroundImage:sideImage })
          // toast.error(res.data.message);
        }
      })
      .catch((err) => {
        if (err == Constant.BACKEND_ERROR_CODE) {
          this.props.history.push('/404');
        } else if (err == Constant.ERROR) {
          this.props.history.push('/404');
        } else {
          // toast.error(err.toString());
          this.setState({ showMessage: err.toString(),backgroundImage:sideImage })
        }
      });
  }

  getAddressObject = (add_component) => {
    const ShouldBeComponent = {
      street_number: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      province: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      country: ["country"],
    };

    let address = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: "",
    };

    add_component.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });

    // Fix the shape to match our schema
    address.address = address.street_number + " " + address.street;
    delete address.street_number;
    delete address.street;
    if (address.country === "US") {
      address.state = address.province;
      delete address.province;
    }
    return address;
  };

  sellGiftCard = () => {
    const path ="/" + localStorage.getItem("tenant") + "/gift-card";
    this.props.history.push(path);
  }

  handleRedirect = () => {
    const path = "/" + localStorage.getItem("tenant") + "/products";
    this.props.history.push(path);
  }
  getProducts = (location_id) => {
    syncWithServer(location_id)
      .then((res) => {
        if (res.data.error) {
          if (res.data.error.code === 404) {
            // const path = "/" + localStorage.getItem("tenant") + "/404";
            this.props.history.push('/404');
          }
        } else if (res.data.flag) {
          if (
            null != res.data.data.categoryWiseProducts &&
            undefined != res.data.data.categoryWiseProducts &&
            "" != res.data.data.categoryWiseProducts &&
            res.data.data.categoryWiseProducts.length > 0
          ) {
            res.data.data.categoryWiseProducts.sort(function(a, b) {
              return (a.online_ordering_sort != null ? a.online_ordering_sort : Infinity) - (b.online_ordering_sort != null ? b.online_ordering_sort : Infinity) ||  a.name.localeCompare(b.name)
            })
            // localStorage.setItem("products",res.data.data.categoryWiseProducts.length)
            if (
              null != res.data.data.featuredProducts &&
              undefined != res.data.data.featuredProducts &&
              "" != res.data.data.featuredProducts &&
              res.data.data.featuredProducts.length > 0
            ) {
              this.props.viewFeaturedProducts(res.data.data.featuredProducts);
              this.props.viewProducts(res.data.data.categoryWiseProducts);
              // this.props.addLocations(this.state.locationDetails,this.state.todays_date);
              this.handleRedirect()
              // localStorage.setItem("featuredProduct",res.data.data.featuredProducts.length)
            } else {
              this.props.viewProducts(res.data.data.categoryWiseProducts);
              // this.props.addLocations(this.state.locationDetails,this.state.todays_date);
              this.handleRedirect()
            }
            this.setState({ loading: false });
            // setTimeout(() => {
            
            // this.props.history.push(`${localStorage.getItem("tenant")}/products`);
            // }, 5000);
          }
        } else {
          // toast.error(res.data.message);
          this.setState({ loading: false, showMessage: res.data.message });
        }
      })
      .catch((error) => {
        // toast.error(error.toString());
        this.setState({ loading: false, showMessage: error.toString() });
      });
  };

  startOrder = () => {
    this.setState({ loading: true });
    if (this.state.value == "delivery") {
      if (
        "" != this.state.selectedDeliveryLocation &&
        null != this.state.selectedDeliveryLocation &&
        undefined != this.state.selectedDeliveryLocation
      ) {
        if (
          "" != this.state.deliveryAddress &&
          null != this.state.deliveryAddress &&
          undefined != this.state.deliveryAddress
        ) {
          if (
            null != this.state.addressObj &&
            undefined != this.state.addressObj &&
            "" != this.state.addressObj
          ) {
            if (this.state.s_Order === "custom") {
              if (
                null != this.state.deliveryTime &&
                undefined != this.state.deliveryTime &&
                "" != this.state.deliveryTime
              ) {
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
              });
                let deliverydetails = {
                  order_type: this.state.value,
                  location: this.state.selectedDeliveryLocation,
                  address: this.state.addressObj.address,
                  city: this.state.addressObj.city,
                  state: this.state.addressObj.state,
                  zipCode: this.state.addressObj.postal_code,
                  country: this.state.addressObj.country,
                  scheduleOrder: this.state.s_Order,
                  DeliveryTime: this.state.deliveryTime,
                };
                this.props.addDeliveryPickupDetails(deliverydetails);
                this.getProducts(this.state.selectedDeliveryLocation.id);
              } else {
                // toast.error(Constant.DELIVERY_TIME_SELECT_ERROR);
                this.setState({ loading: false, showMessage: Constant.DELIVERY_TIME_SELECT_ERROR });
              }
            } else {
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
              let deliverydetails = {
                order_type: this.state.value,
                location: this.state.selectedDeliveryLocation,
                address: this.state.addressObj.address,
                city: this.state.addressObj.city,
                state: this.state.addressObj.state,
                zipCode: this.state.addressObj.postal_code,
                country: this.state.addressObj.country,
                scheduleOrder: this.state.s_Order,
                DeliveryTime: this.state.deliveryTime,
              };
              this.props.addDeliveryPickupDetails(deliverydetails);
              this.getProducts(this.state.selectedDeliveryLocation.id);
            }
          }
        } else {
          // toast.error(Constant.DELIVERY_ADDRESS_ZIPCODE_NOT_FOUND);
          this.setState({ loading: false, showMessage: Constant.DELIVERY_ADDRESS_ZIPCODE_NOT_FOUND });
        }
      } else {
        let message = Constant.DELIVERY_ADDRESS_LOCATION_NOT_FOUND
        for(let i=0 ; i < this.state.delivery_Area.length ; i++) {
          if(i == (this.state.delivery_Area.length - 1 )){
            message = message + this.state.delivery_Area[i] 
          } else {
            message = message + this.state.delivery_Area[i] + ","
          }
        }
        // toast.error(message);
        this.setState({ loading: false, showMessage: message  });
      }
    } else {
      let delivery_Location = "";
      this.state.locationDetails.map((location) => {
        if (location.id == this.state.pickUpAddress) {
          delivery_Location = location;
        }
      });
      if (
        null != delivery_Location &&
        undefined != delivery_Location &&
        "" != delivery_Location
      ) {
        if (this.state.s_Order === "custom") {
          if (
            null != this.state.deliveryTime &&
            undefined != this.state.deliveryTime &&
            "" != this.state.deliveryTime
          ) {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
          });
            let deliverydetails = {
              order_type: this.state.value,
              location: delivery_Location,
              address: delivery_Location.address1,
              city: delivery_Location.city,
              state: delivery_Location.state,
              zipCode: delivery_Location.zipcode,
              country: delivery_Location.country,
              scheduleOrder: this.state.s_Order,
              DeliveryTime: this.state.deliveryTime,
            };
            this.props.addDeliveryPickupDetails(deliverydetails);
            this.getProducts(delivery_Location.id);
          } else {
            // toast.error(Constant.PICKUP_TIME_SELECT_ERROR);
            this.setState({ loading: false, showMessage: Constant.PICKUP_TIME_SELECT_ERROR });
          }
        } else {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
          let deliverydetails = {
            order_type: this.state.value,
            location: delivery_Location,
            address: delivery_Location.address1,
            city: delivery_Location.city,
            state: delivery_Location.state,
            zipCode: delivery_Location.zipcode,
            country: delivery_Location.country,
            scheduleOrder: this.state.s_Order,
            DeliveryTime: this.state.deliveryTime,
          };
          this.props.addDeliveryPickupDetails(deliverydetails);
          this.getProducts(delivery_Location.id);
        }
      } else {
        // toast.error(Constant.PICKUP_LOCATION_NOT_FOUND);
        this.setState({ loading: false, showMessage: Constant.PICKUP_LOCATION_NOT_FOUND  });
      }
    }
    //
  };

  handleChangeOrderType = (event) => {
    this.setState({
      value: event.target.value,
      timeSlot: [],
      address: "",
      pickUpAddress: "",
      s_Order: "asap",
      addressObj: "",
      showMessage:""
    });
  };
  handleChangeScheduleOrder = (event) => {
    this.setState({ s_Order: event.target.value });
    if(event.target.value == 'asap'){
      this.setState({ deliveryTime: '' });
      this.setState({ disabledFlag: 0 });

    }
    else{
      this.setState({ disabledFlag: 1 });
    }
  };

  selectDeliveryTime = (newValue) => {
    if (null != newValue) {
      this.setState({ deliveryTime: newValue.id });
      this.setState({ disabledFlag: 0 });
    } else {
      this.setState({ disabledFlag: 1,deliveryTime: "" });
    }
  };

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
  };
    this._isMounted = false;
  }

  selectPickAddress = (newValue) => {
    if(null != newValue) {
    this.setState({ pickUpAddress: newValue.location_id, s_Order: "asap"  });
      if (this.state.pickupLocations.length > 0) {
        this.state.pickupLocations.map((location) => {
          if (location.id == newValue.location_id) {
            location.location_online_order_settings.map((online_settings) => {
              let final_time;
              let addtimeToday;
              if (this.state.value === "pickup") {
                let array = online_settings.pickup_lead_time.split(":");
                addtimeToday = moment()
                  .add(array[2], "seconds")
                  .add(array[1], "minutes")
                  .add(array[0], "hours")
                  .format("ddd");
                // final_time = moment()
                //   .add(array[2], "seconds")
                //   .add(array[1], "minutes")
                //   .add(array[0], "hours")
                //   .format("HH:mm:ss");
                final_time = moment()
                  .add(array[2], "seconds")
                  .add(array[1], "minutes")
                  .add(array[0], "hours")
                  .format("hh:mm A");
              }
              if (online_settings.location_hrs_sub_slot.length > 0) {
                let current_time = moment().format("hh:mm A")
                let store_open_time = online_settings.location_hrs_sub_slot[0].from_time
                let store_close_time = online_settings.location_hrs_sub_slot[online_settings.location_hrs_sub_slot.length - 1].to_time

                if(moment(moment(current_time, ["h:mm A"])).isBefore(moment(store_open_time, ["h:mm A"]))) {
                  this.setState({ timeSlot: [], showMessage: Constant.STORE_OPEN_BEFORE_ORDER_START});
                } else if(moment(moment(current_time, ["h:mm A"])).isAfter(moment(store_close_time, ["h:mm A"]))) {
                  this.setState({ timeSlot: [], showMessage: Constant.STORE_CLOSE_AFTER_ORDER_START});
                } else {
                  let time_slot = [];
                  let i = 1;
                  if (
                    online_settings.location_hrs_sub_slot[0].day ==
                    addtimeToday.toUpperCase()
                  ) {
                    online_settings.location_hrs_sub_slot.map((timeSlot) => {
                      let dateIsAfter = moment(timeSlot.from_time, [
                        "h:mm A",
                      ]).isAfter(moment(final_time, ["h:mm A"]));
                      if (dateIsAfter) {
                        if (i == 1) {
                          this.setState({ asapTimeSlot: timeSlot.id });
                          i = 0;
                        }
                        let obj = {
                          id: timeSlot.id,
                          time: timeSlot.from_time + " - " + timeSlot.to_time,
                        };
                        time_slot.push(obj);
                      }
                    });
                    if(time_slot.length > 0) {
                      this.setState({ timeSlot: time_slot, showMessage: "" });
                    } else {
                      this.setState({ timeSlot: [] , showMessage: Constant.PICKUP_TIME_SLOTE_NOT_FOUND });
                    }
                  } else {
                    // toast.error(Constant.DAY_CHANGE_TIME_SLOTE_NOT_AVAILABLE);
                    this.setState({ timeSlot: [],showMessage: Constant.DAY_CHANGE_TIME_SLOTE_NOT_AVAILABLE});
                  }
                }
              }
            });
          }
        });
      }
    } else {
      this.setState({ pickUpAddress: '',s_Order: "asap", deliveryTime:"",timeSlot:[], showMessage: '' });
    }
    
  };

  setValue = async (val) => {
    this.setState({ address: val });
    if (null == val) {
      this.setState({ showMessage: "",  deliveryTime:"",timeSlot:[],s_Order: "asap"})
    }
    const geocodeObj =
      val && val.value && (await geocodeByPlaceId(val.value.place_id));
    const addressObject =
      geocodeObj && this.getAddressObject(geocodeObj[0].address_components);
    this.setState({ addressObj: addressObject });
    let i = 1;
    let without_delivery = 0;
    let deliverylocation = "";
    let deliveryZip = "";
    if (
      null != addressObject &&
      undefined != addressObject &&
      "" != addressObject
    ) {
      if (null != addressObject.address && undefined != addressObject.address && " " != addressObject.address) {
        if (null != addressObject.city && undefined != addressObject.city && "" != addressObject.city) {
          if (null != addressObject.state && undefined != addressObject.state && "" != addressObject.state) { 
            if (null != addressObject.country && undefined != addressObject.country && "" != addressObject.country) { 
              if (null != addressObject.postal_code && undefined != addressObject.postal_code && "" != addressObject.postal_code) { 
                if (this.state.DeliveryLocation.length > 0) {
                  this.state.DeliveryLocation.map((location) => {
                    if (i === 1) {
                      location.location_online_order_settings.map((location_settings) => {
                        if (location_settings.is_delivery == 1) {
                              if (location_settings.delivery_zips.length > 0) {
                                location_settings.delivery_zips.map((zips) => {
                                  if (
                                    zips.zipcode.toString() == addressObject.postal_code
                                  ) {
                                    deliveryZip = zips.zipcode;
                                    deliverylocation = location;
                                    let final_time;
                                    let addtimeToday;
                                    if (this.state.value === "delivery") {
                                      let array =
                                        location_settings.delivery_lead_time.split(":");
                                      addtimeToday = moment()
                                        .add(array[2], "seconds")
                                        .add(array[1], "minutes")
                                        .add(array[0], "hours")
                                        .format("ddd");
                                      final_time = moment()
                                        .add(array[2], "seconds")
                                        .add(array[1], "minutes")
                                        .add(array[0], "hours")
                                        .format("hh:mm A");
                                    }
                                    if (
                                      location_settings.location_hrs_sub_slot.length > 0
                                    ) {
                                      let current_time = moment().format("hh:mm A")
                                      let store_open_time = location_settings.location_hrs_sub_slot[0].from_time
                                      let store_close_time = location_settings.location_hrs_sub_slot[location_settings.location_hrs_sub_slot.length - 1].to_time
                if(moment(moment(current_time, ["h:mm A"])).isBefore(moment(store_open_time, ["h:mm A"]))) {
                  this.setState({ timeSlot: [], showMessage: Constant.STORE_OPEN_BEFORE_ORDER_START});
                } else if(moment(moment(current_time, ["h:mm A"])).isAfter(moment(store_close_time, ["h:mm A"]))) {
                  this.setState({ timeSlot: [], showMessage: Constant.STORE_CLOSE_AFTER_ORDER_START});
                } else {
                                      let time_slot = [];
                                      let j = 1;
                                      if (
                                        location_settings.location_hrs_sub_slot[0].day ==
                                        addtimeToday.toUpperCase()
                                      ) {
                                        location_settings.location_hrs_sub_slot.map(
                                          (timeSlot) => {
                                            let dateIsAfter = moment(timeSlot.from_time, [
                                              "h:mm A",
                                            ]).isAfter(moment(final_time, ["h:mm A"]));
                                            if (dateIsAfter) {
                                              if (j == 1) {
                                                this.setState({
                                                  asapTimeSlot: timeSlot.id,
                                                });
                                                j = 0;
                                              }
                                              let obj = {
                                                id: timeSlot.id,
                                                time:
                                                  timeSlot.from_time +
                                                  " - " +
                                                  timeSlot.to_time,
                                              };
                                              time_slot.push(obj);
                                            }
                                          }
                                        );
                                        if(time_slot.length > 0) {
                                          this.setState({ timeSlot: time_slot, deliveryAddress: deliveryZip,
                                            selectedDeliveryLocation: deliverylocation,showMessage:"" });
                                        } else {
                                          this.setState({ timeSlot: [], showMessage: Constant.DELIVERY_TIME_SLOTE_NOT_FOUND });
                                        }
                                      } else {
                                        this.setState({ timeSlot: [], showMessage: Constant.DAY_CHANGE_TIME_SLOTE_NOT_AVAILABLE });
                                      }
                                    }
                                    }
                                    i = 0;
                                  } else {

                                  }
                                });
                              }
                        } else {
                          without_delivery += 1;
                        }
                      });
                    }
                  });
                  if(i == 1) {
                    let message = Constant.DELIVERY_ADDRESS_LOCATION_NOT_FOUND
                    for(let i=0 ; i < this.state.delivery_Area.length ; i++) {
                      if(i == (this.state.delivery_Area.length - 1 )){
                        message = message + this.state.delivery_Area[i] 
                      } else {
                        message = message + this.state.delivery_Area[i] + ","
                      }
                  }
                  if(this.state.delivery_Area.length > 0) {
                    this.setState({ showMessage: message })
                  } else {
                    this.setState({ showMessage: Constant.DELIVERY_ADDRESS_ZIPCODES_NOT_FOUND })
                  }
                  }
                  
                } else {
                  // toast.error(Constant.DELIVERY_LOCATIONS_NOT_AVAILABLE);
                  this.setState({ showMessage: Constant.DELIVERY_LOCATIONS_NOT_AVAILABLE })
                }
              } else {
                  let message = Constant.ENTER_DELIVERY_ADDRESS_ZIPCODE_NOT_AVAILABLE
                  for(let i=0 ; i < this.state.delivery_Area.length ; i++) {
                      if(i == (this.state.delivery_Area.length - 1 )){
                        message = message + this.state.delivery_Area[i] 
                      } else {
                        message = message + this.state.delivery_Area[i] + ","
                      }
                  }
                  if(this.state.delivery_Area.length > 0) {
                    this.setState({ showMessage: message })
                  } else {
                    this.setState({ showMessage: Constant.DELIVERY_ADDRESS_ZIPCODES_NOT_FOUND })
                  }
              }
            } else {
              this.setState({ showMessage: Constant.DELIVERY_ADDRESS_COUNTRY_NOT_FOUND})
            }
          } else {
            this.setState({ showMessage: Constant.DELIVERY_ADDRESS_STATE_NOT_FOUND })
          }
        } else {
          this.setState({ showMessage: Constant.DELIVERY_ADDRESS_CITY_NOT_FOUND})
        }
      } else {
        this.setState({ showMessage: Constant.DELIVERY_ADDRESS_NOT_FOUND})
      }
      
    } else {
      this.setState({ showMessage: "", deliveryTime:""})
    }

    // if (
    //   null != deliverylocation &&
    //   undefined != deliverylocation &&
    //   "" != deliverylocation
    // ) { 
      // if(null != deliveryZip &&
      // undefined != deliveryZip &&
      // "" != deliveryZip) {
      //   if (null != addressObject.address && undefined != addressObject.address && " " != addressObject.address) {
      //     if (
      //       null != deliverylocation &&
      //       undefined != deliverylocation &&
      //       "" != deliverylocation
      //     ) { 
      //     this.setState({
      //       deliveryAddress: deliveryZip,
      //       selectedDeliveryLocation: deliverylocation,
      //     });
      //   } else {
      //       this.setState({ showMessage: Constant.DELIVERY_ADDRESS_LOCATION_NOT_FOUND})
      //   }
      //   } else {
      //     this.setState({ showMessage: Constant.DELIVERY_ADDRESS_NOT_FOUND})
      //   }   
      // } else {
      //   if(null == val) {
      //     this.setState({ showMessage: ""})
      //   } else {
      //        this.setState({ showMessage: Constant.DELIVERY_ADDRESS_ZIPCODE_NOT_FOUND})
      //   }
      // }
  };
  handleMenuClick = (e) => {
    this.props.removeUserCardDetails();
    this.props.userLogout();
    localStorage.removeItem("token");
  };
  handleClick = (e) => {
    this.setState({ current: e.key });
  };
  handleClick1=(e)=>{
    this.setState({anchorEl:e.currentTarget,open:true})
  }
  handleClose=()=>{
    this.setState({anchorEl:null,open:false})
  }
  render() {
    const arr_pickAddress = [];
    const arr_time_slot = [];
    const { classes } = this.props;
    const { current } = this.state;
    this.state.pickupLocations.map((location, index) => {
      var obj = {};
      obj.address =
        location.address1 +
        ", " +
        location.city +
        ", " +
        location.country +
        ", " +
        location.zipcode;
      obj.location_id = location.id;
      arr_pickAddress.push(obj);
    });
    this.state.timeSlot.map((time, index) => {
      var obj_time = {};
      obj_time.time = time.time;
      obj_time.id = time.id;
      arr_time_slot.push(obj_time);
    });
    let buttonDisable = ""
    let giftcardButton=false
    if(this.state.value === "delivery") {
      if(this.state.DeliveryLocation.length === 0) {
        buttonDisable = true
      } else if(this.state.addressObj === "" ||this.state.addressObj === null || this.state.showMessage.length > 0) {
        buttonDisable = true
      } else {
        if(this.state.s_Order === "asap") {
          if(this.state.loading) {
            buttonDisable = true
            giftcardButton = true
          } else {
            buttonDisable = false
          }
        } else if (this.state.s_Order === "custom") {
          if(this.state.loading) {
            buttonDisable = true
            giftcardButton = true
          } else if(this.state.deliveryTime) {
            buttonDisable = false
          } else {
            buttonDisable = true
          }
        }
      }
    } else if (this.state.value === "pickup") {
      if(null === this.state.pickUpAddress || "" === this.state.pickUpAddress || undefined === this.state.pickUpAddress || this.state.showMessage.length > 0) {
        buttonDisable = true
      } else {
        if(this.state.s_Order === "asap") {
          if(this.state.loading) {
            buttonDisable = true
            giftcardButton = true
          } else {
            buttonDisable = false
          }
        } else if (this.state.s_Order === "custom") {
          if(this.state.loading) {
            buttonDisable = true
            giftcardButton = true
          } else if(this.state.deliveryTime) {
            buttonDisable = false
          }  else {
            buttonDisable = true
          }
        }
      }
    }
    return (
      <>
      {/* <LinearProgress color="primary" style={{zIndex:3}}/> */}
        {this.state.loading ? <LinearProgress color="primary" style={{zIndex:3}}/> : null}
        <Layout className="layout-default layout-signin">
          <Header>
            <div className="header-brand" key={"brandName"} style={{width:'50%'}}>
              {/* <img src={'data:image/png;base64,'+this.state.image_loc} alt=""  height={40} /> */}
            </div>
            <div className="header-col header-nav" key={"signIn"}style={{width:'50%',alignSelf:'center'}}>
            {localStorage.getItem("token") != null &&
              localStorage.getItem("token") != undefined &&
              localStorage.getItem("token") != "" &&
              this.props.user?.user ? ( 
                <div>
              <Box sx={{ display: 'flex',justifyContent:'flex-end'}}>
                    <IconButton
                      onClick={this.handleClick1}
                      size="small"
                      aria-controls={this.state.anchorEl ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={this.state.anchorEl ? 'true' : undefined}
                    >

                  {this.props.user.user.last_name != null &&
                        this.props.user.user.last_name != "" &&
                        this.props.user.user.last_name != undefined ? (
                          <Avatar sx={{ width: 32, height: 32 }}>
                            {this.props.user.user.first_name[0].toUpperCase() +
                              this.props.user.user.last_name[0].toUpperCase()}
                          </Avatar>
                        ) : (
                          <Avatar sx={{ width: 32, height: 32 }}>
                            {this.props.user.user.first_name[0].toUpperCase()}
                          </Avatar>
                        )}
                      {/* <Avatar sx={{ width: 32, height: 32 }}>M</Avatar> */}
                    </IconButton>
                </Box>
      <Menu
        id="simple-menu"
        anchorEl={this.state.anchorEl}
        className="popover_class"
        style={PopoverStyle}
        keepMounted
        open={Boolean(this.state.anchorEl)}
        onClose={this.handleClose}
      >
         <MenuItem onClick={this.handleMenuClick}>
         
          Logout
          {/* <ListItemIcon>
            <ExitToAppIcon style={{ marginLeft: "5%",  fontSize: 25 }} />
          </ListItemIcon> */}
        </MenuItem>
      </Menu></div>) : (
        // null
                <TMenu mode="horizontal">
                  <Item key="logged.in">
                    <Link to={`/${localStorage.getItem("tenant")}/sign-in`}>
                      {" "}
                      Login
                      <AccountCircleIcon
                        style={{ marginLeft: "5%", fontSize: 25 }}
                      />
                    </Link>
                  </Item>
                </TMenu>
              )}
            </div>
          </Header>
          <Content className="signin">
            <Row gutter={[24, 0]} justify="flex-start" flex-direction="column">
              <LinearProgress color="primary" />

              <div className={classes.quoteContainer}>
                <Col
                  className="sign-img"
                  style={{
                    backgroundImage: `url("${this.state.backgroundImage}")`,
                    height: "300px",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '30%',
                     position: 'absolute',
                     left: '0px',
                     top:'0px',
                     height: '100%',
                    boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
                  }}             
                   >
                  {/* <img src="https://img.freepik.com/premium-photo/closeup-drop-water-leaf-flower-reflecting-surrounding-colors-textures_674594-4382.jpg" alt="" /> */}
                </Col>
              </div>
              <div className={classes.quote}>
                <Col
                  // xs={{ span: 24  }}
                  // lg={{ span: 6 }}
                  // md={{ span: 12 }}
                  className="select-order"
                >
                  <div className={classes.imageLogo}>
                  <img src={this.state.image_loc} alt=""  className="location-image"/>
                  </div>
                  {/* <Title className="mb-15">Sign In</Title> */}
                  <Title className="font-regular text-muted" level={3}>
                    Select Order Type
                  </Title>
                  <div
                    layout="vertical"
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      // alignItems: "center",
                      justifyContent: "center",
                      // border: "1px solid rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <FormControl component="fieldset">
                      {/* <FormLabel component="legend">Select Order Type</FormLabel> */}
                      <RadioGroup
                        style={{ padding: "15px" }}
                        row
                        aria-label="position"
                        name="position"
                        defaultValue="delivery"
                        value={this.state.value}
                        onChange={this.handleChangeOrderType}
                      >
                        {this.state.DeliveryLocation.length > 0 ? (
                          <FormControlLabel
                          value="delivery"
                          control={<Radio color="primary" />}
                          label="Delivery"
                          key={"delivery"}
                        />
                        ): null}
                        
                        <FormControlLabel
                          value="pickup"
                          control={<Radio color="primary" />}
                          label="Pickup"
                          key={"Pickup"}
                        />
                      </RadioGroup>
                    </FormControl>
                    {this.state.value === "delivery" && this.state.DeliveryLocation.length > 0 ? (
                      <div
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <GooglePlacesAutocomplete
                          apiKey="AIzaSyBwCbMogALZHWTwVFKRiD_Oljb54a2aJ54"
                          selectProps={{
                            placeholder: "Enter the delivery address",
                            isClearable: true,
                            value: this.state.address,
                            onChange: (val) => {
                              this.setValue(val);
                            },
                          }}
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["us"],
                            },
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        {this.state.pickupLocations.length > 0 ? (
                          <Autocomplete
                            id="size-small-outlined"
                            size="small"
                            options={arr_pickAddress}
                            style={{ marginTop: "10px" }}
                            getOptionSelected={(option, value) => option.address === value.address}
                            getOptionLabel={(option) => option.address}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Select a pickup location"
                                placeholder="Select a pickup location"
                              />
                            )}
                            onChange={(event, newValue) => {
                              this.selectPickAddress(newValue);
                            }}
                          />
                        ) : null}
                      </>
                    )}
                  {this.state.showMessage.length > 0 ? 
                   <MTypography variant="subtitle2"  style={{marginTop:'1%',color:'red',whiteSpace: 'pre-wrap'}}>
                   {this.state.showMessage}
                  </MTypography>: null}

                    <div
                      style={{
                        marginTop: "25px",
                      }}
                    ></div>
                    <Title className="font-regular text-muted" level={3}>
                      Schedule Order
                    </Title>
                    <FormControl component="fieldset">
                      {/* <FormLabel component="legend">Schedule Order</FormLabel> */}
                      <RadioGroup
                        style={{ padding: "15px" }}
                        row
                        aria-label="position"
                        name="position"
                        defaultValue="asap"
                        value={this.state.s_Order}
                        onChange={this.handleChangeScheduleOrder}
                      >
                        <FormControlLabel
                          value="asap"
                          disabled={
                            (this.state.pickUpAddress == "" || this.state.showMessage.length) > 0 &&
                            (this.state.addressObj == "" ||
                            this.state.addressObj == null || this.state.showMessage.length > 0)
                          }
                          control={<Radio color="primary" />}
                          label="ASAP"
                          key={"asap"}
                        />
                        {(this.state.value == "delivery") ? <FormControlLabel
                          value="custom"
                          disabled={
                            (this.state.pickUpAddress == "" || this.state.showMessage.length > 0) &&
                            (this.state.addressObj == "" ||
                              this.state.addressObj == null || this.state.showMessage.length > 0)
                          }
                          control={<Radio color="primary" />}
                          label="Custom Delivery Time"
                          key={"custom"}
                        /> : <FormControlLabel
                        value="custom"
                        disabled={
                          (this.state.pickUpAddress == "" || this.state.showMessage.length > 0) &&
                          (this.state.addressObj == "" ||
                            this.state.addressObj == null || this.state.showMessage.length > 0)
                        }
                        control={<Radio color="primary" />}
                        label="Custom Pickup Time"
                        key={"custom"}
                      />}
                        
                      </RadioGroup>
                    </FormControl>

                    {this.state.s_Order === "custom" ? (
                      <>
                        {this.state.timeSlot.length > 0 ? (
                          (this.state.value == "delivery") ?
                          <Autocomplete
                            id="size-small-outlined"
                            size="small"
                            options={arr_time_slot}
                            style={{ marginTop: "5px" }}
                            getOptionSelected={(option, value) => option.time === value.time}
                            getOptionLabel={(option) => option.time}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Select a delivery time"
                                placeholder="Select a delivery time"
                              />
                            )}
                            onChange={(event, newValue) => {
                              this.selectDeliveryTime(newValue);
                            }}
                          /> :
                          <Autocomplete
                            id="size-small-outlined"
                            size="small"
                            options={arr_time_slot}
                            style={{ marginTop: "5px" }}
                            getOptionLabel={(option) => option.time}
                            getOptionSelected={(option, value) => option.time === value.time}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Select a pickup time"
                                placeholder="Select a pickup time"
                              />
                            )}
                            onChange={(event, newValue) => {
                              this.selectDeliveryTime(newValue);
                            }}
                          />
                        ) : null}
                      </>
                    ) : null}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.startOrder}
                      style={{ marginTop: "30px" }}
                      disabled={buttonDisable }
                    >
                      Start Order
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.sellGiftCard}
                      style={{ marginTop: "30px" }}
                      disabled={giftcardButton}
                    >
                      Buy a Gift Card
                    </Button>
             <Footer/>
                  </div>
                </Col>
              </div>
            </Row>
          </Content>
        </Layout>
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addDeliveryPickupDetails,
      removeProducts,
      removeDeliveryPickupDetails,
      viewProducts,
      addLocations,
      removeLocations,
      userLogout,
      removeUserCardDetails,
      viewFeaturedProducts,
      removeFeaturedProducts,
      clearItems,
      currentItem, removeCurrentItem
    },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    delivery_pickup: state.delivery_pickup,
    user: state.user,
    category_products: state.category_products,
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(DeliveryDetails);
