import React from "react";
import OrderItems from "./myBags/OrderItems";
import OrderTotals from "./myBags/OrderTotal";
import DeliveryPickUp from "./delivery/deliverypickUp";
import OrderItemsDetails from "./product/orderItemDetails";
import CreateUser from "../orderItems/user/createUser";
import SignIn from "../orderItems/user/signIn";
import CheckOut from "../orderItems/checkOut/checkOut";
import WhoIsThisFor from "../orderItems/checkOut/whoIsThisFor";
import PickUp from "../orderItems/checkOut/pickUp";
import DeliveryTime from "../orderItems/checkOut/deliveryTime";
import {  withStyles } from "@material-ui/styles";
import { currentItem, removeCurrentItem } from "../../../actions/current_item";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import UserCardDetails from "../orderItems/checkOut/userCardDetails";
import OrderStatus from "./orderStatus/orderStatus";
import Instruction from "./checkOut/instructions";
import AddRewardCard from "./checkOut/rewardCardAdd"
import ForgotPassword from "./user/forgotPassword"
import GiftCard from './checkOut/giftCard'
import SellGiftCard from './delivery/SellGiftCard'
const useStyles = (theme) => ({
  layoutContainer:{
    height: "calc(100vh - 12%)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  }
});

class OrderItemsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userCreate: false,
      openSignInLayout: false,
      CkeckOutLayout: false,
      DeliveryPickupLayout: false,
      scheduleTimeLayout: false,
      instructionsLayout: false,
      userCardLayout: false,
      whoIsThisLayout: false,
      orderStatusLayout: false,
      addRewardCardLayout: false,
      ForgotPasswordLayout: false,
      GiftCardLayout: false,
      SellGiftCardLayout: false,
      backLayout: "",
      payload:{}
    };
  }
  componentDidMount() {}

  componentWillUnmount() {}

  continueCheckOut = () => {
    let token = localStorage.getItem("token");

    if (null !== token && undefined !== token && "" !== token && (this.props.user?.user)) {
      this.setState({
        CkeckOutLayout: true,
        openSignInLayout: false,
        userCreate: false,
      });
    } else {
      this.setState({
        userCreate: true,
        openSignInLayout: false,
        CkeckOutLayout: false,
      });
    }
  };

  signIn = () => {
    this.setState({ openSignInLayout: true, userCreate: false });
  };

  createUser = () => {
    this.setState({ userCreate: true, openSignInLayout: false });
  };

  checkuoutInsidePage = (type,key) => {
    if(type == 'orderItemDetails'){
      this.props.onPressOut()
    }
    if(null !== key && undefined !== key && "" !== key){
      this.setState({ backLayout : key})
    } else {
      this.setState({ backLayout : ""})
    }
    if (type === "DeliveryPickup") {
      this.setState({
        userCreate: false,
        openSignInLayout: false,
        CkeckOutLayout: false,
        DeliveryPickupLayout: true,
        scheduleTimeLayout: false,
        instructionsLayout: false,
        userCardLayout: false,
        orderStatusLayout:false,
        ForgotPasswordLayout: false,
        GiftCardLayout: false,
        SellGiftCardLayout: false
      });
    } else if (type === "Time") {
      this.setState({
        userCreate: false,
        openSignInLayout: false,
        CkeckOutLayout: false,
        DeliveryPickupLayout: false,
        scheduleTimeLayout: true,
        instructionsLayout: false,
        userCardLayout: false,
        whoIsThisLayout: false,
        orderStatusLayout:false,
        addRewardCardLayout:false,
        ForgotPasswordLayout: false,
        GiftCardLayout: false,
        SellGiftCardLayout: false
      });
    } else if (type === "Instructions") {
      this.setState({
        userCreate: false,
        openSignInLayout: false,
        CkeckOutLayout: false,
        DeliveryPickupLayout: false,
        scheduleTimeLayout: false,
        instructionsLayout: true,
        userCardLayout: false,
        whoIsThisLayout: false,
        orderStatusLayout:false,
        addRewardCardLayout:false,
        ForgotPasswordLayout: false,
        GiftCardLayout: false,
        SellGiftCardLayout: false
      });
    } else if (type === "userCard") {
      this.setState({
        userCreate: false,
        openSignInLayout: false,
        CkeckOutLayout: false,
        DeliveryPickupLayout: false,
        scheduleTimeLayout: false,
        instructionsLayout: false,
        userCardLayout: true,
        whoIsThisLayout: false,
        orderStatusLayout:false,
        addRewardCardLayout:false,
        ForgotPasswordLayout: false,
        GiftCardLayout: false,
        SellGiftCardLayout: false
      });
    } else if (type === "checkout") {
      this.setState({
        userCreate: false,
        openSignInLayout: false,
        CkeckOutLayout: true,
        DeliveryPickupLayout: false,
        scheduleTimeLayout: false,
        instructionsLayout: false,
        userCardLayout: false,
        whoIsThisLayout: false,
        orderStatusLayout:false,
        addRewardCardLayout:false,
        ForgotPasswordLayout: false,
        GiftCardLayout: false,
        SellGiftCardLayout: false
      });
    } else if (type === "whoisthis") {
      this.setState({
        userCreate: false,
        openSignInLayout: false,
        CkeckOutLayout: false,
        DeliveryPickupLayout: false,
        scheduleTimeLayout: false,
        instructionsLayout: false,
        userCardLayout: false,
        whoIsThisLayout: true,
        orderStatusLayout:false,
        addRewardCardLayout:false,
        ForgotPasswordLayout: false,
        GiftCardLayout: false,
        SellGiftCardLayout: false
      });
    } else if (type === "orderStatus") {
      this.setState({
        userCreate: false,
        openSignInLayout: false,
        CkeckOutLayout: false,
        DeliveryPickupLayout: false,
        scheduleTimeLayout: false,
        instructionsLayout: false,
        userCardLayout: false,
        whoIsThisLayout: false,
        orderStatusLayout:true,
        addRewardCardLayout:false,
        ForgotPasswordLayout: false,
        GiftCardLayout:false,
        SellGiftCardLayout: false
      });
    } else if (type === "OrderItem") {
      this.setState({
        userCreate: false,
        openSignInLayout: false,
        CkeckOutLayout: false,
        DeliveryPickupLayout: false,
        scheduleTimeLayout: false,
        instructionsLayout: false,
        userCardLayout: false,
        whoIsThisLayout: false,
        orderStatusLayout:false,
        addRewardCardLayout:false,
        ForgotPasswordLayout: false,
        GiftCardLayout:false,
        SellGiftCardLayout: false
      });
    } else if (type === "userCreate") {
      this.setState({
        userCreate: true,
        openSignInLayout: false,
        CkeckOutLayout: false,
        DeliveryPickupLayout: false,
        scheduleTimeLayout: false,
        instructionsLayout: false,
        userCardLayout: false,
        whoIsThisLayout: false,
        orderStatusLayout:false,
        addRewardCardLayout:false,
        ForgotPasswordLayout: false,
        GiftCardLayout:false,
        SellGiftCardLayout: false
      });
    } else if (type === "rewardCardAdd") {
      this.setState({
        userCreate: false,
        openSignInLayout: false,
        CkeckOutLayout: false,
        DeliveryPickupLayout: false,
        scheduleTimeLayout: false,
        instructionsLayout: false,
        userCardLayout: false,
        whoIsThisLayout: false,
        orderStatusLayout:false,
        addRewardCardLayout:true,
        ForgotPasswordLayout: false,
        GiftCardLayout:false,
        SellGiftCardLayout: false
      });
    } else if (type === "signIn") {
      this.setState({
        userCreate: false,
        openSignInLayout: true,
        CkeckOutLayout: false,
        DeliveryPickupLayout: false,
        scheduleTimeLayout: false,
        instructionsLayout: false,
        userCardLayout: false,
        whoIsThisLayout: false,
        orderStatusLayout:false,
        addRewardCardLayout:false,
        ForgotPasswordLayout: false,
        GiftCardLayout:false,
        SellGiftCardLayout: false
      });
    } else if (type === "forgotPassword") {
      this.setState({
        userCreate: false,
        openSignInLayout: false,
        CkeckOutLayout: false,
        DeliveryPickupLayout: false,
        scheduleTimeLayout: false,
        instructionsLayout: false,
        userCardLayout: false,
        whoIsThisLayout: false,
        orderStatusLayout:false,
        addRewardCardLayout:false,
        ForgotPasswordLayout: true,
        GiftCardLayout:false,
        SellGiftCardLayout: false
      });
    } else if (type === "giftCard") {
      this.setState({
        userCreate: false,
        openSignInLayout: false,
        CkeckOutLayout: false,
        DeliveryPickupLayout: false,
        scheduleTimeLayout: false,
        instructionsLayout: false,
        userCardLayout: false,
        whoIsThisLayout: false,
        orderStatusLayout:false,
        addRewardCardLayout:false,
        ForgotPasswordLayout: false,
        GiftCardLayout: true,
        SellGiftCardLayout: false
      });
    } else if (type === "SellGiftCard") {
      this.setState({
        userCreate: false,
        openSignInLayout: false,
        CkeckOutLayout: false,
        DeliveryPickupLayout: false,
        scheduleTimeLayout: false,
        instructionsLayout: false,
        userCardLayout: false,
        whoIsThisLayout: false,
        orderStatusLayout:false,
        addRewardCardLayout:false,
        ForgotPasswordLayout: false,
        GiftCardLayout: false,
        SellGiftCardLayout: true
      });
    } else {
      this.setState({
        userCreate: false,
        openSignInLayout: false,
        CkeckOutLayout: false,
        DeliveryPickupLayout: false,
        scheduleTimeLayout: false,
        instructionsLayout: false,
        userCardLayout: false,
        whoIsThisLayout: false,
        orderStatusLayout:false,
        addRewardCardLayout: false,
        ForgotPasswordLayout: false,
        GiftCardLayout:false,
        SellGiftCardLayout: false
      });
    }
  };

  addQty = () => {
    if (this.state.item_qty < 99999) {
      let qty = this.state.item_qty + 1;
      this.setState({ item_qty: qty });
    }
  };
  minusQty = () => {
    if (this.state.item_qty > 1) {
      let qty = this.state.item_qty - 1;
      this.setState({ item_qty: qty });
    }
  };
  openDrawer=()=>{
    this.props.onPress()
  }
  render() {
    const { classes } = this.props;

    // eslint-disable-next-line
    if (this.props.current_item.length) {
      return (
        <>
          <div
            layout="vertical"
            className="layoutContainer"
          >
            <OrderItemsDetails onClose={() => this.props.onClose()} checkOutInsidePage={this.checkuoutInsidePage} layout={this.state.backLayout} openDrawer={this.openDrawer}/>
          </div>
        </>
      );
    } else if (
      this.props.order_items.length > 0 &&
      this.props.current_item.length === 0 &&
      this.state.userCreate === false &&
      this.state.openSignInLayout === false &&
      this.state.CkeckOutLayout === false &&
      this.state.DeliveryPickupLayout === false &&
      this.state.instructionsLayout === false &&
      this.state.userCardLayout === false &&
      this.state.scheduleTimeLayout === false && 
      this.state.whoIsThisLayout === false &&
      this.state.addRewardCardLayout === false &&
      this.state.ForgotPasswordLayout === false &&
      this.state.GiftCardLayout === false &&
      this.state.SellGiftCardLayout === false
    ) {
      return (
        <>
          <div
            layout="vertical"
            className="layoutContainer"
          >
            <OrderItems />
            <OrderTotals continueCheckOut={this.continueCheckOut} checkOutInsidePage={this.checkuoutInsidePage}/>
          </div>
        </>
      );
    } else if (this.state.userCreate) {
      return (
        <>
          <div
            layout="vertical"
            className="layoutContainer"
          >
            <CreateUser signIn={this.signIn} continueCheckOut={this.continueCheckOut} checkOutInsidePage={this.checkuoutInsidePage}/>
          </div>
        </>
      );
    } else if (this.state.openSignInLayout) {
      return (
        <>
          <div
            layout="vertical"
            className="layoutContainer"
          >
            <SignIn createUser={this.createUser} layout={this.state.backLayout} checkOutInsidePage={this.checkuoutInsidePage}/>
          </div>
        </>
      );
    } else if (this.state.ForgotPasswordLayout) {
      return (
        <>
          <div
            layout="vertical"
            className="layoutContainer"
          >
            <ForgotPassword checkOutInsidePage={this.checkuoutInsidePage}/>
          </div>
        </>
      );
    } else if (this.state.CkeckOutLayout) {
      return (
        <>
          <div
            layout="vertical"
            className="layoutContainer"
          >
            <CheckOut checkOutInsidePage={this.checkuoutInsidePage} />
          </div>
        </>
      );
    } else if (this.state.DeliveryPickupLayout) {
      return (
        <>
          <div
            layout="vertical"
            className="layoutContainer"
          >
            <PickUp checkOutInsidePage={this.checkuoutInsidePage} />
          </div>
        </>
      );
    } else if (this.state.instructionsLayout) {
      return (
        <>
          <div
            layout="vertical"
            className="layoutContainer"
          >
            <Instruction checkOutInsidePage={this.checkuoutInsidePage} layout={this.state.backLayout}/>
          </div>
        </>
      );
    } else if (this.state.scheduleTimeLayout) {
      return (
        <>
          <div
            layout="vertical"
            className="layoutContainer"
          >
            <DeliveryTime checkOutInsidePage={this.checkuoutInsidePage} layout={this.state.backLayout}/>
          </div>
        </>
      );
    } else if (this.state.userCardLayout) {
      return (
        <>
          <div
            layout="vertical"
            className="layoutContainer"
          >
            <UserCardDetails checkOutInsidePage={this.checkuoutInsidePage} />
          </div>
        </>
      );
    } else if (this.state.whoIsThisLayout) {
      return (
        <>
          <div
            layout="vertical"
            className="layoutContainer"
          >
            <WhoIsThisFor checkOutInsidePage={this.checkuoutInsidePage} />
          </div>
        </>
      );
    } else if (this.state.orderStatusLayout) {
      return (
        <>
          <div
            layout="vertical"
            className="layoutContainer"
          >
            <OrderStatus checkOutInsidePage={this.checkuoutInsidePage} payload={this.state.backLayout}/>
          </div>
        </>
      );
    } else if (this.state.addRewardCardLayout) {
      return (
        <>
          <div
            layout="vertical"
            className="layoutContainer"
          >
            <AddRewardCard checkOutInsidePage={this.checkuoutInsidePage} />
          </div>
        </>
      );
    } else if (this.state.GiftCardLayout) {
      return (
        <>
          <div
            layout="vertical"
            className="layoutContainer"
          >
            <GiftCard checkOutInsidePage={this.checkuoutInsidePage} />
          </div>
        </>
      );
    }  else if (this.state.SellGiftCardLayout) {
      return (
        <>
          <div
            layout="vertical"
            className="layoutContainer"
          >
            <SellGiftCard checkOutInsidePage={this.checkuoutInsidePage} layout={this.state.backLayout}/>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            layout="vertical"
            className="layoutContainer"
          >
            <DeliveryPickUp checkOutInsidePage={this.checkuoutInsidePage}/>
          </div>
        </>
      );
    }
   
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ currentItem, removeCurrentItem }, dispatch);
}
function mapStateToProps(state) {
  return {
    order_items: state.order_items,
    categories: state.categories,
    category_products: state.category_products,
    current_item: state.current_item,
    user: state.user,
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles, { withTheme: true })
)(OrderItemsContainer);
