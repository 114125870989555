import React, { Component } from "react";
import { Layout, Typography, Row, Col } from "antd";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  Button,
  TextField,
} from "@material-ui/core";
import { userRegistration } from "../services/user/user";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/images/Octopos-logo-blue.png";
import { LinearProgress } from "@material-ui/core";
import * as Constant from "../constants"
import Footer from "../../src/components/layout/Footer";
import sideImage from "../assets/images/auth13.jpg"

const { Title } = Typography;

const { Header, Content } = Layout;
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const styles = (theme) => ({
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
      width: "0%",
    },
  },
  imageLogo:{
    [theme.breakpoints.down("md")]: {
      textAlign:'center',
    },
    // textAlign:'center',
    paddingBottom:16,
    width:"40%"
  }
});

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView();

  return { executeScroll, elRef };
};

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      last_name: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zip: "",
      email: "",
      phone: "",
      password: "",
      addressObj: "",
      confirmpassword: "",
      errorMessage: "",
      loading: false,
      image_loc:'',
      backgroundImage:"",
      errors: {
        name: "",
        last_name: "",
        address: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        email: "",
        phone: "",
        password: "",
        confirmpassword: "",
      },
    };
    this.inputRefs = []
    this.elScroll = utilizeScroll();
  }
  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
  };
  }
  componentDidMount(){
    let flag_image=false
    let online_location_image = false
    if(null != this.props.locations.allLocations && undefined != this.props.locations.allLocations && '' != this.props.locations.allLocations) {
      this.props.locations.allLocations.map((locations, index) => {
        if(!flag_image && locations.image_url != null && locations.image_url != undefined && locations.image_url != ''){
          
          this.setState({image_loc:locations.image_url})
          flag_image=true
        }
        locations.location_online_order_settings.map((settings, i) => {
          if(!online_location_image && settings.image_url_online != null && settings.image_url_online != undefined && settings.image_url_online != ''){
            online_location_image=true
            this.setState({backgroundImage:settings.image_url_online})
          } else if(!online_location_image && locations.location_online_order_settings.length - 1 == i && this.state.backgroundImage == ""){
            this.setState({backgroundImage:sideImage })
            online_location_image=true
          }
        });
      })
    } else {
      this.setState({backgroundImage:sideImage })
    }
  }
  validate = (phone) => {
    const regex = /^[0-9\b]+$/;
    return regex.test(phone);
  };
  getAddressObject = (add_component) => {
    const ShouldBeComponent = {
      street_number: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      province: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      country: ["country"],
    };

    let address = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: "",
    };

    add_component.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });

    // Fix the shape to match our schema
    address.address = address.street_number + " " + address.street;
    delete address.street_number;
    delete address.street;
    if (address.country === "US") {
      address.state = address.province;
      delete address.province;
    }
    return address;
  };
  setValue = async (val) => {
    this.setState({ address: val });
    if (null == val) {
      this.setState({ addressObj: ""})
    }
    const geocodeObj =
      val && val.value && (await geocodeByPlaceId(val.value.place_id));
    const addressObject =
      geocodeObj && this.getAddressObject(geocodeObj[0].address_components);
    if (
      null != addressObject &&
      undefined != addressObject &&
      "" != addressObject
    ) {
      if (
        null != addressObject.address &&
        undefined != addressObject.address &&
        " " != addressObject.address
      ) {
        if (
          null != addressObject.city &&
          undefined != addressObject.city &&
          "" != addressObject.city
        ) {
          if (
            null != addressObject.state &&
            undefined != addressObject.state &&
            "" != addressObject.state
          ) {
            if (
              null != addressObject.country &&
              undefined != addressObject.country &&
              "" != addressObject.country
            ) {
              if (
                null != addressObject.postal_code &&
                undefined != addressObject.postal_code &&
                "" != addressObject.postal_code
              ) {
                this.setState({ addressObj: addressObject, errorMessage: "" });
              } else {
                this.setState({
                  errorMessage: Constant.USER_ENTER_ADDRESS_ZIPCODE_NOT_FOUND,
                });
              }
            } else {
              this.setState({
                errorMessage: Constant.USER_ENTER_ADDRESS_COUNTRY_NOT_FOUND,
              });
            }
          } else {
            this.setState({
              errorMessage: Constant.USER_ENTER_ADDRESS_STATE_NOT_FOUND,
            });
          }
        } else {
          this.setState({
            errorMessage: Constant.USER_ENTER_ADDRESS_CITY_NOT_FOUND,
          });
        }
      } else {
        this.setState({ errorMessage: Constant.USER_ENTER_ADDRESS_NOT_FOUND });
      }
    } else {
      this.setState({ errorMessage: "" });
    }
  };
  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "name":
        errors.name =
          value.length === 0
            ? Constant.USER_FIRST_NAME_REQUIRED
            : value.length > 255
            ? Constant.USER_FIRST_NAME_LENGTH_GREATE_THEN_255
            : "";
        break;
      case "last_name":
        errors.last_name =
          value.length === 0
            ? Constant.USER_LAST_NAME_REQUIRED
            : value.length > 255
            ? Constant.USER_LAST_NAME_LENGTH_GREATE_THEN_255
            : "";
        break;
      case "email":
        errors.email = value.length === 0 
            ?  Constant.USER_EMAIL_REQUIRED
            : validEmailRegex.test(value) 
            ? value.length < 255 
            ? ""
            : Constant.USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER 
            : Constant.USER_EMAIL_NOT_VALID;
        break;
      case "phone":
        errors.phone =
          value.length === 0
            ? Constant.USER_PHONE_NUMBER_REQUIRED
            : this.validate(value)
            ? value.length > 10
            ? Constant.USER_PHONE_NUMBER_GREATER_THEN_10
            :  value.length < 3
            ? Constant.USER_PHONE_NUMBER_MUST_BE_3_DIGITS
            :  ""
            : Constant.USER_PHONE_NUMBER__NOT_VALID;
        break;
        case "password":
        errors.password =
          value.length === 0
            ? Constant.PASSWORD_FIELD_REQUIRED
            : value.length < 8
            ? Constant.PASSWORD_MUST_BE_8_CHARACHTER
            : "";
        break;
      case "confirmpassword":
        errors.confirmpassword =
          value.length === 0
            ? Constant.CONFIRM_PASSWORD_FIELD_REQUIRED
            : value.length < 8
            ? Constant.CONFIRM_PASSWORD_MUST_BE_8_CHARACHTER
            : this.state.password != value
            ? Constant.PASSWORD_CONFIRM_PASSWORD_NOT_SAME
            : "";
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };
  backToHome = () => {
    this.props.history.push("/" + localStorage.getItem("tenant"));
  };
  addUserInServer = () => {
    this.elScroll.executeScroll()
    this.setState({ loading: true });
    if (
      null != this.state.email &&
      undefined != this.state.email &&
      "" != this.state.email.trim()
    ) {
      if (
        null != this.state.name &&
        undefined != this.state.name &&
        "" != this.state.name.trim()
      ) {
        if (
          null != this.state.last_name &&
          undefined != this.state.last_name &&
          "" != this.state.last_name.trim()
        ) {
          if (
            null != this.state.password &&
            undefined != this.state.password &&
            "" != this.state.password.trim()
          ) {
            if (
              null != this.state.confirmpassword &&
              undefined != this.state.confirmpassword &&
              "" != this.state.confirmpassword.trim() 
            ) {
              if(this.state.confirmpassword.trim() == this.state.password.trim()){
                let payload = {
                  first_name: this.state.name,
                  last_name: this.state.last_name,
                  email: this.state.email,
                  phone: this.state.phone,
                  password: this.state.password,
                  address: this.state.addressObj?.address,
                  city: this.state.addressObj?.city,
                  state: this.state.addressObj?.state,
                  country: this.state.addressObj?.country,
                  zipcode: parseInt(this.state.addressObj?.postal_code),
                };
  
                userRegistration(payload)
                  .then((res) => {
                    if (res.data.error) {
                      if (res.data.error.code === 404) {
                        // const path = "/" + localStorage.getItem("tenant") + "/404";
                        this.props.history.push('/404');
                      }
                    } else if (res.data.flag) {
                      this.setState({ loading: false });
                      toast.success(res.data.message);
                      const path =
                        "/" + localStorage.getItem("tenant") + "/sign-in";
                      this.props.history.push({
                        pathname: path,
                        state: {
                          gift_card: this.props.history.location.state?.gift_card,
                        },
                      });
                    } else {
                      this.setState({ loading: false });                      
                      toast.error(res.data.message);
                    }
                  })
                  .catch((err) => {
                    this.setState({ loading: false });
                    toast.error(err.toString());
                  });
              }else{
                this.setState({ loading: false });
                toast.error(Constant.PASSWORD_CONFIRM_PASSWORD_NOT_SAME);
              }
              
            }
          }
        }
      }
    }
  };
  _handleKeyPress = e => {
    const event = e;
    const { currentTarget } = e;
    if (event.key === "Enter") {
      let inputIndex = this.inputRefs.indexOf(currentTarget);
      if (inputIndex < this.inputRefs.length - 1) {
        this.inputRefs[inputIndex + 1].focus();
      } else {
        this.inputRefs[0].focus();
      }
      event.preventDefault();
    }
  };
  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
  };
  }
  render() {
    const { classes } = this.props;
    let buttonDisable =
    this.state.name == "" ||
    this.state.errors.name.length > 0 ||
    this.state.last_name == "" ||
    this.state.errors.last_name.length > 0 ||
    this.state.email == "" ||
    this.state.errors.email.length > 0 ||
    this.state.phone == "" ||
    this.state.errors.phone.length > 0 ||
    this.state.password == "" ||
    this.state.errors.password.length > 0 ||
    this.state.confirmpassword == "" ||
    this.state.errors.confirmpassword.length > 0 ||
    null == this.state.addressObj ||
    undefined == this.state.addressObj ||
    "" == this.state.addressObj ||
    this.state.errorMessage.length > 0;
    return (
      <>
      <div ref={this.elScroll.elRef}></div>
      {this.state.loading ? <LinearProgress color="primary" /> : null}
        <Layout className="layout-default layout-signin">
          <Header>
            <div className="header-brand" key={"brandName"}>
              {/* <img src={logo} alt=""  height={40} /> */}
            </div>
          </Header>
          <Content className="signin">
            <Row gutter={[24, 0]} justify="flex-start" flex-direction="column">
              <div className={classes.quoteContainer}>
              <Col
                  className="sign-img"
                  style={{
                    backgroundImage: `url("${this.state.backgroundImage}")`,
                    height: "300px",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '30%',
                     position: 'absolute',
                     left: '0px',
                     top:'0px',
                     height: '100%',
                     boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
                  }}             
                   >
                  {/* <img src="https://img.freepik.com/premium-photo/closeup-drop-water-leaf-flower-reflecting-surrounding-colors-textures_674594-4382.jpg" alt="" /> */}
                </Col>
              </div>
              <div className={classes.quote}>
                <Col className="select-order">
                <div className={classes.imageLogo}>
                  <img src={this.state.image_loc} alt=""  className="location-image"/>
                  </div>
                  <Title className="font-regular text-muted" level={2}>
                    Sign Up
                  </Title>
                  <div
                    layout="vertical"
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      autoFocus
                      style={{ marginBottom: "2%" }}
                      error={this.state.errors.name.length == 0 ? false : true}
                      size="small"
                      helperText={this.state.errors.name}
                      label="First Name *"
                      name="name"
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      inputProps={{ onKeyPress: this._handleKeyPress }}
                      inputRef={ref => this.inputRefs.push(ref)}
                      type="text"
                      value={this.state.name}
                      variant="outlined"
                    />
                    <TextField
                      style={{ marginBottom: "2%" }}
                      error={
                        this.state.errors.last_name.length == 0 ? false : true
                      }
                      size="small"
                      helperText={this.state.errors.last_name}
                      label="Last Name *"
                      name="last_name"
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      inputRef={ref => this.inputRefs.push(ref)}
                      ref={"input2"}
                      inputProps={{ onKeyPress: this._handleKeyPress }}
                      type="text"
                      value={this.state.last_name}
                      variant="outlined"
                    />
                    <TextField
                      style={{ marginBottom: "2%" }}
                      error={this.state.errors.email.length == 0 ? false : true}
                      size="small"
                      helperText={this.state.errors.email}
                      label="Email address *"
                      name="email"
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      inputRef={ref => this.inputRefs.push(ref)}
                      ref={"input3"}
                      inputProps={{ onKeyPress: this._handleKeyPress }}
                      type="email"
                      value={this.state.email}
                      variant="outlined"
                    />
                    <TextField
                      style={{ marginBottom: "2%" }}
                      error={this.state.errors.phone.length == 0 ? false : true}
                      size="small"
                      helperText={this.state.errors.phone}
                      label="Phone Number *"
                      name="phone"
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      inputRef={ref => this.inputRefs.push(ref)}
                      ref={"input4"}
                      inputProps={{ onKeyPress: this._handleKeyPress }}
                      type="text"
                      value={this.state.phone}
                      variant="outlined"
                    />
                    <TextField
                      style={{ marginBottom: "2%" }}
                      error={
                        this.state.errors.password.length == 0 ? false : true
                      }
                      size="small"
                      helperText={this.state.errors.password}
                      label="Password *"
                      name="password"
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      inputRef={ref => this.inputRefs.push(ref)}
                      ref={"input5"}
                      inputProps={{ onKeyPress: this._handleKeyPress }}
                      type="password"
                      value={this.state.password}
                      variant="outlined"
                    />
                    <TextField
                      style={{ marginBottom: "2%" }}
                      error={
                        this.state.errors.confirmpassword.length == 0
                          ? false
                          : true
                      }
                      size="small"
                      helperText={this.state.errors.confirmpassword}
                      label="Confirm Password *"
                      name="confirmpassword"
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      inputRef={ref => this.inputRefs.push(ref)}
                      ref={"input6"}
                      // inputProps={{ onKeyPress: this._handleKeyPress }}
                      type="password"
                      value={this.state.confirmpassword}
                      variant="outlined"
                    />
                    <div style={{ marginBottom: "2%" }}>
                      <GooglePlacesAutocomplete
                        apiKey="AIzaSyBwCbMogALZHWTwVFKRiD_Oljb54a2aJ54"
                        selectProps={{
                          placeholder: "Address *",
                          isClearable: true,
                          value: this.state.address,
                          onChange: (val) => {
                            this.setValue(val);
                          },
                        }}
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: ["us"],
                          },
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errorMessage}
                      </span>
                    </div>

                    <p
                      className="font-semibold text-muted text-right lh-1"
                      style={{ marginTop: "2%" }}
                    >
                      Already have an account?{" "}
                      <Link
                        // to={`/${localStorage.getItem("tenant")}/sign-in`}
                        to={{pathname:`/${localStorage.getItem("tenant")}/sign-in`, state: {gift_card: this.props.history.location.state?.gift_card }}}
                        className="text-dark font-bold"
                      >
                        Sign In
                      </Link>
                    </p>
                    <Row gutter={[24, 0]} justify="center">
                      <Col xl={12} xs={12} sm={12} md={12} lg={12}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={this.addUserInServer}
                          style={{ marginBottom: "7px" }}
                          disabled={buttonDisable}
                        >
                          Sign up
                        </Button>
                      </Col>
                      <Col xl={12} xs={12} sm={12} md={12} lg={12}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={this.backToHome}
                          style={{ marginBottom: "7px" }}
                        >
                          Go Home
                        </Button>
                      </Col>
                    </Row>
             <Footer/>

                  </div>
                </Col>
              </div>
            </Row>
          </Content>
        </Layout>
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}
function mapStateToProps(state) {
  return {
    // current_item: state.current_item,
    // order: state.order,
    // order_items: state.order_items,
    // coupon_status: state.coupon_status,
    // weight: state.weight,
    locations:state.locations

  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SignUp);
